import React from "react";

function AppleIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 41.289 50.645"
    >
      <g transform="translate(-2.104)">
        <g fill="#fff" data-name="Group 2649" transform="translate(2.104)">
          <path
            d="M21.19 0h.36a10.845 10.845 0 01-2.731 8.173c-1.626 1.919-3.852 3.781-7.452 3.5a10.519 10.519 0 012.78-7.919A12.007 12.007 0 0121.19 0z"
            data-name="Path 10982"
            transform="translate(9.2)"
          ></path>
          <path
            d="M43.393 30.454v.1a29.466 29.466 0 01-4.217 8.128c-1.608 2.213-3.578 5.191-7.1 5.191-3.04 0-5.059-1.955-8.175-2.008-3.3-.053-5.108 1.635-8.122 2.059h-1.023c-2.213-.32-4-2.073-5.3-3.652A31.993 31.993 0 012.1 21.867V19.6c.234-5.52 2.916-10.008 6.48-12.183a10.944 10.944 0 017.348-1.7 18.484 18.484 0 013.6 1.032 9.942 9.942 0 003.6 1.079 8.638 8.638 0 002.524-.772c2.482-.9 4.915-1.924 8.122-1.441 3.854.583 6.589 2.3 8.28 4.937-3.26 2.075-5.838 5.2-5.4 10.541.397 4.851 3.216 7.688 6.739 9.361z"
            data-name="Path 10983"
            transform="translate(-2.104 6.721)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default AppleIcon;
