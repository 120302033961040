import React from "react";

function ForgetPasswordIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 322.741 240.481"
    >
      <g data-name="Forgot password-rafiki" transform="translate(0 -55)">
        <g transform="translate(0 55)">
          <path
            fill="#ebebeb"
            d="M0 0H322.741V0.161H0z"
            data-name="Rectangle 5168"
            transform="translate(0 211.331)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H16.434V0.161H0z"
            data-name="Rectangle 5169"
            transform="translate(187.325 215.727)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H38.942V0.161H0z"
            data-name="Rectangle 5170"
            transform="translate(255.036 219.219)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H40.233V0.161H0z"
            data-name="Rectangle 5171"
            transform="translate(143.594 215.804)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H8.417V0.161H0z"
            data-name="Rectangle 5172"
            transform="translate(109.086 220.632)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H38.329V0.161H0z"
            data-name="Rectangle 5173"
            transform="translate(125.682 220.632)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H24.819V0.161H0z"
            data-name="Rectangle 5174"
            transform="translate(33.862 217.908)"
          ></path>
          <path
            fill="#ebebeb"
            d="M166.525 237.542H41.889a3.686 3.686 0 01-3.679-3.686V58.653A3.686 3.686 0 0141.889 55h124.636a3.686 3.686 0 013.686 3.686v175.171a3.686 3.686 0 01-3.686 3.685zM41.889 55.129a3.524 3.524 0 00-3.518 3.524v175.2a3.524 3.524 0 003.518 3.524h124.636a3.531 3.531 0 003.524-3.524v-175.2a3.531 3.531 0 00-3.524-3.524z"
            data-name="Path 16432"
            transform="translate(-13.546 -55)"
          ></path>
          <path
            fill="#ebebeb"
            d="M382.828 237.542H258.186a3.692 3.692 0 01-3.686-3.686V58.653A3.692 3.692 0 01258.186 55h124.642a3.686 3.686 0 013.673 3.653v175.2a3.686 3.686 0 01-3.673 3.689zM258.186 55.129a3.531 3.531 0 00-3.524 3.524v175.2a3.531 3.531 0 003.524 3.524h124.642a3.531 3.531 0 003.524-3.524v-175.2a3.531 3.531 0 00-3.524-3.524z"
            data-name="Path 16433"
            transform="translate(-90.225 -55)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M170.991 279.185h-42.86l-2.6-30.015h42.86z"
            data-name="Path 16434"
            transform="translate(-44.503 -123.837)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M174.431 279.185h-42.86l-2.6-30.015h42.86z"
            data-name="Path 16435"
            transform="translate(-45.722 -123.837)"
          ></path>
          <path
            fill="#fff"
            d="M141.293 276.275l-1.433-17.125h29.97l1.433 17.125z"
            data-name="Path 16436"
            transform="translate(-49.583 -127.375)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H5.028V55.983H0z"
            data-name="Rectangle 5175"
            transform="translate(131.097 155.348)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H3.492V55.983H0z"
            data-name="Rectangle 5176"
            transform="translate(134.222 155.348)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H48.676V6.177H0z"
            data-name="Rectangle 5177"
            transform="rotate(180 68.857 80.763)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H5.028V49.805H0z"
            data-name="Rectangle 5178"
            transform="translate(85.726 161.525)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H3.492V49.805H0z"
            data-name="Rectangle 5179"
            transform="translate(88.851 161.525)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H5.028V49.805H0z"
            data-name="Rectangle 5180"
            transform="translate(49.934 161.525)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H3.492V49.805H0z"
            data-name="Rectangle 5181"
            transform="translate(53.059 161.525)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H5.028V49.805H0z"
            data-name="Rectangle 5182"
            transform="translate(99.275 161.525)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H3.492V49.805H0z"
            data-name="Rectangle 5183"
            transform="translate(102.393 161.525)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H39.103V6.177H0z"
            data-name="Rectangle 5184"
            transform="translate(49.934 155.348)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H151.256V84.21H0z"
            data-name="Rectangle 5185"
            transform="translate(83.596 15.756)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H158.802V84.21H0z"
            data-name="Rectangle 5186"
            transform="translate(84.358 15.756)"
          ></path>
          <path
            fill="#fff"
            d="M0 0H79.02V153.612H0z"
            data-name="Rectangle 5187"
            transform="rotate(90 111.107 129.458)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M204.783 162.45l29-79.02h-47.861l-29 79.02z"
            data-name="Path 16437"
            transform="translate(-55.631 -65.079)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M282.608 162.45l29-79.02H275.5l-29 79.02z"
            data-name="Path 16438"
            transform="translate(-87.389 -65.079)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H12.858V80.427H0z"
            data-name="Rectangle 5188"
            transform="translate(269.934 130.904)"
          ></path>
          <path
            fill="#fafafa"
            d="M430.924 379h3.382v-6.19h-6.926z"
            data-name="Path 16439"
            transform="translate(-151.514 -167.669)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H12.858V80.427H0z"
            data-name="Rectangle 5189"
            transform="translate(151.385 130.904)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H121.499V78.755H0z"
            data-name="Rectangle 5190"
            transform="translate(161.293 130.904)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M370.347 284.953H253.45V262.71h116.9zm-116.247-.645h115.6v-20.953H254.1z"
            data-name="Path 16440"
            transform="translate(-89.853 -128.637)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M357.231 275.04h-73.475c-3.408 0-6.694-2.75-9.037-7.552l-.219-.458h91.981l-.219.458c-2.362 4.803-5.59 7.552-9.031 7.552zm-81.673-7.384c2.182 4.28 5.164 6.719 8.2 6.719h73.475c3.047 0 6.016-2.44 8.2-6.719z"
            data-name="Path 16441"
            transform="translate(-97.315 -130.168)"
          ></path>
          <path
            fill="#fafafa"
            d="M253.262 379h-3.382v-6.19h6.926z"
            data-name="Path 16442"
            transform="translate(-88.587 -167.669)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M370.347 323.8H253.45v-22.22h116.9zm-116.247-.648h115.6v-20.927H254.1z"
            data-name="Path 16443"
            transform="translate(-89.853 -142.417)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M357.231 313.907h-73.475c-3.408 0-6.694-2.756-9.037-7.552l-.219-.465h91.981l-.219.465c-2.362 4.77-5.59 7.552-9.031 7.552zm-81.673-7.371c2.182 4.28 5.164 6.726 8.2 6.726h73.475c3.047 0 6.016-2.446 8.2-6.726z"
            data-name="Path 16444"
            transform="translate(-97.315 -143.945)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M370.347 362.683H253.45V340.44h116.9zm-116.247-.645h115.6v-20.952H254.1z"
            data-name="Path 16445"
            transform="translate(-89.853 -156.194)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M357.231 352.77h-73.475c-3.408 0-6.694-2.75-9.037-7.546l-.219-.465h91.981l-.219.465c-2.362 4.797-5.59 7.546-9.031 7.546zm-81.673-7.365c2.182 4.28 5.164 6.719 8.2 6.719h73.475c3.047 0 6.016-2.44 8.2-6.719z"
            data-name="Path 16446"
            transform="translate(-97.315 -157.725)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M112.3 274.28v1.562h-.994l-.826 12.245H98.32l-.82-12.245h-1v-1.562z"
            data-name="Path 16447"
            transform="translate(-34.211 -132.739)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M93.349 219.467c-.994-7.294-10.838-9.372-23.328-9.644-11.883-.252-15.692-4.454-15.692-4.454s.039 17.021 17.163 23.192c16.261 5.849 22.858-1.787 21.857-9.094z"
            data-name="Path 16448"
            transform="translate(-19.261 -108.309)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M105.364 248.147c.207-3 5-8.507 16.518-6 8.327 1.814 11.425-2.5 11.425-2.5s-.775 12.187-13.3 15.847c-11.893 3.472-15.007-2.066-14.643-7.347z"
            data-name="Path 16449"
            transform="translate(-37.343 -120.458)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M107.652 261.237a.445.445 0 00.342-.155c.833-.988.148-3.35-.794-6.623-1.214-4.2-2.724-9.424-1.446-13.213a53.148 53.148 0 012.479-5.861c1.807-3.821 3.369-7.1 2.066-10.573a.445.445 0 10-.833.316c1.168 3.092-.245 6.087-2.04 9.882a55.25 55.25 0 00-2.517 5.951c-1.362 4.054.187 9.43 1.433 13.742.736 2.582 1.5 5.164.975 5.809a.439.439 0 00.052.645.426.426 0 00.283.08z"
            data-name="Path 16450"
            transform="translate(-37.004 -115.1)"
          ></path>
        </g>
        <g transform="translate(36.218 280.867)">
          <ellipse
            cx="125.153"
            cy="7.307"
            fill="#f5f5f5"
            rx="125.153"
            ry="7.307"
          ></ellipse>
        </g>
        <g transform="translate(64.671 100.565)">
          <path
            fill="#177ba3"
            d="M196.864 280.089h-91.659L100.19 132.68h91.658z"
            data-name="Path 16451"
            transform="translate(-100.19 -128.104)"
          ></path>
          <path
            fill="#177ba3"
            d="M203.95 273h-91.658l-5.022-147.41h91.658z"
            data-name="Path 16452"
            transform="translate(-102.7 -125.59)"
          ></path>
          <path
            fill="#fff"
            d="M203.95 273h-91.658l-5.022-147.41h91.658z"
            data-name="Path 16453"
            opacity="0.3"
            transform="translate(-102.7 -125.59)"
          ></path>
          <ellipse
            cx="25.348"
            cy="26.226"
            fill="#177ba3"
            data-name="Ellipse 170"
            rx="25.348"
            ry="26.226"
            transform="rotate(-45.5 57.739 2.663)"
          ></ellipse>
          <path
            fill="#fff"
            d="M190.086 179.391a20.656 20.656 0 01-2.227 10.134 20.391 20.391 0 01-18.332 11.141 22.114 22.114 0 01-19.08-11.141 21.617 21.617 0 01-2.918-10.134 20.423 20.423 0 0120.552-21.3 22.179 22.179 0 0122.005 21.3z"
            data-name="Path 16454"
            transform="translate(-116.966 -137.112)"
          ></path>
          <path
            fill="#177ba3"
            d="M189.452 203.674a20.391 20.391 0 01-18.332 11.141 22.114 22.114 0 01-19.08-11.141A32.655 32.655 0 01170.565 198a34.52 34.52 0 0118.887 5.674z"
            data-name="Path 16455"
            opacity="0.6"
            transform="translate(-118.572 -151.26)"
          ></path>
          <ellipse
            cx="9.321"
            cy="9.644"
            fill="#177ba3"
            data-name="Ellipse 171"
            opacity="0.6"
            rx="9.321"
            ry="9.644"
            transform="rotate(-45.5 60.694 -28.097)"
          ></ellipse>
          <path
            fill="#fff"
            d="M196.192 264.879h-58.468l-.394-11.619h58.47z"
            data-name="Path 16456"
            transform="translate(-113.357 -170.851)"
          ></path>
          <path
            fill="#fff"
            d="M197.288 297.049h-58.474l-.394-11.619h58.474z"
            data-name="Path 16457"
            transform="translate(-113.743 -182.256)"
          ></path>
          <path
            fill="#177ba3"
            d="M186.443 287.9l-14.091 1.936A.981.981 0 00171.5 291l1.853 9.443a1.136 1.136 0 001.252.884l14.091-1.936a.988.988 0 00.846-1.168l-1.846-9.443a1.13 1.13 0 00-1.252-.878zm-4.047 9.566l-2.582.342-.252-3.047a1.807 1.807 0 01-1.052-1.291 1.607 1.607 0 011.394-1.936 1.846 1.846 0 012.04 1.439 1.581 1.581 0 01-.5 1.5z"
            data-name="Path 16458"
            transform="translate(-125.464 -183.13)"
          ></path>
          <path
            fill="#177ba3"
            d="M185.363 282.842a1.007 1.007 0 01-1.11-.781l-.181-.923a5.312 5.312 0 00-5.564-4.234 4.518 4.518 0 00-4.17 5.416l.207 1.065a.865.865 0 01-.755 1.033.994.994 0 01-1.11-.781l-.174-.9a6.358 6.358 0 015.164-7.584 7.229 7.229 0 018.262 5.6l.207 1.059a.865.865 0 01-.775 1.033z"
            data-name="Path 16459"
            transform="translate(-125.778 -178.578)"
          ></path>
          <path
            fill="#177ba3"
            d="M193.626 324.485h-38.064l-.252-7.565h38.058z"
            data-name="Path 16460"
            transform="translate(-119.731 -193.42)"
          ></path>
          <path
            fill="#263238"
            d="M183.883 261.094a2.04 2.04 0 01-2.053 2.124 2.22 2.22 0 01-2.2-2.124 2.04 2.04 0 012.053-2.124 2.214 2.214 0 012.2 2.124z"
            data-name="Path 16461"
            transform="translate(-128.352 -172.876)"
          ></path>
          <path
            fill="#263238"
            d="M169.666 261.094a2.033 2.033 0 01-2.053 2.124 2.214 2.214 0 01-2.195-2.124 2.04 2.04 0 012.053-2.124 2.208 2.208 0 012.195 2.124z"
            data-name="Path 16462"
            transform="translate(-123.314 -172.876)"
          ></path>
          <path
            fill="#263238"
            d="M155.536 261.094a2.04 2.04 0 01-2.053 2.124 2.208 2.208 0 01-2.195-2.124 2.04 2.04 0 012.053-2.124 2.208 2.208 0 012.195 2.124z"
            data-name="Path 16463"
            transform="translate(-118.305 -172.876)"
          ></path>
          <path
            fill="#263238"
            d="M198.057 261.1a2.04 2.04 0 01-2.057 2.128 2.208 2.208 0 01-2.195-2.124 2.033 2.033 0 012.059-2.1 2.207 2.207 0 012.193 2.096z"
            data-name="Path 16464"
            transform="translate(-133.379 -172.886)"
          ></path>
          <path
            fill="#263238"
            d="M212.226 261.094a2.033 2.033 0 01-2.053 2.124 2.214 2.214 0 01-2.195-2.124 2.04 2.04 0 012.053-2.124 2.208 2.208 0 012.195 2.124z"
            data-name="Path 16465"
            transform="translate(-138.403 -172.876)"
          ></path>
        </g>
        <g transform="translate(178.36 101.83)">
          <path
            fill="#ff8b7b"
            d="M330.673 174.28c.858 1.381 1.575 2.7 2.311 4.073s1.4 2.756 2.053 4.17a59.615 59.615 0 013.292 8.862c.116.381.2.788.3 1.181l.142.6.071.3.084.491a8.83 8.83 0 01-.2 3.331 18.873 18.873 0 01-2.014 4.809 38.225 38.225 0 01-5.732 7.507l-2.717-2.053a71.9 71.9 0 003.634-7.746 16.046 16.046 0 00.988-3.634 2.879 2.879 0 00-.052-1.143l-.258-.787c-.1-.323-.2-.645-.323-.975a73.206 73.206 0 00-3.4-7.8 177.809 177.809 0 00-4.067-7.63z"
            data-name="Path 16466"
            transform="translate(-293.5 -144.117)"
          ></path>
          <path
            fill="#ff8b7b"
            d="M329.609 224.43L326.64 226l3.408 3.989a5.288 5.288 0 002.227-3.634z"
            data-name="Path 16467"
            transform="translate(-294.16 -161.896)"
          ></path>
          <path
            fill="#ff8b7b"
            d="M320.47 229.319l3.647 2.9 3.744-1.368-3.408-3.989z"
            data-name="Path 16468"
            transform="translate(-291.972 -162.757)"
          ></path>
          <path
            fill="#263238"
            d="M300.672 141.149c-.071.361-.323.613-.555.568s-.368-.381-.3-.736.323-.645.562-.568.364.374.293.736z"
            data-name="Path 16469"
            transform="translate(-284.645 -132.106)"
          ></path>
          <path
            fill="#ff5652"
            d="M299.417 142.52a13.077 13.077 0 01-2.337 2.724 2.124 2.124 0 001.652.645z"
            data-name="Path 16470"
            transform="translate(-283.68 -132.857)"
          ></path>
          <path
            fill="#ff8b7b"
            d="M298.9 159.814v-4.234l5.6.645s-.09 3.641-3.227 4.712z"
            data-name="Path 16471"
            transform="translate(-284.326 -137.487)"
          ></path>
          <path
            fill="#ff8b7b"
            d="M300.528 150.86l3.944 1.14.071 2.588-5.6-.678z"
            data-name="Path 16472"
            transform="translate(-284.34 -135.814)"
          ></path>
          <path
            d="M304.922 152l-3.944-1.136-.768 1.472a6.281 6.281 0 003.641 5.474 6.171 6.171 0 001.143-3.227z"
            data-name="Path 16473"
            opacity="0.2"
            transform="translate(-284.79 -135.814)"
          ></path>
          <path
            fill="#263238"
            d="M302.794 138.295a.219.219 0 00.161-.361 2.143 2.143 0 00-1.833-.71.213.213 0 00-.174.245.219.219 0 00.245.181 1.7 1.7 0 011.446.574.219.219 0 00.155.071z"
            data-name="Path 16474"
            transform="translate(-285.051 -130.975)"
          ></path>
          <path
            fill="#ff8b7b"
            d="M311.106 151.68c-.645 3.324-1.046 7.927 1.291 10.141 0 0-.9 3.369-7.1 3.369-6.784 0-3.26-2.956-3.26-2.956 2.821-.878 2.795-4.086 2.149-6.668z"
            data-name="Path 16475"
            transform="translate(-285.113 -136.105)"
          ></path>
          <path
            fill="#177ba3"
            d="M313.783 168.289c.607-.852.581-3.557-.065-4.034-1.039-.768-5.5-.949-11.5.432a4.22 4.22 0 01-.742 3.989z"
            data-name="Path 16476"
            transform="translate(-285.24 -140.352)"
          ></path>
          <path
            fill="#177ba3"
            d="M351.1 407.711a1.478 1.478 0 01-1.136-.064.439.439 0 01-.1-.5.5.5 0 01.278-.316c.613-.3 2.02.278 2.085.3a.1.1 0 01.065.1.123.123 0 01-.058.1 6.346 6.346 0 01-1.134.38zm-.742-.729a.542.542 0 00-.123.045.271.271 0 00-.168.181c-.058.194 0 .258.039.278.245.207 1.149 0 1.788-.239a3.544 3.544 0 00-1.537-.265z"
            data-name="Path 16477"
            transform="translate(-302.379 -226.531)"
          ></path>
          <path
            fill="#177ba3"
            d="M352.588 406.669h-.052c-.516-.148-1.614-.93-1.627-1.439a.355.355 0 01.278-.349.555.555 0 01.471.039c.607.31.994 1.562 1.007 1.62a.1.1 0 010 .1.09.09 0 01-.077.029zm-1.291-1.607h-.052c-.129.045-.129.11-.129.142 0 .3.736.917 1.291 1.162a2.688 2.688 0 00-.826-1.291.374.374 0 00-.278-.013z"
            data-name="Path 16478"
            transform="translate(-302.764 -225.856)"
          ></path>
          <path
            fill="#177ba3"
            d="M299.088 408.818c-.581 0-1.143-.084-1.33-.342a.407.407 0 010-.471.536.536 0 01.342-.252c.755-.207 2.343.7 2.408.736a.1.1 0 01.052.11.1.1 0 01-.084.09 7.875 7.875 0 01-1.388.129zm-.716-.884a.646.646 0 00-.207 0 .329.329 0 00-.213.148c-.077.136-.045.2 0 .239.213.3 1.356.3 2.2.181a4.913 4.913 0 00-1.782-.568z"
            data-name="Path 16479"
            transform="translate(-283.894 -226.876)"
          ></path>
          <path
            fill="#177ba3"
            d="M301.122 408.1h-.045c-.549-.245-1.633-1.226-1.549-1.743 0-.123.1-.271.4-.3a.775.775 0 01.594.181 3.085 3.085 0 01.71 1.749.129.129 0 01-.045.1.122.122 0 01-.065.013zm-1.11-1.84h-.065c-.194 0-.207.09-.207.116-.052.31.684 1.084 1.246 1.42a2.5 2.5 0 00-.6-1.4.568.568 0 00-.381-.136z"
            data-name="Path 16480"
            transform="translate(-284.546 -226.284)"
          ></path>
          <path
            fill="#ff8b7b"
            d="M306.98 403.285h-4.538l-.452-10.515h4.538z"
            data-name="Path 16481"
            transform="translate(-285.421 -221.575)"
          ></path>
          <path
            fill="#ff8b7b"
            d="M356.465 400.422l-4.434.962-4.46-9.792 4.441-.962z"
            data-name="Path 16482"
            transform="translate(-301.58 -220.817)"
          ></path>
          <path
            fill="#263238"
            d="M350.266 406l4.99-1.078a.394.394 0 01.439.219l1.73 3.757a.645.645 0 01-.478.93c-1.743.342-2.614.426-4.8.9-1.349.3-4.4 1.039-6.261 1.439s-2.5-1.388-1.775-1.717c3.26-1.491 4.615-2.8 5.5-4.021a1.1 1.1 0 01.655-.429z"
            data-name="Path 16483"
            transform="translate(-300.248 -225.881)"
          ></path>
          <path
            fill="#263238"
            d="M296.722 408.24h4.957a.4.4 0 01.387.31l.9 4.034a.645.645 0 01-.645.813c-1.788-.032-4.37-.136-6.616-.136-2.634 0-4.9.142-7.985.142-1.865 0-2.382-1.885-1.6-2.059 3.55-.775 6.455-.858 9.521-2.75a2.111 2.111 0 011.084-.355z"
            data-name="Path 16484"
            transform="translate(-279.669 -227.06)"
          ></path>
          <path
            fill="#177ba3"
            d="M328.028 168.932c4.254.975 7.746 11.838 7.746 11.838l-8.5 6.5a18.673 18.673 0 01-4.518-8.391c-1.505-5.694.922-10.954 5.272-9.947z"
            data-name="Path 16485"
            transform="translate(-292.624 -142.176)"
          ></path>
          <path
            d="M328.028 168.932c4.254.975 7.746 11.838 7.746 11.838l-8.5 6.5a18.673 18.673 0 01-4.518-8.391c-1.505-5.694.922-10.954 5.272-9.947z"
            data-name="Path 16486"
            opacity="0.4"
            transform="translate(-292.624 -142.176)"
          ></path>
          <path
            fill="#ff8b7b"
            d="M289.346 178.463c-1.059 1.143-2.117 2.162-3.227 3.227-.549.516-1.1 1.033-1.672 1.53l-.858.755-.213.187-.368.3a5.165 5.165 0 01-1.246.736 4.06 4.06 0 01-1.575.323 3.873 3.873 0 01-1.355-.239 3.815 3.815 0 01-1.291-.82 3.873 3.873 0 01-1.007-1.672 4.4 4.4 0 01-.187-.962 6.517 6.517 0 010-1.149 8.35 8.35 0 01.123-.833 15.986 15.986 0 01.749-2.582 23.117 23.117 0 011-2.337c.413-.82.807-1.42 1.2-2.1a52.706 52.706 0 015.454-7.4 49.977 49.977 0 016.616-6.274l2.375 2.446a128.043 128.043 0 00-4.822 6.984 75.7 75.7 0 00-4.131 7.262c-.278.607-.646 1.291-.82 1.782a17.324 17.324 0 00-.6 1.743 11.265 11.265 0 00-.368 1.627 2.547 2.547 0 000 .258v-.058a1.8 1.8 0 00-.084-.426 2.769 2.769 0 00-.742-1.2 3.163 3.163 0 00-1.065-.646 3.086 3.086 0 00-1.084-.2 2.633 2.633 0 00-1.039.2 2.377 2.377 0 00-.323.161l.245-.226.781-.71 1.543-1.446c1.02-.962 2.046-1.988 3.014-2.943z"
            data-name="Path 16487"
            transform="translate(-276.321 -138.753)"
          ></path>
          <path
            d="M324.485 181.36c2.311 2.285 3.066 6.455 3.227 10.07l-.374.29a18.674 18.674 0 01-4.518-8.391 11.13 11.13 0 01-.219-1.013z"
            data-name="Path 16488"
            opacity="0.2"
            transform="translate(-292.728 -146.627)"
          ></path>
          <path
            fill="#177ba3"
            d="M289.083 172.856c-.929 4.906-1.51 14.982 1.181 34.927h24.309c.168-3.724-2.175-21.914 1.42-39.478a63.913 63.913 0 00-8.133-1.026 90.2 90.2 0 00-10.328 0c-1.136.1-2.259.271-3.227.445a6.455 6.455 0 00-5.222 5.132z"
            data-name="Path 16489"
            transform="translate(-280.57 -141.582)"
          ></path>
          <path
            d="M289.083 172.856c-.929 4.906-1.51 14.982 1.181 34.927h24.309c.168-3.724-2.175-21.914 1.42-39.478a63.913 63.913 0 00-8.133-1.026 90.2 90.2 0 00-10.328 0c-1.136.1-2.259.271-3.227.445a6.455 6.455 0 00-5.222 5.132z"
            data-name="Path 16490"
            opacity="0.4"
            transform="translate(-280.57 -141.582)"
          ></path>
          <path
            d="M292.8 186.692l-4.389-1.052c-.136 2.659-.148 5.951.045 10.024 2.024-2.298 4.606-5.886 4.344-8.972z"
            data-name="Path 16491"
            opacity="0.2"
            transform="translate(-280.572 -148.144)"
          ></path>
          <path
            d="M301.99 392.77l.232 5.422h4.544l-.232-5.422z"
            data-name="Path 16492"
            opacity="0.2"
            transform="translate(-285.421 -221.575)"
          ></path>
          <path
            d="M352.011 390.63l-4.441.962 2.3 5.048 4.441-.962z"
            data-name="Path 16493"
            opacity="0.2"
            transform="translate(-301.58 -220.817)"
          ></path>
          <path
            fill="#ff8b7b"
            d="M312.506 139.883c-1.162 4.351-1.627 6.958-4.312 8.766-4.034 2.75-9.069-.4-9.2-5-.142-4.15 1.788-10.573 6.455-11.451a6.145 6.145 0 017.055 7.681z"
            data-name="Path 16494"
            transform="translate(-284.357 -129.162)"
          ></path>
          <path
            fill="#263238"
            d="M303.779 140.679c-.219-.581-1.22-5.08-.278-6.248s11.69-.323 13.278 1.239c2.64 2.582.807 4.906-2.078 7.894-2.169 2.246-2.253 4.777-3.35 5.164-1.936.645-4.893.039-5.487-2.007s-2.085-6.042-2.085-6.042z"
            data-name="Path 16495"
            transform="translate(-285.816 -129.796)"
          ></path>
          <path
            fill="#263238"
            d="M314.16 128.305c-3.8-1.614-10.915.174-14.762-.755a5.925 5.925 0 004.912 6.042 50.768 50.768 0 009.682.9c4.222-.192 3.396-4.812.168-6.187z"
            data-name="Path 16496"
            transform="translate(-284.501 -127.55)"
          ></path>
          <path
            fill="#263238"
            d="M323.19 136.438a7.236 7.236 0 004.021-.768c.71 1.717-3.195 2.188-4.021.768z"
            data-name="Path 16497"
            transform="translate(-292.937 -130.429)"
          ></path>
          <path
            fill="#ff8b7b"
            d="M305.412 143.655a5.106 5.106 0 01-1.291 3.014c-.994 1.091-2.027.407-2.2-.917-.155-1.2.168-3.227 1.381-3.808s2.198.361 2.11 1.711z"
            data-name="Path 16498"
            transform="translate(-285.383 -132.591)"
          ></path>
          <path
            fill="#177ba3"
            d="M304.69 230.133s5.164 34.695 7.985 47.695c3.092 14.233 19.707 61.321 19.707 61.321l8.127-1.762s-11.619-38.38-14.2-58.281c-2-15.285-5.932-48.986-5.932-48.986z"
            data-name="Path 16499"
            transform="translate(-286.378 -163.913)"
          ></path>
          <path
            fill="#263238"
            d="M344.57 393.858c-.032 0 1.517 2.943 1.517 2.943l9.14-1.982-.768-2.75z"
            data-name="Path 16500"
            transform="translate(-300.516 -221.327)"
          ></path>
          <path
            d="M309 249.263c5.958 1.168 5.325 23.386 4.609 34.817-1.717-8.081-4.234-23.625-5.951-34.656a2.033 2.033 0 011.342-.161z"
            data-name="Path 16501"
            opacity="0.3"
            transform="translate(-287.431 -170.682)"
          ></path>
          <path
            fill="#177ba3"
            d="M291.159 230.126s-.645 33.946-.129 47.766c.516 14.4 5.545 61.553 5.545 61.553h7.694s.278-46.313.646-60.456c.374-15.414 2.233-48.889 2.233-48.889z"
            data-name="Path 16502"
            transform="translate(-281.465 -163.906)"
          ></path>
          <path
            fill="#263238"
            d="M297.439 394.8c-.039 0 .432 3.189.432 3.189h9.353l.258-2.847z"
            data-name="Path 16503"
            transform="translate(-283.807 -222.295)"
          ></path>
          <path
            fill="#177ba3"
            d="M295.38 174.72c2.175 5.867-10.612 12.781-10.612 12.781l-5.538-7.1s3.724-7.371 8.243-10.689c5.583-4.093 6.248.542 7.907 5.008z"
            data-name="Path 16504"
            transform="translate(-277.352 -141.936)"
          ></path>
          <path
            d="M295.38 174.72c2.175 5.867-10.612 12.781-10.612 12.781l-5.538-7.1s3.724-7.371 8.243-10.689c5.583-4.093 6.248.542 7.907 5.008z"
            data-name="Path 16505"
            opacity="0.4"
            transform="translate(-277.352 -141.936)"
          ></path>
        </g>
        <g transform="translate(212.209 60.377)">
          <ellipse
            cx="23.295"
            cy="14.846"
            fill="#177ba3"
            data-name="Ellipse 172"
            rx="23.295"
            ry="14.846"
            transform="translate(10.986)"
          ></ellipse>
          <path
            fill="#fff"
            d="M365.286 90.729a6.526 6.526 0 01-6.41-5.416 6.6 6.6 0 014.977-7.436 6.494 6.494 0 016.984 2.918l14.956-3.085a2.337 2.337 0 012.737 1.607 2.253 2.253 0 01-1.723 2.782l-.387.077a.381.381 0 00-.271.2.407.407 0 000 .336l.265.684a1.026 1.026 0 01-.4 1.246 1.226 1.226 0 00-.574.91 1.013 1.013 0 01-.781.9l-.3.065a1.007 1.007 0 01-1.077-.517 1.259 1.259 0 00-2.33.484 1 1 0 01-.775.891l-.3.065a1.013 1.013 0 01-1.072-.516 1.291 1.291 0 00-.9-.607A1.026 1.026 0 01377 85.3v-.736a.4.4 0 00-.478-.374l-4.77.981a6.5 6.5 0 01-5.132 5.422 6.686 6.686 0 01-1.334.136zm-1.291-12.264a5.945 5.945 0 00-4.518 6.726 5.88 5.88 0 0011.619-.336v-.232l5.261-1.065a1.046 1.046 0 011.291.981v.736a.381.381 0 00.31.368 1.878 1.878 0 011.362.923.374.374 0 00.381.207l.3-.065a.362.362 0 00.271-.336 1.9 1.9 0 013.55-.749.368.368 0 00.374.207l.31-.065a.362.362 0 00.265-.336 1.872 1.872 0 01.884-1.388.394.394 0 00.136-.465l-.265-.684a1.039 1.039 0 01.762-1.394l.394-.084a1.62 1.62 0 001.233-1.982 1.685 1.685 0 00-1.988-1.143l-15.375 3.2-.116-.2a5.809 5.809 0 00-6.4-2.808zm1.291 9.863a3.969 3.969 0 11.839-.052 4.113 4.113 0 01-.833.065zm0-7.623a3.874 3.874 0 00-.716.071 3.5 3.5 0 001.459 6.849 3.492 3.492 0 001.214-6.332 3.434 3.434 0 00-1.93-.574z"
            data-name="Path 16506"
            transform="translate(-339.408 -68.409)"
          ></path>
          <ellipse
            cx="7.655"
            cy="4.88"
            fill="#177ba3"
            data-name="Ellipse 173"
            opacity="0.6"
            rx="7.655"
            ry="4.88"
            transform="translate(7.668 28.64)"
          ></ellipse>
          <ellipse
            cx="3.143"
            cy="2.001"
            fill="#177ba3"
            data-name="Ellipse 174"
            opacity="0.3"
            rx="3.143"
            ry="2.001"
            transform="translate(0 36.399)"
          ></ellipse>
        </g>
      </g>
    </svg>
  );
}

export default ForgetPasswordIcon;
