import React from "react";

function ActiveJobsIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 17.55 17.55"
    >
      <g transform="translate(.25 .25)">
        <g data-name="Group 22305" transform="translate(4.048 2.142)">
          <g data-name="Group 22304">
            <path
              //   fill="#fff"
              stroke="#fff"
              strokeWidth="0.5"
              d="M12.514.189a.642.642 0 00-.909 0L3.896 7.873 1.115 4.852a.643.643 0 10-.945.87l3.234 3.513a.642.642 0 00.459.207h.014a.643.643 0 00.454-.187l8.182-8.161a.642.642 0 00.001-.905z"
              data-name="Path 16708"
            ></path>
          </g>
        </g>
        <g data-name="Group 22307">
          <g data-name="Group 22306">
            <path
              //   fill="#fff"
              stroke="#fff"
              strokeWidth="0.5"
              d="M16.407 7.882a.643.643 0 00-.643.643 7.24 7.24 0 11-7.24-7.24A.643.643 0 108.525 0a8.525 8.525 0 108.525 8.525.643.643 0 00-.643-.643z"
              data-name="Path 16709"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ActiveJobsIcon;
