import React from "react";

function EditIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 15.847 15.83"
    >
      <g data-name="Group 22144" transform="translate(-249.475 -261.487)">
        <g fill="rgba(23,123,163,0.75)" transform="translate(249.475 261.487)">
          <path
            d="M9.786 82.473l-8.719 8.72a.347.347 0 00-.091.159L.01 95.231a.343.343 0 00.333.426.341.341 0 00.083-.01L4.3 94.68a.343.343 0 00.159-.09l8.72-8.719zm0 0"
            data-name="Path 15411"
            transform="translate(0 -79.827)"
          ></path>
          <path
            d="M338.915 1.7l-.971-.971a1.758 1.758 0 00-2.427 0l-1.189 1.189 3.4 3.4 1.189-1.189a1.717 1.717 0 000-2.427zm0 0"
            data-name="Path 15412"
            transform="translate(-323.571 -.246)"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#e6f4fa"
          strokeWidth="0.6"
          d="M5.157 0L0 5.157"
          data-name="Line 666"
          transform="translate(253.375 267.95)"
        ></path>
      </g>
    </svg>
  );
}

export default EditIcon;