export default function convertToFormData(formData, body) {
  convertObjToFormData(formData, body);
}

function convertObjToFormData(formData, obj) {
  for (const key in obj) {
    // if (Array.isArray(obj[key])) convertArrayToFormData(formData[key], obj[key])
    // else formData.appen(key, obj[key])
    formData.append(key, obj[key]);
  }
  return formData;
}

// eslint-disable-next-line no-unused-vars
function convertArrayToFormData(formData, array) {
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (typeof item === "object") convertObjToFormData(formData[i], item);
    else formData[i] = item;
  }
  return formData;
}
