import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ForgotPasswordPage from "../pages/auth/forgot-password-page";
import LoginPage from "../pages/auth/login-page";
import SignupPage from "../pages/auth/signup-page";
import PrivacyPolicy from "../pages/privacy-policy";
import routes from "../routes";

const AuthLayout = () => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path={routes.auth.privacyPolicy}
          component={PrivacyPolicy}
        />
        <Route exact path={routes.auth.signup} component={SignupPage} />
        <Route exact path={routes.auth.login} component={LoginPage} />
        <Route
          exact
          path={routes.auth.forgotPassword}
          component={ForgotPasswordPage}
        />
        <Redirect to={routes.auth.login} component={LoginPage} />
      </Switch>
      {/* <FooterComponent /> */}
    </div>
  );
};

export default AuthLayout;
