function InstructorsIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 17.368 17.368"
    >
      <g id="teacher_1_" data-name="teacher" transform="translate(0.135 0.135)">
        <path
          id="Path_18021"
          data-name="Path 18021"
          d="M19.148,17.25h13.3a1.9,1.9,0,0,1,1.9,1.9v8.9a1.9,1.9,0,0,1-1.9,1.9H26.375v4.081a.32.32,0,0,1-.32.32H21.441a.32.32,0,0,1-.32-.32V32.1h-1.41a.32.32,0,0,1-.32-.32V29.946h-.243a1.9,1.9,0,0,1-1.9-1.9v-8.9A1.9,1.9,0,0,1,19.148,17.25ZM27.37,29.306l-.029-.026-.966-.966v.992Zm-7.338,2.152h1.09v-3.63a.32.32,0,1,1,.64,0v5.879h3.973V27.542a.32.32,0,0,1,.546-.226l1.512,1.512a.8.8,0,0,0,1.135,0l2.505-2.505-.77-.771-2.076,2.076a.32.32,0,0,1-.226.094h0a.32.32,0,0,1-.227-.1l-1.779-1.805a1.48,1.48,0,0,0-1.048-.439H22.078a2.048,2.048,0,0,0-2.046,2.046Zm-2.141-3.41a1.259,1.259,0,0,0,1.258,1.258h.243V27.428a2.689,2.689,0,0,1,2.686-2.686h3.228a2.123,2.123,0,0,1,1.5.629l1.553,1.575,1.877-1.877V21.354a.32.32,0,0,1,.64,0v3.509l.01.009L32.113,26.1a.32.32,0,0,1,0,.453L29.381,29.28l-.029.026h3.1a1.259,1.259,0,0,0,1.258-1.258v-8.9A1.259,1.259,0,0,0,32.45,17.89h-13.3a1.259,1.259,0,0,0-1.258,1.258Z"
          transform="translate(-17.25 -17.25)"
          fill="#fff"
          stroke="currentColor"
          strokeWidth="0.27"
        />
        <path
          id="Path_18022"
          data-name="Path 18022"
          d="M66.9,33.348a1.963,1.963,0,1,1-1.963,1.963A1.963,1.963,0,0,1,66.9,33.348Zm0,3.285a1.323,1.323,0,1,0-1.323-1.323A1.323,1.323,0,0,0,66.9,36.633Z"
          transform="translate(-60.491 -30.404)"
          fill="#fff"
          stroke="currentColor"
          strokeWidth="0.27"
        />
      </g>
    </svg>
  );
}

export default InstructorsIcon;
