import aboutEjad from "../../assets/pictures/aboutEjad.svg";
import aboutEjadltr from "../../assets/pictures/aboutEjad-ltr.svg";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import useMediaQuery from "../../components/hooks/use-media-query";

const AboutUs = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const isSmall = useMediaQuery("(max-width: 768px)");

  return (
    <div id="about-us" className="max-w-screen-xl">
      {isSmall ? (
        <div className="mt-10 bg-[#E2F0F4] py-4">
          <div className="mx-2 mt-4 text-center text-lg font-bold">
            {selectedContent[localizationKeys.aboutEjad]}
          </div>
          <div className="mx-4 mt-4 text-center">
            {selectedContent[localizationKeys.aboutEjadBody]}.
          </div>
        </div>
      ) : (
        <div className="relative mt-56 text-center text-white">
          {lang === "ar" ? (
            <img src={aboutEjad} alt="" />
          ) : (
            <img src={aboutEjadltr} alt="" />
          )}

          <div
            className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform `}
          >
            <div className={`${isSmall ? "mt-12 text-lg" : "mt-8 text-3xl"}`}>
              {selectedContent[localizationKeys.aboutEjad]}
            </div>
            <div className={`${isSmall ? "text-lg" : "mt-10 px-12 text-xl"}`}>
              {selectedContent[localizationKeys.aboutEjadBody]}.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutUs;
