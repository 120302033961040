import React from "react";

function JobsActionsIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 34.969 50.11"
    >
      <g data-name="Group 22656" transform="translate(-252.516 -58.969)">
        <path
          fill="#69bd3d"
          d="M29.847 5.121A17.485 17.485 0 005.121 29.848 17.485 17.485 0 0029.847 5.121zM7.588 29.32a10 10 0 019.9-8.8 9.966 9.966 0 019.9 8.8 15.4 15.4 0 01-19.792 0zm9.9-10.913a5.31 5.31 0 115.31-5.31 5.317 5.317 0 01-5.314 5.31zm11.66 9.181a12.045 12.045 0 00-7.54-8.4 7.359 7.359 0 10-8.238 0 12.034 12.034 0 00-7.544 8.393 15.435 15.435 0 1123.321 0zm0 0"
          data-name="user (3)"
          transform="translate(252.516 58.969)"
        ></path>
        <path
          fill="#69bd3d"
          d="M17.216 16.082l3.425-3.425a.8.8 0 10-1.135-1.135l-3.425 3.425-3.425-3.425a.8.8 0 10-1.135 1.135l3.425 3.425-3.425 3.425a.8.8 0 001.135 1.135l3.425-3.425 3.425 3.425a.8.8 0 001.135-1.135z"
          data-name="Icon ionic-ios-close"
          transform="translate(243.183 88.156)"
        ></path>
        <path
          fill="none"
          stroke="#69bd3d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M285.532 100.397l-7.682 7.682-3.492-3.492"
          data-name="Icon feather-check"
        ></path>
      </g>
    </svg>
  );
}

export default JobsActionsIcon;
