import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { useAppMedia } from "../components/hooks/use-media-query";
import NavbarComponent from "../components/shared/navbar";
import SidebarComponent from "../components/shared/sidebar";
import AllEmploymentApplicationsPage from "../pages/employment-applications/all-employment-applications-page";
import JobEmploymentApplicationsPage from "../pages/employment-applications/job-employment-applications-page";
import InstructorProfilePage from "../pages/instructor/instructor-profile-page";
import InstructorProfile from "../pages/instructor/instructor-profile";
import InstructorsList from "../pages/instructors/instructors-list";
import ActiveJobsPage from "../pages/jobs/active-jobs-page";
import ClosedJobsPage from "../pages/jobs/closed-jobs-page";
import DraftedJobsPage from "../pages/jobs/drafted-jobs-page";
import MainPage from "../pages/main/main-page";
import AllNotifications from "../pages/notifications/all-notifications";
import SchoolProfilePage from "../pages/settings/profile/school-profile-page";
import AllSubscribtionsPage from "../pages/subscribtions/all-subscribtions-page";
import routes from "../routes";
import PrivateRoute from "./private-route";
import classNames from "classnames";

const SidebarLayout = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const { isScreen } = useAppMedia();
  return (
    <div className="mx-auto  h-screen ">
      <NavbarComponent
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        isSmall={isScreen}
      />
      <div className="flex">
        <SidebarComponent
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          isSmall={isScreen}
        />
        <div
          className={classNames("mx-auto mt-28 w-full bg-none", {
            "lg:ltr:ml-64 lg:rtl:mr-64 ": !isScreen,
          })}
          // className={`${
          //   isSmall ? "" : "lg:ltr:ml-80 lg:rtl:mr-80"
          // } mx-auto mt-28 w-full bg-none lg:ltr:mr-8 lg:rtl:ml-8  xs:px-8`}
        >
          <Switch>
            <PrivateRoute exact path={routes.home} component={MainPage} />
            <PrivateRoute
              exact
              path={routes.employmentApplications.all}
              component={AllEmploymentApplicationsPage}
            />
            <PrivateRoute
              exact
              path={routes.instructor.profile()}
              component={InstructorProfilePage}
            />
            <PrivateRoute
              exact
              path={routes.employmentApplications.job()}
              component={JobEmploymentApplicationsPage}
            />
            <PrivateRoute
              exact
              path={routes.instructor.profilePage}
              component={InstructorProfile}
            />
            <PrivateRoute
              exact
              path={routes.instructor.all}
              component={InstructorsList}
            />
            <PrivateRoute
              exact
              path={routes.jobs.active}
              component={ActiveJobsPage}
            />
            <PrivateRoute
              exact
              path={routes.jobs.closed}
              component={ClosedJobsPage}
            />
            <PrivateRoute
              exact
              path={routes.jobs.drafted}
              component={DraftedJobsPage}
            />
            <PrivateRoute
              exact
              path={routes.settings}
              component={SchoolProfilePage}
            />
            <PrivateRoute
              exact
              path={routes.subscribtions.all}
              component={AllSubscribtionsPage}
            />
            <PrivateRoute
              exact
              path={routes.notifications.all}
              component={AllNotifications}
            />
            <Redirect to={routes.landingPage} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default SidebarLayout;
