import React from "react";
import { Button, Confirm } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { AiFillLike } from "react-icons/ai";
import toast from "react-hot-toast";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import useAxios from "../../lib/use-axios";

const ChangeEmploymentApplicantionState = ({
  isApproving,
  applicant,
  isRejecting,
  setIsApproving,
  setIsRejecting,
  setForceReload,
  setApplicant,
}) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const { run: changeApplicantState, isLoading } = useAxios({});

  const changeState = (id) => {
    changeApplicantState(
      authAxios.patch(
        `${api.applicants.changeState(id)}`,
        isApproving
          ? {
              status: "accepted",
            }
          : {
              status: "rejected",
            }
      )
    )
      .then(() => {
        isApproving
          ? toast.success(
              selectedContent[localizationKeys.approvedSuccessfully]
            )
          : toast.success(
              selectedContent[localizationKeys.rejectedSuccessfully]
            );

        setForceReload((p) => !p);
        setIsApproving(false);
        setIsRejecting(false);
        setApplicant("");
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  return (
    <Confirm
      closeOnEscape
      className="text-center text-xl font-extrabold text-black"
      confirmButton={
        isApproving ? (
          <Button
            className="mx-auto flex w-6/12 items-center justify-center"
            disabled={isLoading}
          >
            <AiFillLike className="mx-1" />
            {selectedContent[localizationKeys.approve]}
          </Button>
        ) : (
          <Button
            className="mx-auto flex w-6/12 items-center justify-center"
            disabled={isLoading}
          >
            <AiFillLike className="mx-1" />
            {selectedContent[localizationKeys.decline]}
          </Button>
        )
      }
      cancelButton={
        <Button
          primary
          basic
          disabled={isLoading}
          className="mx-auto mb-2 flex w-6/12 items-center justify-center"
        >
          {selectedContent[localizationKeys.no]}
        </Button>
      }
      content={
        <div className="py-8">
          <span className="mx-auto flex w-96 items-center justify-center text-base">
            {isApproving
              ? selectedContent[localizationKeys.approvingTitle]
              : selectedContent[localizationKeys.rejectingTitle]}{" "}
            {lang === "ar"
              ? applicant?.instructor?.name?.ar
              : applicant?.instructor?.name?.en}{" "}
            {lang === "ar" ? "؟" : "?"}
          </span>
          <span className="mx-auto mt-4 w-80 text-base font-normal text-greyText">
            {isApproving
              ? selectedContent[localizationKeys.approvingBody]
              : selectedContent[localizationKeys.rejectingBody]}
          </span>
        </div>
      }
      size="tiny"
      open={isApproving || isRejecting}
      onCancel={() => {
        setIsApproving(false);
        setIsRejecting(false);
      }}
      onConfirm={() => changeState(applicant?._id)}
    />
  );
};

export default ChangeEmploymentApplicantionState;
