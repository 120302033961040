import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import api from "../../api";
import toast from "react-hot-toast";
import routes from "../../routes";
import content from "../../localization/content";
import { useLanguage } from "../../lib/language-context";
import localizationKeys from "../../localization/localization-keys";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import NavbarComponent from "../../components/shared/navbar";
import { AiFillCheckCircle } from "react-icons/ai";

const SuccessPage = () => {
  const location = useLocation();
  const history = useHistory();
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [paymentId, setPaymentId] = React.useState();

  const { run: getStatus } = useAxios({});

  React.useEffect(() => {
    if (location.search) {
      setPaymentId(
        location?.search
          .split("=")[2]
          .substring(0, location?.search.split("=")[1].length - 3)
      );
    }
  }, [location?.search]);

  React.useEffect(() => {
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: `Bearer ${token}`,
    // };
    // if (token && paymentId)
    //   axios
    //     .post(
    //       `${api.subscribtion.checkStatus}`,
    //       {
    //         paymentId: paymentId,
    //       },
    //       {
    //         headers: headers,
    //       }
    //     )
    //     .then(({ data }) => {
    //       console.log(data);
    //       data?.data === "success" &&
    //         toast.success(
    //           selectedContent[localizationKeys.publishedSuccessfully]
    //         ) &&
    //         history.push(routes.home);
    //     })
    //     .catch((e) => {
    //       e?.response?.data?.errors?.map((s) => toast.error(s?.message));
    //     });
    if (paymentId)
      getStatus(
        authAxios.post(`${api.subscribtion.checkStatus}`, {
          paymentId: paymentId,
        })
      )
        .then(({ data }) => {
          data?.data === "success" &&
            toast.success(
              selectedContent[localizationKeys.publishedSuccessfully]
            ) &&
            history.push(routes.home);
        })
        .catch((e) => {
          e?.errors?.map((s) => toast.error(s.message));
        });
  }, [getStatus, history, paymentId, selectedContent]);

  return (
    <div className="px-8">
      <NavbarComponent withLogo={true} />
      <div className="flex items-center justify-center">
        <div
          className="mx-4 mt-28 h-[30rem] max-w-screen-lg flex-grow flex-col
           justify-center rounded-2xl bg-white shadow-sm md:px-32 lg:px-96 "
        >
          {/* {isGettingStatus && (
            <Loader active inline="centered" className="text-2xl">
              {selectedContent[localizationKeys.loading]}..
            </Loader> */}
          <div className="">
            <AiFillCheckCircle
              className="mx-auto mt-28 justify-center fill-green-600 text-center"
              size={150}
            />
            <div className="mt-6 text-center text-xl text-black">
              <div>{selectedContent[localizationKeys.loading]}</div>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
