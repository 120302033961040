import React from "react";
import { useLanguage } from "./language-context";
import content from "../localization/content";
import localizationKeys from "../localization/localization-keys";
import { AiOutlineMinusCircle } from "react-icons/ai";
import useMediaQuery from "../components/hooks/use-media-query";

function FileAttachment({
  setAttachmentData,
  accept,
  label,
  name,
  labelClassName,
  textClassName,
  UploadIcon,
  error,
  dimensions,
}) {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [file, setFile] = React.useState(null);
  const isSmall = useMediaQuery("(max-width: 776px)");

  const handleOnSelectedFile = (e) => {
    // setFile(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    setAttachmentData(e.target.files[0]);
  };

  const clearAttachment = () => {
    setFile(null);
    setAttachmentData(null);
  };

  return (
    <>
      {file ? (
        <div
          className={`flex justify-between   ${
            isSmall ? "rtl:pr-1 ltr:pl-1 px-1" : "rtl:pr-6 ltr:pl-6 px-4"
          }rounded-lg bg-gray-100`}
        >
          <span className={`${isSmall ? "py-4" : "py-10"}`}>
            <img
              src={file}
              className={` ${isSmall ? "h-24" : "h-36"} w-full`}
              alt=""
            />
          </span>
          {isSmall ? (
            <>
              <div
                className="flex items-center justify-center cursor-pointer font-bold text-base float-left"
                // className={labelClassName}
                onClick={clearAttachment}
              >
                <div
                  className={`flex items-center my-auto text-red-700 rounded-full h-min ${
                    isSmall ? "px-1" : "px-4"
                  }`}
                >
                  <AiOutlineMinusCircle
                    size="16"
                    className="ltr:mr-1 rtl:ml-1 "
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="flex items-center justify-center cursor-pointer font-bold text-base"
                // className={labelClassName}
                onClick={clearAttachment}
              >
                <div className="flex items-center my-auto text-red-700 rounded-full h-min px-4">
                  <AiOutlineMinusCircle
                    size="16"
                    className="ltr:mr-1 rtl:ml-1 "
                  />
                  {selectedContent[localizationKeys.delete]}
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        // <div class={`relative z-0 ${labelClassName}`}>
        //   <div
        //     className="cursor-pointer font-bold"
        //     onClick={clearAttachment}
        //   >
        //     <div className="flex items-center my-auto text-red-700 rounded-full h-min px-4">
        //       <AiOutlineMinusCircle size="16" className="ltr:mr-1 rtl:ml-1 " />
        //       {selectedContent[localizationKeys.delete]}
        //     </div>
        //   </div>
        //   <div class="absolute inset-0 flex justify-center items-center z-10 mx-5 rounded-md">
        //     <img src={file} className="h-36 w-full" alt="" />
        //   </div>
        // </div>
        <>
          <input
            type="file"
            id={name}
            accept={accept}
            className="hidden"
            onChange={handleOnSelectedFile}
          />
          <label
            htmlFor={name}
            className={`${labelClassName} ${
              error && `border-2 border-[#e0b4b4] bg-[#e0b4b4]`
            }`}
            // className="text-gray-500 flex border-4 border-dashed py-2 cursor-pointer bg-gray-200 hover:bg-slate-300 "
          >
            <div className=" cursor-pointer mx-auto justify-center my-auto">
              <UploadIcon
                size="50"
                className={`mx-auto fill-gray-300 ${error && `fill-[#9f3a38]`}`}
              />
              <div className={`${textClassName} ${error && `text-[#9f3a38]`}`}>
                <span className="mx-1">+</span>
                {label}
              </div>
              <div
                dir="ltr"
                className={`flex items-center justify-center text-sm ${
                  error && `text-[#9f3a38]`
                }`}
              >
                {dimensions}
              </div>
            </div>
          </label>
        </>
      )}
    </>
  );
}

export default FileAttachment;
