import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Loader } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import ChangeEmploymentApplicantionState from "../employment-applications/change-employment-applicant-state";
import { BsPerson, BsGenderFemale, BsGenderMale } from "react-icons/bs";
import CVIcon from "../../assets/icons/cv-icon";
import { AiOutlineArrowRight, AiFillEye, AiFillLike } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import EducationalOfficeIcon from "../../assets/icons/educational-office-icon";
import EducationalLevelIcon from "../../assets/icons/educational-level-icon";
import EducationalTrackIcon from "../../assets/icons/educational-track-icon";
import SubjectIcon from "../../assets/icons/subject-icon";
import GlobeIcon from "../../assets/icons/world-icon";
import LocationIcon from "../../assets/icons/location-icon";
import useMediaQuery from "../../components/hooks/use-media-query";

const InstructorProfilePage = () => {
  const params = useParams();
  const history = useHistory();
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [isApproving, setIsApproving] = React.useState(false);
  const [isRejecting, setIsRejecting] = React.useState(false);

  const isSmall = useMediaQuery("(max-width: 860px)");

  const {
    run: getApplication,
    data: application,
    isLoading: isGettingApplication,
  } = useAxios({});
  const {
    run: getInstructor,
    data: instructor,
    isLoading: isGettingInstructor,
  } = useAxios({});

  React.useEffect(() => {
    if (params.application)
      getApplication(
        authAxios.get(`${api.applicants.get(params.application)}`)
      );
  }, [getApplication, params.application]);

  React.useEffect(() => {
    if (application?.data?.instructor?._id)
      getInstructor(
        authAxios.get(
          `${api.instructor.profile(application?.data?.instructor?._id)}`
        )
      );
  }, [application?.data?.instructor?._id, getInstructor]);

  return (
    <div className="px-8">
      {isGettingApplication || isGettingInstructor ? (
        <Loader active={true} size="small" className="mt-4" />
      ) : (
        <>
          {!isSmall && (
            <div
              className={`flex h-16
           w-full items-center justify-between rounded-lg px-6 ${
             application?.data?.vacancy?.status === "active"
               ? "bg-[#E4F5DF40] ring-1 ring-[#E4F5DF] "
               : "bg-[#F9E3E340] ring-1 ring-[#E4F5DF] "
           }`}
            >
              <div className="flex items-center">
                <AiOutlineArrowRight
                  size="20px"
                  className="mt-1 hover:cursor-pointer ltr:rotate-180"
                  onClick={() => history.goBack()}
                />
                <span
                  className="mx-2 cursor-pointer text-lg font-extrabold text-[#9F9F9F]"
                  onClick={() => history.goBack()}
                >
                  {application?.data?.vacancy?.title}
                </span>
                <span className="mx-1 text-lg font-extrabold">-</span>
                <span className="mx-1 text-lg font-extrabold">
                  {lang === "ar"
                    ? instructor?.data?.name?.ar
                    : instructor?.data?.name?.en}
                </span>
              </div>
              <></>
              {application?.data?.status === "onReview" &&
                application?.data?.vacancy?.status === "active" && (
                  <div className="flex items-center gap-2 ">
                    <Button
                      className="flex items-center bg-inherit px-2 py-2 text-black "
                      size="small"
                      onClick={() => {
                        setIsApproving(true);
                      }}
                    >
                      <AiFillLike className="fill-[#34C00A] ltr:mr-1 rtl:ml-1" />
                      {selectedContent[localizationKeys.approve]}
                    </Button>
                    /
                    <Button
                      className="flex items-center bg-inherit px-2 py-2 text-black"
                      size="small"
                      onClick={() => {
                        setIsRejecting(true);
                      }}
                    >
                      <AiFillLike className="rotate-180 fill-[#E80505] ltr:mr-1 rtl:ml-1" />
                      {selectedContent[localizationKeys.decline]}
                    </Button>
                  </div>
                )}
              {application?.data?.status === "accepted" &&
                application?.data?.vacancy?.status === "active" && (
                  <div className="mx-6 flex items-center gap-2">
                    <span>{selectedContent[localizationKeys.status]} :</span>
                    <span className="mx-2 text-[#34C00A]">
                      {selectedContent[localizationKeys.approved]}
                    </span>
                  </div>
                )}
              {application?.data?.status === "rejected" &&
                application?.data?.vacancy?.status === "active" && (
                  <div className="mx-6 flex items-center gap-2">
                    <span>{selectedContent[localizationKeys.status]} :</span>
                    <span className="mx-2 text-[#E80505]">
                      {selectedContent[localizationKeys.notApproved]}
                    </span>
                  </div>
                )}
            </div>
          )}
          <div className="mt-6 min-h-[31rem] rounded-xl bg-white p-12 ring-1 ring-[#E3E3E3]">
            <div className={`grid ${isSmall ? "grid-cols-2" : "grid-cols-5"} `}>
              {instructor?.data?.image_link ? (
                <img
                  alt=""
                  src={instructor?.data?.image_link}
                  className={` ${
                    isSmall ? "h-28 w-28 " : "h-[10.56rem] w-[10.25rem] "
                  }rounded-xl cursor-pointer 
                  shadow-md ltr:float-right rtl:float-left`}
                  onClick={() => window.open(instructor?.data?.image_link)}
                ></img>
              ) : (
                <div
                  className={`${
                    isSmall ? "h-28 w-28" : "h-[10.56rem] w-[10.25rem]"
                  } flex cursor-pointer items-center justify-center
                rounded-xl bg-[#ECECEC] shadow-md ltr:float-right rtl:float-left`}
                >
                  <FaUserAlt className="h-[3.25rem] w-[2.8rem] fill-[#ADADAD]" />
                </div>
              )}
              <div className={`${isSmall ? "" : "col-span-3"}`}>
                <div className="mt-2 flex items-center">
                  <BsPerson className="fill-primary-900" size="18px" />
                  <span className="mx-2">
                    {lang === "ar"
                      ? instructor?.data?.name?.ar
                      : instructor?.data?.name?.en}
                  </span>
                </div>
                <div className="mt-2 flex items-center">
                  {instructor?.data?.gender === "ذكر" ? (
                    <BsGenderMale className="fill-primary-900" size="18px" />
                  ) : (
                    <BsGenderFemale className="fill-primary-900" size="18px" />
                  )}
                  <span className="mx-2">
                    {lang === "ar"
                      ? instructor?.data?.gender
                      : instructor?.data?.gender}
                  </span>
                </div>
                {instructor?.data?.resume_link && (
                  <div className="mt-2 flex items-center">
                    <CVIcon className="fill-primary-900" size="18px" />
                    <span
                      className="mx-2 cursor-pointer underline"
                      onClick={() => window.open(instructor?.data?.resume_link)}
                    >
                      {selectedContent[localizationKeys.cv]}
                    </span>
                    <Button
                      circular
                      icon
                      size="mini"
                      className="bg-[#177BA321] p-2 ring-1 ring-primary-900"
                      onClick={() => window.open(instructor?.data?.resume_link)}
                    >
                      <AiFillEye className="fill-primary-900" size="10px" />
                    </Button>
                  </div>
                )}
                {!isSmall && (
                  <div className="mt-2 break-all">
                    {instructor?.data?.summary}
                  </div>
                )}
              </div>
            </div>
            {isSmall && (
              <div className="mt-2 break-all">{instructor?.data?.summary}</div>
            )}
            <div
              className={`mt-6 min-h-[12rem] rounded-xl bg-white p-12 
              ${
                isSmall ? "" : "grid grid-cols-3"
              } shadow-[0px_0px_6px_rgba(0,0,0,0.09)]`}
            >
              <div>
                {instructor?.data?.education_center && (
                  <div className="flex items-center gap-2">
                    <EducationalOfficeIcon />
                    <div>
                      {instructor?.data?.education_center?.name} -{" "}
                      {instructor?.data?.education_center?.gender}
                    </div>
                  </div>
                )}
                <div className="mt-2 flex items-center gap-2">
                  <EducationalLevelIcon />
                  <div>
                    <span className="w-max">
                      {instructor?.data?.education_levels?.map((d, index) => (
                        <>
                          <span className="mx-0.5">{d?.name}</span>
                          {index !==
                            instructor?.data?.education_levels?.length - 1 && (
                            <span>-</span>
                          )}
                        </>
                      ))}
                    </span>
                  </div>
                </div>
                {instructor?.data?.secondary_tracks?.length > 0 && (
                  <div className="mt-2 flex items-center gap-2">
                    <EducationalTrackIcon />
                    <div>
                      <span className="w-max">
                        {instructor?.data?.secondary_tracks?.map((d, index) => (
                          <>
                            <span className="mx-0.5">{d?.name}</span>
                            {index !==
                              instructor?.data?.secondary_tracks?.length -
                                1 && <span>-</span>}
                          </>
                        ))}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <GlobeIcon />
                  <div>{instructor?.data?.city?.name}</div>
                </div>
                <div className="mt-2 flex items-center gap-2">
                  <SubjectIcon />
                  <div>
                    <span className="w-max">
                      {instructor?.data?.subject?.map((d, index) => (
                        <>
                          <span className="mx-0.5">{d?.name}</span>
                          {index !== instructor?.data?.subject?.length - 1 && (
                            <span>-</span>
                          )}
                        </>
                      ))}
                    </span>
                  </div>
                </div>
                <div className="mt-2 flex items-center gap-2">
                  <BsTelephone className="fill-primary-900" />
                  <div dir="ltr">
                    {instructor?.data?.country_code}
                    {instructor?.data?.phone}
                  </div>
                </div>
              </div>
              <div>
                {instructor?.data?.address && (
                  <div className="flex gap-2 rtl:text-right">
                    <LocationIcon size="16" />
                    <div dir="ltr">{instructor?.data?.address}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <ChangeEmploymentApplicantionState
        isApproving={isApproving}
        applicant={application?.data}
        isRejecting={isRejecting}
        setIsApproving={setIsApproving}
        setIsRejecting={setIsRejecting}
      />
    </div>
  );
};

export default InstructorProfilePage;
