import React from "react";

function ViewJobsIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 40 50"
    >
      <g transform="translate(-44)">
        <g data-name="Group 22639" transform="translate(44 16.333)">
          <g data-name="Group 22638">
            <path
              fill="#69bd3d"
              d="M45.606 167.276a.94.94 0 10.276.665.947.947 0 00-.276-.665z"
              data-name="Path 16645"
              transform="translate(-44 -167)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22641" transform="translate(44)">
          <g data-name="Group 22640">
            <path
              fill="#69bd3d"
              d="M81.17 0H62.082a.961.961 0 00-.943.977.961.961 0 00.943.977H81.17a.961.961 0 01.943.977V47.07a.961.961 0 01-.943.977H46.83a.961.961 0 01-.943-.977V20.931a.944.944 0 10-1.887 0V47.07A2.885 2.885 0 0046.83 50h34.34A2.885 2.885 0 0084 47.07V2.93A2.885 2.885 0 0081.17 0z"
              data-name="Path 16646"
              transform="translate(-44)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22643" transform="translate(51.647 3.763)">
          <g data-name="Group 22642">
            <path
              fill="#69bd3d"
              d="M137.049 40a12.664 12.664 0 00-1.912.145.941.941 0 10.285 1.86 10.761 10.761 0 011.627-.124 10.625 10.625 0 018.131 17.472 9.426 9.426 0 00-4.911-4.11 4.6 4.6 0 001.39-3.295v-.917a4.61 4.61 0 10-9.22 0v.917a4.6 4.6 0 001.39 3.295 9.428 9.428 0 00-4.921 4.127 10.6 10.6 0 01-2.343-5.085.941.941 0 00-1.856.311A12.514 12.514 0 10137.049 40zm-2.728 11.031a2.728 2.728 0 115.457 0v.917a2.728 2.728 0 11-5.457 0zm2.728 12.113a10.618 10.618 0 01-6.745-2.4 7.525 7.525 0 0113.482-.016 10.582 10.582 0 01-6.737 2.416z"
              data-name="Path 16647"
              transform="translate(-124.696 -40)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22645" transform="translate(58.398 38.925)">
          <g data-name="Group 22644">
            <path
              fill="#69bd3d"
              d="M210.657 398H197.3a.941.941 0 000 1.882h13.359a.941.941 0 000-1.882z"
              data-name="Path 16648"
              transform="translate(-196.357 -398.002)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22647" transform="translate(54.345 33.448)">
          <g data-name="Group 22646">
            <path
              fill="#69bd3d"
              d="M155.246 342.276a.94.94 0 10.276.665.947.947 0 00-.276-.665z"
              data-name="Path 16649"
              transform="translate(-153.64 -342)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22649" transform="translate(54.345 38.925)">
          <g data-name="Group 22648">
            <path
              fill="#69bd3d"
              d="M155.246 398.276a.94.94 0 10.276.665.946.946 0 00-.276-.665z"
              data-name="Path 16650"
              transform="translate(-153.64 -398)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22651" transform="translate(58.398 33.448)">
          <g data-name="Group 22650">
            <path
              fill="#69bd3d"
              d="M210.657 342H197.3a.941.941 0 000 1.882h13.359a.941.941 0 000-1.882z"
              data-name="Path 16651"
              transform="translate(-196.357 -342.002)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22653" transform="translate(48.019 5.146)">
          <g data-name="Group 22652">
            <path
              fill="#69bd3d"
              d="M93.764 54.976a.941.941 0 00-1.331 0l-3.064 3.064-1.047-1.047a.941.941 0 10-1.331 1.331l1.709 1.712a.941.941 0 001.331 0l3.729-3.729a.941.941 0 00.004-1.331z"
              data-name="Path 16652"
              transform="translate(-86.716 -54.701)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22655" transform="translate(44)">
          <g data-name="Group 22654">
            <path
              fill="#69bd3d"
              d="M51.715 0a7.715 7.715 0 107.715 7.715A7.723 7.723 0 0051.715 0zm0 13.548a5.833 5.833 0 115.833-5.833 5.84 5.84 0 01-5.833 5.833z"
              data-name="Path 16653"
              transform="translate(-44)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ViewJobsIcon;
