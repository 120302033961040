import React from "react";
import ReactDOM from "react-dom";

import App from "./app";
import { LanguageProvider } from "./lib/language-context";

import "semantic-ui-css/components/icon.min.css";
import "./assets/css/tailwind.css";
import "./assets/css/app.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


ReactDOM.render(
  <React.StrictMode>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
