import { useLanguage } from "../../../lib/language-context";
import content from "../../../localization/content";
import localizationKeys from "../../../localization/localization-keys";
import SpContainer from "../../container/sp-container";
import PaginationComponent from "../../shared/pagination";
import RenderedInstructorsList from "../rendered-instructors-list";

const TabletInstructorList = ({
  instructors,
  loading,
  ModalAction,
  priceData,
  viewLimit,
  pageState: { page, setPage, totalPages },
}) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  return (
    <SpContainer className={"p-0"}>
      <div className="px-1 py-2">
        <div className="table-header mb-[16px] grid-cols-7 hover:shadow-none">
          <div>{selectedContent[localizationKeys.instructorName]}</div>
          <div className="mx-auto">
            {selectedContent[localizationKeys.eduLevel]}
          </div>
          <div className="mx-auto">
            {selectedContent[localizationKeys.subject]}
          </div>
          <div className="mx-auto">
            {selectedContent[localizationKeys.nationality]}
          </div>
          <div className="col-span-2 mx-auto">
            {selectedContent[localizationKeys.cv]}
          </div>
          <div className="mx-auto">
            {selectedContent[localizationKeys.view]}
          </div>
        </div>
        <div className="relative h-full flex-1">
          <RenderedInstructorsList
            price={priceData}
            loading={loading}
            instructors={instructors}
            ModalAction={ModalAction}
            viewLimit={viewLimit}
          />
        </div>
        <div className="mx-auto mb-4 flex w-full items-center justify-center pb-4">
          <PaginationComponent
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            className="absolute bottom-0 mb-4 rtl:flex-row-reverse"
          />
        </div>
      </div>
    </SpContainer>
  );
};
export default TabletInstructorList;
