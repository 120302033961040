import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import { AiOutlineArrowRight, AiFillEye, AiFillLike } from "react-icons/ai";
import { Button, Image, Loader, Message } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import useFilter from "../../lib/use-filter";
import toast from "react-hot-toast";
import PaginationComponent from "../../components/shared/pagination";
import ChangeEmploymentApplicantionState from "./change-employment-applicant-state";
import routes from "../../routes";
import { BsPersonFill } from "react-icons/bs";
import JobsNotFoundIcon from "../../assets/icons/jobs-not-found-icon";
import useMediaQuery from "../../components/hooks/use-media-query";
import { truncateString } from "../../utils/string";
import SpContainer from "../../components/container/sp-container";
import classNames from "classnames";

const JobEmploymentApplicationsPage = () => {
  const params = useParams();
  const history = useHistory();
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [limit] = React.useState("8");
  const [totalPages, setTotalPages] = React.useState(0);
  const [applicants, setApplicants] = React.useState([]);
  const [page, setPage] = useFilter("page", 1);

  const [forceReload, setForceReload] = React.useState(false);
  const [applicant, setApplicant] = React.useState(false);
  const [isApproving, setIsApproving] = React.useState(false);
  const [isRejecting, setIsRejecting] = React.useState(false);

  const location = useLocation();
  const queryString = require("query-string");
  const parsed = queryString.parse(location.search);

  const isSmall = useMediaQuery("(max-width: 776px)");

  const {
    run: getVacancy,
    data: vacancy,
    isLoading: loadingVacancy,
  } = useAxios({});
  const { run: getApplicants, isLoading: isGettingApplicants } = useAxios({});

  React.useEffect(() => {
    getVacancy(authAxios.get(`${api.vacancies.get(params.id)}`));
  }, [getVacancy, params.id]);

  React.useEffect(() => {
    if (page < 1) setPage(1);
    if (page && page !== "undefined")
      getApplicants(
        authAxios.get(
          `${api.applicants.all(params?.id)}&limit=${limit}&page=${
            parsed?.page
          }`
        )
      )
        .then(({ data }) => {
          setTotalPages(data?.totalPages);
          // if (page > data?.totalPages) setPage(1);
          if (page > data?.totalPages && data?.totalPages !== 0) setPage(1);
          setApplicants(data);
        })
        .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicants, limit, page, forceReload]);

  return (
    <div className="px-8">
      {!window.navigator.onLine && (
        <Message
          error
          className="mb-8"
          content={selectedContent[localizationKeys.noInternet]}
        />
      )}
      {loadingVacancy || isGettingApplicants ? (
        <Loader active={true} size="small" className="mt-4" />
      ) : (
        <>
          <div
            className={` flex h-16 w-full items-center rounded-lg px-6 ${
              vacancy?.data?.status === "active"
                ? "bg-[#E4F5DF40] ring-1 ring-[#E4F5DF] "
                : "bg-[#F9E3E340] ring-1 ring-[#E4F5DF] "
            }`}
          >
            <AiOutlineArrowRight
              size="20px"
              className="hover:cursor-pointer ltr:rotate-180"
              onClick={() => history.goBack()}
            />
            <span className="mx-4 text-lg font-extrabold">
              {vacancy?.data?.title}
            </span>
          </div>
          {applicants?.data?.length > 0 ? (
            <SpContainer>
              {isSmall ? (
                <div className="mt-4 flex flex-wrap items-center gap-4">
                  {applicants.data?.map((d) => (
                    <div
                      className={`h-44 w-72 rounded-lg shadow-md ${
                        d?.instructor?._id === params?.instructor
                          ? "bg-primary-300/40"
                          : "bg-white"
                      }`}
                    >
                      <div
                        className="mt-2 flex w-full items-center text-black ltr:ml-4 rtl:mr-4"
                        onClick={() =>
                          history.push(routes.instructor.profile(d?._id))
                        }
                      >
                        {d.instructor?.image_link ? (
                          <Image
                            className="h-7 w-7"
                            src={
                              d.instructor?.image_link
                                ? d.instructor?.image_link
                                : ""
                            }
                            circular
                            size="mini"
                          />
                        ) : (
                          <div className="flex h-7 w-7 items-center justify-center rounded-full bg-[#DCDCDC]">
                            <BsPersonFill
                              size="10"
                              className=" fill-gray-500"
                            />
                          </div>
                        )}
                        {lang === "en" ? (
                          <div className="mx-2 cursor-pointer underline">
                            {truncateString(d?.instructor?.name?.en, 30)}
                          </div>
                        ) : (
                          <div className="mx-2 cursor-pointer underline">
                            {truncateString(d?.instructor?.name?.ar, 30)}
                          </div>
                        )}
                      </div>
                      <div className="mx-4 mt-2 grid grid-cols-2">
                        <div className="text-gray-400">
                          {selectedContent[localizationKeys.cv]}
                        </div>
                        <Button
                          primary
                          className="flex items-center justify-center rounded-lg bg-[#E2F0F4] text-primary-900 "
                          size="mini"
                          disabled={!d?.instructor?.resume_link}
                          onClick={() =>
                            window.open(d?.instructor?.resume_link)
                          }
                        >
                          <AiFillEye className="fill-primary-900 ltr:mr-1 rtl:ml-1" />
                          {selectedContent[localizationKeys.view]}
                        </Button>
                      </div>
                      <div className="mx-4 mt-2 grid grid-cols-2">
                        <div className="text-gray-400">
                          {selectedContent[localizationKeys.status]}
                        </div>
                        <div className="flex items-center">
                          {d?.status === "accepted" && (
                            <div className="text-[#34C00A] ltr:pl-5 rtl:pr-5">
                              {selectedContent[localizationKeys.approved]}
                            </div>
                          )}
                          {d?.status === "rejected" && (
                            <div className="text-[#E80505] ltr:pl-5 rtl:pr-5">
                              {selectedContent[localizationKeys.notApproved]}
                            </div>
                          )}
                          {d?.status === "onReview" && (
                            <div className="text-[#DFC200]">
                              {selectedContent[localizationKeys.underReview]}
                            </div>
                          )}
                        </div>
                      </div>
                      {vacancy?.data?.status === "active" && (
                        <>
                          {d?.status === "onReview" ? (
                            <div className="mx-4 mt-4 flex items-center gap-2 ltr:float-right rtl:float-left">
                              <Button
                                className="flex items-center rounded-sm bg-white px-2
                                 py-2 text-[#34C00A] ring-1 ring-[#34C00A]"
                                size="small"
                                onClick={() => {
                                  setIsApproving(true);
                                  setApplicant(d);
                                }}
                              >
                                <AiFillLike className="fill-[#34C00A] ltr:mr-1 rtl:ml-1" />
                                {selectedContent[localizationKeys.approve]}
                              </Button>
                              <Button
                                className="flex items-center rounded-sm bg-white px-2
                                 py-2 text-[#E80505] ring-1 ring-[#E80505]"
                                size="small"
                                onClick={() => {
                                  setIsRejecting(true);
                                  setApplicant(d);
                                }}
                              >
                                <AiFillLike className="rotate-180 fill-[#E80505] ltr:mr-1 rtl:ml-1" />
                                {selectedContent[localizationKeys.decline]}
                              </Button>
                            </div>
                          ) : (
                            <div className="ltr:ml-4 rtl:mr-4"></div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                  {applicants?.data?.length > 0 && (
                    <div className="mx-auto mb-4 flex w-full items-center justify-center pb-4">
                      <PaginationComponent
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                        className="absolute bottom-0 mb-4 rtl:flex-row-reverse"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div
                    className={classNames(`table-header`, {
                      "grid-cols-3": vacancy?.data?.status !== "active",
                      "grid-cols-4": vacancy?.data?.status === "active",
                    })}
                  >
                    <div>{selectedContent[localizationKeys.applicantName]}</div>
                    <div>{selectedContent[localizationKeys.cv]}</div>
                    <div>{selectedContent[localizationKeys.status]}</div>
                    {vacancy?.data?.status === "active" && (
                      <div>{selectedContent[localizationKeys.actions]}</div>
                    )}
                  </div>
                  {applicants.data?.map((d) =>
                    d?.instructor ? (
                      <div
                        //     className="grid grid-cols-4 items-center bg-white w-full rounded-xl
                        //  rtl:pr-10 ltr:pl-10 h-10 my-2 hover:shadow-md"
                        className={` items-center ${
                          d?.instructor?._id === params?.instructor
                            ? "bg-primary-300/40"
                            : "bg-white"
                        } my-2 h-10
              w-full rounded-xl hover:shadow-md ltr:pl-10 rtl:pr-10  ${
                vacancy?.data?.status === "active"
                  ? "grid grid-cols-4"
                  : "grid grid-cols-3"
              }`}
                        key={d._id}
                      >
                        <div
                          className=" flex w-full items-center text-black ltr:ml-4 rtl:mr-4"
                          onClick={() =>
                            history.push(routes.instructor.profile(d?._id))
                          }
                        >
                          {d.instructor?.image_link ? (
                            <Image
                              className="h-7 w-7"
                              src={
                                d.instructor?.image_link
                                  ? d.instructor?.image_link
                                  : ""
                              }
                              circular
                              size="mini"
                            />
                          ) : (
                            <div className="flex h-7 w-7 items-center justify-center rounded-full bg-[#DCDCDC]">
                              <BsPersonFill
                                size="10"
                                className=" fill-gray-500"
                              />
                            </div>
                          )}
                          {lang === "en" ? (
                            <div className="mx-2 cursor-pointer underline">
                              {d?.instructor?.name?.en}
                            </div>
                          ) : (
                            <div className="mx-2 cursor-pointer underline">
                              {d?.instructor?.name?.ar}
                            </div>
                          )}
                        </div>
                        <div className="flex items-center gap-2 ltr:ml-5 rtl:mr-5">
                          <Button
                            primary
                            basic
                            className="flex items-center rounded-sm px-2 py-2"
                            size="small"
                            disabled={!d?.instructor?.resume_link}
                            onClick={() =>
                              window.open(d?.instructor?.resume_link)
                            }
                          >
                            <AiFillEye className="fill-primary-900 ltr:mr-1 rtl:ml-1" />
                            {selectedContent[localizationKeys.view]}
                          </Button>
                        </div>
                        <div className="flex items-center">
                          {d?.status === "accepted" && (
                            <div className="text-[#34C00A] ltr:pl-5 rtl:pr-5">
                              {selectedContent[localizationKeys.approved]}
                            </div>
                          )}
                          {d?.status === "rejected" && (
                            <div className="text-[#E80505] ltr:pl-5 rtl:pr-5">
                              {selectedContent[localizationKeys.notApproved]}
                            </div>
                          )}
                          {d?.status === "onReview" && (
                            <div className="text-[#DFC200]">
                              {selectedContent[localizationKeys.underReview]}
                            </div>
                          )}
                        </div>
                        {vacancy?.data?.status === "active" && (
                          <>
                            {d?.status === "onReview" ? (
                              <div className="flex items-center gap-2 ">
                                <Button
                                  className="flex items-center bg-white px-2 py-2 text-black"
                                  size="small"
                                  onClick={() => {
                                    setIsApproving(true);
                                    setApplicant(d);
                                  }}
                                >
                                  <AiFillLike className="fill-[#34C00A] ltr:mr-1 rtl:ml-1" />
                                  {selectedContent[localizationKeys.approve]}
                                </Button>
                                /
                                <Button
                                  className="flex items-center bg-white px-2 py-2 text-black"
                                  size="small"
                                  onClick={() => {
                                    setIsRejecting(true);
                                    setApplicant(d);
                                  }}
                                >
                                  <AiFillLike className="rotate-180 fill-[#E80505] ltr:mr-1 rtl:ml-1" />
                                  {selectedContent[localizationKeys.decline]}
                                </Button>
                              </div>
                            ) : (
                              <div className="ltr:ml-4 rtl:mr-4">--</div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div
                        className={` my-2 h-10 w-full items-center
                  rounded-xl bg-[#DB000012] hover:shadow-md ltr:pl-10 rtl:pr-10 ${
                    vacancy?.data?.status === "active"
                      ? "grid grid-cols-4"
                      : "grid grid-cols-3"
                  }`}
                      >
                        <div className=" flex w-full items-center text-black ltr:ml-4 rtl:mr-4">
                          <div className="flex h-7 w-7 items-center justify-center rounded-full bg-[#DCDCDC]">
                            <BsPersonFill
                              size="10"
                              className=" fill-gray-500"
                            />
                          </div>
                          <div className="mx-2 text-gray-500">
                            {selectedContent[localizationKeys.deletedAccount]}
                          </div>
                        </div>
                        <div className="ltr:ml-16 rtl:mr-16">--</div>
                        <div className="ltr:ml-8 rtl:mr-8">--</div>
                        {vacancy?.data?.status === "active" && (
                          <div className="ltr:ml-4 rtl:mr-4">--</div>
                        )}
                      </div>
                    )
                  )}
                </>
              )}
              {applicants?.data?.length > 0 && (
                <div className="mx-auto mb-4 flex w-full items-center justify-center pb-4">
                  <PaginationComponent
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    className="absolute bottom-0 mb-4 rtl:flex-row-reverse"
                  />
                </div>
              )}
            </SpContainer>
          ) : (
            <div className="mt-6 flex h-[28rem] items-center justify-center rounded-xl bg-white px-8 shadow-sm">
              <div className="mt-8 flex-col justify-center ">
                <JobsNotFoundIcon className="h-44 w-64" />
                <div className="mt-8 flex items-center justify-center text-lg font-semibold">
                  {selectedContent[localizationKeys.noJobApplicationsInThisJob]}
                  .
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <ChangeEmploymentApplicantionState
        isApproving={isApproving}
        applicant={applicant}
        isRejecting={isRejecting}
        setIsApproving={setIsApproving}
        setIsRejecting={setIsRejecting}
        setForceReload={setForceReload}
        setApplicant={setApplicant}
      />
    </div>
  );
};

export default JobEmploymentApplicationsPage;
