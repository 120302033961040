import React from "react";

function GooglePlayIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 43.41 48.355"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.915"
          x2="-0.383"
          y1="17.626"
          y2="16.957"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#00a0ff"></stop>
          <stop offset="0.007" stopColor="#00a2ff"></stop>
          <stop offset="0.26" stopColor="#00beff"></stop>
          <stop offset="0.512" stopColor="#00d2ff"></stop>
          <stop offset="0.76" stopColor="#00dfff"></stop>
          <stop offset="1" stopColor="#00e3ff"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="1.076"
          x2="-1.305"
          y1="49.373"
          y2="49.373"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ffe000"></stop>
          <stop offset="0.409" stopColor="#ffbd00"></stop>
          <stop offset="0.775" stopColor="#ffa600"></stop>
          <stop offset="1" stopColor="#ff9c00"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="0.862"
          x2="-0.501"
          y1="32.329"
          y2="30.56"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ff3743"></stop>
          <stop offset="1" stopColor="#e30864"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="-0.188"
          x2="0.421"
          y1="35.049"
          y2="34.259"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#00a06f"></stop>
          <stop offset="0.068" stopColor="#00a86e"></stop>
          <stop offset="0.476" stopColor="#00cf6e"></stop>
          <stop offset="0.801" stopColor="#00e76d"></stop>
          <stop offset="1" stopColor="#00ef6d"></stop>
        </linearGradient>
      </defs>
      <g transform="translate(-26.183)">
        <path
          fill="url(#linear-gradient)"
          d="M27.053 6.6a3.764 3.764 0 00-.87 2.641v41.583a3.763 3.763 0 00.87 2.641l.139.136L50.483 30.31v-.55L27.192 6.468z"
          data-name="Path 10978"
          transform="translate(0 -5.857)"
        ></path>
        <path
          fill="url(#linear-gradient-2)"
          d="M291.246 186.954l-7.763-7.767v-.549l7.765-7.765.175.1 9.2 5.227c2.628 1.493 2.628 3.935 0 5.429l-9.2 5.227z"
          data-name="Path 10979"
          transform="translate(-233 -154.734)"
        ></path>
        <path
          fill="url(#linear-gradient-3)"
          d="M66.766 263.942L58.825 256 35.4 279.431c.866.917 2.3 1.03 3.907.115l27.464-15.6"
          data-name="Path 10980"
          transform="translate(-8.342 -231.823)"
        ></path>
        <path
          fill="url(#linear-gradient-4)"
          d="M66.766 16.237L39.3.632c-1.611-.915-3.041-.8-3.907.115l23.43 23.43z"
          data-name="Path 10981"
          transform="translate(-8.342)"
        ></path>
      </g>
    </svg>
  );
}

export default GooglePlayIcon;
