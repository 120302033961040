import { Button, Image } from "semantic-ui-react";
import { AiFillPhone } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import localizationKeys from "../../localization/localization-keys";
import content from "../../localization/content";
import { useLanguage } from "../../lib/language-context";
import logo from "../../assets/pictures/logo.svg";
import { RiInstagramFill, RiTwitterFill } from "react-icons/ri";
import useMediaQuery from "../hooks/use-media-query";

const FooterComponent = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const isSmall = useMediaQuery("(max-width: 768px)");

  return (
    <div className="">
      {!isSmall && (
        <>
          <div className="">
            <div className="bg-[#F0F7FA]">
              <div className="mx-10 grid grid-cols-4 pb-4">
                <div className="col-span-2 mt-10 ">
                  <Image className="mx-10 pt-2" size="small" src={logo} />
                </div>
                <div className="my-5 mt-10 text-gray-800">
                  <div className="text-xl font-bold">
                    {selectedContent[localizationKeys.contactUs]}
                  </div>
                  <div className="mt-2 flex items-center">
                    <AiFillPhone
                      className="fill-primary-900 ltr:mr-2 rtl:ml-2"
                      size={16}
                    />
                    <span dir="ltr" className="text-lg">
                      +966-550-623-668
                    </span>
                  </div>
                </div>
                <div className="mt-12 flex items-center">
                  <MdEmail
                    className="fill-primary-900 ltr:mr-2 rtl:ml-2"
                    size={16}
                  />
                  <a
                    className="text-lg text-gray-800 hover:underline"
                    rel="noreferrer"
                    href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=ejadapp75@gmail.com"
                    target="_blank"
                  >
                    ejadapp75@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="flex h-20 items-center justify-center bg-[#F0F7FA]">
            <Button
              icon
              circular
              className="bg-[#b3d5d8]"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/ejadcareers/?igshid=YmMyMTA2M2Y="
                )
              }
            >
              <RiInstagramFill className="fill-primary-900" />
            </Button>
            <Button
              icon
              circular
              className="bg-[#b3d5d8]"
              onClick={() => window.open("https://twitter.com/EjadCareers")}
            >
              <RiTwitterFill className="fill-primary-900" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default FooterComponent;
