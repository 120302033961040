import React from "react";
import toast from "react-hot-toast";
import { Button, Confirm, Modal, Popup } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import moment from "moment";
import BinIcon from "../../assets/icons/bin-icon";
import EditIcon from "../../assets/icons/edit-icon";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import AddVacancy from "./add-vacancy";
import "moment/locale/en-au";
import { BsInfoCircle } from "react-icons/bs";

const ViewVacancyDetails = ({
  isOpen,
  onClose,
  vacancy,
  setForceReload,
  school,
  isEditingVacancy,
  setIsEditingVacancy,
}) => {
  moment.locale("en-au");
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const { run: deleteVacancy } = useAxios({ data: [] });
  const { run: closeVacancy } = useAxios({ data: [] });

  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isClosingVacancy, setIsClosingVacancy] = React.useState(false);

  const handleModalClose = () => {
    onClose();
  };

  const DeleteVacancy = (id) => {
    deleteVacancy(authAxios.delete(`${api.vacancies.delete(vacancy._id)}`))
      .then(() => {
        toast.success(selectedContent[localizationKeys.afterDeletionMessage]);
        setIsDeleting(false);
        handleModalClose();
        setForceReload((p) => !p);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  const CloseVacancy = (id) => {
    closeVacancy(
      authAxios.patch(`${api.vacancies.archive(id)}`, {
        status: "archived",
      })
    )
      .then(() => {
        toast.success(
          selectedContent[localizationKeys.vacancyIsClosedSuccessfully]
        );
        setIsClosingVacancy(false);
        handleModalClose();
        setForceReload((p) => !p);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      size="small"
      closeOnEscape={false}
      closeIcon
      closeOnDimmerClick={false}
      className="max-w-screen-2xl "
    >
      <Modal.Content className="px-16">
        <div className="my-2 flex items-center justify-between ">
          <div></div>
          <div className="font-extrabold ">
            {selectedContent[localizationKeys.vacancyDetails]}
          </div>
          <div className="">
            {vacancy?.status === "drafted" && (
              <div className="mx-1 rounded-xl bg-[#E2C6061F] px-4 py-1 text-sm text-[#E2C606]">
                {selectedContent[localizationKeys.drafted]}
              </div>
            )}
            {vacancy?.status === "active" && (
              <div className="mx-1 rounded-xl bg-[#34C00A1F] px-4 py-1 text-sm text-[#34C00A] ">
                {selectedContent[localizationKeys.active]}
              </div>
            )}
            {vacancy?.status === "archived" && (
              <div className="mx-1 rounded-xl bg-[#DB00001A] px-4 py-1 text-sm text-[#DB0000]">
                {selectedContent[localizationKeys.archived]}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center font-extrabold">
          {vacancy?.title}
          {vacancy?.isSchoolNameHidden && (
            <div className="mx-1 flex items-center rounded-3xl bg-[#E6E6E6] p-1 text-sm font-normal text-[#909090]">
              {selectedContent[localizationKeys.hiddenIdentity]}
              <Popup
                trigger={<BsInfoCircle className="mx-1" />}
                position="bottom right"
                className="-mt-0.5 w-60 rounded-xl border-primary-300 p-2 before:bg-transparent  before:shadow-none"
              >
                <Popup.Content className="text-center text-sm text-[#909090]">
                  {selectedContent[localizationKeys.hiddenIdentityInfo]}
                </Popup.Content>
              </Popup>
            </div>
          )}
        </div>
        <div className="mt-2 text-greyText">
          <span className="flex items-center gap-1">
            <span>{moment(vacancy.createdAt).format("L")}</span>,
            <span>{moment(vacancy.createdAt).format("LT")}</span>
          </span>
        </div>
        <div className="grid grid-cols-2">
          {/* right */}
          <div>
            <div className="mt-2 grid grid-cols-2">
              <span className="text-greyText ltr:mr-2 rtl:ml-2">
                {selectedContent[localizationKeys.educationalOffice]}:
              </span>
              <span>
                {vacancy?.education_center?.name} -{" "}
                {vacancy?.education_center?.gender}
              </span>
            </div>
            <div className="mt-2 grid grid-cols-2">
              <span className="text-greyText ltr:mr-2 rtl:ml-2">
                {selectedContent[localizationKeys.educationalLevel]}:
              </span>
              <span>{vacancy?.education_levels?.name}</span>
            </div>
            {vacancy?.secondary_tracks && (
              <div className="mt-2 grid grid-cols-2">
                <span className="text-greyText ltr:mr-2 rtl:ml-2">
                  {selectedContent[localizationKeys.track]}:
                </span>
                <span>{vacancy?.secondary_tracks?.name}</span>
              </div>
            )}
          </div>
          {/* lef
          \
          \t */}
          <div>
            <div className="mt-2 grid grid-cols-2">
              <span className="text-greyText ltr:mr-2 rtl:ml-2">
                {selectedContent[localizationKeys.subject]}:
              </span>
              <span>{vacancy?.subject?.name}</span>
            </div>
            <div className="mt-2 grid grid-cols-2">
              <span className="text-greyText ltr:mr-2 rtl:ml-2">
                {selectedContent[localizationKeys.typeOfInstructors]}:
              </span>
              <span>{vacancy?.gender}</span>
            </div>
            <div className="mt-2 grid grid-cols-2">
              <span className="text-greyText ltr:mr-2 rtl:ml-2">
                {selectedContent[localizationKeys.salary]}:
              </span>
              {vacancy?.salary ? (
                <span>
                  <span>{vacancy?.salary}</span>
                  <span className="mx-1">
                    {selectedContent[localizationKeys.RS]}
                  </span>
                </span>
              ) : (
                <span>
                  {selectedContent[localizationKeys.determinedAtTheInterview]}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="mt-4 flex items-center">
          <div className="flex h-8 w-max items-center justify-center rounded-xl bg-[#F4F0FF] px-2 text-primary-900 ltr:mr-2 rtl:ml-2">
            {lang === "ar"
              ? vacancy?.work_type?.name?.ar
              : vacancy?.work_type?.name?.en}
          </div>
          <div className="mx-2 flex h-8 w-max items-center justify-center rounded-xl bg-[#F4F0FF] px-2 text-primary-900">
            {lang === "ar"
              ? vacancy?.work_place?.name?.ar
              : vacancy?.work_place?.name?.en}
          </div>
        </div>
        <hr className="my-4" />
        <div className="font-extrabold">
          {selectedContent[localizationKeys.jobDescription]}
        </div>
        <div className="mt-2 mb-4"> {vacancy?.description}</div>
        {vacancy.status === "drafted" && (
          <div className="mb-6 flex items-center ltr:float-right rtl:float-left">
            <Button
              primary
              className="flex w-32 items-center justify-center rounded-sm bg-white text-warning ring-1 ring-warning"
              onClick={() => {
                setIsDeleting(true);
              }}
            >
              <BinIcon className="mx-1" />
              {selectedContent[localizationKeys.delete]}
            </Button>
            <Button
              primary
              className="ring-primary-900 mx-2 flex w-32 items-center justify-center rounded-sm bg-white text-primary-900 ring-1"
              onClick={() => {
                setIsEditingVacancy(true);
              }}
            >
              <EditIcon className="mx-1" />
              {selectedContent[localizationKeys.edit]}
            </Button>
          </div>
        )}
        {vacancy.status === "active" && (
          <Button
            primary
            className="mb-8 w-36 rounded-sm bg-white text-warning ring-1 ring-warning ltr:float-right rtl:float-left rtl:ml-4"
            onClick={() => {
              setIsClosingVacancy(true);
            }}
          >
            {selectedContent[localizationKeys.close]}
          </Button>
        )}
      </Modal.Content>
      <Confirm
        closeOnEscape
        className="text-center text-xl font-extrabold text-black "
        cancelButton={selectedContent[localizationKeys.no]}
        confirmButton={selectedContent[localizationKeys.yesDeleteVacancy]}
        content={
          <div className="p-8">
            <div className="text-base">
              {selectedContent[localizationKeys.deleteVacancy]}
            </div>
            <div className="mx-auto mt-4 w-80 text-base font-normal text-greyText">
              {selectedContent[localizationKeys.deleteVacancyNote]}
            </div>
          </div>
        }
        size="tiny"
        open={isDeleting}
        onCancel={() => setIsDeleting(false)}
        onConfirm={() => DeleteVacancy(vacancy?._id)}
      />
      <Confirm
        closeOnEscape
        className="text-center text-xl font-extrabold text-black "
        cancelButton={selectedContent[localizationKeys.no]}
        confirmButton={selectedContent[localizationKeys.yesCloseVacancy]}
        content={
          <div className="p-8">
            <div className="text-base">
              {selectedContent[localizationKeys.closeVacancyTitle]}
            </div>
            <div className="mx-auto mt-4 w-80 text-base font-normal text-greyText">
              {selectedContent[localizationKeys.closeVacancyNote]}
            </div>
          </div>
        }
        size="tiny"
        open={isClosingVacancy}
        onCancel={() => setIsClosingVacancy(false)}
        onConfirm={() => CloseVacancy(vacancy?._id)}
      />
      <AddVacancy
        isOpen={isEditingVacancy}
        onClose={() => {
          setIsEditingVacancy(false);
        }}
        reload={() => setForceReload((p) => !p)}
        isEditingVacancy={isEditingVacancy}
        vacancy={vacancy}
        school={school}
      />
    </Modal>
  );
};

export default ViewVacancyDetails;
