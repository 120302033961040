import React from "react";
import { Formik } from "formik";
import { Modal, Form, Button } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import FormikInput from "../../components/formik/formik-input";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import toast from "react-hot-toast";
import FormikDropdown from "../../components/formik/formik-dropdown";
import * as Yup from "yup";
import FormikRadio from "../../components/formik/formik-radio";

const AddEducationalOffice = ({ isOpen, onClose, reload, cities, city }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const {
    run: postEducationalOffice,
    isLoading: loadPostingEducationalOffice,
  } = useAxios({});

  const [gender, setGender] = React.useState("بنين");

  const addNewEducationalCenterSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(selectedContent[localizationKeys.required]),
    city: Yup.string()
      .trim()
      .required(selectedContent[localizationKeys.required]),
  });

  const handleModalClose = () => {
    onClose();
  };

  const onSubmit = (values) => {
    postEducationalOffice(
      authAxios.post(api.educationalOffices.all, { ...values, gender })
    )
      .then(() => {
        reload();
        handleModalClose();
        toast.success(selectedContent[localizationKeys.createdSuccessfully]);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      size="tiny"
      closeOnEscape={true}
      closeOnDimmerClick={true}
      className="max-w-screen-2xl "
    >
      <h3 className="pt-6 text-center text-lg font-extrabold">
        {selectedContent[localizationKeys.addEducationalOffice]}
      </h3>
      <Modal.Content>
        <Formik
          initialValues={{
            name: "",
            city: city || "",
            // gender: "",
          }}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={addNewEducationalCenterSchema}
        >
          {(formik) => (
            <Form
              onSubmit={formik.handleSubmit}
              loading={loadPostingEducationalOffice}
            >
              <div className="grid w-56 grid-cols-2 items-center justify-center">
                <span className="mt-4 text-center font-bold">
                  {selectedContent[localizationKeys.educationalOfficeName]}
                </span>
                <FormikInput fluid name="name" className="w-64" />
              </div>
              <div className="grid w-56 grid-cols-2 items-center justify-center">
                <span className="mt-4 text-center font-bold">
                  {selectedContent[localizationKeys.city]}
                </span>
                <FormikDropdown
                  className="w-64"
                  options={cities}
                  name="city"
                  onChangeCallback={(e) => console.log("")}
                />
              </div>
              <div className="grid w-56 grid-cols-2 items-center justify-center">
                <span className="mt-4 text-center font-bold">
                  {selectedContent[localizationKeys.gender]}
                </span>
                <div className="grid grid-cols-2 items-center justify-center">
                  <div className="flex items-center">
                    <FormikRadio
                      checked={gender === "بنين"}
                      className="fill-primary-900"
                      name="gender1"
                      value={gender === "بنين"}
                      onChangeCallback={(e) => {
                        setGender("بنين");
                      }}
                    />
                    <span
                      className="mt-5 font-bold hover:cursor-pointer"
                      onClick={() => setGender("بنين")}
                    >
                      {selectedContent[localizationKeys.male]}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <FormikRadio
                      checked={gender === "بنات"}
                      className="mt-2 ltr:ml-16 rtl:mr-16"
                      name="gender2"
                      value={gender === "بنات"}
                      onChangeCallback={(e) => {
                        setGender("بنات");
                      }}
                    />
                    <span
                      className="mt-5 font-bold hover:cursor-pointer"
                      onClick={() => setGender("بنات")}
                    >
                      {selectedContent[localizationKeys.female]}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex items-center pb-8 ltr:float-right rtl:float-left">
                <Button
                  type="button"
                  className="rounded-md bg-white font-bold"
                  onClick={() => handleModalClose()}
                >
                  {selectedContent[localizationKeys.cancel]}
                </Button>
                <Button
                  type="submit"
                  primary
                  className="w-28 rounded-md font-bold"
                  disabled={loadPostingEducationalOffice}
                >
                  {selectedContent[localizationKeys.create]}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default AddEducationalOffice;
