import React from "react";

function SettingsIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 18 16"
    >
      <path
        // fill="#fefefe"
        fillRule="evenodd"
        d="M3.132 12.5a1 1 0 010-.992l4-7A1 1 0 018 4h8a1 1 0 01.868.5l4 7a1 1 0 010 .992l-4 7A1 1 0 0116 20H8a1 1 0 01-.868-.5zM15.42 6l3.428 6-3.428 6H8.58l-3.428-6L8.58 6zM12 8a4 4 0 11-4 4 4 4 0 014-4zm0 2a2 2 0 102 2 2 2 0 00-2-2z"
        transform="translate(-3 -4)"
      ></path>
    </svg>
  );
}

export default SettingsIcon;
