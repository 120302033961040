import React from "react";

function JobsGreenIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 44.221 39.799"
    >
      <path
        fill="#69bd3d"
        d="M42.01 6.633h-9.581V3.685A3.689 3.689 0 0028.743 0H15.477a3.689 3.689 0 00-3.685 3.685v2.948H2.211A2.211 2.211 0 000 8.844V25.25a2.221 2.221 0 001.474 2.081v10.257A2.211 2.211 0 003.685 39.8h36.851a2.211 2.211 0 002.211-2.211V27.331a2.221 2.221 0 001.474-2.081V8.844a2.211 2.211 0 00-2.211-2.211zM13.266 3.685a2.211 2.211 0 012.211-2.211h13.266a2.211 2.211 0 012.211 2.211v2.948H29.48V3.685a.737.737 0 00-.737-.737H15.477a.737.737 0 00-.737.737v2.948h-1.474zm14.74 2.948H16.214V4.422h11.792zm13.267 30.955a.737.737 0 01-.737.737H3.685a.737.737 0 01-.737-.737v-10l16.214 2.232v1.871a2.211 2.211 0 002.211 2.209h1.474a2.211 2.211 0 002.211-2.211V29.82l16.214-2.236zm-17.688-5.9a.737.737 0 01-.737.737h-1.475a.737.737 0 01-.737-.737v-4.419a.737.737 0 01.737-.737h1.474a.737.737 0 01.737.737zm19.162-6.438a.737.737 0 01-.634.731l-.2.028-16.85 2.324v-1.064a2.211 2.211 0 00-2.211-2.211h-1.479a2.211 2.211 0 00-2.211 2.211v1.063L2.109 25.98a.737.737 0 01-.634-.731V8.844a.737.737 0 01.737-.737h39.8a.737.737 0 01.737.737zm0 0"
      ></path>
    </svg>
  );
}

export default JobsGreenIcon;
