import { BsInfoCircle, BsXCircle } from "react-icons/bs";
import { Popup, Progress } from "semantic-ui-react";
import { useAppMedia } from "../../components/hooks/use-media-query";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";

const InstructorsListStatus = ({
  totalInstructors,
  subjectsList,
  states: { setSelected, selected, limit },
}) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const { isSmallMobile } = useAppMedia();

  return (
    <div className="mt-8 mb-5 flex max-w-full flex-col items-center gap-x-4 text-[#909090] ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 md:max-w-[90%] md:flex-row md:ltr:ml-20 md:ltr:mr-8 md:rtl:mr-20 md:rtl:ml-8">
      <span className="flex flex-shrink justify-start whitespace-nowrap">
        {+totalInstructors}{" "}
        {totalInstructors && totalInstructors > 1
          ? selectedContent[localizationKeys.instructorsPlural]
          : selectedContent[localizationKeys.instructor]}
      </span>
      <div className="flex max-w-full flex-grow flex-row flex-wrap items-center gap-3 overflow-x-auto ltr:ml-12 rtl:mr-12">
        {subjectsList.length > 0 &&
          subjectsList
            .filter((item) => selected.includes(item._id))
            .map((subject) => (
              <div className="flex cursor-default flex-row flex-nowrap items-center gap-4 whitespace-nowrap rounded-full bg-primary-900 px-3 py-1 text-lg text-white outline outline-1 outline-primary-900 transition-[outline] hover:bg-primary-900 hover:bg-opacity-90 ">
                <span className="">{subject.name}</span>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setSelected(
                      [...selected].filter((sub) => sub !== subject._id)
                    );
                  }}
                >
                  <BsXCircle />
                </span>
              </div>
            ))}
      </div>
      <div className="-mt-1 flex items-center gap-2 text-primary-900 ltr:ml-auto rtl:mr-auto">
        <span className="whitespace-nowrap text-sm">
          {selectedContent[localizationKeys.freeTrial]}
        </span>
        <span> {limit}/3</span>
        <span className="cursor-pointer ltr:mr-5 rtl:ml-5">
          <Popup
            trigger={<BsInfoCircle />}
            position="bottom center"
            basic
            style={{ borderRadius: "0.5rem" }}
          >
            <div className="text-[#909090]">
              {selectedContent[localizationKeys.remainLimitPopup]
                .replace("{X}", limit)
                .replace("{NUM}", 20)}
            </div>
          </Popup>
        </span>
        <Progress
          color="blue"
          percent={(limit / 3) * 100}
          size="small"
          className="m-0 w-[100px] rounded-full bg-primary-900/10 text-[0.65rem]"
        />
      </div>
    </div>
  );
};

export default InstructorsListStatus;
