import { Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, Form, Modal } from "semantic-ui-react";
import * as Yup from "yup";
import { axios } from "../../config/axios-config";
import routes from "../../routes";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import toast from "react-hot-toast";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import useAxios from "../../lib/use-axios";
import FormikInput from "../../components/formik/formik-input";
import api from "../../api";
import loginImage from "../../assets/pictures/login.png";
import EmailVerificationIcon from "../../assets/icons/email-verification-icon";
import AuthNavbarComponent from "../../components/shared/auth-navbar-component";

const SignupPage = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const history = useHistory();
  const { run, isLoading } = useAxios();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [viewingEmailPopup, setIsViewingEmailPopup] = React.useState(false);
  const [countryCode] = React.useState("+966");
  const [agreeToAllTermsAndConditions, setAgreeToAllTermsAndConditions] =
    React.useState(false);

  const signupSchema = Yup.object({
    // phone: Yup.number()
    //   .typeError(selectedContent[localizationKeys.phoneMustBeNumber])
    //   .required(selectedContent[localizationKeys.required]),
    // .min(9, selectedContent[localizationKeys.phoneMustBeNineCharchters]),
    // .max(9, selectedContent[localizationKeys.phoneMustBeNineCharchters]),
    name: Yup.object().shape({
      ar: Yup.string().required(selectedContent[localizationKeys.required]),
      en: Yup.string().required(selectedContent[localizationKeys.required]),
    }),
    // "name.ar": Yup.string().required(
    //   selectedContent[localizationKeys.required]
    // ),
    // "name.en": Yup.string().required(
    //   selectedContent[localizationKeys.required]
    // ),
    email: Yup.string()
      .required(selectedContent[localizationKeys.required])
      .email(selectedContent[localizationKeys.emailError]),
    password: Yup.string()
      .min(8, selectedContent[localizationKeys.passwordError])
      .required(selectedContent[localizationKeys.required]),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        selectedContent[localizationKeys.confirmPasswordMssg]
      )
      .required(selectedContent[localizationKeys.required]),
  });

  const onSignup = (values) => {
    run(
      axios.post(api.auth.signup, {
        ...values,
        phone_country_code: countryCode,
      })
    )
      .then(() => {
        setIsViewingEmailPopup(true);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  return (
    <div>
      <AuthNavbarComponent />
      <div
        className="flex h-screen w-screen items-center justify-center "
        style={{
          backgroundImage: `url(${loginImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="mt-10 max-w-sm flex-grow justify-center rounded-lg bg-white px-4 py-8">
          <h2 className="text-center text-xl text-gray-600">
            {selectedContent[localizationKeys.welcomeMessage]}
          </h2>
          <Formik
            initialValues={{
              name: {
                ar: "",
                en: "",
              },
              // phone: "",
              email: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={signupSchema}
            onSubmit={onSignup}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit} loading={isLoading}>
                <FormikInput
                  name="name.ar"
                  placeholder={selectedContent[localizationKeys.name_ar]}
                  dir="rtl"
                  error={Boolean(
                    formik?.touched?.name?.ar && formik?.errors?.name?.ar
                  )}
                />
                <FormikInput
                  name="name.en"
                  placeholder={selectedContent[localizationKeys.name_en]}
                  dir="ltr"
                  error={Boolean(
                    formik?.touched?.name?.en && formik?.errors?.name?.en
                  )}
                />
                <FormikInput
                  name="email"
                  placeholder={selectedContent[localizationKeys.email]}
                  dir="ltr"
                />
                {/* <FormikInput
                  name="phone"
                  className="w-full"
                  dir="ltr"
                  maxLength="9"
                  placeholder={selectedContent[localizationKeys.phone]}
                  iconPosition={lang === "ar" ? "right" : "left"}
                  icon={
                    <>
                      <KsaFlagIcon
                        className="text-gray-700 absolute flex items-center justify-center mt-2 rtl:mx-2 ltr:mx-2"
                        size={24}
                        type="button"
                      />
                    </>
                  }
                >
                  <Label className="flex items-center justify-center" dir="ltr">
                    <KsaFlagIcon className="mx-1" size={24} type="button" />
                  </Label>
                  <input
                    placeholder={selectedContent[localizationKeys.phone]}
                  />
                </FormikInput> */}
                <FormikInput
                  name="password"
                  dir="ltr"
                  type={showPassword === true ? "text" : "password"}
                  placeholder={selectedContent[localizationKeys.password]}
                  icon={
                    showPassword === true ? (
                      <FaEyeSlash
                        className="absolute mt-2 flex items-center justify-center text-gray-700 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                        size={20}
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <FaEye
                        className="absolute mt-2 flex items-center justify-center text-gray-700 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                        size={20}
                        onClick={() => setShowPassword(true)}
                      />
                    )
                  }
                />
                <FormikInput
                  name="confirmPassword"
                  dir="ltr"
                  type={showConfirmPassword === true ? "text" : "password"}
                  placeholder={
                    selectedContent[localizationKeys.confirmPassword]
                  }
                  icon={
                    showConfirmPassword === true ? (
                      <FaEyeSlash
                        className="absolute mt-2 flex items-center justify-center text-gray-700 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                        size={20}
                        onClick={(e) => setShowConfirmPassword(false)}
                      />
                    ) : (
                      <FaEye
                        className="absolute mt-2 flex items-center justify-center text-gray-700 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                        size={20}
                        onClick={(e) => setShowConfirmPassword(true)}
                      />
                    )
                  }
                />
                <div className="mt-4 flex items-center">
                  <Checkbox
                    className="text-sm "
                    checked={agreeToAllTermsAndConditions}
                    onChange={() =>
                      setAgreeToAllTermsAndConditions(
                        !agreeToAllTermsAndConditions
                      )
                    }
                  />
                  <span className="mx-1">
                    {selectedContent[localizationKeys.agreeToAll]}
                  </span>
                  <span
                    className="cursor-pointer hover:underline"
                    onClick={() => {
                      history.push(routes.auth.privacyPolicy);
                    }}
                  >
                    {selectedContent[localizationKeys.termsAndConditions]}
                  </span>
                </div>
                <div className="-mt-2 h-6" />
                <Button
                  primary
                  className=" block h-12 w-full"
                  type="submit"
                  disabled={!agreeToAllTermsAndConditions}
                >
                  {selectedContent[localizationKeys.registration]}
                </Button>
              </Form>
            )}
          </Formik>
          <div className="mt-2 flex items-center justify-center">
            <div className="font-bold text-gray-600">
              {selectedContent[localizationKeys.alreadyHaveAnAccount]}
            </div>
            <a
              href
              onClick={() => history.push(routes.auth.login)}
              className="mx-2 font-bold text-primary-900 hover:cursor-pointer hover:underline"
            >
              {selectedContent[localizationKeys.loginButton]}
            </a>
          </div>
        </div>
      </div>
      <EmailVerification
        isOpen={viewingEmailPopup}
        onClose={() => {
          setIsViewingEmailPopup(false);
          history.push(routes.auth.login);
        }}
      />
    </div>
  );
};

const EmailVerification = ({ isOpen, onClose }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="tiny"
      closeOnEscape
      closeIcon
      className=""
    >
      <Modal.Content className="">
        <EmailVerificationIcon
          size="200"
          className="mx-auto h-[13rem] w-[20rem] text-center"
        />
        <div className="mx-auto mt-4  text-center text-lg font-bold">
          {selectedContent[localizationKeys.emailVerification]}
        </div>
        <div className="mx-auto mt-4 mb-4 w-9/12 text-center text-lg text-gray-400">
          {selectedContent[localizationKeys.emailVerificationText]}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default SignupPage;
