import classNames from "classnames";

const CardContainer = ({ children, className }) => {
  return (
    <div
      className={classNames("mt-8 rounded-lg bg-white p-8 shadow-md", {
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};
export default CardContainer;
