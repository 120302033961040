import React from "react";

function PeopleIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20.787 14.926"
    >
      <g
        fill="#177ba3"
        stroke="#177ba3"
        strokeWidth="0.6"
        transform="translate(.3 .3)"
      >
        <path
          d="M3.993 6.842a2.32 2.32 0 112.342-2.32 2.334 2.334 0 01-2.342 2.32zm0-3.989a1.669 1.669 0 101.69 1.669 1.682 1.682 0 00-1.69-1.669zm0 0"
          data-name="Path 16712"
        ></path>
        <path
          d="M4.935 13.266H.851A.849.849 0 010 12.421v-1.065a4 4 0 016.376-3.173.326.326 0 01-.387.524 3.35 3.35 0 00-5.338 2.649v1.065a.2.2 0 00.2.194h4.084a.326.326 0 110 .651zm0 0"
          data-name="Path 16713"
        ></path>
        <path
          d="M19.335 13.02h-4.084a.326.326 0 010-.651h4.084a.2.2 0 00.2-.194v-1.066a3.326 3.326 0 00-3.342-3.3 3.372 3.372 0 00-2.116.746.326.326 0 11-.409-.506 4.025 4.025 0 012.525-.891 3.978 3.978 0 013.993 3.954v1.065a.849.849 0 01-.851.845zm0 0"
          data-name="Path 16714"
        ></path>
        <path
          d="M16.193 6.681a2.32 2.32 0 112.342-2.32 2.334 2.334 0 01-2.342 2.32zm0-3.989a1.669 1.669 0 101.691 1.669 1.682 1.682 0 00-1.691-1.673zm0 0"
          data-name="Path 16715"
        ></path>
        <path
          d="M14.513 14.325H5.675a1.062 1.062 0 01-1.065-1.057v-1.5a5.484 5.484 0 0110.967 0v1.5a1.062 1.062 0 01-1.065 1.057zm-4.419-7.333a4.811 4.811 0 00-4.833 4.778v1.5a.41.41 0 00.413.406h8.838a.41.41 0 00.414-.406v-1.5a4.811 4.811 0 00-4.832-4.778zm0 0"
          data-name="Path 16716"
        ></path>
        <path
          d="M9.98 5.961A3 3 0 016.971 2.98a3.01 3.01 0 016.019 0 3 3 0 01-3.009 2.981zm0-5.311a2.347 2.347 0 00-2.358 2.33 2.358 2.358 0 004.716 0A2.347 2.347 0 009.98.65zm0 0"
          data-name="Path 16717"
        ></path>
      </g>
    </svg>
  );
}

export default PeopleIcon;
