import classNames from "classnames";
import { AiFillEye } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import { generatePath, Link, useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import routes from "../../routes";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { useLanguage } from "../../lib/language-context";
import TableButton from "./table-button";
import { useState } from "react";
import ResumeModal from "../instructor-profile/resume-modal";

const InstructorRow = ({ instructor, modalOpen, price, viewLimit }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [modal, setModal] = useState({ open: false, loaded: false });
  const history = useHistory();

  return (
    <div
      key={instructor._id}
      className={classNames(
        "table-header mb-3 cursor-default grid-cols-7 gap-4 text-black",
        {
          "bg-white": !instructor.viewed,
          "bg-[#E8F9FFCF]": !!instructor?.viewed,
        }
      )}
    >
      <Link
        to={{
          pathname: generatePath(routes.instructor.profilePage, {
            instructorId: instructor._id,
          }),

          // routes.instructor.profile(instructor._id),
        }}
        className="overflow-hidden text-ellipsis whitespace-nowrap text-black hover:underline"
      >
        {instructor.name?.[lang] ||
          selectedContent[localizationKeys.instructorName]}
      </Link>
      <div className="mx-auto whitespace-nowrap">
        {instructor.education_levels?.[0]?.name}
        {instructor.education_levels?.length > 1 ? "..." : ""}
      </div>
      <div className="mx-auto whitespace-nowrap">
        {instructor.subject?.[0]?.name}
        {instructor.subject?.length > 1 ? "..." : ""}
      </div>
      <div className="mx-auto whitespace-nowrap">{instructor.country}</div>
      {!instructor?.viewed ? (
        <div className="col-span-2">
          {viewLimit === 0 ? (
            <Button
              primary
              className="flex w-full  items-center justify-center rounded-md bg-primary-900 px-2  py-2 text-xs text-white hover:shadow-md"
              size="small"
              color="blue"
              // disabled={!d?.instructor?.resume_link}
              onClick={modalOpen}
            >
              {viewLimit === 0 && lang === "en"
                ? `Pay ${price} SAR to view the full instructor profile`
                : `ادفع${price} ريال سعودي لعرض ملف المعلم بالكامل`}
            </Button>
          ) : (
            <Button
              primary
              className="flex w-full items-center justify-center rounded-md bg-primary-900 px-2  py-2 text-xs text-white hover:shadow-md "
              size="small"
            >
              <Link
                to={{
                  pathname: generatePath(routes.instructor.profilePage, {
                    instructorId: instructor._id,
                  }),
                  state: { list: "instructors", price: price },
                }}
                className="mx-auto text-white"
              >
                {lang === "en"
                  ? "Click to see the full teacher profile in the trial period"
                  : "انقر لمشاهدة الملف الشخصي الكامل للمعلم في الفترة التجريبية"}
              </Link>
            </Button>
          )}
        </div>
      ) : (
        <>
          <div>
            <TableButton
              onClick={() => setModal((state) => ({ ...state, open: true }))}
              disabled={!instructor.resume_link}
            >
              <AiFillEye className="fill-primary-900 ltr:mr-1 rtl:ml-1" />
              <span>{selectedContent[localizationKeys.view]}</span>
            </TableButton>
          </div>
          <a
            href={instructor.resume_link}
            target="_blank"
            rel="noopener noreferrer"
            download={(instructor?.name?.en || "CV") + ".pdf"}
          >
            <TableButton disabled={!instructor.resume_link}>
              <BsDownload className="fill-primary-900 ltr:mr-1 rtl:ml-1" />
              <span>{selectedContent[localizationKeys.download]}</span>
            </TableButton>
          </a>
        </>
      )}
      <Link
        to={{
          pathname: generatePath(routes.instructor.profilePage, {
            instructorId: instructor._id,
          }),
          state: { list: "instructors", price: price },
        }}
        className="mx-auto"
      >
        <AiFillEye className="mx-auto cursor-pointer fill-black hover:fill-primary-900" />
      </Link>

      <ResumeModal modal={modal} setModal={setModal} instructor={instructor} />
    </div>
  );
};

export default InstructorRow;
