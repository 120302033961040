import React from "react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form, Image } from "semantic-ui-react";
import FormikInput from "../../components/formik/formik-input";
import { useLocation } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import routes from "../../routes";
import { useHistory } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import api from "../../api";
import logo from "../../assets/pictures/logo.svg";

const ResetPasswordPage = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [token, setToken] = React.useState();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const resetPasswordSchema = Yup.object({
    password: Yup.string()
      .min(8, selectedContent[localizationKeys.passwordError])
      .required(selectedContent[localizationKeys.required]),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        selectedContent[localizationKeys.confirmPasswordMssg]
      )
      .required(selectedContent[localizationKeys.required]),
  });
  React.useEffect(() => {
    if (location.search) {
      setToken(location?.search.split("=")[1]);
    }
  }, [location?.search]);

  const resetPasswordSubmit = (values) => {
    axios
      .patch(`${api.auth.resetPassword}?token=${token}`, values)
      .then(() => {
        toast.success(selectedContent[localizationKeys.resetPasswordMessage]);
        history.push(routes.auth.login);
      })
      .catch((e) => {
        e?.response?.data?.errors?.map((s) => toast.error(s?.message));
      });
  };

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="mx-auto mt-8 w-auto max-w-md flex-grow rounded-xl bg-white p-8">
        <Image src={logo} className=" mx-auto mb-4 h-12" />
        <h3 className="text-primary-900 text-center">
          {selectedContent[localizationKeys.resetPassword]}
        </h3>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={resetPasswordSchema}
          onSubmit={resetPasswordSubmit}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <FormikInput
                name="password"
                type={showPassword === true ? "text" : "password"}
                dir="ltr"
                label={selectedContent[localizationKeys.password]}
                icon={
                  showPassword === true ? (
                    <FaEyeSlash
                      className="absolute mt-2 flex items-center justify-center text-gray-700 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                      size={20}
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <FaEye
                      className="absolute mt-2 flex items-center justify-center text-gray-700 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                      size={20}
                      onClick={() => setShowPassword(true)}
                    />
                  )
                }
              />
              <FormikInput
                name="confirmPassword"
                type={showConfirmPassword === true ? "text" : "password"}
                dir="ltr"
                label={selectedContent[localizationKeys.confirmPassword]}
                icon={
                  showConfirmPassword === true ? (
                    <FaEyeSlash
                      className="absolute mt-2 flex items-center justify-center text-gray-700 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                      size={20}
                      onClick={(e) => setShowConfirmPassword(false)}
                    />
                  ) : (
                    <FaEye
                      className="absolute mt-2 flex items-center justify-center text-gray-700 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                      size={20}
                      onClick={(e) => setShowConfirmPassword(true)}
                    />
                  )
                }
              />
              <Button
                primary
                className="my-4 mx-auto block h-14 w-96"
                type="submit"
              >
                {selectedContent[localizationKeys.submit]}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
