import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Form, Modal } from "semantic-ui-react";

import localizationKeys from "../../localization/localization-keys";
import content from "../../localization/content";
import { useLanguage } from "../../lib/language-context";
import KsaFlagIcon from "../../assets/icons/ksa-flag-icon";
import CustomerServiceIcon from "../../assets/icons/customer-service-icon";
import Lottie from "react-lottie-player";
import lottieJson from "../../assets/pictures/my-lottie.json";
import FormikInput from "../../components/formik/formik-input";
import FormikTextArea from "../../components/formik/formik-textArea";
import useMediaQuery from "../../components/hooks/use-media-query";

const ContactUs = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [submitted, setSubmitted] = React.useState(false);
  const isSmall = useMediaQuery("(max-width: 768px)");

  const contactusSchema = Yup.object({
    name: Yup.string().required(selectedContent[localizationKeys.required]),
    email: Yup.string()
      .required(selectedContent[localizationKeys.required])
      .email(selectedContent[localizationKeys.emailError]),
    phone: Yup.number()
      .typeError(selectedContent[localizationKeys.phoneMustBeNumber])
      .required(selectedContent[localizationKeys.required]),
    description: Yup.string().required(
      selectedContent[localizationKeys.required]
    ),
  });

  const onSubmition = (values, { resetForm }) => {
    // console.log(values);
    setSubmitted(true);
    resetForm();
  };

  return (
    <div id="contact-us">
      {isSmall ? (
        <div>
          <div className="mx-auto mt-20 mb-2 h-1 w-5 bg-primary-900 "></div>
          <div className="mx-2 text-center text-lg font-bold">
            {selectedContent[localizationKeys.contactUs]}
          </div>
          <CustomerServiceIcon className="mx-auto h-72 w-72" />
          <div className="mx-6 text-center text-base font-medium">
            {selectedContent[localizationKeys.contactUsBody]}.
          </div>
          <div className="mx-10">
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                description: "",
              }}
              validationSchema={contactusSchema}
              onSubmit={onSubmition}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit} className="">
                  <FormikInput
                    name="name"
                    placeholder={selectedContent[localizationKeys.fullName]}
                  />
                  <FormikInput
                    name="email"
                    placeholder={selectedContent[localizationKeys.email]}
                    dir="ltr"
                  />
                  <FormikInput
                    name="phone"
                    className="w-full"
                    dir="ltr"
                    maxLength="9"
                    placeholder={selectedContent[localizationKeys.phone]}
                    iconPosition={lang === "ar" ? "right" : "left"}
                    icon={
                      <>
                        <KsaFlagIcon
                          className="absolute mt-2 flex items-center justify-center text-gray-700 ltr:mx-2 rtl:mx-2"
                          size={24}
                          type="button"
                        />
                      </>
                    }
                  ></FormikInput>
                  <FormikTextArea
                    name="description"
                    rows={6}
                    className=""
                    placeholder={selectedContent[localizationKeys.writeHere]}
                  />
                  <Button
                    primary
                    className="mt-3 mb-8 w-full"
                    size="large"
                    type="submit"
                  >
                    {selectedContent[localizationKeys.send]}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="mt-16 mb-8 grid grid-cols-2">
          <div
            className="flex ltr:pl-20
       rtl:pr-20"
          >
            <div>
              <div className="mb-2 h-1 w-10 bg-primary-900"></div>
              <div className="mb-2 text-2xl font-bold">
                {selectedContent[localizationKeys.contactUs]}
              </div>
              <CustomerServiceIcon className="h-72 w-72 " />
              <div className="text-xl font-medium lg:w-96">
                {selectedContent[localizationKeys.contactUsBody]}
              </div>
            </div>
          </div>
          <div className="w-8/12">
            {!submitted ? (
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  description: "",
                }}
                validationSchema={contactusSchema}
                onSubmit={onSubmition}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit} className="">
                    <FormikInput
                      name="name"
                      placeholder={selectedContent[localizationKeys.fullName]}
                    />
                    <FormikInput
                      name="email"
                      placeholder={selectedContent[localizationKeys.email]}
                      dir="ltr"
                    />
                    <FormikInput
                      name="phone"
                      className="w-full"
                      dir="ltr"
                      maxLength="9"
                      placeholder={selectedContent[localizationKeys.phone]}
                      iconPosition={lang === "ar" ? "right" : "left"}
                      icon={
                        <>
                          <KsaFlagIcon
                            className="absolute mt-2 flex items-center justify-center text-gray-700 ltr:mx-2 rtl:mx-2"
                            size={24}
                            type="button"
                          />
                        </>
                      }
                    ></FormikInput>
                    <FormikTextArea
                      name="description"
                      rows={6}
                      className=""
                      placeholder={selectedContent[localizationKeys.writeHere]}
                    />
                    <Button
                      primary
                      className="mt-3 w-full"
                      size="large"
                      type="submit"
                    >
                      {selectedContent[localizationKeys.send]}
                    </Button>
                  </Form>
                )}
              </Formik>
            ) : (
              <div className="flex items-center justify-center rounded-lg ring-1 ring-secondary-300 lg:h-[26rem] lg:w-[33rem]">
                <div>
                  <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={{ width: 240, height: 240 }}
                  />
                  <div className="mb-6 text-center text-xl font-bold">
                    {selectedContent[localizationKeys.sendSuccessfully]}.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {submitted && isSmall ? (
        <FormSubmittedSuccessfully
          isOpen={submitted && isSmall}
          onClose={() => {
            setSubmitted(false);
          }}
        />
      ) : null}
    </div>
  );
};

const FormSubmittedSuccessfully = ({ isOpen, onClose }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
      onClose();
    }, 2000);
    return () => {
      clearTimeout();
    };
  }, [isOpen, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="tiny"
      closeOnEscape
      closeIcon
      className="max-w-xl"
    >
      <Modal.Content className="flex items-center justify-center">
        <div>
          <Lottie
            loop={false}
            animationData={lottieJson}
            play
            style={{ width: 180, height: 180 }}
          />
          <div className="mb-6 text-center text-xl font-bold">
            {selectedContent[localizationKeys.sendSuccessfully]}.
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ContactUs;
