import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import routes from "../routes";
import React from "react";

function PrivateRoute({ component: Component, ...rest }) {
  const isCompleteProfile = localStorage.getItem("isCompleteProfile");

  return (
    <Route
      {...rest}
      render={(props) =>
        isCompleteProfile === "true" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.auth.landingPage,
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
