import React from "react";

function CompleteProfileIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 287.467 258.659"
    >
      <g data-name="Fill out-bro" transform="translate(-9.795 -41.5)">
        <g transform="translate(12.421 42)">
          <path
            fill="#177ba3"
            d="M285.774 196.389c-8.881 23.774-27.018 49.508-52.541 57.13-38.589 11.516-43.171 42.6-112.245 39.716a139.615 139.615 0 01-29.882-4.377 117.221 117.221 0 01-14.043-4.673c-40.344-16.347-61.393-52.319-63.135-89.955-2.3-49.508 32.234-73.1 54.682-86.915s49.5-67.356 117.424-65.052a135.1 135.1 0 0130.7 4.486c22.858 6.186 46.09 19.9 59.6 39.867q1.519 2.249 2.906 4.607c18.074 30.659 18.635 72.751 6.534 105.166z"
            data-name="Path 16582"
            transform="translate(-13.818 -42.076)"
          ></path>
          <path
            fill="#fff"
            d="M285.774 196.389c-8.881 23.774-27.018 49.508-52.541 57.13-38.589 11.516-43.171 42.6-112.245 39.716a139.615 139.615 0 01-29.882-4.377 117.221 117.221 0 01-14.043-4.673c-40.344-16.347-61.393-52.319-63.135-89.955-2.3-49.508 32.234-73.1 54.682-86.915s49.5-67.356 117.424-65.052a135.1 135.1 0 0130.7 4.486c22.858 6.186 46.09 19.9 59.6 39.867q1.519 2.249 2.906 4.607c18.074 30.659 18.635 72.751 6.534 105.166z"
            data-name="Path 16583"
            opacity="0.7"
            transform="translate(-13.818 -42.076)"
          ></path>
          <g
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
            data-name="Group 22296"
            opacity="0.2"
            transform="translate(46.537)"
          >
            <path d="M0 0H66.614V187.433H0z" data-name="Rectangle 5233"></path>
            <path
              d="M.356 5.698h66.126L64.926.549"
              data-name="Path 16584"
            ></path>
            <path
              d="M.356 11.938h66.126l-1.556-5.149"
              data-name="Path 16585"
            ></path>
            <path
              d="M.356 18.179h66.126l-1.556-5.155"
              data-name="Path 16586"
            ></path>
            <path
              d="M.356 24.419h66.126l-1.556-5.155"
              data-name="Path 16587"
            ></path>
            <path
              d="M.356 30.66h66.126l-1.556-5.155"
              data-name="Path 16588"
            ></path>
            <path
              d="M.356 36.9h66.126l-1.556-5.155"
              data-name="Path 16589"
            ></path>
            <path
              d="M.356 43.141h66.126l-1.556-5.155"
              data-name="Path 16590"
            ></path>
            <path
              d="M.356 49.381h66.126l-1.556-5.155"
              data-name="Path 16591"
            ></path>
            <path
              d="M.356 55.622h66.126l-1.556-5.155"
              data-name="Path 16592"
            ></path>
            <path
              d="M.356 61.862h66.126l-1.556-5.155"
              data-name="Path 16593"
            ></path>
            <path
              d="M.356 68.103h66.126l-1.556-5.155"
              data-name="Path 16594"
            ></path>
            <path
              d="M.356 74.344h66.126l-1.556-5.155"
              data-name="Path 16595"
            ></path>
            <path
              d="M.356 80.584h66.126l-1.556-5.155"
              data-name="Path 16596"
            ></path>
            <path
              d="M.356 86.825h66.126l-1.556-5.155"
              data-name="Path 16597"
            ></path>
            <path
              d="M.356 93.059h66.126l-1.556-5.149"
              data-name="Path 16598"
            ></path>
            <path
              d="M.356 99.301h66.126l-1.556-5.149"
              data-name="Path 16599"
            ></path>
            <path
              d="M.356 105.54h66.126l-1.556-5.149"
              data-name="Path 16600"
            ></path>
            <path
              d="M.356 111.782h66.126l-1.556-5.149"
              data-name="Path 16601"
            ></path>
            <path
              d="M.356 118.021h66.126l-1.556-5.149"
              data-name="Path 16602"
            ></path>
            <path
              d="M.356 124.263h66.126l-1.556-5.149"
              data-name="Path 16603"
            ></path>
            <path
              d="M.356 130.502h66.126l-1.556-5.149"
              data-name="Path 16604"
            ></path>
            <path
              d="M.356 136.744h66.126l-1.556-5.149"
              data-name="Path 16605"
            ></path>
            <path
              d="M.356 142.983h66.126l-1.556-5.155"
              data-name="Path 16606"
            ></path>
            <path
              d="M.356 149.229h66.126l-1.556-5.155"
              data-name="Path 16607"
            ></path>
            <path
              d="M.356 155.464h66.126l-1.556-5.155"
              data-name="Path 16608"
            ></path>
            <path
              d="M.356 161.71h66.126l-1.556-5.155"
              data-name="Path 16609"
            ></path>
            <path
              d="M.356 167.945h66.126l-1.556-5.155"
              data-name="Path 16610"
            ></path>
            <path
              d="M.356 174.191h66.126l-1.556-5.155"
              data-name="Path 16611"
            ></path>
            <path
              d="M.356 180.427h66.126l-1.556-5.155"
              data-name="Path 16612"
            ></path>
          </g>
          <g
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
            data-name="Group 22297"
            opacity="0.2"
            transform="translate(118.167)"
          >
            <path d="M0 0H66.614V187.433H0z" data-name="Rectangle 5234"></path>
            <path
              d="M.356 5.698h66.126L64.932.549"
              data-name="Path 16613"
            ></path>
            <path
              d="M.356 11.938h66.126l-1.55-5.149"
              data-name="Path 16614"
            ></path>
            <path
              d="M.356 18.179h66.126l-1.55-5.155"
              data-name="Path 16615"
            ></path>
            <path
              d="M.356 24.419h66.126l-1.55-5.155"
              data-name="Path 16616"
            ></path>
            <path
              d="M.356 30.66h66.126l-1.55-5.155"
              data-name="Path 16617"
            ></path>
            <path
              d="M.356 36.9h66.126l-1.55-5.155"
              data-name="Path 16618"
            ></path>
            <path
              d="M.356 43.141h66.126l-1.55-5.155"
              data-name="Path 16619"
            ></path>
            <path
              d="M.356 49.381h66.126l-1.55-5.155"
              data-name="Path 16620"
            ></path>
            <path
              d="M.356 55.622h66.126l-1.55-5.155"
              data-name="Path 16621"
            ></path>
            <path
              d="M.356 61.862h66.126l-1.55-5.155"
              data-name="Path 16622"
            ></path>
            <path
              d="M.356 68.103h66.126l-1.55-5.155"
              data-name="Path 16623"
            ></path>
            <path
              d="M.356 74.344h66.126l-1.55-5.155"
              data-name="Path 16624"
            ></path>
            <path
              d="M.356 80.584h66.126l-1.55-5.155"
              data-name="Path 16625"
            ></path>
            <path
              d="M.356 86.825h66.126l-1.55-5.155"
              data-name="Path 16626"
            ></path>
            <path
              d="M.356 93.059h66.126l-1.55-5.149"
              data-name="Path 16627"
            ></path>
            <path
              d="M.356 99.301h66.126l-1.55-5.149"
              data-name="Path 16628"
            ></path>
            <path
              d="M.356 105.54h66.126l-1.55-5.149"
              data-name="Path 16629"
            ></path>
            <path
              d="M.356 111.782h66.126l-1.55-5.149"
              data-name="Path 16630"
            ></path>
            <path
              d="M.356 118.021h66.126l-1.55-5.149"
              data-name="Path 16631"
            ></path>
            <path
              d="M.356 124.263h66.126l-1.55-5.149"
              data-name="Path 16632"
            ></path>
            <path
              d="M.356 130.502h66.126l-1.55-5.149"
              data-name="Path 16633"
            ></path>
            <path
              d="M.356 136.744h66.126l-1.55-5.149"
              data-name="Path 16634"
            ></path>
            <path
              d="M.356 142.983h66.126l-1.55-5.155"
              data-name="Path 16635"
            ></path>
            <path
              d="M.356 149.229h66.126l-1.55-5.155"
              data-name="Path 16636"
            ></path>
            <path
              d="M.356 155.464h66.126l-1.55-5.155"
              data-name="Path 16637"
            ></path>
            <path
              d="M.356 161.71h66.126l-1.55-5.155"
              data-name="Path 16638"
            ></path>
            <path
              d="M.356 167.945h66.126l-1.55-5.155"
              data-name="Path 16639"
            ></path>
            <path
              d="M.356 174.191h66.126l-1.55-5.155"
              data-name="Path 16640"
            ></path>
            <path
              d="M.356 180.427h66.126l-1.55-5.155"
              data-name="Path 16641"
            ></path>
          </g>
        </g>
        <g
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="1"
          transform="translate(18.361 43.61)"
        >
          <g
            data-name="Group 22298"
            opacity="0.3"
            transform="translate(0 5.824)"
          >
            <path
              d="M0 0H10.853V10.853H0z"
              data-name="Rectangle 5235"
              transform="translate(0 4.426)"
            ></path>
            <path
              d="M0 0H10.853V10.853H0z"
              data-name="Rectangle 5236"
              transform="translate(0 22.912)"
            ></path>
            <path
              d="M0 0L16.485 0"
              data-name="Line 667"
              transform="translate(14.869 10.051)"
            ></path>
            <path
              d="M0 0L16.485 0"
              data-name="Line 668"
              transform="translate(14.869 28.14)"
            ></path>
            <path
              d="M2.412 7.639l1.6 3.214L11.251 0"
              data-name="Path 16642"
            ></path>
          </g>
          <g
            data-name="Group 22299"
            opacity="0.3"
            transform="translate(160.385 222.284)"
          >
            <path
              d="M0 0H10.853V10.853H0z"
              data-name="Rectangle 5237"
              transform="translate(0 4.426)"
            ></path>
            <path
              d="M0 0H10.853V10.853H0z"
              data-name="Rectangle 5238"
              transform="translate(0 22.912)"
            ></path>
            <path
              d="M0 0L16.485 0"
              data-name="Line 669"
              transform="translate(14.869 10.051)"
            ></path>
            <path
              d="M0 0L16.485 0"
              data-name="Line 670"
              transform="translate(14.869 28.14)"
            ></path>
            <path
              d="M2.412 7.639l1.6 3.214L11.247 0"
              data-name="Path 16643"
            ></path>
          </g>
          <g
            data-name="Group 22300"
            opacity="0.3"
            transform="translate(196.761)"
          >
            <path
              d="M0 0H10.853V10.853H0z"
              data-name="Rectangle 5239"
              transform="translate(0 4.42)"
            ></path>
            <path
              d="M0 0H10.853V10.853H0z"
              data-name="Rectangle 5240"
              transform="translate(0 22.912)"
            ></path>
            <path
              d="M0 0L16.479 0"
              data-name="Line 671"
              transform="translate(14.875 10.045)"
            ></path>
            <path
              d="M0 0L16.479 0"
              data-name="Line 672"
              transform="translate(14.875 28.134)"
            ></path>
            <path
              d="M2.412 7.63l1.61 3.22L11.257-.003"
              data-name="Path 16644"
            ></path>
          </g>
        </g>
        <g
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(19.163 287.316)"
        >
          <path
            d="M0 0L8.972 0"
            data-name="Line 673"
            transform="translate(268.626)"
          ></path>
          <path d="M0 0L264.448 0" data-name="Line 674"></path>
        </g>
        <g transform="translate(10.3 201.115)">
          <ellipse
            cx="7.024"
            cy="18.42"
            fill="#177ba3"
            stroke="#263238"
            strokeWidth="1"
            data-name="Ellipse 177"
            rx="7.024"
            ry="18.42"
            transform="translate(34.706 8.897)"
          ></ellipse>
          <ellipse
            cx="13.126"
            cy="5.716"
            fill="#177ba3"
            stroke="#263238"
            strokeWidth="1"
            data-name="Ellipse 178"
            rx="13.126"
            ry="5.716"
            transform="rotate(-72.37 41.443 -20.768)"
          ></ellipse>
          <path
            fill="#177ba3"
            stroke="#263238"
            strokeWidth="1"
            d="M12.023 57.621C4.872 56.415-.494 52.906.036 49.783s6.747-4.655 13.868-3.443 12.517 4.715 11.993 7.838-6.729 4.649-13.874 3.443z"
            data-name="Path 16645"
          ></path>
          <ellipse
            cx="15.224"
            cy="7.024"
            fill="#177ba3"
            stroke="#263238"
            strokeWidth="1"
            data-name="Ellipse 179"
            rx="15.224"
            ry="7.024"
            transform="rotate(-44.77 74.947 -39.374)"
          ></ellipse>
          <path
            fill="#177ba3"
            stroke="#263238"
            strokeWidth="1"
            d="M29.02 24.351c5.77 8.375 7.495 17.208 3.847 19.729s-11.293-2.237-17.063-10.612-7.495-17.2-3.847-19.722 11.292 2.23 17.063 10.605z"
            data-name="Path 16646"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M0 0L0.543 25.324"
            data-name="Line 675"
            transform="translate(42.225 44.815)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M0 0L0.657 24.347"
            data-name="Line 676"
            transform="translate(41.567 20.468)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M0.048 0L0 1.863"
            data-name="Line 677"
            transform="translate(41.567 16.494)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M32.837 42.873a70.756 70.756 0 016.94 26.578"
            data-name="Path 16647"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M20.778 24.423a83.208 83.208 0 0112.059 18.45"
            data-name="Path 16648"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M16.979 20.407s.73.663 1.923 1.935"
            data-name="Path 16649"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M54.845 45.478a82.971 82.971 0 00-11.384 22.593"
            data-name="Path 16650"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M62.924 36.193a66.116 66.116 0 00-8.079 9.285"
            data-name="Path 16651"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M71.57 29.608a36.485 36.485 0 00-6.15 4.359"
            data-name="Path 16652"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M54.549 25.026c-3.9 12.24-9.117 30.334-11.553 46.035"
            data-name="Path 16653"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M56.919 17.76c-.693 2.068-1.5 4.516-2.37 7.235"
            data-name="Path 16654"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M59.343 10.736s-.675 1.875-1.773 5.089"
            data-name="Path 16655"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M25.559 52.484a19.247 19.247 0 0111.91 12.131"
            data-name="Path 16656"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M16.581 50.657a30.479 30.479 0 018.978 1.809"
            data-name="Path 16657"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M10.763 50.802s.868-.127 2.291-.181"
            data-name="Path 16658"
          ></path>
          <path
            fill="#177ba3"
            stroke="#263238"
            strokeWidth="1"
            d="M73.192 57.621c7.151-1.206 12.517-4.715 11.987-7.838s-6.747-4.655-13.868-3.443-12.517 4.715-11.993 7.838 6.729 4.649 13.874 3.443z"
            data-name="Path 16659"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M59.813 52.424a19.2 19.2 0 00-12.059 12.192"
            data-name="Path 16660"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M69.388 50.621a31.126 31.126 0 00-9.575 1.809"
            data-name="Path 16661"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeWidth="1"
            d="M74.453 50.802s-.567-.084-1.55-.145"
            data-name="Path 16662"
          ></path>
          <path
            fill="#263238"
            d="M85.15 433.38a34.139 34.139 0 003.316-14.471 29.875 29.875 0 00-1.326-8.869H49.756a29.876 29.876 0 00-1.326 8.869 34.055 34.055 0 003.322 14.471z"
            data-name="Path 16663"
            transform="translate(-25.439 -347.245)"
          ></path>
          <path
            fill="#7a7a7a"
            d="M48.837 416.88a28.477 28.477 0 00-.247 2.068h39.825c-.06-.7-.145-1.387-.253-2.068z"
            data-name="Path 16664"
            transform="translate(-25.503 -349.961)"
          ></path>
          <path
            fill="#7a7a7a"
            d="M88.058 433.078c.127-.687.235-1.375.32-2.068H48.77c.078.693.187 1.381.314 2.068z"
            data-name="Path 16665"
            transform="translate(-25.574 -355.571)"
          ></path>
          <path
            fill="#8c8c8c"
            d="M54.282 423.836a1.616 1.616 0 11-1.61-1.616 1.616 1.616 0 011.61 1.616z"
            data-name="Path 16666"
            transform="translate(-26.48 -352.081)"
          ></path>
          <path
            fill="#8c8c8c"
            d="M67.65 423.836a1.61 1.61 0 11-.469-1.142 1.61 1.61 0 01.469 1.142z"
            data-name="Path 16667"
            transform="translate(-31.792 -352.081)"
          ></path>
          <path
            fill="#8c8c8c"
            d="M81.012 423.836a1.616 1.616 0 11-1.61-1.616 1.616 1.616 0 011.61 1.616z"
            data-name="Path 16668"
            transform="translate(-37.093 -352.081)"
          ></path>
          <path
            fill="#8c8c8c"
            d="M94.38 423.836a1.61 1.61 0 11-.469-1.142 1.61 1.61 0 01.469 1.142z"
            data-name="Path 16669"
            transform="translate(-42.405 -352.081)"
          ></path>
          <path
            fill="#8c8c8c"
            d="M107.742 423.836a1.616 1.616 0 11-1.61-1.616 1.616 1.616 0 011.61 1.616z"
            data-name="Path 16670"
            transform="translate(-47.706 -352.081)"
          ></path>
        </g>
        <g transform="translate(141.785 64.11)">
          <path
            fill="#263238"
            d="M0 0H141.259V199.745H0z"
            data-name="Rectangle 5241"
            transform="translate(3.491 3.491)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0H141.259V199.745H0z"
            data-name="Rectangle 5242"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0H128.374V36.183H0z"
            data-name="Rectangle 5243"
            transform="translate(5.951 19.825)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0H42.128V7.434H0z"
            data-name="Rectangle 5244"
            transform="translate(5.951 12.391)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0H42.128V7.434H0z"
            data-name="Rectangle 5245"
            transform="translate(92.191 12.391)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L128.374 0"
            data-name="Line 678"
            transform="translate(5.951 31.721)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L128.374 0"
            data-name="Line 679"
            transform="translate(5.951 43.123)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0H128.374V36.183H0z"
            data-name="Rectangle 5246"
            transform="translate(5.951 67.151)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L128.374 0"
            data-name="Line 680"
            transform="translate(5.951 79.047)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L128.374 0"
            data-name="Line 681"
            transform="translate(5.951 90.449)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.61 0"
            data-name="Line 682"
            transform="translate(8.043 22.731)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.61 0"
            data-name="Line 683"
            transform="translate(87.657 22.731)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.61 0"
            data-name="Line 684"
            transform="translate(8.043 14.266)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.61 0"
            data-name="Line 685"
            transform="translate(92.848 14.266)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.61 0"
            data-name="Line 686"
            transform="translate(8.043 34.012)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.61 0"
            data-name="Line 687"
            transform="translate(8.043 46.421)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.604 0"
            data-name="Line 688"
            transform="translate(56.255 46.421)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L31.215 0"
            data-name="Line 689"
            transform="translate(53.741 12.951)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L31.215 0"
            data-name="Line 690"
            transform="translate(53.741 16.147)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L7.079 0"
            data-name="Line 691"
            transform="translate(50.594 53.168)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L7.079 0"
            data-name="Line 692"
            transform="translate(59.439 53.168)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L7.079 0"
            data-name="Line 693"
            transform="translate(68.284 53.168)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0H128.374V36.183H0z"
            data-name="Rectangle 5247"
            transform="translate(5.951 110.937)"
          ></path>
          <path
            fill="none"
            stroke="#177ba3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M8.767 120.247s2.412-5.969.953-4.54-2.629 6.452.953 5.258 5.969-6.452 5.017-4.3-.476 3.817 2.412 2.147 3.34-1.67 3.582-.718.953.718 3.582-.235 2.412.718 2.412 1.429 3.346.482 4.54 0 2.147-1.905 2.412-.235 2.623 1.67 2.623 1.67h6.446"
            data-name="Path 16671"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L128.374 0"
            data-name="Line 694"
            transform="translate(5.951 122.833)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L128.374 0"
            data-name="Line 695"
            transform="translate(5.951 134.235)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.61 0"
            data-name="Line 696"
            transform="translate(8.043 113.843)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.61 0"
            data-name="Line 697"
            transform="translate(87.657 113.843)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.61 0"
            data-name="Line 698"
            transform="translate(8.043 125.124)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.61 0"
            data-name="Line 699"
            transform="translate(8.043 137.533)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.604 0"
            data-name="Line 700"
            transform="translate(56.255 137.533)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L7.079 0"
            data-name="Line 701"
            transform="translate(50.594 144.28)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L7.079 0"
            data-name="Line 702"
            transform="translate(59.439 144.28)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L7.079 0"
            data-name="Line 703"
            transform="translate(68.284 144.28)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L36.708 0"
            data-name="Line 704"
            transform="translate(9.02 70.859)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L22.556 0"
            data-name="Line 705"
            transform="translate(9.02 82.797)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L22.556 0"
            data-name="Line 706"
            transform="translate(66.071 82.797)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L22.556 0"
            data-name="Line 707"
            transform="translate(101.458 97.841)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L22.556 0"
            data-name="Line 708"
            transform="translate(96.593 82.797)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0H5.306V5.306H0z"
            data-name="Rectangle 5248"
            transform="translate(92.167 95.182)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L22.556 0"
            data-name="Line 709"
            transform="translate(64.745 97.841)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0H5.306V5.306H0z"
            data-name="Rectangle 5249"
            transform="translate(55.459 95.182)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L22.556 0"
            data-name="Line 710"
            transform="translate(28.037 97.841)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0H5.306V5.306H0z"
            data-name="Rectangle 5250"
            transform="translate(18.746 95.182)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L14.79 0"
            data-name="Line 711"
            transform="translate(118.95 154.005)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L56.798 0"
            data-name="Line 712"
            transform="translate(60.247 154.005)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L34.881 0"
            data-name="Line 713"
            transform="translate(23.654 154.005)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.508 0"
            data-name="Line 714"
            transform="translate(6.807 154.005)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L32.324 0"
            data-name="Line 715"
            transform="translate(101.416 157.436)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L60.229 0"
            data-name="Line 716"
            transform="translate(39.662 157.436)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L30.949 0"
            data-name="Line 717"
            transform="translate(6.807 157.436)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L23.937 0"
            data-name="Line 718"
            transform="translate(109.803 160.867)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L50.129 0"
            data-name="Line 719"
            transform="translate(58.341 160.867)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L26.681 0"
            data-name="Line 720"
            transform="translate(28.99 160.867)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L20.464 0"
            data-name="Line 721"
            transform="translate(6.807 160.867)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L10.787 0"
            data-name="Line 722"
            transform="translate(122.954 164.298)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L106.167 0"
            data-name="Line 723"
            transform="translate(14.881 164.298)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L6.554 0"
            data-name="Line 724"
            transform="translate(6.807 164.298)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L8.309 0"
            data-name="Line 725"
            transform="translate(125.432 167.729)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L24.781 0"
            data-name="Line 726"
            transform="translate(98.938 167.729)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L39.264 0"
            data-name="Line 727"
            transform="translate(58.341 167.729)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L36.406 0"
            data-name="Line 728"
            transform="translate(20.41 167.729)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L11.504 0"
            data-name="Line 729"
            transform="translate(6.807 167.729)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L14.977 0"
            data-name="Line 730"
            transform="translate(118.763 171.159)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L45.933 0"
            data-name="Line 731"
            transform="translate(70.732 171.159)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L28.592 0"
            data-name="Line 732"
            transform="translate(39.662 171.159)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L30.949 0"
            data-name="Line 733"
            transform="translate(6.807 171.159)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L36.901 0"
            data-name="Line 734"
            transform="translate(96.84 174.596)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L70.521 0"
            data-name="Line 735"
            transform="translate(23.654 174.596)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L14.748 0"
            data-name="Line 736"
            transform="translate(6.807 174.596)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.924 0"
            data-name="Line 737"
            transform="translate(8.134 184.967)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.924 0"
            data-name="Line 738"
            transform="translate(86.861 184.967)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L53.518 0"
            data-name="Line 739"
            transform="translate(28.037 184.967)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L26.096 0"
            data-name="Line 740"
            transform="translate(107.205 184.967)"
          ></path>
          <path
            fill="none"
            stroke="#177ba3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M10.124 77.564a2.5 2.5 0 003.015-2.273c.555-2.84-1.706-1.326-1.706.187s.76 3.015 3.618.76 2.08-1.893 3.787-.193.187 2.086 3.618.76 3.618-.76 3.407.38-.947.567 2.273-.567 4.733-2.086 4.353-.6-1.706 1.893.754.193 3.979-7.193 1.893-3.015-2.84 3.618.193 3.015 3.973-2.653 3.973-1.14-2.46 2.466 0 1.519 3.407-1.893 3.407-.947-2.273 2.647.947 1.134 4.166-3.22 4.166-1.893-.573.947 0 1.326-.573-.76 1.134-.193.947 1.706 3.407.76 2.273-2.273 3.618-.947 0 3.22 3.618 1.513 4.54-.754 5.68-.567a16.525 16.525 0 002.84.187c.754 0 .567-.754 2.46 0a7.531 7.531 0 003.618.76h2.159"
            data-name="Path 16672"
          ></path>
          <path
            fill="none"
            stroke="#177ba3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M87.916 77.75a26.21 26.21 0 002.647-1.326c1.706-.947 6.06-4.16 6.247-2.08s-4.733 4.733-4.16 2.46 3.973-1.134 6.434-.187 3.015-.193 6.06-.193 2.647.947 4.16 1.326.947 0 3.618-1.134.754-6.632-.947-2.84-2.273 5.3 1.7 3.407 2.84.38 3.407.38-.38.187 1.893-.76-.567.38 1.14.76 2.647-1.519 4.16-2.086 1.706 1.14 1.706 1.14h1.513"
            data-name="Path 16673"
          ></path>
          <path
            fill="none"
            stroke="#177ba3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M8.984 41.2s.947-.38 2.46-2.647-.947-3.015-1.513-.567-1.893 5.3 2.273 3.214 2.647-6.03 1.327-3.015-.947 6.06 3.015 3.787 4.353-3.618 4.92-2.46-1.513 1.513 1.893 1.7 3.973-.947 5.487-1.513a2.454 2.454 0 012.273 0 4.534 4.534 0 001.327.193c.754 0 1.7-.6 2.08 0s-3.787 1.32 1.14.567 6.029-3.979 6.433-1.513-3.407 4.54-3.407 2.267 2.273-4.54 5.867-2.46 3.407 2.653 5.3 3.015 4.92-1.7 4.92-1.7-.187.187.947.754.76.573 2.84 0 .38-.187 3.22-.567 4.16-.187 3.015.76-4.353-.38-.187-1.134 4.727.567 4.727 1.134 1.14.754 2.086.38a14.127 14.127 0 012.84-.573"
            data-name="Path 16674"
          ></path>
          <path
            fill="none"
            stroke="#177ba3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M13.904 30.051a29.3 29.3 0 004.486-1.115 6.194 6.194 0 002.9-1.724c1.32-1.893-2.653-1.326-5.113 1.14s1.513 2.647 6.632.947 4.92-6.06 1.513-2.84-2.08 3.973 3.22 2.84 11.92-2.466 9.84-.6-2.647.947.947.193 5.867-2.46 5.3-1.14-2.08 3.015.76 1.706 2.46 0 2.46 0c5.107-.567 7.947-7.006 4.353-4.733s-3.618 5.3-.38 5.113 6.247-3.22 4.733-1.706-3.973 2.653 1.32 1.326 7.006-4.166 6.632-2.273-.567 1.706 1.327 1.706 5.3-2.653 4.166-.76-1.706 2.08 0 1.706 4.16-1.706 4.92-1.706-1.513 2.08.754 1.893 7.006-3.015 7.006-3.015"
            data-name="Path 16675"
          ></path>
          <path
            fill="none"
            stroke="#177ba3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M76.352 27.024s4.733 3.214 5.867 3.214"
            data-name="Path 16676"
          ></path>
          <path
            fill="none"
            stroke="#177ba3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M56.744 96.538l.561 2.629 5.818-6.94"
            data-name="Path 16677"
          ></path>
        </g>
        <g transform="translate(19.658 106.891)">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M114.529 168.27l4.739 4.619s10.817 4.01 11.305 4.739 0 2.8 0 2.8H105.66l.657-9.925z"
            data-name="Path 16678"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M130.785 178.846h-25.016l-.109 1.58h24.914a9.306 9.306 0 00.211-1.58z"
            data-name="Path 16679"
          ></path>
          <path
            fill="#263238"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M63.34 88.989l3.419 40.4s-2.737 24.329-2.737 26.741-1.207 10.247-1.207 10.247l8.242 1.007 10.992-41.6 1.61-16.883 13.464 30.346 9.213 31.269 8.212-2.231-6.367-40.09-11.812-37.789-.85-4.42-32.161.4z"
            data-name="Path 16680"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M62.814 166.377l-2.412 6.584s-4.492 5.221-4.13 6.2a2.014 2.014 0 001.206 1.206h11.179a1.435 1.435 0 001.091-1.206 15.382 15.382 0 00.241-2.677l1.055-9.1z"
            data-name="Path 16681"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M69.745 179.159q.054-.235.09-.488H56.309a.82.82 0 00-.054.488 2.014 2.014 0 001.206 1.206H68.64a1.435 1.435 0 001.105-1.206z"
            data-name="Path 16682"
          ></path>
          <path
            fill="#177ba3"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M74.795 31.153s-10.552.6-13.717 2.713-18.541 14.773-18.541 14.773l.79 13.168 17-12.264 2.412 42.508 33.614-1.658-.754-23.666-.452-.754S106 77.128 110.07 76.826s4.974-7.085 1.96-11.305-7.537-10.25-10.7-14.772-3.165-11.155-7.838-14.32-12.813-4.974-12.813-4.974z"
            data-name="Path 16683"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0.657 13.609L0 0"
            data-name="Line 741"
            transform="translate(94.94 53.119)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 2.333L0.386 0"
            data-name="Line 742"
            transform="translate(97.092 56.206)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 5.8L0.971 0"
            data-name="Line 743"
            transform="translate(95.844 60.215)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L1.513 1.652"
            data-name="Line 744"
            transform="translate(81.802 74.71)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L15.327 16.732"
            data-name="Line 745"
            transform="translate(65.522 56.935)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L5.933 5.818"
            data-name="Line 746"
            transform="translate(71.027 67.825)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L1.706 1.67"
            data-name="Line 747"
            transform="translate(68.06 64.924)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M65.341 46.221l-5.017 3.32 4.836-.416"
            data-name="Path 16684"
          ></path>
          <path
            fill="#ffc3bd"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M73.752 18.859a52.9 52.9 0 01.543 6.946 49.617 49.617 0 01-.543 6.807s6.807 10.353 8.031 11.167 2.044-2.725 2.044-4.221.139-5.855.139-5.855v-4.9z"
            data-name="Path 16685"
          ></path>
          <path
            fill="#263238"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M83.918 34.373v-5.575l-2.315-2.249-5.65-2.285z"
            data-name="Path 16686"
          ></path>
          <path
            fill="#ffc3bd"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M90.364 5.239a51.584 51.584 0 011.771 7.488c.139 2.177-1.091 3.817-1.091 4.5s1.905 3.4 1.905 4.359-2.315 2.177-2.315 2.177-.953 5.041-3.4 6.675-8.037-2.315-10.214-4.359a13.108 13.108 0 01-3.817-6.126c-.681-2.183.139-7.235 1.5-11.305a9.261 9.261 0 017.489-6.536c3.131-.556 7.756 1.221 8.172 3.127z"
            data-name="Path 16687"
          ></path>
          <path
            fill="#263238"
            d="M171.673 178.9c0 .639-.211 1.158-.476 1.158s-.476-.519-.476-1.158.217-1.158.476-1.158.476.517.476 1.158z"
            data-name="Path 16688"
            transform="translate(-83.353 -160.786)"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M86.933 15.723s1.911-.953 3.015.277"
            data-name="Path 16689"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M88.048 25.805s-2.858-.953-3.015-3.015"
            data-name="Path 16690"
          ></path>
          <path
            fill="#263238"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M74.27 18.359s2.587.41 2.858-.953-1.363-1.634.41-2.858 3.015-1.206 3.539-2.044-.217-2.134.736-4.311 2.1-1.616 4.824-1.206a12.331 12.331 0 014.516 1.568s1.676-3.545-.1-5.589-7.4-4.118-13.663-2.213-8.164 6.44-8.707 11.341 1.363 7.627 3.172 8.17 2.415-1.905 2.415-1.905z"
            data-name="Path 16691"
          ></path>
          <path
            fill="#ffc3bd"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M75.657 18.039s-1.634-5.035-3.015-2.448-.681 4.763.681 5.583a1.248 1.248 0 001.905-.681"
            data-name="Path 16692"
          ></path>
          <path
            fill="#ffc3bd"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M105.757 69.618c1.736-3.19 9.424-12.578 9.424-12.578l5.011 2.412s-3.793 13.82-5.613 15.791-3.823 2.641-5.951 1.122"
            data-name="Path 16693"
          ></path>
          <path
            fill="#177ba3"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M108.735 76.272a4.763 4.763 0 005.951-1.121 16.8 16.8 0 002.026-4.317 7.838 7.838 0 01-8.8-4.414 20.914 20.914 0 00-2.333 3.72c-.06.187.44 2.5.41 2.665"
            data-name="Path 16694"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M165.75 78.135a.869.869 0 01-.344-.066l-6.433-2.671a.904.904 0 11.693-1.67l6.433 2.671a.9.9 0 01-.35 1.736z"
            data-name="Path 16695"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M145.666 73.456l-143-64.184A4.528 4.528 0 01.393 3.291h0a4.522 4.522 0 015.975-2.273l143 64.184z"
            data-name="Path 16696"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M22.075 8.066L6.398 1.018a4.529 4.529 0 00-3.723 8.255l15.315 6.922z"
            data-name="Path 16697"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M149.368 65.202l14.314 9.8-.892 1.905-17.124-3.455z"
            data-name="Path 16698"
          ></path>
          <path
            fill="#177ba3"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M18.281 6.372L6.373 1.03a4.522 4.522 0 00-3.7 8.242l11.408 5.119z"
            data-name="Path 16699"
          ></path>
          <path
            fill="#ffc3bd"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M32.74 15.778s-1.206 6.482-.3 7.688 5.879 5.728 5.879 5.728-1.96 26.379-1.507 28.791 5.577 4.221 7.235 3.768 2.864-2.261 4.371-5.577-5.278-26.53-5.278-27.585-3.165-9.195-3.165-9.195a25.889 25.889 0 003.015-2.412c.452-.6-1.809-.151-2.261-.151a25.109 25.109 0 01-4.371-.9 15.624 15.624 0 00-3.618-.155z"
            data-name="Path 16700"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M40.036 16.797s-2.134.537-2.056 1.067.078 3.889.078 3.889"
            data-name="Path 16701"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M36.762 16.267s-1.809-.078-1.809.916v4.191"
            data-name="Path 16702"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L0.308 5.185"
            data-name="Line 748"
            transform="translate(32.715 16.188)"
          ></path>
          <path
            fill="#177ba3"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M44.045 61.729c1.658-.452 2.864-2.261 4.371-5.577.573-1.248-.042-5.33-1.019-9.985a11.305 11.305 0 01-10.28 1.206 98.619 98.619 0 00-.308 10.618c.453 2.406 5.575 4.214 7.236 3.738z"
            data-name="Path 16703"
          ></path>
          <path
            fill="#ffc3bd"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M115.127 49.815a3.334 3.334 0 012.237-.784c1.206.115 1.453 1.905 1.339 3.467s-1.809 1.905-2.014.9-.446-2.575-.446-2.575z"
            data-name="Path 16704"
          ></path>
          <path
            fill="#ffc3bd"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M118.479 49.706a1.773 1.773 0 012.128 1.007c.669 1.453-.115 3.352-.9 3.467s-1.007-1.682-1.007-1.682z"
            data-name="Path 16705"
          ></path>
          <path
            fill="#ffc3bd"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M120.607 50.713a1.085 1.085 0 011.562.778c.452 1.345.9 3.015-.109 3.015s-1.345-1.809-1.345-1.809z"
            data-name="Path 16706"
          ></path>
          <path
            fill="#ffc3bd"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M122.169 51.481s1.459-.109 1.809.784 1.007 2.575-.223 2.575c-.332 0-1.115-1.568-1.115-1.568z"
            data-name="Path 16707"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default CompleteProfileIcon;
