import React from "react";

function ClipboardIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 33.253 35.463"
    >
      <g opacity="0.85" transform="translate(-13.577 .478)">
        <g data-name="Group 22280" transform="translate(13.827)">
          <path
            fill="#177ba3"
            stroke="#177ba3"
            strokeWidth="0.5"
            d="M31.613 8.183a3.188 3.188 0 00-4.508-.08l-.08.08-2.5 2.412V5.923a2.547 2.547 0 00-2.547-2.547h-2.325V2.043a.844.844 0 00-.843-.843h-3.88a3.543 3.543 0 00-5.314 0H5.773a.844.844 0 00-.843.843v1.332H2.564A2.581 2.581 0 000 5.922v26.266a2.547 2.547 0 002.547 2.547h19.434a2.53 2.53 0 002.547-2.513V19.823l7.085-7.085a3.239 3.239 0 000-4.555zm-25-5.3h3.374a.843.843 0 00.709-.388 1.788 1.788 0 011.535-.86 1.687 1.687 0 011.484.843.844.844 0 00.725.4h3.526v2.657H6.613zm16.127 29.3a.843.843 0 01-.826.86H2.564a.86.86 0 01-.86-.86l-.017-26.26a.894.894 0 01.86-.86h2.379v1.332a.844.844 0 00.843.843h13.04a.844.844 0 00.843-.843V5.062h2.311a.86.86 0 01.86.86v6.36L14.862 20.3l-.135.135-.152.152a1.452 1.452 0 00-.169.169.844.844 0 00-.236.337l-1.872 5.28a.843.843 0 00.186.877.826.826 0 00.422.219h.422l5.213-1.839a.793.793 0 00.371-.287l.253-.219 3.576-3.576v10.64zm-5.267-9.965a1.687 1.687 0 01.287 1.872l-.017.017-3.27 1.148.422-1.181.742-2.058a1.569 1.569 0 011.836.202zm10.965-8.755l-.017.067-5.3 5.3-3.62 3.57a3.372 3.372 0 00-.793-1.417 3.272 3.272 0 00-1.383-.776l8.924-8.924a1.545 1.545 0 012.193 2.176zm1.991-1.906a3.188 3.188 0 00-.81-1.4 3.221 3.221 0 00-1.383-.776 1.509 1.509 0 012.193 0 1.569 1.569 0 01.003 2.176z"
            data-name="Path 16431"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ClipboardIcon;
