import React from "react";

function NotificationsIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 20"
    >
      <path
        // fill="#C5E6F3"
        fillRule="evenodd"
        d="M13 4.083V3a1 1 0 00-2 0v1.083A6 6 0 006 10v2a1 1 0 01-1 1 1 1 0 00-1 1v4a1 1 0 001 1h4a3 3 0 006 0h4a1 1 0 001-1v-4a1 1 0 00-1-1 1 1 0 01-1-1v-2a6 6 0 00-5-5.917zM11 19h2a1 1 0 01-2 0zm1-13a4 4 0 014 4v2a3 3 0 002 2.829V17H6v-2.171A3 3 0 008 12v-2a4 4 0 014-4z"
        transform="translate(-4 -2)"
      ></path>
    </svg>
  );
}

export default NotificationsIcon;
