import { Dimmer, Loader } from "semantic-ui-react";
import { useLanguage } from "../../../lib/language-context";
import content from "../../../localization/content";
import localizationKeys from "../../../localization/localization-keys";
import InstructorCard from "../instructor-card";

const MobileInstructorList = ({
  loading,
  instructors,
  ModalAction,
  priceData,
}) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  if (loading === "loading") {
    return (
      <Dimmer inverted active className="my-16">
        <Loader inverted size="medium">
          {selectedContent[localizationKeys.loading]}
        </Loader>
      </Dimmer>
    );
  }
  return (
    <div className="mx-auto mt-4 mb-8 flex max-w-full flex-row flex-wrap justify-center gap-4">
      {instructors.map((instructor) => (
        <InstructorCard
          priceData={priceData}
          key={instructor._id}
          instructor={instructor}
          modalOpen={() => ModalAction.open(instructor)}
        />
      ))}
    </div>
  );
};
export default MobileInstructorList;
