import React from "react";

function SchoolsIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 18 20"
    >
      <path
        // fill="#fcfcfc"
        fillRule="evenodd"
        d="M20 22a1 1 0 001-1V3a1 1 0 00-1-1H10a1 1 0 00-1 1v7H4a1 1 0 00-1 1v10a1 1 0 001 1zm-6-2h-3V4h8v16h-3v-2a1 1 0 00-2 0zm-5-8v8H5v-8zm7 0h-2a1 1 0 010-2h2a1 1 0 010 2zm0-4h-2a1 1 0 010-2h2a1 1 0 010 2z"
        transform="translate(-3 -2)"
      ></path>
    </svg>
  );
}

export default SchoolsIcon;
