import React from "react";

function ErrorCodeIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 227.288 137.282"
    >
      <g
        data-name="Computer troubleshooting-pana (1)"
        transform="translate(-33.85 -42.46)"
      >
        <g transform="translate(36.819 42.46)">
          <path
            fill="#f5f5f5"
            d="M351.99 136.859a2.387 2.387 0 01-.791-.134l-4.082-1.415a2.434 2.434 0 01-1.586-1.833l-.818-4.249a2.423 2.423 0 01.794-2.29l3.268-2.831a2.437 2.437 0 012.383-.461l4.086 1.419a2.427 2.427 0 011.589 1.833l.815 4.246a2.44 2.44 0 01-.791 2.293l-3.271 2.831a2.43 2.43 0 01-1.596.591zm-3.872-4.026l3.819 1.335 3.058-2.644-.764-3.969-3.819-1.335-3.054 2.647z"
            data-name="Path 17917"
            transform="translate(-243.887 -96.459)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M499.653 108.28a2.387 2.387 0 01-.791-.134l-2.07-.718a2.427 2.427 0 01-1.586-1.833l-.414-2.15a2.43 2.43 0 01.791-2.29l1.669-1.435a2.427 2.427 0 012.38-.457l2.066.718a2.427 2.427 0 011.589 1.833l.414 2.15a2.423 2.423 0 01-.794 2.29l-1.669 1.435a2.447 2.447 0 01-1.585.591zm-1.853-3.338l1.8.624 1.439-1.248-.36-1.869-1.8-.624-1.442 1.268z"
            data-name="Path 17918"
            transform="translate(-343.87 -80.214)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M674.847 206.807a1.769 1.769 0 01-1.372-.668l-1.165-1.455a1.763 1.763 0 01-.267-1.736l.668-1.736a1.756 1.756 0 011.369-1.1l1.843-.284a1.766 1.766 0 011.639.638l1.165 1.455a1.762 1.762 0 01.267 1.736l-.668 1.736a1.752 1.752 0 01-1.372 1.1l-1.843.284a1.72 1.72 0 01-.264.03zm1.352-5.651h-.063l-1.843.284a.427.427 0 00-.334.264l-.668 1.736a.421.421 0 00.063.417l1.165 1.455a.434.434 0 00.394.154l1.843-.284a.421.421 0 00.334-.264l.668-1.736a.427.427 0 00-.063-.417l-1.165-1.455a.431.431 0 00-.33-.155z"
            data-name="Path 17919"
            transform="translate(-461.904 -147.287)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M47.736 238.924a2.276 2.276 0 01-1.766-.845l-2.737-3.418a2.263 2.263 0 01-.334-2.23l1.6-4.082a2.263 2.263 0 011.759-1.412l4.339-.668a2.267 2.267 0 012.1.821l2.714 3.411a2.266 2.266 0 01.334 2.233l-1.569 4.086a2.263 2.263 0 01-1.762 1.415l-4.339.668a1.943 1.943 0 01-.34.02zm-2.65-5.675l2.67 3.338L52 235.94l1.556-4.006-2.67-3.338-4.239.651zm8.505-1.265z"
            data-name="Path 17920"
            transform="translate(-42.744 -164.897)"
          ></path>
          <path
            fill="#ebebeb"
            d="M79.65 158.691h-2.58a1.759 1.759 0 01-1.522-.868l-1.292-2.233a1.779 1.779 0 010-1.759l1.292-2.233a1.756 1.756 0 011.522-.878h2.58a1.756 1.756 0 011.522.878l1.292 2.22a1.779 1.779 0 010 1.759l-1.292 2.246a1.763 1.763 0 01-1.522.868zm-2.58-6.649a.427.427 0 00-.367.21l-1.288 2.233a.421.421 0 000 .424l1.288 2.247a.431.431 0 00.367.214h2.58a.431.431 0 00.374-.214l1.288-2.233a.421.421 0 000-.424l-1.288-2.246a.427.427 0 00-.367-.21z"
            data-name="Path 17921"
            transform="translate(-63.582 -114.583)"
          ></path>
          <path
            fill="#ebebeb"
            d="M179.93 61.316l-5.03-3.167-6.179-10.428-8.6-5.261-1.662 1.8.871 1.175 2.714 3.638 10.772 14.46 4.393 2.417z"
            data-name="Path 17922"
            transform="translate(-119.834 -42.46)"
          ></path>
          <path
            fill="#ebebeb"
            d="M165.15 75.735l-1.442-4.8L147.88 52.85l-2.11 1.25 3.341 9.51 9.19 9.026 2.06 5.635z"
            data-name="Path 17923"
            transform="translate(-111.38 -49.382)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M222.885 101.63s17.875 9.81 21.477 13.122 12.017 17.1 11.162 18.863-1.509 2-3.448.294-8.208-11.156-9.891-12.684-22.625-13.8-22.625-13.8z"
            data-name="Path 17924"
            transform="translate(-160.539 -81.879)"
          ></path>
          <circle
            cx="5.661"
            cy="5.661"
            r="5.661"
            fill="#ebebeb"
            data-name="Ellipse 200"
            transform="translate(44.564 12.631)"
          ></circle>
          <path
            fill="#e0e0e0"
            d="M180.364 91.291a8.426 8.426 0 001.382.144 5.408 5.408 0 001.6-.237 5.6 5.6 0 004.006-5.9 5.308 5.308 0 00-.414-1.569 6.1 6.1 0 00-.361-.744 5.774 5.774 0 00-.491-.691 5.548 5.548 0 00-6.379-1.669 6.639 6.639 0 00-.761.364 6.206 6.206 0 00-.668.477 5.191 5.191 0 00-1.092 1.175 5.6 5.6 0 00.608 7.1 5.441 5.441 0 001.285 1 8.68 8.68 0 001.278.547.358.358 0 01-.09 0l-.26-.073a2.573 2.573 0 01-.414-.144 4.043 4.043 0 01-.544-.254 5.341 5.341 0 01-1.335-.978 5.663 5.663 0 01-.668-7.26 5.434 5.434 0 011.122-1.215 5.307 5.307 0 011.485-.871 5.718 5.718 0 016.573 1.709 6.409 6.409 0 01.5.714 6.039 6.039 0 01.37.771 5.278 5.278 0 01.387 1.6 5.675 5.675 0 01-2.18 5.034 5.615 5.615 0 01-1.943.981 5.274 5.274 0 01-1.629.21 3.957 3.957 0 01-.6-.043 2.714 2.714 0 01-.437-.067l-.264-.063a.3.3 0 01-.066-.048z"
            data-name="Path 17925"
            transform="translate(-131.544 -67.521)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M186.025 93.967a3.2 3.2 0 113.9-2.29 3.2 3.2 0 01-3.9 2.29z"
            data-name="Path 17926"
            transform="translate(-136.6 -72.581)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M185.893 93.8c0-.023.277.067.778.067a3.181 3.181 0 002.851-1.829 3.125 3.125 0 00-.417-3.291 3.094 3.094 0 00-3.558-.925 2.848 2.848 0 00-.8.461 2.9 2.9 0 00-.611.668 3.157 3.157 0 00-.551 1.549 3.107 3.107 0 00.227 1.422 3.178 3.178 0 001.372 1.562 6.767 6.767 0 00.714.334 2.41 2.41 0 01-.748-.264 3.181 3.181 0 01-1.452-1.572 3.214 3.214 0 01-.257-1.482 3.281 3.281 0 01.559-1.63 3.114 3.114 0 01.624-.708 3.081 3.081 0 01.848-.5 3.267 3.267 0 013.245 5.6 3.2 3.2 0 01-2.036.668 2.383 2.383 0 01-.788-.13z"
            data-name="Path 17927"
            transform="translate(-136.469 -72.417)"
          ></path>
          <rect
            width="9.57"
            height="5.177"
            fill="#ebebeb"
            data-name="Rectangle 17139"
            rx="2.589"
            transform="rotate(-60.12 49.11 -35.378)"
          ></rect>
          <path
            fill="#e0e0e0"
            d="M211.449 99.8a3.31 3.31 0 00-.277.938 2.566 2.566 0 00.15 1.125 2.627 2.627 0 00.334.621 2.434 2.434 0 00.581.537 3.579 3.579 0 00.781.4 2.383 2.383 0 002.694-.858c.768-1.278 1.579-2.744 2.453-4.249a2.39 2.39 0 000-2.337 2.336 2.336 0 00-.754-.855 2.884 2.884 0 00-1.025-.454 2.393 2.393 0 00-1.959.467 4.907 4.907 0 00-1.061 1.489l-1.375 2.337-.381.631a1.262 1.262 0 01-.144.214 2.185 2.185 0 01.113-.234c.09-.16.207-.377.354-.644.334-.574.771-1.375 1.335-2.36l.441-.781a2.64 2.64 0 01.638-.761 2.534 2.534 0 012.063-.514 3.024 3.024 0 011.075.467 2.474 2.474 0 01.851.9 2.544 2.544 0 010 2.517c-.878 1.512-1.669 2.954-2.473 4.259a2.567 2.567 0 01-1.883 1.025 2.6 2.6 0 01-.985-.134 3.75 3.75 0 01-.8-.427 2.537 2.537 0 01-.6-.571 2.761 2.761 0 01-.334-.668 2.41 2.41 0 01.087-1.876 1.361 1.361 0 01.101-.204z"
            data-name="Path 17928"
            transform="translate(-154.904 -77.106)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M191.47 132.9s6.773 19.234 9.457 23.326 14.918 14.634 16.794 14.08 2.206-1.165.851-3.338-9.68-9.911-10.909-11.823-9.951-24.568-9.951-24.568z"
            data-name="Path 17929"
            transform="translate(-141.825 -101.165)"
          ></path>
          <rect
            width="9.57"
            height="5.177"
            fill="#ebebeb"
            data-name="Rectangle 17140"
            rx="2.589"
            transform="rotate(-20.54 103.37 -114.71)"
          ></rect>
          <path
            fill="#e0e0e0"
            d="M190.946 121.455a1.175 1.175 0 01.24-.093 2.461 2.461 0 01.738-.093 2.51 2.51 0 011.128.31 2.788 2.788 0 01.588.451 2.67 2.67 0 01.467.688 3.607 3.607 0 01.287.865 2.507 2.507 0 01-.027 1 2.557 2.557 0 01-1.335 1.689c-1.415.571-2.967 1.128-4.6 1.749a2.567 2.567 0 01-2.487-.4 2.537 2.537 0 01-.748-1 3 3 0 01-.287-1.148 2.53 2.53 0 01.841-1.969 2.637 2.637 0 01.855-.5l.841-.31 2.544-.921.694-.244a1.4 1.4 0 01.247-.073 1.113 1.113 0 01-.234.107l-.668.274-2.524.975a5.007 5.007 0 00-1.642.8 2.407 2.407 0 00-.781 1.859 2.941 2.941 0 00.284 1.085 2.337 2.337 0 00.7.921 2.39 2.39 0 002.32.371c1.626-.618 3.2-1.182 4.593-1.729a2.4 2.4 0 001.285-2.517 3.569 3.569 0 00-.27-.838 2.494 2.494 0 00-.434-.668 2.57 2.57 0 00-.554-.441 2.487 2.487 0 00-1.085-.334 3.235 3.235 0 00-.976.134z"
            data-name="Path 17930"
            transform="translate(-137.452 -94.962)"
          ></path>
        </g>
        <g transform="translate(33.85 179.569)">
          <path
            fill="#263238"
            d="M261.138 453.3c0 .047-50.885.087-113.639.087S33.85 453.344 33.85 453.3s50.875-.087 113.649-.087 113.639.037 113.639.087z"
            data-name="Path 17931"
            transform="translate(-33.85 -453.21)"
          ></path>
        </g>
        <g transform="translate(37.816 98.418)">
          <path
            fill="#e0e0e0"
            d="M87 253.284C87.09 253 97.491 219 97.491 219l-.26-4.223 2.72-4.48 3.378 1.118-.461 5.007-2.4 3.652L90 254.262z"
            data-name="Path 17932"
            transform="translate(-73.224 -210.233)"
          ></path>
          <path
            fill="#263238"
            d="M87 253.15l.2.057.577.18 2.226.7-.037.02c1.692-5.544 5.6-18.376 10.425-34.174l2.4-3.648v.037c.147-1.6.3-3.3.461-5.007l.06.09-.28-.09-3.1-1.025.1-.037-2.72 4.48v-.05q.13 2.16.257 4.223v.03c-3.041 9.877-5.675 18.432-7.547 24.521-.941 3.048-1.7 5.474-2.213 7.147-.257.835-.461 1.479-.6 1.919l-.157.5-.057.17a1.481 1.481 0 01.043-.164c.033-.117.08-.28.144-.491.134-.441.334-1.082.574-1.909.5-1.669 1.238-4.1 2.16-7.143 1.863-6.109 4.473-14.687 7.491-24.6v.03q-.13-2.063-.264-4.223v-.05l2.72-4.48.033-.06.067.023 3.1 1.021.277.093.067.02v.07c-.157 1.719-.314 3.418-.464 5.007v.04l-2.4 3.652V220c-4.87 15.765-8.816 28.58-10.525 34.124v.03h-.03l-2.23-.738-.564-.19z"
            data-name="Path 17933"
            transform="translate(-73.224 -210.1)"
          ></path>
          <path
            fill="#177ba3"
            d="M57 337.344l-4.673 6.316-6.532 23.7a5.389 5.389 0 004.4 6.205c6.008 1.542 7.377-3.051 7.377-3.051l6.342-23.142-.067-8.025 1.566-1.766a.668.668 0 00-.284-1.065l-7.5-2.477a.668.668 0 00-.865.691z"
            data-name="Path 17934"
            transform="translate(-45.73 -292.648)"
          ></path>
          <path
            fill="#263238"
            d="M86.209 346.407c-.017.047-1.589-.411-3.512-1.021s-3.475-1.145-3.458-1.192 1.586.414 3.512 1.025 3.472 1.141 3.458 1.188z"
            data-name="Path 17935"
            transform="translate(-68.054 -299.431)"
          ></path>
          <path
            fill="#263238"
            d="M77.323 366.735a2.225 2.225 0 01-.467-.08 37.1 37.1 0 01-1.258-.3c-1.061-.264-2.517-.668-4.1-1.175s-3-1.021-4.029-1.419c-.507-.2-.918-.367-1.2-.487a2.381 2.381 0 01-.427-.2 2.427 2.427 0 01.454.14l1.215.434c1.028.367 2.45.865 4.036 1.369s3.034.928 4.082 1.225l1.245.35a2.589 2.589 0 01.449.143z"
            data-name="Path 17936"
            transform="translate(-59.127 -312.009)"
          ></path>
          <g
            data-name="Group 22537"
            opacity="0.3"
            transform="translate(1.788 53.168)"
          >
            <rect
              width="2.373"
              height="22.862"
              data-name="Rectangle 17141"
              rx="1.187"
              transform="rotate(16.26 3.2 22.404)"
            ></rect>
          </g>
          <g
            data-name="Group 22538"
            opacity="0.3"
            transform="translate(5.137 54.254)"
          >
            <rect
              width="2.373"
              height="22.862"
              data-name="Rectangle 17142"
              rx="1.187"
              transform="rotate(16.26 3.2 22.404)"
            ></rect>
          </g>
          <g
            data-name="Group 22539"
            opacity="0.3"
            transform="translate(8.071 55.248)"
          >
            <rect
              width="2.373"
              height="22.862"
              data-name="Rectangle 17143"
              rx="1.187"
              transform="rotate(16.49 3.245 22.39)"
            ></rect>
          </g>
          <path
            fill="#263238"
            d="M121.447 237.581a6.969 6.969 0 01-1.489-.424 6.677 6.677 0 01-1.429-.587 7.215 7.215 0 011.485.424 6.761 6.761 0 011.433.587z"
            data-name="Path 17937"
            transform="translate(-94.229 -227.734)"
          ></path>
          <path
            fill="#263238"
            d="M125.681 211.425a2.711 2.711 0 01-.117.467c-.1.334-.224.754-.381 1.275l-1.335 4.339-.027.083-.083-.027-1.539-.537-1.582-.554-.077-.027.023-.077c.481-1.716.9-3.221 1.218-4.339.154-.517.277-.945.374-1.265a3.062 3.062 0 01.154-.454 2.709 2.709 0 01-.1.471l-.334 1.278c-.3 1.112-.7 2.62-1.158 4.339l-.053-.1 1.586.554 1.535.537-.11.057 1.4-4.309c.174-.517.334-.938.427-1.258a2.859 2.859 0 01.179-.453z"
            data-name="Path 17938"
            transform="translate(-95.568 -210.24)"
          ></path>
        </g>
        <g transform="translate(134.963 51.626)">
          <path
            fill="#e0e0e0"
            d="M416.277 85.293a2.387 2.387 0 01-.791-.134l-4.086-1.415a2.433 2.433 0 01-1.586-1.833l-.818-4.249a2.423 2.423 0 01.794-2.29l3.271-2.831a2.43 2.43 0 012.38-.457l4.086 1.415a2.433 2.433 0 011.589 1.833l.818 4.249a2.44 2.44 0 01-.794 2.29l-3.274 2.829a2.43 2.43 0 01-1.589.591zm-3.877-4.025l3.822 1.335 3.054-2.644-.764-3.986-3.812-1.335-3.061 2.67z"
            data-name="Path 17939"
            transform="translate(-384.854 -71.274)"
          ></path>
          <path
            fill="none"
            d="M416.277 85.293a2.387 2.387 0 01-.791-.134l-4.086-1.415a2.433 2.433 0 01-1.586-1.833l-.818-4.249a2.423 2.423 0 01.794-2.29l3.271-2.831a2.43 2.43 0 012.38-.457l4.086 1.415a2.433 2.433 0 011.589 1.833l.818 4.249a2.44 2.44 0 01-.794 2.29l-3.274 2.829a2.43 2.43 0 01-1.589.591zm-3.877-4.025l3.822 1.335 3.054-2.644-.764-3.986-3.812-1.335-3.061 2.67z"
            data-name="Path 17940"
            transform="translate(-384.854 -71.274)"
          ></path>
          <path
            fill="#263238"
            d="M417.651 86.49a2.109 2.109 0 01-.067-.264l-.167-.784c-.143-.7-.334-1.7-.591-2.907a.067.067 0 01.02-.063l3.044-2.67a.087.087 0 01.08 0l3.819 1.335.06.067v.124l.027.13.05.26c.033.17.063.334.1.514.067.334.13.668.194 1l.367 1.926a.08.08 0 01-.027.077l-3.044 2.637a.063.063 0 01-.063 0l-2.794-1-.751-.28a1.884 1.884 0 01-.254-.1 2.03 2.03 0 01.28.08l.771.247 2.791.935h-.063l3.044-2.67-.027.08c-.12-.621-.247-1.262-.374-1.926a55.24 55.24 0 00-.2-1c-.033-.17-.063-.334-.1-.514l-.05-.26-.027-.13V81.2s.107.124.053.063l-3.815-1.335h.08l-3.068 2.634.023-.063c.217 1.208.4 2.193.524 2.9.057.334.1.6.137.8a1.605 1.605 0 01.018.291z"
            data-name="Path 17941"
            transform="translate(-390.1 -76.496)"
          ></path>
          <path
            fill="#263238"
            d="M416.1 85.145a2.394 2.394 0 00.414-.053 2.43 2.43 0 001.065-.491l3.321-2.907a2.37 2.37 0 00.744-1.2 2.45 2.45 0 00.08-.751 7.005 7.005 0 00-.133-.784c-.21-1.078-.434-2.226-.668-3.435a3.389 3.389 0 00-.244-.878 2.336 2.336 0 00-1.335-1.225l-.941-.334-1.933-.668-1-.334a2.69 2.69 0 00-1-.21 2.374 2.374 0 00-1 .22 3.3 3.3 0 00-.828.591l-1.542 1.341-1.5 1.291a2.357 2.357 0 00-.761 1.609 3.463 3.463 0 00.1.885c.057.3.11.587.167.875.107.574.214 1.128.314 1.669s.2 1.061.3 1.549a2.387 2.387 0 00.748 1.188 2.43 2.43 0 00.557.357c.2.087.407.15.6.224l1.118.394 1.789.634a9.535 9.535 0 001.135.377c.267.05.414.047.414.053a1.746 1.746 0 01-.421 0 8.581 8.581 0 01-1.148-.351l-1.782-.611-1.122-.381c-.2-.07-.4-.13-.614-.22a2.571 2.571 0 01-.591-.371 2.507 2.507 0 01-.8-1.245c-.113-.507-.2-1.021-.31-1.559s-.214-1.095-.334-1.669q-.087-.424-.17-.871a3.6 3.6 0 01-.107-.921 2.527 2.527 0 01.811-1.726l1.489-1.3 1.545-1.335c.133-.113.257-.227.4-.334a2.751 2.751 0 01.467-.28 2.513 2.513 0 011.061-.234 2.867 2.867 0 011.058.22l1 .334 1.933.668.941.334a2.524 2.524 0 01.858.517 2.476 2.476 0 01.577.794 3.5 3.5 0 01.254.918q.334 1.819.651 3.438a6.516 6.516 0 01.13.8 2.507 2.507 0 01-.881 2.036l-3.358 2.867a2.46 2.46 0 01-1.1.474 1.715 1.715 0 01-.418.021z"
            data-name="Path 17942"
            transform="translate(-384.68 -71.126)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M339.831 77.137a1.759 1.759 0 01-1.375-.668l-1.165-1.452a1.769 1.769 0 01-.267-1.739l.668-1.736a1.759 1.759 0 011.372-1.1l1.839-.284a1.766 1.766 0 011.639.638l1.165 1.455a1.762 1.762 0 01.267 1.736l-.668 1.739a1.766 1.766 0 01-1.362 1.108l-1.843.28a1.785 1.785 0 01-.27.02zm1.335-5.651h-.066l-1.829.277a.427.427 0 00-.334.264l-.668 1.739a.427.427 0 00.063.417l1.165 1.452a.427.427 0 00.394.154l1.843-.284a.424.424 0 00.334-.264l.668-1.736a.421.421 0 00-.063-.417l-1.165-1.452a.427.427 0 00-.33-.15z"
            data-name="Path 17943"
            transform="translate(-336.858 -70.067)"
          ></path>
          <path
            fill="none"
            d="M339.831 77.137a1.759 1.759 0 01-1.375-.668l-1.165-1.452a1.769 1.769 0 01-.267-1.739l.668-1.736a1.759 1.759 0 011.372-1.1l1.839-.284a1.766 1.766 0 011.639.638l1.165 1.455a1.762 1.762 0 01.267 1.736l-.668 1.739a1.766 1.766 0 01-1.362 1.108l-1.843.28a1.785 1.785 0 01-.27.02zm1.335-5.651h-.066l-1.829.277a.427.427 0 00-.334.264l-.668 1.739a.427.427 0 00.063.417l1.165 1.452a.427.427 0 00.394.154l1.843-.284a.424.424 0 00.334-.264l.668-1.736a.421.421 0 00-.063-.417l-1.165-1.452a.427.427 0 00-.33-.15z"
            data-name="Path 17944"
            transform="translate(-336.858 -70.067)"
          ></path>
          <path
            fill="#263238"
            d="M343.686 74.148a.24.24 0 01.144 0 .4.4 0 01.157.083 2.164 2.164 0 01.16.177l.948 1.138.154.19a.481.481 0 01.093.27.668.668 0 01-.067.277c-.03.083-.063.167-.093.254-.133.334-.274.711-.421 1.1-.037.1-.073.194-.113.294a.5.5 0 01-.247.277 1.315 1.315 0 01-.334.077l-.334.053c-.451.067-.891.144-1.392.207a.507.507 0 01-.381-.17l-.22-.277-.431-.534-.411-.521-.1-.127a.557.557 0 01-.093-.157.514.514 0 010-.367l.434-1.092.2-.494a1.188 1.188 0 01.114-.24.474.474 0 01.214-.167c.641-.117 1.128-.164 1.479-.21a2.54 2.54 0 01.544-.047.607.607 0 01-.137.033l-.394.08c-.334.067-.861.14-1.445.257a.384.384 0 00-.157.127 1.356 1.356 0 00-.093.217l-.187.5-.421 1.1a.357.357 0 000 .25 1.14 1.14 0 00.17.237l.427.524.431.534.217.27a.334.334 0 00.25.11l1.365-.2.334-.05a1.108 1.108 0 00.3-.06.334.334 0 00.17-.187c.037-.1.077-.2.113-.294.154-.381.3-.748.434-1.092a2.666 2.666 0 00.1-.25.571.571 0 00.063-.224.407.407 0 00-.063-.2l-.147-.187-.9-1.172a.564.564 0 00-.434-.317z"
            data-name="Path 17945"
            transform="translate(-339.365 -72.729)"
          ></path>
          <path
            fill="#263238"
            d="M339.736 76.99a1.533 1.533 0 01.22-.033l.638-.11 1-.17a2.44 2.44 0 00.608-.147 1.669 1.669 0 00.568-.387 1.766 1.766 0 00.414-.648c.1-.254.2-.521.3-.8a3.387 3.387 0 00.421-1.766 1.772 1.772 0 00-.427-.868l-.668-.828-.334-.427a1.923 1.923 0 00-.374-.381 1.669 1.669 0 00-1-.334 3.615 3.615 0 00-.554.063l-.557.083-1.085.17a1.669 1.669 0 00-.868.494 1.732 1.732 0 00-.28.4c-.07.144-.127.31-.187.461l-.357.9a4.753 4.753 0 00-.294.848 1.759 1.759 0 00.457 1.465l.838 1.068c.127.154.23.307.35.424a1.731 1.731 0 00.364.274 2.12 2.12 0 00.824.24h-.227a1.692 1.692 0 01-.628-.184 1.943 1.943 0 01-.391-.274 5.341 5.341 0 01-.37-.424l-.858-1.051a1.876 1.876 0 01-.5-1.562 4.652 4.652 0 01.29-.885l.351-.9c.063-.157.113-.31.19-.474a1.907 1.907 0 01.3-.444 1.869 1.869 0 01.928-.544c.367-.063.724-.113 1.095-.174l.557-.083a3.508 3.508 0 01.581-.067 1.823 1.823 0 011.1.361 2.043 2.043 0 01.4.411l.334.427c.227.28.447.561.668.831a1.9 1.9 0 01.457.945 1.822 1.822 0 01-.107 1c-.114.29-.227.574-.334.851s-.214.537-.314.794a1.853 1.853 0 01-.431.668 1.959 1.959 0 01-1.252.544l-1 .14c-.277.037-.494.067-.641.077a.975.975 0 01-.215.026z"
            data-name="Path 17946"
            transform="translate(-336.763 -69.92)"
          ></path>
          <path
            fill="#263238"
            d="M439.273 78.05a5.063 5.063 0 01-.861 1.1 4.16 4.16 0 01-1.052.925 9.118 9.118 0 01.925-1.038 6.17 6.17 0 01.988-.987z"
            data-name="Path 17947"
            transform="translate(-403.781 -75.336)"
          ></path>
          <path
            fill="#263238"
            d="M424.34 72.3a3.983 3.983 0 01.444 1.262 3.933 3.933 0 01.177 1.335 7.431 7.431 0 01-.334-1.288 7.285 7.285 0 01-.287-1.309z"
            data-name="Path 17948"
            transform="translate(-395.107 -71.505)"
          ></path>
          <path
            fill="#263238"
            d="M411.79 86.2a7.883 7.883 0 01-1.282-.367 8.415 8.415 0 01-1.268-.421 6.142 6.142 0 012.55.788z"
            data-name="Path 17949"
            transform="translate(-385.047 -80.239)"
          ></path>
          <path
            fill="#263238"
            d="M414.75 101.536a4 4 0 01.668-.955 4.091 4.091 0 01.814-.841 3.834 3.834 0 01-.668.955 4.093 4.093 0 01-.815.841z"
            data-name="Path 17950"
            transform="translate(-388.718 -89.786)"
          ></path>
          <path
            fill="#263238"
            d="M430.96 106.246a3.472 3.472 0 01-.234-1.075 3.437 3.437 0 01-.063-1.1 3.577 3.577 0 01.234 1.078 3.532 3.532 0 01.063 1.097z"
            data-name="Path 17951"
            transform="translate(-399.307 -92.671)"
          ></path>
          <path
            fill="#263238"
            d="M442.009 96.314a3.077 3.077 0 01-.985-.287 3 3 0 01-.925-.447 6.155 6.155 0 011.91.734z"
            data-name="Path 17952"
            transform="translate(-405.606 -87.015)"
          ></path>
        </g>
        <g transform="translate(63.809 49.507)">
          <path
            fill="#263238"
            d="M644.34 128.56a61.951 61.951 0 006.282-8.545 21.525 21.525 0 003.094-10.041c0-.537-.217-4.259-.734-4.41-.367-.107-.621 3.181-.921 3.418a18.2 18.2 0 00-5.835 9.1 48.013 48.013 0 00-1.886 10.475"
            data-name="Path 17953"
            transform="translate(-470.517 -91.545)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M555.66 127.637c.9-2.337 5.341-14.02 5.317-14.02s-7.647-3.979-5.007-12.084c1.268-3.915 4.536-11.763 7.574-18.833a15.872 15.872 0 0119.656-8.762l.778.264c8.232 3.338 10.014 12.174 6.452 20.308l-16.354 37.89-14.333-4.43z"
            data-name="Path 17954"
            transform="translate(-411.275 -69.919)"
          ></path>
          <path
            fill="#ff9a6c"
            d="M570.5 194.5a20.7 20.7 0 0011.553.441s-4.546 5.137-12.351 1.692z"
            data-name="Path 17955"
            transform="translate(-420.792 -150.796)"
          ></path>
          <path
            fill="#263238"
            d="M579.595 123.768a1.218 1.218 0 00.718 1.539 1.165 1.165 0 001.539-.638 1.215 1.215 0 00-.718-1.539 1.162 1.162 0 00-1.539.638z"
            data-name="Path 17956"
            transform="translate(-427.338 -103.186)"
          ></path>
          <path
            fill="#263238"
            d="M581.5 112.405c.093.2 1.185-.154 2.423.27s1.939 1.335 2.123 1.242c.09-.043.037-.364-.264-.788a3.318 3.318 0 00-1.636-1.178 3.191 3.191 0 00-2-.027c-.485.164-.695.391-.646.481z"
            data-name="Path 17957"
            transform="translate(-428.647 -95.685)"
          ></path>
          <path
            fill="#263238"
            d="M612.183 136.875a1.212 1.212 0 00.718 1.535 1.162 1.162 0 001.539-.638 1.218 1.218 0 00-.718-1.539 1.165 1.165 0 00-1.539.641z"
            data-name="Path 17958"
            transform="translate(-449.048 -111.917)"
          ></path>
          <path
            fill="#263238"
            d="M617.388 126.194c.09.2 1.185-.15 2.423.27s1.936 1.335 2.123 1.245c.087-.047.037-.367-.264-.788a3.338 3.338 0 00-1.639-1.182 3.181 3.181 0 00-2-.027c-.47.165-.69.388-.643.482z"
            data-name="Path 17959"
            transform="translate(-452.557 -104.871)"
          ></path>
          <path
            fill="#263238"
            d="M588.607 131.255a8.8 8.8 0 00-1.893-1.041c-.3-.14-.584-.3-.564-.541a1.9 1.9 0 01.527-.865l1.752-2.1a44.054 44.054 0 004.233-5.6 43.5 43.5 0 00-4.673 5.244c-.6.741-1.172 1.445-1.716 2.123a2.014 2.014 0 00-.584 1.188.844.844 0 00.364.638 2.372 2.372 0 00.514.257 8.374 8.374 0 002.04.697z"
            data-name="Path 17960"
            transform="translate(-431.444 -101.904)"
          ></path>
          <path
            fill="#263238"
            d="M594.384 157.754c-.2-.077-.668 1.268-2.13 1.833s-2.861-.063-2.934.12c-.047.077.234.367.831.591a3.562 3.562 0 002.423-.023 3.087 3.087 0 001.7-1.566c.24-.541.203-.932.11-.955z"
            data-name="Path 17961"
            transform="translate(-433.859 -126.313)"
          ></path>
          <path
            fill="#263238"
            d="M622.69 111.649c0 .377 1.3.638 2.7 1.3s2.44 1.462 2.734 1.225c.133-.117.037-.5-.334-1a5.191 5.191 0 00-4.136-1.926c-.604.047-.961.224-.964.401z"
            data-name="Path 17962"
            transform="translate(-456.093 -95.333)"
          ></path>
          <path
            fill="#263238"
            d="M587.206 100.348c.123.357 1.075.334 2.106.668s1.836.831 2.133.6c.137-.113.1-.441-.194-.838a3.215 3.215 0 00-3.418-1.038c-.46.187-.677.438-.627.608z"
            data-name="Path 17963"
            transform="translate(-432.449 -87.577)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M646.3 154.5c.16 0 6.1.23 4.062 5.675s-7.1 2.287-7.05 2.126 2.988-7.801 2.988-7.801z"
            data-name="Path 17964"
            transform="translate(-469.83 -124.148)"
          ></path>
          <path
            fill="#ff9a6c"
            d="M650.583 165.629c.03 0 .07.1.2.237a1 1 0 00.7.28c.668 0 1.482-.731 1.876-1.706a3.338 3.338 0 00.244-1.462 1.161 1.161 0 00-.444-1 .514.514 0 00-.667.05c-.13.127-.137.247-.167.247s-.083-.137.043-.334a.668.668 0 01.334-.264.771.771 0 01.577.057 1.432 1.432 0 01.668 1.218 3.48 3.48 0 01-.24 1.646 2.638 2.638 0 01-2.266 1.839 1.1 1.1 0 01-.818-.471c-.08-.197-.064-.334-.04-.337z"
            data-name="Path 17965"
            transform="translate(-474.663 -128.908)"
          ></path>
          <path
            fill="#263238"
            d="M600.471 73.257c-.694-.821 3.041-2.67 5.581-2.811 3.839-.227 9.183.774 11.867 2.787 3.238 2.42 5.558 5.384 6.112 9.386a22.935 22.935 0 01-1.789 11.847 6.96 6.96 0 01-2.043 3.028c-1.218.911-2.968 1.108-3.542 2.794a2.437 2.437 0 01-1.2 1.442c-1.4.668-2.237-.784-2.413-2-.377-2.59.464-5.184 1.3-7.677a16.9 16.9 0 012.337-5.04c.614-.8 1.369-1.5 1.939-2.337a3.542 3.542 0 00.668-2.864 3.438 3.438 0 00-.27-.618 6.079 6.079 0 00-5.214-3.231c-3.482-.09-9.346-.481-10.3-2.043a8.992 8.992 0 01-1.489-3.4z"
            data-name="Path 17966"
            transform="translate(-441.235 -68.13)"
          ></path>
          <path
            fill="#177ba3"
            d="M585.667 65.343c.351.177 8.552 5.244 8.5 12.634s-1.405 9.2-1.405 9.2a4.547 4.547 0 01.668 1.118c.22.561-1.038 2.463-2.116 1.829-7.364-6.833-17.511-10.758-29.087-14.353l-8.732-3.128c-2.557-.557-3.194-1.509-1.946-1.766.958-.2 8.889-.6 8.889-.6l1.712-.527 5.341-3.775s-.207-.855.878-1.132 10.401-3.005 17.298.5z"
            data-name="Path 17967"
            transform="translate(-408.285 -63.57)"
          ></path>
          <path
            fill="#263238"
            d="M584.52 82.12s.117.047.334.12l1 .314c.861.257 2.086.691 3.6 1.245A71.816 71.816 0 01600.7 89.3a110.113 110.113 0 0110.348 7.1c1.282.971 2.3 1.779 3 2.337l.818.638c.187.144.29.214.29.214s-.09-.087-.274-.24l-.8-.668c-.7-.567-1.709-1.389-2.981-2.37a102.592 102.592 0 00-10.348-7.143 68.563 68.563 0 00-11.309-5.471 51.591 51.591 0 00-4.934-1.572z"
            data-name="Path 17968"
            transform="translate(-430.665 -75.928)"
          ></path>
          <g
            data-name="Group 22540"
            opacity="0.3"
            transform="translate(163.796 2.87)"
          >
            <path
              d="M634.675 77.787s-9.053-4.847-14.053-4.259l.588-.08-4.673.387a2.683 2.683 0 01-2.236-.487 20.087 20.087 0 017.31-1.178c4.475.023 11.045 1.612 13.064 5.617z"
              data-name="Path 17969"
              transform="translate(-614.3 -72.169)"
            ></path>
          </g>
          <path
            fill="#263238"
            d="M625.6 76.955a7.281 7.281 0 00-.668-.858 12.655 12.655 0 00-2.2-1.983 14.93 14.93 0 00-3.9-2 12.574 12.574 0 00-5.311-.738 22.093 22.093 0 00-2.75.454c-.875.2-1.726.334-2.53.567a13.572 13.572 0 01-2.263.541 4.372 4.372 0 01-1.978-.265c-2.367-.778-3.645-1.762-3.485-1.873a.387.387 0 00.123.25 3.168 3.168 0 00.641.484 13.743 13.743 0 002.69 1.238 4.527 4.527 0 002.03.287 13.657 13.657 0 002.29-.524c.788-.217 1.649-.364 2.524-.561a23.412 23.412 0 012.73-.441 12.655 12.655 0 015.251.718 15.275 15.275 0 013.885 1.953 13.4 13.4 0 012.213 1.933c.237.257.414.464.527.607a2.222 2.222 0 00.181.211z"
            data-name="Path 17970"
            transform="translate(-441.31 -68.387)"
          ></path>
          <g
            data-name="Group 22541"
            opacity="0.3"
            transform="translate(172.749 8.942)"
          >
            <path
              d="M654.145 98.879a22.753 22.753 0 01-4.443-2.457c-1.669-1.021-3.375-1.956-5.007-3.058-1.239-.835-2.737-1.716-3.288-3a2.1 2.1 0 00-.03 2.066 10.381 10.381 0 002.47 2.29 53.3 53.3 0 0010.148 5.835 12.594 12.594 0 00.147-1.669"
              data-name="Path 17971"
              transform="translate(-641.12 -90.36)"
            ></path>
          </g>
          <path
            fill="#177ba3"
            d="M654.427 93.653c.928.845 1.485 1.392 1.2 2.337-.237.781-.718 1.669-1.526 1.546a1.971 1.971 0 01-.668-.294l-11.055-6.472a1.832 1.832 0 01-.915-.881 1.27 1.27 0 01.971-1.489 3.358 3.358 0 011.953.22 41.789 41.789 0 0110.041 5.03"
            data-name="Path 17972"
            transform="translate(-468.566 -80.064)"
          ></path>
          <path
            fill="#263238"
            d="M641.473 89.327a1.224 1.224 0 01-.14-.628 1.572 1.572 0 011-1.335 3.883 3.883 0 012.567.294c.921.374 1.959.8 3 1.282a33.382 33.382 0 015.2 2.884c.628.431 1.112.811 1.449 1.075l.38.31a.814.814 0 00.14.1 1.194 1.194 0 00-.12-.123l-.364-.334c-.334-.277-.8-.668-1.422-1.118a30.537 30.537 0 00-5.2-2.934 58.838 58.838 0 00-3.028-1.278 3.966 3.966 0 00-2.67-.26 1.626 1.626 0 00-1.021 1.432 1.055 1.055 0 00.1.484.552.552 0 00.129.149z"
            data-name="Path 17973"
            transform="translate(-468.457 -79.263)"
          ></path>
          <path
            fill="#fff"
            d="M591.743 74.36l7.514 2.407-1.357 1.886-8.05-2.953z"
            data-name="Path 17974"
            transform="translate(-434.215 -70.758)"
          ></path>
          <path
            fill="#263238"
            d="M594.193 91.665c.017-.04-3.144-1.669-7.067-3.612S580 84.538 580 84.579s3.144 1.669 7.067 3.612 7.106 3.509 7.126 3.474z"
            data-name="Path 17975"
            transform="translate(-427.653 -77.565)"
          ></path>
          <path
            fill="#177ba3"
            d="M504.737 201.821l.1-4.934-16.884 6.272s-3.121-10.138-3.121-10.615l-3.742 2.337-6.219-2.67-6.219-3.071-12.578 22.031-13.2 38.9 31.084 20.736 23.052-24.7 14.02-21.363 7.7-13.526z"
            data-name="Path 17976"
            transform="translate(-336.304 -147.225)"
          ></path>
          <path
            fill="#263238"
            d="M562.011 215.227a3.374 3.374 0 01-.023-.494c0-.334 0-.808-.02-1.382l.077.047a21.153 21.153 0 00-5.084 3.482l-.134.127v-.184c0-.22-.027-.454-.04-.691-.137-2.42-.25-4.61-.334-6.2l-.08-1.879v-.511a.09.09 0 11.033 0c0 .133.027.3.047.507l.13 1.876c.107 1.586.237 3.775.374 6.2 0 .237.027.471.037.691l-.143-.06A19.946 19.946 0 01562 213.3l.08-.033v.083c0 .574-.023 1.038-.03 1.382a3.366 3.366 0 01-.039.495z"
            data-name="Path 17977"
            transform="translate(-411.864 -159.366)"
          ></path>
          <path
            fill="#263238"
            d="M593.9 227.84a.942.942 0 01-.194.1l-.564.27-2.09.958-6.93 3.1-3.291 1.455-.14.06.027-.15a25.948 25.948 0 00.381-4.74l.077.047-1.559.758-.417.194c-.094.043-.144.063-.147.057a.63.63 0 01.134-.083l.4-.22 1.539-.8.073-.04v.09a24.246 24.246 0 01-.334 4.783l-.114-.09 3.288-1.462 6.95-3.054 2.116-.908.577-.237a.943.943 0 01.218-.088z"
            data-name="Path 17978"
            transform="translate(-427.02 -173.007)"
          ></path>
          <rect
            width="136.872"
            height="97.764"
            fill="#455a64"
            data-name="Rectangle 17144"
            rx="14.57"
            transform="translate(5.351 30.145)"
          ></rect>
          <path
            fill="#455a64"
            d="M123.6 427.84h148.291v2.614a5.942 5.942 0 01-5.942 5.942H129.542a5.942 5.942 0 01-5.942-5.942z"
            data-name="Path 17979"
            transform="translate(-123.6 -306.246)"
          ></path>
          <path
            fill="#263238"
            d="M0 0H129.141V82.559H0z"
            data-name="Rectangle 17145"
            transform="translate(9.026 34.031)"
          ></path>
          <path
            fill="#263238"
            d="M304.23 425.1l1.839 3.161h25.893l1.709-3.161z"
            data-name="Path 17980"
            transform="translate(-243.936 -304.421)"
          ></path>
          <rect
            width="124.087"
            height="79.498"
            fill="#ebebeb"
            data-name="Rectangle 17146"
            rx="3.27"
            transform="translate(11.683 36.825)"
          ></rect>
          <path
            fill="#fafafa"
            d="M282.3 265.363H158.21l.13-73.673H282.3z"
            data-name="Path 17981"
            transform="translate(-146.657 -148.924)"
          ></path>
          <path
            fill="#263238"
            d="M185.765 180.951a1.021 1.021 0 11-1.021-1.072 1.048 1.048 0 011.021 1.072z"
            data-name="Path 17982"
            transform="translate(-163.654 -141.056)"
          ></path>
          <path
            fill="#263238"
            d="M167.205 180.951a1.021 1.021 0 11-1.021-1.072 1.048 1.048 0 011.021 1.072z"
            data-name="Path 17983"
            transform="translate(-151.29 -141.056)"
          ></path>
          <path
            fill="#263238"
            d="M176.745 180.951a1.021 1.021 0 11-1.021-1.072 1.048 1.048 0 011.021 1.072z"
            data-name="Path 17984"
            transform="translate(-157.645 -141.056)"
          ></path>
          <path
            fill="#263238"
            d="M275.327 424.907c0 .053-30.376.093-67.845.093s-67.852-.04-67.852-.093 30.376-.1 67.852-.1 67.845.046 67.845.1z"
            data-name="Path 17985"
            transform="translate(-134.279 -304.228)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M267.8 215.266a26.473 26.473 0 016.456-2.8l1.335-6.392 10.8-.19 1.555 6.342a26.579 26.579 0 016.549 2.577l5.458-3.575 7.768 7.5-3.378 5.585a26.44 26.44 0 012.8 6.459l6.389 1.335.19 10.8-6.342 1.556a26.449 26.449 0 01-2.58 6.529l3.578 5.458-7.5 7.768-5.578-3.381a26.439 26.439 0 01-6.459 2.8l-1.335 6.392-10.8.187-1.552-6.342a26.4 26.4 0 01-6.532-2.56l-5.458 3.578-7.768-7.5 3.381-5.578a26.449 26.449 0 01-2.807-6.459l-6.389-1.335-.19-10.8 6.342-1.556a26.53 26.53 0 012.577-6.549l-3.575-5.458 7.5-7.768zm5.755 14.383a8.192 8.192 0 110 .01z"
            data-name="Path 17986"
            transform="translate(-207.401 -158.377)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M429.46 227.4a9.243 9.243 0 01.751-2.307l-1.335-1.8 2.417-2.831 2 1.048a9.136 9.136 0 012.16-1.1l.334-2.226 3.709-.29.668 2.146a9.116 9.116 0 012.307.754l1.8-1.335 2.831 2.42-1.048 2a9.106 9.106 0 011.1 2.163l2.223.334.29 3.712-2.146.668a9.032 9.032 0 01-.754 2.307l1.335 1.806-2.417 2.827-2-1.048a9.1 9.1 0 01-2.163 1.1l-.334 2.226-3.709.29-.668-2.15a9.109 9.109 0 01-2.307-.751L432.7 238.7l-2.824-2.447 1.048-2a9.169 9.169 0 01-1.1-2.163l-2.223-.334-.29-3.712 2.146-.668zm5.03 1.125a4.006 4.006 0 103.285-3.565 4.006 4.006 0 00-3.285 3.562z"
            data-name="Path 17987"
            transform="translate(-325.931 -166.378)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M185.43 332.331a10.681 10.681 0 01.464-2.827L184 327.658l2.317-3.732 2.51.868a10.794 10.794 0 012.337-1.669l-.02-2.654 4.279-1 1.158 2.387a10.659 10.659 0 012.827.464l1.866-1.893 3.732 2.317-.868 2.51a10.794 10.794 0 011.669 2.337l2.654-.02 1 4.276-2.387 1.151a10.822 10.822 0 01-.467 2.827l1.893 1.863-2.317 3.735-2.51-.868a10.714 10.714 0 01-2.337 1.669l.02 2.654-4.276 1-1.162-2.387a10.681 10.681 0 01-2.817-.481l-1.863 1.893-3.735-2.317.868-2.51a10.8 10.8 0 01-1.669-2.337l-2.67.02-1-4.276 2.387-1.162zm6.185 1.2a11.345 11.345 0 110 .01z"
            data-name="Path 17988"
            transform="translate(-163.192 -234.05)"
          ></path>
          <path
            fill="#ebebeb"
            d="M210.019 236.883a6.722 6.722 0 01.567-1.739l-1-1.362 1.823-2.133 1.5.791a6.94 6.94 0 011.629-.831l.247-1.669 2.8-.22.5 1.619a6.987 6.987 0 011.739.567l1.362-1 2.13 1.823-.788 1.5a6.8 6.8 0 01.828 1.629l1.669.244.22 2.8-1.616.487a6.933 6.933 0 01-.564 1.739l1 1.359-1.823 2.133-1.5-.791a6.812 6.812 0 01-1.629.831l-.244 1.669-2.8.217-.5-1.619a6.766 6.766 0 01-1.739-.567l-1.359 1-2.133-1.823.788-1.5a6.8 6.8 0 01-.828-1.629l-1.669-.247-.217-2.8 1.619-.5zm3.789 1.359a3 3 0 10.712-2.192 3 3 0 00-.712 2.192z"
            data-name="Path 17989"
            transform="translate(-180.1 -174.259)"
          ></path>
          <path
            fill="#ebebeb"
            d="M431.379 329.112a6.04 6.04 0 01.487-1.5l-.871-1.168 1.566-1.833 1.292.668a5.882 5.882 0 011.4-.714l.21-1.442 2.4-.187.431 1.392a5.9 5.9 0 011.5.487l1.168-.871 1.833 1.565-.668 1.288a5.908 5.908 0 01.714 1.4l1.442.21.187 2.4-1.392.431a5.9 5.9 0 01-.487 1.5l.871 1.168-1.566 1.829-1.288-.668a6.029 6.029 0 01-1.4.714l-.21 1.439-2.4.187-.431-1.389a6.038 6.038 0 01-1.5-.487l-1.168.871-1.839-1.566.668-1.288a5.881 5.881 0 01-.714-1.4l-1.439-.21-.19-2.4 1.392-.431zm3.255 1.165A2.587 2.587 0 10437 327.5a2.587 2.587 0 00-2.367 2.777z"
            data-name="Path 17990"
            transform="translate(-327.71 -236.362)"
          ></path>
          <path
            fill="#177ba3"
            d="M301.5 272.658h-16.6a5.574 5.574 0 01-4.823-8.345l8.3-14.384 8.3-14.383a5.574 5.574 0 019.65 0l8.3 14.383 8.3 14.383a5.574 5.574 0 01-4.827 8.345z"
            data-name="Path 17991"
            transform="translate(-227.355 -176.286)"
          ></path>
          <path
            fill="#263238"
            d="M327.2 272.543s.043.083.127.257a2.283 2.283 0 01-.22 2.143 2.252 2.252 0 01-.624.621 2.037 2.037 0 01-.955.314c-2.831.03-6.916.023-11.97.037l-17.752.02h-2.537a2.186 2.186 0 01-2.046-1.482 2.383 2.383 0 01-.083-1.335c.027-.11.063-.22.1-.334a2.217 2.217 0 01.147-.31l.334-.584c.427-.784.861-1.572 1.3-2.37l5.381-9.877 5.414-9.87q1.305-2.393 2.57-4.7c.214-.381.411-.764.638-1.142a2.567 2.567 0 01.935-.928 2.747 2.747 0 012.51-.113 2.627 2.627 0 011 .794 10.014 10.014 0 01.618 1.078c.391.714.778 1.419 1.155 2.113q1.142 2.083 2.206 4.042c1.425 2.607 2.75 5.037 3.959 7.254 2.417 4.436 4.369 8.028 5.721 10.515l1.549 2.861c.174.334.307.574.4.748l.134.257s-.053-.083-.147-.25l-.414-.738-1.579-2.847c-1.362-2.48-3.338-6.058-5.768-10.485l-3.976-7.247-2.216-4.036c-.381-.694-.764-1.4-1.158-2.113a10.746 10.746 0 00-.611-1.048 2.483 2.483 0 00-.935-.744 2.584 2.584 0 00-2.363.107 2.44 2.44 0 00-.875.875c-.22.364-.421.754-.631 1.135l-2.57 4.7c-1.742 3.191-3.552 6.492-5.4 9.877l-5.4 9.87c-.437.8-.868 1.589-1.3 2.37a4.973 4.973 0 00-.551 1.182 2.219 2.219 0 00.077 1.252 2 2 0 001.889 1.372h2.537l17.752.017 11.967.017a1.966 1.966 0 00.925-.294 2.213 2.213 0 00.618-.6 2.276 2.276 0 00.394-1.282 2.513 2.513 0 00-.276-1.099z"
            data-name="Path 17992"
            transform="translate(-235.178 -182.867)"
          ></path>
          <path
            fill="#fff"
            d="M337.67 292.908a2.843 2.843 0 112.847 2.62 2.677 2.677 0 01-2.847-2.62zm.027-15.078h5.641l-.948 11.109h-3.769z"
            data-name="Path 17993"
            transform="translate(-266.213 -206.31)"
          ></path>
          <path
            fill="#fff"
            d="M337.1 235.425a4.505 4.505 0 00-1.055-.22 3.759 3.759 0 00-2.41.808 5.975 5.975 0 00-1.579 2.043c-.3.6-.447 1-.474.985a1 1 0 01.07-.287 7.012 7.012 0 01.294-.751 5.623 5.623 0 011.592-2.13 3.672 3.672 0 012.524-.788 2.472 2.472 0 01.791.194c.161.076.247.136.247.146z"
            data-name="Path 17994"
            transform="translate(-262.156 -177.827)"
          ></path>
          <path
            fill="#fff"
            d="M327.31 251.4a2.3 2.3 0 01.364-.8c.244-.411.474-.724.517-.7a2.269 2.269 0 01-.367.788 2.3 2.3 0 01-.514.712z"
            data-name="Path 17995"
            transform="translate(-259.311 -187.701)"
          ></path>
          <g
            data-name="Group 22542"
            opacity="0.3"
            transform="translate(53.255 65.321)"
          >
            <path
              fill="#fff"
              d="M312.364 259.26L286.5 275.2l-3.358 5.821 31.05-18.6z"
              data-name="Path 17996"
              transform="translate(-283.14 -259.26)"
            ></path>
          </g>
          <g
            data-name="Group 22543"
            opacity="0.3"
            transform="translate(52.267 72.385)"
          >
            <path
              fill="#fff"
              d="M314.465 280.42l-34.285 20.222a3.672 3.672 0 00.744 1.442l34.144-20.616z"
              data-name="Path 17997"
              transform="translate(-280.18 -280.42)"
            ></path>
          </g>
          <path
            fill="#e0e0e0"
            d="M517.227 296.27a10.1 10.1 0 00-10.565-2.831l-3.194-3.5-.444-.5-25.279-27.739a10.1 10.1 0 00-10.682-14l-1.235.491 4.3 2.881-.23 4.69-3.475 3.154-4.69-.23-3.064-3.378-1.265.5c-.364 2.827 1.532 6.392 3.6 8.679a10.094 10.094 0 0010.548 2.834l25.7 28.2.788.878 2.437 2.67a10.087 10.087 0 001.8 10.755 10.225 10.225 0 007.627 3.708l1.268-.494-3.068-3.381.23-4.673 3.475-3.154 4.673.227 1.8 3.875 1.268-.5a10.091 10.091 0 00-2.317-9.163z"
            data-name="Path 17998"
            transform="translate(-345.947 -186.173)"
          ></path>
          <g
            data-name="Group 22544"
            opacity="0.3"
            transform="translate(111.408 61.43)"
          >
            <path
              d="M517.227 296.27a10.1 10.1 0 00-10.565-2.831l-3.194-3.5-.444-.5-25.279-27.739a10.1 10.1 0 00-10.682-14l-1.235.491 4.3 2.881-.23 4.69-3.475 3.154-4.69-.23-3.064-3.378-1.265.5c-.364 2.827 1.532 6.392 3.6 8.679a10.094 10.094 0 0010.548 2.834l25.7 28.2.788.878 2.437 2.67a10.087 10.087 0 001.8 10.755 10.225 10.225 0 007.627 3.708l1.268-.494-3.068-3.381.23-4.673 3.475-3.154 4.673.227 1.8 3.875 1.268-.5a10.091 10.091 0 00-2.317-9.163z"
              data-name="Path 17999"
              transform="translate(-457.355 -247.603)"
            ></path>
          </g>
          <path
            fill="#e0e0e0"
            d="M515.5 297.755a10.091 10.091 0 00-10.561-2.827l-3.2-3.508-.444-.5-25.279-27.735a10.094 10.094 0 00-10.682-14l3.064 3.378-.227 4.673-3.471 3.174-4.673-.227-3.064-3.378a10.091 10.091 0 0012.878 12l25.7 28.2.791.878 2.437 2.67a10.094 10.094 0 0010.682 13.983l-3.068-3.385.227-4.673 3.478-3.154 4.673.23 3.064 3.378a10.078 10.078 0 00-2.325-9.177z"
            data-name="Path 18000"
            transform="translate(-345.493 -187.164)"
          ></path>
          <path
            fill="#263238"
            d="M515.283 297.588s.147.16.424.484a10.554 10.554 0 011.045 1.576 10.1 10.1 0 01.9 7.113v.067l-.047-.05-3.068-3.375.027.017-4.673-.224h.033l-3.471 3.158v-.037c-.067 1.455-.144 3.041-.224 4.69v-.043l3.041 3.355.08.087-.117.017a10.156 10.156 0 01-10.772-14.063v.08l-28.935-31.74.09.023a10.294 10.294 0 01-5.641.16 10.181 10.181 0 01-7.344-12.261l.04-.16.11.12 3.064 3.378-.06-.027 4.69.227-.063.023 3.5-3.158-.03.06.23-4.69.02.06-3.064-3.378-.1-.117.154-.02a10.176 10.176 0 0111.553 9.089 10.348 10.348 0 01-.784 5.007v-.08l28.89 31.764h-.04a10.121 10.121 0 018.312.978 10.609 10.609 0 011.749 1.335c.334.357.514.541.514.541l-.527-.527a11.171 11.171 0 00-1.756-1.335 10.108 10.108 0 00-8.268-.941h-.023v-.02l-28.944-31.711-.033-.037.02-.043a10.224 10.224 0 00.768-4.947 10.022 10.022 0 00-11.376-8.943l.05-.137 3.024 3.411.023.023v.037q-.11 2.317-.227 4.673v.037l-.027.023-3.475 3.154-.027.023h-.037l-4.673-.23h-.037l-.023-.027-3.068-3.378.15-.037a10.014 10.014 0 0012.754 11.91h.05l.04.04c10.508 11.543 20.362 22.348 28.917 31.754l.033.037-.02.047a10.025 10.025 0 0010.618 13.889l-.04.1-3.061-3.378-.017-.02v-.047c.08-1.646.157-3.231.23-4.673v-.04l3.482-3.148h.02l4.673.234c1.122 1.242 2.153 2.38 3.058 3.385h-.06a10.084 10.084 0 00-.865-7.08 11.125 11.125 0 00-1.025-1.582 5.054 5.054 0 00-.334-.482z"
            data-name="Path 18001"
            transform="translate(-345.272 -186.996)"
          ></path>
          <path
            fill="#263238"
            d="M500.611 296.169a.881.881 0 01-.11.25 5.884 5.884 0 01-.391.628 8.094 8.094 0 01-3.825 2.941 5.519 5.519 0 01-.708.214.838.838 0 01-.267.043c0-.03.364-.13.931-.371a9.226 9.226 0 003.772-2.9c.378-.488.571-.818.598-.805z"
            data-name="Path 18002"
            transform="translate(-371.233 -218.527)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M543.185 321.194l-9.6-4.473L525 315.65l3.151 2.67s-11.1 3.869-11.289 4.74.875 3.215 2.831 2.781a3.325 3.325 0 00-.641 2.807c.457 1.472 1.469 1.749 3.338 1.405 0 0-1.268 1.9-.274 2.867a3.271 3.271 0 002.544.724s.02 2.313 1.242 2.513a20.011 20.011 0 005.9-.427l6.459-2 4.186-.938z"
            data-name="Path 18003"
            transform="translate(-385.578 -231.506)"
          ></path>
          <path
            fill="#ff9a6c"
            d="M531.167 341a9.967 9.967 0 01-2.851 1.078 9.756 9.756 0 01-2.988.618 19.346 19.346 0 015.838-1.7z"
            data-name="Path 18004"
            transform="translate(-391.231 -248.372)"
          ></path>
          <path
            fill="#ff9a6c"
            d="M538.388 354.659a6.436 6.436 0 01-2.263.935 6.32 6.32 0 01-2.407.471 6.394 6.394 0 012.267-.931 6.519 6.519 0 012.403-.475z"
            data-name="Path 18005"
            transform="translate(-396.819 -257.47)"
          ></path>
          <path
            fill="#ff9a6c"
            d="M544.142 365.912c.023.144-.9.334-1.979.607s-1.926.668-2 .531.718-.688 1.869-1a3.579 3.579 0 012.11-.138z"
            data-name="Path 18006"
            transform="translate(-401.108 -264.927)"
          ></path>
          <path
            fill="#ff9a6c"
            d="M544.421 322.436a28.319 28.319 0 01-5.665 1.949 8.131 8.131 0 012.73-1.278 8.25 8.25 0 012.935-.671z"
            data-name="Path 18007"
            transform="translate(-400.176 -236.005)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M583.725 329.74l20.792 7.53-1.215 14.27-22.892-9.23z"
            data-name="Path 18008"
            transform="translate(-427.926 -240.892)"
          ></path>
          <path
            fill="#177ba3"
            d="M638.742 254.93a21.174 21.174 0 014.289 7.838c1.579 5.037 9.013 26.39 8.832 33.183-.2 7.027-4.927 12.04-15.321 9.263-6.2-1.669-13.592-4.339-13.592-4.339l5.25-17.275 4.109 1.058-6.632-12.458z"
            data-name="Path 18009"
            transform="translate(-456.267 -191.054)"
          ></path>
          <path
            fill="#263238"
            d="M630.091 279.38c.04.03-2.837 4.126-6.422 9.15s-6.519 9.083-6.559 9.049 2.837-4.126 6.422-9.149 6.522-9.077 6.559-9.05z"
            data-name="Path 18010"
            transform="translate(-452.375 -207.342)"
          ></path>
          <path
            fill="#263238"
            d="M652.433 344.052s-.18-.087-.5-.237a9.466 9.466 0 00-1.425-.5 39.811 39.811 0 00-2.2-.511l-2.7-.594a363.147 363.147 0 01-4.893-1.122l-1.479-.361a3.132 3.132 0 01-.537-.154 3.494 3.494 0 01.551.09l1.492.3c1.258.267 3 .644 4.907 1.071l2.694.611c.828.187 1.575.361 2.193.547a8.189 8.189 0 011.422.561 3.387 3.387 0 01.357.21.554.554 0 01.117.087z"
            data-name="Path 18011"
            transform="translate(-466.759 -248.107)"
          ></path>
          <g
            data-name="Group 22545"
            opacity="0.3"
            transform="translate(175.886 93.357)"
          >
            <path
              d="M665.621 346.6a2.574 2.574 0 00-1.429-1.909 6.919 6.919 0 00-2.39-.641 110.744 110.744 0 00-11.282-.8l.334.15a70.337 70.337 0 0113.6 3.966 1.048 1.048 0 00.788.093.825.825 0 00.379-.859z"
              data-name="Path 18012"
              transform="translate(-650.52 -343.25)"
            ></path>
          </g>
          <g
            data-name="Group 22546"
            opacity="0.3"
            transform="translate(91.798 30.231)"
          >
            <path
              d="M412.137 154.4c-3.5-.6-7.7.421-11.2-.177a2.335 2.335 0 00-1.555.1 1.943 1.943 0 00-.771 1.669 6.342 6.342 0 002 4.727 8.976 8.976 0 004.71 2.25 6.475 6.475 0 004.149-.484c2.19-1.193 3.241-5.68 2.667-8.085z"
              data-name="Path 18013"
              transform="translate(-398.608 -154.137)"
            ></path>
          </g>
          <path
            fill="#ffbf9d"
            d="M393.3 147.77s-2.126-2.493-2.433-3.642a19.414 19.414 0 010-4.246 2.337 2.337 0 011.519-2.07.771.771 0 01.791.063 7.948 7.948 0 01.968-1.088c.835-.855 1.232-1.432 3.068-.9 0 0 1.352-1.425 2.13-1.319a12.017 12.017 0 011.546.334s2.961-.127 3.568.814a2.714 2.714 0 001.335.891 3.672 3.672 0 012.113 1.926 7.646 7.646 0 01.591 2.58l-3 .12s.214 6.913-.544 7.47-1.045.357-1.045.357-.577 1.622-2.831.424c0 0-1.869.564-2.67-.207s-3.732-8.178-3.732-8.178l.083 3.672s1.545 2.38.788 2.937-2.245.062-2.245.062z"
            data-name="Path 18014"
            transform="translate(-301.573 -110.865)"
          ></path>
          <path
            fill="#ff9a6c"
            d="M409.63 146.06a2.339 2.339 0 01.17.434l.407 1.2c.334 1 .791 2.43 1.278 3.989a33.324 33.324 0 001.355 3.952 8.491 8.491 0 00.614 1.1c.164.25.267.377.257.387s-.037-.023-.09-.083a3.071 3.071 0 01-.22-.264 6.727 6.727 0 01-.668-1.088 28.876 28.876 0 01-1.409-3.952 246.332 246.332 0 01-1.232-4.006 129 129 0 00-.354-1.218 2.532 2.532 0 01-.11-.454z"
            data-name="Path 18015"
            transform="translate(-314.153 -118.525)"
          ></path>
          <path
            fill="#ff9a6c"
            d="M420.7 143.5a2.226 2.226 0 01.154.447l.357 1.238c.29 1.045.668 2.493 1.108 4.1s.8 3.058 1.058 4.112c.124.5.224.921.3 1.248a2.317 2.317 0 01.087.467 2.922 2.922 0 01-.154-.447c-.093-.334-.21-.741-.354-1.238-.294-1.045-.688-2.493-1.108-4.1s-.8-3.058-1.061-4.112c-.12-.5-.224-.921-.3-1.252a2.478 2.478 0 01-.087-.463z"
            data-name="Path 18016"
            transform="translate(-321.528 -116.819)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ErrorCodeIcon;
