import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../api";
import NavbarComponent from "../../components/shared/navbar";
import { IoMdCloseCircle } from "react-icons/io";
import content from "../../localization/content";
import { useLanguage } from "../../lib/language-context";
import localizationKeys from "../../localization/localization-keys";
import useAxios from "../../lib/use-axios";
import { Loader, Button, Modal } from "semantic-ui-react";
import { authAxios } from "../../config/axios-config";
import routes from "../../routes";
import ErrorCodeIcon from "../../assets/icons/error-code-icon";

const FailedPage = () => {
  const location = useLocation();
  const history = useHistory();

  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [paymentId, setPaymentId] = React.useState();
  const [error, setError] = React.useState("");
  const [viewError, setViewError] = React.useState(false);

  const { run: getStatus, isLoading: isGettingStatus } = useAxios({});

  React.useEffect(() => {
    if (location.search) {
      setPaymentId(
        location?.search
          .split("=")[2]
          .substring(0, location?.search.split("=")[1].length - 3)
      );
    }
  }, [location?.search]);

  React.useEffect(() => {
    if (paymentId)
      getStatus(
        authAxios.post(`${api.subscribtion.checkStatus}`, {
          paymentId: paymentId,
        })
      )
        .then(({ data }) => {
          data?.data.status === "Failed" && setError(data?.data?.error_code);
        })
        .catch((e) => {
          e?.errors?.map((s) => toast.error(s.message));
        });
  }, [getStatus, paymentId]);

  return (
    <div className="px-8">
      <NavbarComponent withLogo={true} />
      <div className="flex items-center justify-center">
        <div
          className="mx-4 mt-36 h-[30rem] max-w-screen-lg flex-grow rounded-2xl
           bg-white shadow-sm md:px-32 lg:px-96"
        >
          {isGettingStatus && !paymentId && !error ? (
            <Loader active={true} size="small" className="mt-8" />
          ) : (
            <>
              <IoMdCloseCircle
                className="mx-auto mt-16 justify-center fill-red-600 text-center"
                size={150}
              />
              <h2 className="mt-6 text-center text-xl text-black">
                {selectedContent[localizationKeys.failedMessage]}
              </h2>
              <div className="mt-4 flex items-center justify-center gap-2 text-lg text-[#888888]">
                <div>{selectedContent[localizationKeys.errorCode]}</div>
                <div
                  className=" cursor-pointer underline"
                  onClick={() => setViewError(true)}
                >
                  {error}
                </div>
              </div>
              <div className="mt-8 flex items-center justify-center ">
                <Button
                  primary
                  className="px-20"
                  onClick={() => history.push(routes.home)}
                >
                  {selectedContent[localizationKeys.returnHome]}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <ViewError
        isOpen={viewError}
        onClose={() => setViewError(false)}
        error={error}
      />
    </div>
  );
};

const ViewError = ({ isOpen, onClose, error }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  return (
    <Modal open={isOpen} onClose={onClose} size="tiny" closeOnEscape>
      <Modal.Content>
        <h4 className="text-center font-extrabold">
          {selectedContent[localizationKeys.errorCode]} {error}
        </h4>
        <ErrorCodeIcon size={170} className="mx-auto" />
        <div className="text-center text-base font-semibold">
          {error === "MF001" && selectedContent[localizationKeys.MF001]}
          {error === "MF002" && selectedContent[localizationKeys.MF002]}
          {error === "MF003" && selectedContent[localizationKeys.MF003]}
          {error === "MF004" && selectedContent[localizationKeys.MF004]}
          {error === "MF005" && selectedContent[localizationKeys.MF005]}
          {error === "MF006" && selectedContent[localizationKeys.MF006]}
          {error === "MF007" && selectedContent[localizationKeys.MF007]}
          {error === "MF008" && selectedContent[localizationKeys.MF008]}
          {error === "MF009" && selectedContent[localizationKeys.MF009]}
          {error === "MF010" && selectedContent[localizationKeys.MF010]}
          {error === "MF020" && selectedContent[localizationKeys.MF020]}
        </div>
        <div className="mt-8 flex items-center justify-center ">
          <Button primary className="px-20" onClick={() => onClose()}>
            {selectedContent[localizationKeys.okUnderstand]}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default FailedPage;
