import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";

const itemMap = (item) => ({
  "work-experience": {
    jobTitle: item.jobTitle,
    schoolName: item.schoolName,
    experienceYears: `${item.duration} ${item.durationType}`,
  },
  "training-courses": {
    courseName: item.courseName,
    courseOrganization: item.courseOrganization,
    courseDuration: `${item.courseDuration} ${item.durationType}`,
  },
  specializations: {
    specializationDegree: item.specializationDegree,
    specialization: item.specialization,
    graduationYear: item.graduationYear,
  },
});

const DetailsCard = ({ api, item }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const mappedItem = itemMap(item)[api];
  if (!mappedItem) {
    return null;
  }
  return (
    <div className="grid min-h-[152px] w-full grid-cols-2 items-center rounded-lg p-4 ring-1 ring-primary-300 sm:w-[300px]">
      {Object.keys(mappedItem).map((key, idx) => (
        <>
          <div className="col-start-1 col-end-2 text-[#9B9B9B]">
            <div className="capitalize">{selectedContent[localizationKeys[key]]}</div>
          </div>
          <div className="col-start-2 col-end-3">{mappedItem[key]}</div>
        </>
      ))}
    </div>
  );
};
export default DetailsCard;

//{
//     "_id": "63551afa8ab4c0fe8c6b2e7c",
//     "instructor": "63551649a6816a49818be0ff",
//     "jobTitle": "مردس اول",
//     "schoolName": "الشرق الاوسط",
//     "duration": 3,
//     "durationType": "year",
//     "createdAt": "2022-10-23T10:44:10.147Z",
//     "updatedAt": "2022-10-23T10:44:10.147Z",
//     "__v": 0
// }
// {
//   "_id": "63551b018ab4c0fe8c6b2e7f",
//   "instructor": "63551649a6816a49818be0ff",
//   "courseName": "CCNA",
//   "courseOrganization": "New Horizons",
//   "courseDuration": "3",
//   "durationType": "شهر",
//   "createdAt": "2022-10-23T10:44:17.184Z",
//   "updatedAt": "2022-10-23T10:44:17.184Z",
//   "__v": 0
// }
//{
//"_id": "63551b038ab4c0fe8c6b2e82",
//"instructor": "63551649a6816a49818be0ff",
//"specializationDegree": "بكالوريوس",
//"specialization": "رياضة",
//"graduationYear": "2020",
//"createdAt": "2022-10-23T10:44:19.660Z",
//"updatedAt": "2022-10-23T10:44:19.660Z",
// "__v": 0
//}
