import classNames from "classnames";
import { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { authAxios } from "../../config/axios-config";
import CardContainer from "./card-container";
import DetailsCard from "./details-card";
import StyledIcon from "./styled-icon";

const DetailsSection = ({ title, api, instructorId }) => {
  const [show, setShow] = useState(false);
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    authAxios
      .get(api, { params: { instructorId } })
      .then((response) => response.data.data)
      .then((data) => {
        setCollection(data);
      });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CardContainer>
        <div className="flex flex-row justify-between">
          <span className="font-bold">{title}</span>
          <span
            className="cursor-pointer rounded-full bg-primary-300/40 p-0.5 shadow-md hover:shadow active:shadow-sm"
            onClick={() => setShow((state) => !state)}
          >
            <StyledIcon
              icon={BsChevronDown}
              className={classNames({ "rotate-180": show })}
            />
          </span>
        </div>
      </CardContainer>

      <div className={classNames("mx-8 mt-8", { hidden: !show })}>
        <div className="mt-4 flex flex-wrap items-center gap-4">
          {(!!collection.length &&
            collection.map((item, idx) => (
              <DetailsCard key={idx} api={api} item={item} />
            ))) ||
            "no data"}
        </div>
      </div>
    </>
  );
};
export default DetailsSection;
