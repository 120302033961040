import { Dimmer, Loader, Modal } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";

function ResumeModal({ instructor, modal, setModal }) {
  const { lang } = useLanguage();
  return (
    <Modal
      closeIcon
      onClose={() => setModal((state) => ({ ...state, open: false }))}
      onOpen={() => setModal((state) => ({ ...state, open: true }))}
      open={modal.open}
      className="relative h-[80vh]"
    >
      <Modal.Content className="relative h-full">
        {instructor.resume_link ? (
          <>
            {modal.loaded || (
              <Dimmer active inverted>
                <Loader inverted>Loading...</Loader>
              </Dimmer>
            )}
            <iframe
              src={instructor.resume_link}
              title={instructor.name[lang]}
              frameborder="0"
              width="100%"
              height="100%"
              loading="lazy"
              onLoad={() => setModal((state) => ({ ...state, loaded: true }))}
            ></iframe>
          </>
        ) : (
          <>No resume found</>
        )}
      </Modal.Content>
    </Modal>
  );
}

export default ResumeModal;
