import React from "react";
import { Loader, Message } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import content from "../../localization/content";
import toast from "react-hot-toast";
import localizationKeys from "../../localization/localization-keys";
import { truncateString } from "../../utils/string";
import moment from "moment";
import useFilter from "../../lib/use-filter";
import PaginationComponent from "../../components/shared/pagination";
import { useLocation } from "react-router-dom";
import JobsNotFoundIcon from "../../assets/icons/jobs-not-found-icon";
import useMediaQuery from "../../components/hooks/use-media-query";
import "moment/locale/en-au";

const AllSubscribtionsPage = () => {
  moment.locale("en-au");
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const id = localStorage.getItem("id");

  const { run, isLoading } = useAxios({ data: [] });

  const [limit] = React.useState("10");
  const [totalPages, setTotalPages] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [page, setPage] = useFilter("page", 1);

  const location = useLocation();
  const queryString = require("query-string");
  const parsed = queryString.parse(location.search);

  const isSmall = useMediaQuery("(max-width: 776px)");

  React.useEffect(() => {
    if (page < 1) setPage(1);
    if (page && page !== "undefined")
      run(
        authAxios.get(
          `${api.subscribtion.all(id)}?limit=${limit}&page=${parsed?.page}`
        )
      )
        .then(({ data }) => {
          setTotalPages(data?.totalPages);
          // if (page > data?.totalPages) setPage(1);
          if (page > data?.totalPages && data?.totalPages !== 0) setPage(1);
          setData(data);
        })
        .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, limit, parsed?.page, run]);

  return (
    <div className="px-8">
      {!window.navigator.onLine && (
        <Message
          error
          className="mb-8"
          content={selectedContent[localizationKeys.noInternet]}
        />
      )}
      {isLoading ? (
        <Loader active={true} />
      ) : data?.data?.length > 0 ? (
        <>
          <div className="relative mb-10 min-h-[36rem] rounded-xl bg-white px-2 py-1 ">
            {isSmall ? (
              <div className="mt-4 flex flex-wrap items-center gap-4 ">
                {data?.data?.map((d) => (
                  <div
                    className="mx-4 h-28 w-80 rounded-lg shadow-md"
                    key={d._id}
                  >
                    <div className="mx-4 mt-2 grid grid-cols-2 text-sm">
                      <div className="text-gray-400">
                        {selectedContent[localizationKeys.vacancyPost]}
                      </div>
                      <div className="ltr:ml-4 rtl:mr-4">
                        {truncateString(d?.vacancy?.title, 18)}
                      </div>
                    </div>
                    <div className="mx-4 mt-2 grid grid-cols-2 text-sm">
                      <div className="text-gray-400">
                        {selectedContent[localizationKeys.dateAndTime]}
                      </div>
                      <div className="mx-2 w-max">
                        <span className="flex items-center gap-1">
                          <span>{moment(d?.createdAt).format("L")}</span>,
                          <span>{moment(d?.createdAt).format("LT")}</span>
                        </span>
                      </div>
                    </div>
                    <div className="mx-4 mt-2 grid grid-cols-2 text-sm">
                      <div className="text-gray-400">
                        {selectedContent[localizationKeys.price]}
                      </div>
                      <div className="w-full">
                        <span className="mx-2">{d?.invoice_value}</span>
                        {selectedContent[localizationKeys.RS]}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div className="my-4 grid w-full grid-cols-5 rounded-md bg-gray-100 p-2 text-[#878787]">
                  <div className="col-span-2 ltr:ml-10 rtl:mr-10">
                    {selectedContent[localizationKeys.vacancyPost]}
                  </div>
                  <div className="col-span-2 ltr:ml-16 rtl:mr-16">
                    {selectedContent[localizationKeys.dateAndTime]}
                  </div>
                  <div className="ltr:ml-16 rtl:mr-16">
                    {selectedContent[localizationKeys.price]}
                  </div>
                </div>
                {data?.data?.map((d) => (
                  <div
                    className="grid w-full grid-cols-5 items-center py-2"
                    key={d._id}
                  >
                    <div className="col-span-2 ltr:ml-10 rtl:mr-10">
                      {truncateString(d?.vacancy?.title, 20)}
                    </div>

                    <div className="col-span-2 text-center ltr:ml-10 rtl:mr-10">
                      <span className="flex items-center gap-1">
                        <span>{moment(d?.createdAt).format("L")}</span>,
                        <span>{moment(d?.createdAt).format("LT")}</span>
                      </span>
                    </div>
                    <div className="w-full font-bold ltr:ml-16 rtl:mr-16">
                      <span className="ltr:mr-1 rtl:ml-1">
                        {d?.invoice_value}
                      </span>
                      {selectedContent[localizationKeys.RS]}
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className="flex items-center justify-center">
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                className="absolute bottom-0 my-8 rtl:flex-row-reverse"
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flex h-[33rem] items-center justify-center rounded-xl bg-white px-2 py-1">
          <div className="flex-col">
            <JobsNotFoundIcon
              size="180"
              className="mx-auto my-4 justify-center text-center"
            />
            <span className="text-center text-lg font-bold">
              {selectedContent[localizationKeys.noSubscribtionsFound]}.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllSubscribtionsPage;
