import React from "react";
import CompleteProfileIcon from "../../assets/icons/complete-profile-icon";
import NavbarComponent from "../../components/shared/navbar";
import { Button } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { useHistory, useParams } from "react-router-dom";
import routes from "../../routes";

const CompleteProfileHome = () => {
  const params = useParams();
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const history = useHistory();

  return (
    <div>
      <NavbarComponent withLogo={true} />
      <div className="flex items-center justify-center">
        <div className="mx-4 mt-28 max-w-screen-lg flex-grow rounded-2xl bg-white shadow-sm md:px-32 lg:px-96">
          {/* <Image className="mx-auto mt-32" src={clock} /> */}
          <CompleteProfileIcon
            className="mx-auto mt-16 justify-center text-center"
            size={150}
          />
          <h2 className="mt-10 text-center text-xl text-black">
            {selectedContent[localizationKeys.pleaseCompleteYourProfile]}
          </h2>
          <div className="mx-auto mt-6 w-10/12 justify-center text-center font-semibold text-gray-400">
            {selectedContent[localizationKeys.pleaseCompleteYourProfileText]}
          </div>
          <Button
            primary
            className="my-6 mx-auto mb-20 block w-60"
            type="submit"
            size="small"
            onClick={() => history.push(routes.completeProfile.form(params.id))}
          >
            {selectedContent[localizationKeys.goToProfile]}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfileHome;
