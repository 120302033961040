import React from "react";

function HiddenIdentityIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 256.851 189.407"
    >
      <g data-name="About me-rafiki" transform="translate(0 -55)">
        <g transform="translate(0 55)">
          <path
            fill="#ebebeb"
            d="M0 0H17.014V0.128H0z"
            data-name="Rectangle 17152"
            transform="translate(214.101 176.452)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H4.464V0.128H0z"
            data-name="Rectangle 17153"
            transform="translate(165.684 177.849)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H9.858V0.128H0z"
            data-name="Rectangle 17154"
            transform="translate(203.729 171.684)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H22.187V0.128H0z"
            data-name="Rectangle 17155"
            transform="translate(26.949 172.548)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H3.252V0.128H0z"
            data-name="Rectangle 17156"
            transform="translate(53.713 172.548)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H48.124V0.128H0z"
            data-name="Rectangle 17157"
            transform="translate(67.536 174.715)"
          ></path>
          <path
            fill="#ebebeb"
            d="M140.329 200.275H41.143a2.933 2.933 0 01-2.933-2.933V57.908A2.933 2.933 0 0141.143 55h99.186a2.933 2.933 0 012.933 2.933v139.409a2.933 2.933 0 01-2.933 2.933zM41.143 55.1a2.81 2.81 0 00-2.8 2.8v139.442a2.81 2.81 0 002.8 2.8h99.186a2.81 2.81 0 002.8-2.8V57.908a2.81 2.81 0 00-2.8-2.8z"
            data-name="Path 18029"
            transform="translate(-18.581 -55)"
          ></path>
          <path
            fill="#ebebeb"
            d="M356.629 200.275h-99.2a2.938 2.938 0 01-2.933-2.933V57.908A2.938 2.938 0 01257.433 55h99.2a2.933 2.933 0 012.923 2.908v139.434a2.933 2.933 0 01-2.927 2.933zM257.433 55.1a2.81 2.81 0 00-2.8 2.8v139.442a2.81 2.81 0 002.8 2.8h99.2a2.81 2.81 0 002.8-2.8V57.908a2.81 2.81 0 00-2.8-2.8z"
            data-name="Path 18030"
            transform="translate(-123.763 -55)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H256.851V0.128H0z"
            data-name="Rectangle 17158"
            transform="translate(0 168.186)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H48.894V65.06H0z"
            data-name="Rectangle 17159"
            transform="translate(27.324 100.095)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H9.329V65.06H0z"
            data-name="Rectangle 17160"
            transform="translate(76.218 100.095)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H42.293V15.801H0z"
            data-name="Rectangle 17161"
            transform="translate(30.75 104.919)"
          ></path>
          <path
            fill="#ebebeb"
            d="M90.29 259.24l.185 2.106a1.341 1.341 0 001.341 1.228h7.978a1.341 1.341 0 001.341-1.228l.185-2.106z"
            data-name="Path 18031"
            transform="translate(-43.908 -154.321)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H42.293V15.801H0z"
            data-name="Rectangle 17162"
            transform="translate(30.75 122.446)"
          ></path>
          <path
            fill="#ebebeb"
            d="M90.29 293.36l.185 2.106a1.346 1.346 0 001.341 1.228h7.978a1.346 1.346 0 001.341-1.228l.185-2.106z"
            data-name="Path 18032"
            transform="translate(-43.908 -170.914)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H42.293V15.801H0z"
            data-name="Rectangle 17163"
            transform="translate(30.75 139.968)"
          ></path>
          <path
            fill="#ebebeb"
            d="M90.29 327.47l.185 2.106a1.341 1.341 0 001.341 1.248h7.978a1.341 1.341 0 001.341-1.228l.185-2.106z"
            data-name="Path 18033"
            transform="translate(-43.908 -187.502)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M102.153 379.732H60.374l-.514-3.252h42.293z"
            data-name="Path 18034"
            transform="translate(-29.11 -211.335)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M150.923 379.732h-8.733v-3.252h9.247z"
            data-name="Path 18035"
            transform="translate(-69.147 -211.335)"
          ></path>
          <path
            fill="#fafafa"
            d="M88.155 229.94H71.46l1.854-21.17h16.7z"
            data-name="Path 18036"
            transform="translate(-34.751 -129.778)"
          ></path>
          <path
            fill="#ebebeb"
            d="M103.96 229.94h.8l1.854-21.17h-.8z"
            data-name="Path 18037"
            transform="translate(-50.555 -129.778)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M88.435 229.825H75.14l1.474-16.855h13.295z"
            data-name="Path 18038"
            transform="translate(-36.54 -131.82)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M88.611 229.751H77.51l1.233-14.081h11.1z"
            data-name="Path 18039"
            transform="translate(-37.693 -133.133)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M112.006 237.575l-1.146-13.1h16.618l1.151 13.1z"
            data-name="Path 18040"
            transform="translate(-53.911 -137.413)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M111.159 237.575l-1.146-13.1h-.894l1.146 13.1z"
            data-name="Path 18041"
            transform="translate(-53.065 -137.413)"
          ></path>
          <path
            fill="#ebebeb"
            d="M115.3 237.5l-.909-10.433h13.228l.914 10.433z"
            data-name="Path 18042"
            transform="translate(-55.628 -138.677)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M117.415 237.462l-.765-8.712h11.05l.76 8.712z"
            data-name="Path 18043"
            transform="translate(-56.727 -139.494)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M210.54 270.48h3.93v57.185a.611.611 0 01-.616.616h-2.712a.611.611 0 01-.611-.611v-57.19z"
            data-name="Path 18044"
            transform="translate(-102.38 -159.787)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M424.436 257.121h-9.046v-.8a2.748 2.748 0 012.9-2.743l3.539.19a2.748 2.748 0 012.6 2.743z"
            data-name="Path 18045"
            transform="translate(-202.003 -151.565)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M374.335 222.929A1.33 1.33 0 00373 221.66h-9.134a1.33 1.33 0 00-1.33 1.269l2.168 19.007h13.623z"
            data-name="Path 18046"
            transform="translate(-176.302 -136.046)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M426.928 167.19h-3.406l-3.822 35.933h3.4a1.161 1.161 0 001.13-1.027l3.6-33.9a.9.9 0 00-.9-1z"
            data-name="Path 18047"
            transform="translate(-204.099 -109.558)"
          ></path>
          <path
            fill="#ebebeb"
            d="M374.39 203.1h-50.98a.9.9 0 01-.909-1.027l3.6-33.9a1.161 1.161 0 011.125-1.027h51.011a.9.9 0 01.909 1.027l-3.6 33.9a1.161 1.161 0 01-1.156 1.027z"
            data-name="Path 18048"
            transform="translate(-156.827 -109.533)"
          ></path>
          <path
            fill="#fafafa"
            d="M375.4 201h-48.08a.791.791 0 01-.8-.894l3.149-29.569a1.027 1.027 0 01.986-.889h48.072a.786.786 0 01.8.889l-3.144 29.569a1.027 1.027 0 01-.983.894z"
            data-name="Path 18049"
            transform="translate(-158.784 -110.754)"
          ></path>
          <rect
            width="18.236"
            height="1.479"
            fill="#e0e0e0"
            data-name="Rectangle 17164"
            rx="0.74"
            transform="translate(185.041 104.431)"
          ></rect>
          <path
            fill="#e6e6e6"
            d="M327.74 218.086l14.286-12.421c1.356-1.176 3.082-1.176 3.878 0l8.281 12.421z"
            data-name="Path 18050"
            transform="translate(-159.379 -127.839)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M342.026 205.727l-14.286 12.421h13.7c-1.366-.694-4.587-1.063-4.2-2.646.514-2.055 6.868-1.336 7.341-3.293s-4.069-1.336-3.5-3.7a8.851 8.851 0 012.527-3.6 3.6 3.6 0 00-1.582.818z"
            data-name="Path 18051"
            transform="translate(-159.379 -127.901)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M368.66 224.324l6.34-6.267c.6-.6 1.372-.6 1.716 0l3.673 6.267z"
            data-name="Path 18052"
            transform="translate(-179.278 -134.077)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M375 218.091l-6.334 6.267h6.072c-.606-.349-2.055-.539-1.86-1.336.221-1.027 3.041-.673 3.252-1.659s-1.8-.678-1.541-1.87a4.489 4.489 0 011.12-1.813 1.484 1.484 0 00-.709.411z"
            data-name="Path 18053"
            transform="translate(-179.283 -134.111)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M389.76 224.324l8.856-6.267a1.832 1.832 0 012.409 0l5.137 6.267z"
            data-name="Path 18054"
            transform="translate(-189.539 -134.077)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M398.6 218.091l-8.872 6.267h8.507c-.853-.349-2.846-.539-2.61-1.336.308-1.027 4.264-.673 4.557-1.659s-2.527-.678-2.173-1.87a4.56 4.56 0 011.572-1.813 2.363 2.363 0 00-.981.411z"
            data-name="Path 18055"
            transform="translate(-189.525 -134.111)"
          ></path>
          <ellipse
            cx="6.349"
            cy="5.137"
            fill="#f0f0f0"
            data-name="Ellipse 204"
            rx="6.349"
            ry="5.137"
            transform="rotate(-42.01 192.46 -221.236)"
          ></ellipse>
          <ellipse
            cx="6.349"
            cy="5.137"
            fill="#f0f0f0"
            data-name="Ellipse 205"
            opacity="0.8"
            rx="6.349"
            ry="5.137"
            transform="rotate(-42.01 192.46 -221.236)"
          ></ellipse>
          <path
            fill="#e6e6e6"
            d="M0 0H34.058V5.137H0z"
            data-name="Rectangle 17165"
            transform="translate(195.741 105.556)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M257.935 270.48h3.93v57.185a.611.611 0 01-.611.616h-2.712a.611.611 0 01-.611-.611v-57.19z"
            data-name="Path 18056"
            transform="translate(-125.431 -159.787)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M435.265 270.48h3.93v57.185a.611.611 0 01-.611.611h-2.712a.611.611 0 01-.611-.611V270.48z"
            data-name="Path 18057"
            transform="translate(-211.666 -159.787)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H99.566V5.137H0z"
            data-name="Rectangle 17166"
            transform="translate(105.884 105.556)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M387.875 270.48h3.93v57.185a.611.611 0 01-.611.611h-2.712a.611.611 0 01-.611-.611V270.48z"
            data-name="Path 18058"
            transform="translate(-188.62 -159.787)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M332.625 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.914a.247.247 0 01.236.18l.354 1.346a.241.241 0 01-.231.3z"
            data-name="Path 18059"
            transform="translate(-161.018 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M336.045 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.914a.247.247 0 01.236.18l.354 1.346a.241.241 0 01-.231.3z"
            data-name="Path 18060"
            transform="translate(-162.681 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M339.465 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.914a.247.247 0 01.236.18l.354 1.346a.241.241 0 01-.231.3z"
            data-name="Path 18061"
            transform="translate(-164.344 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M342.885 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.914a.247.247 0 01.236.18l.354 1.346a.241.241 0 01-.231.3z"
            data-name="Path 18062"
            transform="translate(-166.007 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M346.305 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.894a.247.247 0 01.236.18l.36 1.346a.242.242 0 01-.216.3z"
            data-name="Path 18063"
            transform="translate(-167.671 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M349.725 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.914a.247.247 0 01.236.18l.36 1.346a.241.241 0 01-.236.3z"
            data-name="Path 18064"
            transform="translate(-169.334 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M353.145 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.914a.241.241 0 01.236.18l.36 1.346a.241.241 0 01-.236.3z"
            data-name="Path 18065"
            transform="translate(-170.997 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M356.565 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.914a.241.241 0 01.236.18l.36 1.346a.241.241 0 01-.236.3z"
            data-name="Path 18066"
            transform="translate(-172.66 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M359.985 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.914a.241.241 0 01.236.18l.36 1.346a.241.241 0 01-.236.3z"
            data-name="Path 18067"
            transform="translate(-174.323 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M363.405 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.914a.242.242 0 01.236.18l.36 1.346a.241.241 0 01-.236.3z"
            data-name="Path 18068"
            transform="translate(-175.986 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M366.825 256.409h-1.274a.241.241 0 01-.241-.241v-1.346a.241.241 0 01.241-.241h.914a.241.241 0 01.236.18l.36 1.346a.241.241 0 01-.236.3z"
            data-name="Path 18069"
            transform="translate(-177.649 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M370.245 256.409h-1.269a.241.241 0 01-.247-.241v-1.346a.241.241 0 01.247-.241h.919a.241.241 0 01.236.18l.36 1.346a.241.241 0 01-.247.3z"
            data-name="Path 18070"
            transform="translate(-179.312 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M373.665 256.409H372.4a.241.241 0 01-.247-.241v-1.346a.241.241 0 01.247-.241h.909a.241.241 0 01.236.18l.36 1.346a.241.241 0 01-.236.3z"
            data-name="Path 18071"
            transform="translate(-180.976 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M377.085 256.409h-1.269a.241.241 0 01-.247-.241v-1.346a.241.241 0 01.247-.241h.909a.241.241 0 01.236.18l.36 1.346a.241.241 0 01-.236.3z"
            data-name="Path 18072"
            transform="translate(-182.639 -152.055)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M380.505 256.409h-1.269a.241.241 0 01-.247-.241v-1.346a.241.241 0 01.247-.241h.909a.241.241 0 01.236.18l.36 1.346a.241.241 0 01-.236.3z"
            data-name="Path 18073"
            transform="translate(-184.302 -152.055)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H28.428V2.116H0z"
            data-name="Rectangle 17167"
            transform="translate(169.054 103.439)"
          ></path>
        </g>
        <g transform="translate(26.348 232.777)">
          <ellipse
            cx="99.602"
            cy="5.815"
            fill="#f5f5f5"
            rx="99.602"
            ry="5.815"
          ></ellipse>
        </g>
        <g transform="translate(48.891 81.656)">
          <path
            fill="#177ba3"
            d="M299.231 265.94l-4.731 4.351 3.971 4.346z"
            data-name="Path 18074"
            transform="translate(-192.106 -184.236)"
          ></path>
          <path
            fill="#fff"
            d="M299.231 265.94l-4.731 4.351 3.971 4.346z"
            data-name="Path 18075"
            opacity="0.7"
            transform="translate(-192.106 -184.236)"
          ></path>
          <path
            fill="#177ba3"
            d="M328.58 280.1h-27.272a.842.842 0 01-.853-.935l1.592-18.206a1.027 1.027 0 011.027-.935h27.267a.842.842 0 01.853.935L329.6 279.16a1.027 1.027 0 01-1.02.94z"
            data-name="Path 18076"
            transform="translate(-194.999 -181.357)"
          ></path>
          <path
            fill="#fff"
            d="M328.58 280.1h-27.272a.842.842 0 01-.853-.935l1.592-18.206a1.027 1.027 0 011.027-.935h27.267a.842.842 0 01.853.935L329.6 279.16a1.027 1.027 0 01-1.02.94z"
            data-name="Path 18077"
            opacity="0.7"
            transform="translate(-194.999 -181.357)"
          ></path>
          <path
            fill="#177ba3"
            d="M305.25 264.175l-1.3 14.9a.848.848 0 00.858.935h23.964a1.027 1.027 0 001.027-.935l1.3-14.9a.842.842 0 00-.853-.935h-23.98a1.027 1.027 0 00-1.016.935z"
            data-name="Path 18078"
            transform="translate(-196.697 -182.922)"
          ></path>
          <path
            fill="#fff"
            d="M305.25 264.175l-1.3 14.9a.848.848 0 00.858.935h23.964a1.027 1.027 0 001.027-.935l1.3-14.9a.842.842 0 00-.853-.935h-23.98a1.027 1.027 0 00-1.016.935z"
            data-name="Path 18079"
            opacity="0.4"
            transform="translate(-196.697 -182.922)"
          ></path>
          <path
            fill="#263238"
            d="M332.382 280.863a8.014 8.014 0 00-7.993-7.505h-2.4a.873.873 0 01-.75-.447c-.344-.57-.842-1.079-.75-1.628.139-.842 1.063-1.3 1.063-2.142a2.671 2.671 0 00-.627-1.7 1.569 1.569 0 10-2.7-1.315h-.6a1.561 1.561 0 10-2.676 1.315 2.671 2.671 0 00-.627 1.7c0 .873 1 1.331 1.161 2.214.56 3.041-1.372 7.562-2.3 9.509z"
            data-name="Path 18080"
            transform="translate(-201.19 -183.774)"
          ></path>
          <path
            fill="#263238"
            d="M350.258 294.71c2.877-1.151 1.88 5.353 1.788 6.7-.123 1.854-.118 3.776 2.147 3.92.694.041.688-1.027 0-1.079-1.87-.118-.991-3.6-.914-4.813.1-1.454.283-3.18-.385-4.541a2.3 2.3 0 00-2.923-1.233c-.637.257-.354 1.3.288 1.027z"
            data-name="Path 18081"
            transform="translate(-218.906 -197.646)"
          ></path>
          <path
            fill="#fff"
            d="M317.719 271.894a1.207 1.207 0 001.854 0c.17-.185-.1-.457-.272-.272a.827.827 0 01-1.31 0c-.169-.185-.441.092-.272.272z"
            data-name="Path 18082"
            transform="translate(-203.371 -186.969)"
          ></path>
          <path
            fill="#fff"
            d="M323.549 271.894a1.207 1.207 0 001.854 0c.17-.185-.1-.457-.272-.272a.827.827 0 01-1.31 0c-.169-.185-.441.092-.272.272z"
            data-name="Path 18083"
            transform="translate(-206.206 -186.969)"
          ></path>
          <path
            fill="#177ba3"
            d="M316.753 294.212h-5.178a1.295 1.295 0 01-1.295-1.3.473.473 0 01.473-.473h6.827a.473.473 0 01.473.473 1.3 1.3 0 01-1.3 1.3z"
            data-name="Path 18084"
            transform="translate(-199.78 -197.123)"
          ></path>
          <path
            fill="#fff"
            d="M316.753 294.212h-5.178a1.295 1.295 0 01-1.295-1.3.473.473 0 01.473-.473h6.827a.473.473 0 01.473.473 1.3 1.3 0 01-1.3 1.3z"
            data-name="Path 18085"
            opacity="0.9"
            transform="translate(-199.78 -197.123)"
          ></path>
          <path
            fill="#177ba3"
            d="M178.785 301.038h-24.463a1.027 1.027 0 01-1.027-1.135l2.142-24.462a1.264 1.264 0 011.233-1.14h24.462a1.027 1.027 0 011.027 1.14l-2.136 24.459a1.269 1.269 0 01-1.238 1.135z"
            data-name="Path 18086"
            transform="translate(-123.435 -188.301)"
          ></path>
          <path
            fill="#fff"
            d="M178.785 301.038h-24.463a1.027 1.027 0 01-1.027-1.135l2.142-24.462a1.264 1.264 0 011.233-1.14h24.462a1.027 1.027 0 011.027 1.14l-2.136 24.459a1.269 1.269 0 01-1.238 1.135z"
            data-name="Path 18087"
            opacity="0.7"
            transform="translate(-123.435 -188.301)"
          ></path>
          <path
            fill="#177ba3"
            d="M158.446 278.3l-1.9 21.7a.863.863 0 00.873.955h21.7a1.058 1.058 0 001.031-.955l1.9-21.7a.863.863 0 00-.873-.956h-21.693a1.063 1.063 0 00-1.038.956z"
            data-name="Path 18088"
            transform="translate(-125.016 -189.779)"
          ></path>
          <path
            d="M158.446 278.3l-1.9 21.7a.863.863 0 00.873.955h21.7a1.058 1.058 0 001.031-.955l1.9-21.7a.863.863 0 00-.873-.956h-21.693a1.063 1.063 0 00-1.038.956z"
            data-name="Path 18089"
            opacity="0.1"
            transform="translate(-125.016 -189.779)"
          ></path>
          <path
            fill="#177ba3"
            d="M206.39 298.237l5.435-5-4.562-5z"
            data-name="Path 18090"
            transform="translate(-149.258 -195.08)"
          ></path>
          <path
            fill="#fff"
            d="M206.39 298.237l5.435-5-4.562-5z"
            data-name="Path 18091"
            opacity="0.7"
            transform="translate(-149.258 -195.08)"
          ></path>
          <path
            fill="#177ba3"
            d="M171.76 270.13h-19.731a1.649 1.649 0 00-1.649 1.644v19.742c0 .729 1.967 2.7 2.7 2.7h19.742a1.649 1.649 0 001.644-1.649v-19.74c-.004-.709-1.971-2.697-2.706-2.697z"
            data-name="Path 18092"
            transform="translate(-122.021 -186.273)"
          ></path>
          <path
            d="M171.76 270.13h-19.731a1.649 1.649 0 00-1.649 1.644v19.742c0 .729 1.967 2.7 2.7 2.7h19.742a1.649 1.649 0 001.644-1.649v-19.74c-.004-.709-1.971-2.697-2.706-2.697z"
            data-name="Path 18093"
            opacity="0.3"
            transform="translate(-122.021 -186.273)"
          ></path>
          <rect
            width="23.029"
            height="23.029"
            fill="#177ba3"
            data-name="Rectangle 17168"
            rx="3.41"
            transform="translate(27.887 83.343)"
          ></rect>
          <rect
            width="23.029"
            height="23.029"
            fill="#fff"
            data-name="Rectangle 17169"
            opacity="0.7"
            rx="3.41"
            transform="translate(27.887 83.343)"
          ></rect>
          <circle
            cx="8.199"
            cy="8.199"
            r="8.199"
            fill="#263238"
            data-name="Ellipse 206"
            transform="rotate(-76.72 78.85 32.052)"
          ></circle>
          <circle
            cx="8.199"
            cy="8.199"
            r="8.199"
            data-name="Ellipse 207"
            opacity="0.5"
            transform="rotate(-76.72 78.85 32.052)"
          ></circle>
          <circle
            cx="8.199"
            cy="8.199"
            r="8.199"
            fill="#263238"
            data-name="Ellipse 208"
            transform="translate(31.205 86.174)"
          ></circle>
          <path
            fill="#263238"
            d="M179.438 280.089a8.086 8.086 0 00-3.884-3.889l-3.344 7.228z"
            data-name="Path 18094"
            transform="translate(-132.637 -189.225)"
          ></path>
          <path
            fill="#263238"
            d="M157.48 294.279a8.029 8.029 0 003.889 3.884l3.339-7.223z"
            data-name="Path 18095"
            transform="translate(-125.473 -196.393)"
          ></path>
          <path
            fill="#fff"
            d="M179.438 280.089a8.086 8.086 0 00-3.884-3.889l-3.344 7.228z"
            data-name="Path 18096"
            opacity="0.5"
            transform="translate(-132.637 -189.225)"
          ></path>
          <path
            fill="#fff"
            d="M157.48 294.279a8.029 8.029 0 003.889 3.884l3.339-7.223z"
            data-name="Path 18097"
            opacity="0.5"
            transform="translate(-125.473 -196.393)"
          ></path>
          <path
            fill="#177ba3"
            d="M172.018 288.144a2.569 2.569 0 11-.752-1.842 2.594 2.594 0 01.752 1.842z"
            data-name="Path 18098"
            transform="translate(-130.02 -193.772)"
          ></path>
          <path
            fill="#263238"
            d="M171.906 289.2a.483.483 0 00-.966 0v.483a.483.483 0 00.966 0z"
            data-name="Path 18099"
            transform="translate(-132.019 -195.313)"
          ></path>
          <circle
            cx="0.483"
            cy="0.483"
            r="0.483"
            fill="#fff"
            data-name="Ellipse 209"
            transform="translate(38.921 93.407)"
          ></circle>
          <path
            fill="#177ba3"
            d="M153.444 305.68a.334.334 0 01-.334-.334v-3.082a.334.334 0 01.334-.334.334.334 0 01.329.334v3.082a.334.334 0 01-.329.334z"
            data-name="Path 18100"
            transform="translate(-123.348 -201.737)"
          ></path>
          <path
            d="M153.444 305.68a.334.334 0 01-.334-.334v-3.082a.334.334 0 01.334-.334.334.334 0 01.329.334v3.082a.334.334 0 01-.329.334z"
            data-name="Path 18101"
            opacity="0.5"
            transform="translate(-123.348 -201.737)"
          ></path>
          <rect
            width="2.717"
            height="1.151"
            fill="#177ba3"
            data-name="Rectangle 17170"
            rx="0.575"
            transform="translate(28.734 101.009)"
          ></rect>
          <rect
            width="2.717"
            height="1.151"
            fill="#fff"
            data-name="Rectangle 17171"
            opacity="0.3"
            rx="0.575"
            transform="translate(28.734 101.009)"
          ></rect>
          <path
            fill="#177ba3"
            d="M188.381 273.29a.765.765 0 00-.765.765v2.42a.76.76 0 00.406.668v11.266h-.586a.765.765 0 00-.765.765v1.541a.765.765 0 00.765.765h.586a.365.365 0 00.724 0v-14.337a.765.765 0 00.4-.668v-2.42a.765.765 0 00-.765-.765z"
            data-name="Path 18102"
            transform="translate(-139.668 -187.81)"
          ></path>
          <path
            d="M188.381 273.29a.765.765 0 00-.765.765v2.42a.76.76 0 00.406.668v11.266h-.586a.765.765 0 00-.765.765v1.541a.765.765 0 00.765.765h.586a.365.365 0 00.724 0v-14.337a.765.765 0 00.4-.668v-2.42a.765.765 0 00-.765-.765z"
            data-name="Path 18103"
            opacity="0.5"
            transform="translate(-139.668 -187.81)"
          ></path>
          <path
            fill="#177ba3"
            d="M175.329 252.712a.123.123 0 00-.185-.1c-.966.514-1.51-2.019-2.512-1.182a.144.144 0 00-.051.1v5.144a1.711 1.711 0 00-.925-.241 1.916 1.916 0 00-1.87 1.069c-.154.591.421 1.069 1.295 1.069a1.921 1.921 0 001.87-1.069.724.724 0 00.026-.205v-4.562c.837-.1 1.377 2.024 2.307 1.248a.139.139 0 00.046-.1z"
            data-name="Path 18104"
            transform="translate(-131.446 -177.095)"
          ></path>
          <path
            d="M175.329 252.712a.123.123 0 00-.185-.1c-.966.514-1.51-2.019-2.512-1.182a.144.144 0 00-.051.1v5.144a1.711 1.711 0 00-.925-.241 1.916 1.916 0 00-1.87 1.069c-.154.591.421 1.069 1.295 1.069a1.921 1.921 0 001.87-1.069.724.724 0 00.026-.205v-4.562c.837-.1 1.377 2.024 2.307 1.248a.139.139 0 00.046-.1z"
            data-name="Path 18105"
            opacity="0.2"
            transform="translate(-131.446 -177.095)"
          ></path>
          <path
            fill="#177ba3"
            d="M185.289 263.838a.1.1 0 00-.154-.087c-.812.442-1.274-1.7-2.116-1a.118.118 0 00-.041.087v4.335a1.418 1.418 0 00-.781-.205 1.632 1.632 0 00-1.577.9c-.134.493.36.9 1.094.9a1.628 1.628 0 001.577-.9.679.679 0 000-.175v-3.837c.7-.082 1.156 1.706 1.942 1.053a.113.113 0 00.036-.087z"
            data-name="Path 18106"
            transform="translate(-136.715 -182.617)"
          ></path>
          <path
            d="M185.289 263.838a.1.1 0 00-.154-.087c-.812.442-1.274-1.7-2.116-1a.118.118 0 00-.041.087v4.335a1.418 1.418 0 00-.781-.205 1.632 1.632 0 00-1.577.9c-.134.493.36.9 1.094.9a1.628 1.628 0 001.577-.9.679.679 0 000-.175v-3.837c.7-.082 1.156 1.706 1.942 1.053a.113.113 0 00.036-.087z"
            data-name="Path 18107"
            opacity="0.3"
            transform="translate(-136.715 -182.617)"
          ></path>
          <path
            fill="#177ba3"
            d="M190.126 245.66a.1.1 0 00-.159-.087c-.827.452-1.3-1.736-2.163-1.027a.118.118 0 00-.041.087v4.423a1.474 1.474 0 00-.8-.211 1.656 1.656 0 00-1.608.92c-.134.5.36.92 1.115.92a1.648 1.648 0 001.608-.92.514.514 0 000-.175v-3.909c.719-.087 1.182 1.736 1.978 1.069a.118.118 0 00.041-.087z"
            data-name="Path 18108"
            transform="translate(-139.021 -173.762)"
          ></path>
          <path
            d="M190.126 245.66a.1.1 0 00-.159-.087c-.827.452-1.3-1.736-2.163-1.027a.118.118 0 00-.041.087v4.423a1.474 1.474 0 00-.8-.211 1.656 1.656 0 00-1.608.92c-.134.5.36.92 1.115.92a1.648 1.648 0 001.608-.92.514.514 0 000-.175v-3.909c.719-.087 1.182 1.736 1.978 1.069a.118.118 0 00.041-.087z"
            data-name="Path 18109"
            opacity="0.4"
            transform="translate(-139.021 -173.762)"
          ></path>
          <path
            fill="#177ba3"
            d="M339.248 204.767h-29.476a1.027 1.027 0 01-1.027-1.135l2.569-29.476a1.269 1.269 0 011.238-1.135h29.476a1.027 1.027 0 011.027 1.135l-2.569 29.476a1.259 1.259 0 01-1.238 1.135z"
            data-name="Path 18110"
            transform="translate(-199.03 -139.049)"
          ></path>
          <path
            fill="#fff"
            d="M339.248 204.767h-29.476a1.027 1.027 0 01-1.027-1.135l2.569-29.476a1.269 1.269 0 011.238-1.135h29.476a1.027 1.027 0 011.027 1.135l-2.569 29.476a1.259 1.259 0 01-1.238 1.135z"
            data-name="Path 18111"
            opacity="0.7"
            transform="translate(-199.03 -139.049)"
          ></path>
          <path
            fill="#177ba3"
            d="M314.879 177.755l-2.235 25.772a1.027 1.027 0 001.027 1.135h25.772a1.259 1.259 0 001.233-1.135l2.255-25.772a1.027 1.027 0 00-1.027-1.135h-25.766a1.269 1.269 0 00-1.259 1.135z"
            data-name="Path 18112"
            transform="translate(-200.927 -140.8)"
          ></path>
          <path
            d="M314.879 177.755l-2.235 25.772a1.027 1.027 0 001.027 1.135h25.772a1.259 1.259 0 001.233-1.135l2.255-25.772a1.027 1.027 0 00-1.027-1.135h-25.766a1.269 1.269 0 00-1.259 1.135z"
            data-name="Path 18113"
            opacity="0.1"
            transform="translate(-200.927 -140.8)"
          ></path>
          <path
            fill="#177ba3"
            d="M330.161 172.2c0-1.567-1.649-1.2-3.683-1.2s-3.683-.365-3.683 1.2a1.6 1.6 0 00.673 1.356h-.19c-2.245 0-4.063-.067-4.063 1.865a3.38 3.38 0 001.741 2.872 2.019 2.019 0 00-1.13 1.762c0 1.13 1.027 1.228 2.281 1.228h.211a2.055 2.055 0 00-.283 2.533h-.313c-1.649 0-2.979.914-3.015 2.055h-.207c-2.795 0-5.065 1.582-5.065 3.534s2.271 3.529 5.065 3.529 5.065-1.582 5.065-3.529a2.944 2.944 0 00-1.12-2.214c1.315-.051 2.3-.283 2.3-1.269a1.69 1.69 0 00-.514-1.156 2.055 2.055 0 00-.031-4.017 1.136 1.136 0 00.175-.658 1.9 1.9 0 00-.426-1.187 3.7 3.7 0 003.39-3.447 1.639 1.639 0 00-.421-1.212c1.81-.106 3.243-.599 3.243-2.045z"
            data-name="Path 18114"
            transform="translate(-201.312 -138.046)"
          ></path>
          <path
            fill="#fff"
            d="M330.161 172.2c0-1.567-1.649-1.2-3.683-1.2s-3.683-.365-3.683 1.2a1.6 1.6 0 00.673 1.356h-.19c-2.245 0-4.063-.067-4.063 1.865a3.38 3.38 0 001.741 2.872 2.019 2.019 0 00-1.13 1.762c0 1.13 1.027 1.228 2.281 1.228h.211a2.055 2.055 0 00-.283 2.533h-.313c-1.649 0-2.979.914-3.015 2.055h-.207c-2.795 0-5.065 1.582-5.065 3.534s2.271 3.529 5.065 3.529 5.065-1.582 5.065-3.529a2.944 2.944 0 00-1.12-2.214c1.315-.051 2.3-.283 2.3-1.269a1.69 1.69 0 00-.514-1.156 2.055 2.055 0 00-.031-4.017 1.136 1.136 0 00.175-.658 1.9 1.9 0 00-.426-1.187 3.7 3.7 0 003.39-3.447 1.639 1.639 0 00-.421-1.212c1.81-.106 3.243-.599 3.243-2.045z"
            data-name="Path 18115"
            opacity="0.9"
            transform="translate(-201.312 -138.046)"
          ></path>
          <path
            fill="#177ba3"
            d="M328.981 202.306a2.943 2.943 0 00-1.12-2.214c1.315-.051 2.3-.283 2.3-1.269a1.69 1.69 0 00-.514-1.156 1.6 1.6 0 00.3-.1c-1.788.082-3.206 1.058-3.206 2.26a1.541 1.541 0 00.149.622c-1.325.318-2.271 1.146-2.271 2.132 0 1.12 1.228 2.055 2.846 2.229a3.108 3.108 0 001.516-2.504z"
            data-name="Path 18116"
            transform="translate(-206.753 -150.987)"
          ></path>
          <path
            fill="#177ba3"
            d="M334.818 190.816a2.163 2.163 0 00-1.418 1.829 1.947 1.947 0 00.966 1.541 2.055 2.055 0 00.452-3.4z"
            data-name="Path 18117"
            transform="translate(-211.023 -147.69)"
          ></path>
          <g
            fill="#fafafa"
            data-name="Group 22586"
            opacity="0.7"
            transform="translate(117.867 43.1)"
          >
            <path
              d="M328.981 202.306a2.943 2.943 0 00-1.12-2.214c1.315-.051 2.3-.283 2.3-1.269a1.69 1.69 0 00-.514-1.156 1.6 1.6 0 00.3-.1c-1.788.082-3.206 1.058-3.206 2.26a1.541 1.541 0 00.149.622c-1.325.318-2.271 1.146-2.271 2.132 0 1.12 1.228 2.055 2.846 2.229a3.108 3.108 0 001.516-2.504z"
              data-name="Path 18118"
              transform="translate(-324.62 -194.087)"
            ></path>
            <path
              d="M334.818 190.816a2.163 2.163 0 00-1.418 1.829 1.947 1.947 0 00.966 1.541 2.055 2.055 0 00.452-3.4z"
              data-name="Path 18119"
              transform="translate(-328.89 -190.79)"
            ></path>
          </g>
          <path
            fill="#177ba3"
            d="M323.959 207.74v4.536c0 3.19-2.61 4.161-5.8 4.161s-5.8-.971-5.8-4.161v-4.536a1.33 1.33 0 011.33-1.33h8.938a1.33 1.33 0 011.332 1.33z"
            data-name="Path 18120"
            transform="translate(-200.791 -155.286)"
          ></path>
          <path
            fill="#fff"
            d="M323.959 207.74v4.536c0 3.19-2.61 4.161-5.8 4.161s-5.8-.971-5.8-4.161v-4.536a1.33 1.33 0 011.33-1.33h8.938a1.33 1.33 0 011.332 1.33z"
            data-name="Path 18121"
            opacity="0.4"
            transform="translate(-200.791 -155.286)"
          ></path>
          <path
            fill="#177ba3"
            d="M336.508 208.948a3.668 3.668 0 00-1.68.1v1.4a1.937 1.937 0 011.253-.19c.981.175 1.212.945 1.058 1.819s-.647 1.51-1.628 1.336a1.993 1.993 0 01-.683-.262v.318a4.784 4.784 0 01-.118 1.089 3.6 3.6 0 00.745.226c1.819.329 2.717-.858 3.01-2.471s-.144-3.031-1.957-3.365z"
            data-name="Path 18122"
            transform="translate(-211.66 -156.494)"
          ></path>
          <path
            fill="#fff"
            d="M336.508 208.948a3.668 3.668 0 00-1.68.1v1.4a1.937 1.937 0 011.253-.19c.981.175 1.212.945 1.058 1.819s-.647 1.51-1.628 1.336a1.993 1.993 0 01-.683-.262v.318a4.784 4.784 0 01-.118 1.089 3.6 3.6 0 00.745.226c1.819.329 2.717-.858 3.01-2.471s-.144-3.031-1.957-3.365z"
            data-name="Path 18123"
            opacity="0.2"
            transform="translate(-211.66 -156.494)"
          ></path>
          <path
            fill="#177ba3"
            d="M353.687 220.77c0-.38-5.034-.57-10.063-.57s-10.063.19-10.063.57v.514c0 .755 4.505 1.865 10.063 1.865s10.063-1.11 10.063-1.865z"
            data-name="Path 18124"
            transform="translate(-211.101 -161.992)"
          ></path>
          <path
            fill="#fff"
            d="M353.687 220.77c0-.38-5.034-.57-10.063-.57s-10.063.19-10.063.57v.514c0 .755 4.505 1.865 10.063 1.865s10.063-1.11 10.063-1.865z"
            data-name="Path 18125"
            opacity="0.2"
            transform="translate(-211.101 -161.992)"
          ></path>
          <path
            fill="#177ba3"
            d="M353.687 220.77c0 .755-4.505 1.366-10.063 1.366s-10.063-.611-10.063-1.366c0-.38 5.029-.57 10.063-.57s10.063.19 10.063.57z"
            data-name="Path 18126"
            transform="translate(-211.101 -161.992)"
          ></path>
          <path
            fill="#fff"
            d="M353.687 220.77c0 .755-4.505 1.366-10.063 1.366s-10.063-.611-10.063-1.366c0-.38 5.029-.57 10.063-.57s10.063.19 10.063.57z"
            data-name="Path 18127"
            opacity="0.8"
            transform="translate(-211.101 -161.992)"
          ></path>
          <path
            fill="#fff"
            d="M353.3 222.256c0 .324-1.587.591-3.539.591s-3.539-.267-3.539-.591 1.582-.6 3.539-.6 3.539.271 3.539.6z"
            data-name="Path 18128"
            opacity="0.7"
            transform="translate(-217.257 -162.702)"
          ></path>
          <path
            fill="#177ba3"
            d="M355.415 203.4a.986.986 0 00-1.474.3l-.205.324a1.027 1.027 0 00-.8.39c-.216.262-.4.467-.57.652a.786.786 0 00-.95.031 15.051 15.051 0 01-1.416 1.078 1.094 1.094 0 00-1.171-.2 10.829 10.829 0 01-2.45.853 1.069 1.069 0 00-.781.853c-.4.031-.837.046-1.356.051a.781.781 0 00-.75.591c-.252-.031-.514-.077-.853-.134a1.058 1.058 0 00-.868.221l-.365-.118a.981.981 0 00-1.32.719 1.408 1.408 0 00.966 1.541l.288.1a1.541 1.541 0 001.187 1.084c.462.082.822.139 1.171.18a1.14 1.14 0 001.125.945c.591 0 1.094 0 1.541-.046a1.464 1.464 0 001.4.473 14.27 14.27 0 004.043-1.408 1.438 1.438 0 00.791-1.2 18.58 18.58 0 001.67-1.279 1.14 1.14 0 00.252-1.449c.241-.257.478-.514.776-.894a1.541 1.541 0 00.211-1.592l.159-.262a1.408 1.408 0 00-.251-1.804z"
            data-name="Path 18129"
            transform="translate(-214.267 -153.721)"
          ></path>
          <path
            fill="#fff"
            d="M355.415 203.4a.986.986 0 00-1.474.3l-.205.324a1.027 1.027 0 00-.8.39c-.216.262-.4.467-.57.652a.786.786 0 00-.95.031 15.051 15.051 0 01-1.416 1.078 1.094 1.094 0 00-1.171-.2 10.829 10.829 0 01-2.45.853 1.069 1.069 0 00-.781.853c-.4.031-.837.046-1.356.051a.781.781 0 00-.75.591c-.252-.031-.514-.077-.853-.134a1.058 1.058 0 00-.868.221l-.365-.118a.981.981 0 00-1.32.719 1.408 1.408 0 00.966 1.541l.288.1a1.541 1.541 0 001.187 1.084c.462.082.822.139 1.171.18a1.14 1.14 0 001.125.945c.591 0 1.094 0 1.541-.046a1.464 1.464 0 001.4.473 14.27 14.27 0 004.043-1.408 1.438 1.438 0 00.791-1.2 18.58 18.58 0 001.67-1.279 1.14 1.14 0 00.252-1.449c.241-.257.478-.514.776-.894a1.541 1.541 0 00.211-1.592l.159-.262a1.408 1.408 0 00-.251-1.804z"
            data-name="Path 18130"
            opacity="0.4"
            transform="translate(-214.267 -153.721)"
          ></path>
          <path
            d="M350.987 213.24s-.591 3.185.293 3.457-.293-3.457-.293-3.457z"
            data-name="Path 18131"
            opacity="0.1"
            transform="translate(-219.481 -158.608)"
          ></path>
          <path
            d="M346.815 214.26s-.514 2.2.123 2.363-.123-2.363-.123-2.363z"
            data-name="Path 18132"
            opacity="0.1"
            transform="translate(-217.451 -159.104)"
          ></path>
          <path
            d="M342.943 213.9s-.349 1.454 0 1.644 0-1.644 0-1.644z"
            data-name="Path 18133"
            opacity="0.1"
            transform="translate(-215.588 -158.929)"
          ></path>
          <path
            d="M360.39 210.33s2.5 2.055 2 2.83-2-2.83-2-2.83z"
            data-name="Path 18134"
            opacity="0.1"
            transform="translate(-224.148 -157.193)"
          ></path>
          <path
            d="M364.72 207.42s1.829 1.336 1.428 1.88-1.428-1.88-1.428-1.88z"
            data-name="Path 18135"
            opacity="0.1"
            transform="translate(-226.254 -155.777)"
          ></path>
          <path
            d="M367.43 204.65s1.207.884 1.063 1.259-1.063-1.259-1.063-1.259z"
            data-name="Path 18136"
            opacity="0.1"
            transform="translate(-227.572 -154.43)"
          ></path>
          <path
            fill="#177ba3"
            d="M354.785 209.949a.986.986 0 00-1.49-.221l-.3.236a1.027 1.027 0 00-.889.092c-.293.164-.514.3-.76.416a.776.776 0 00-.9-.3 13.871 13.871 0 01-1.69.514 1.094 1.094 0 00-1.027-.591 10.48 10.48 0 01-2.6-.041 1.094 1.094 0 00-1.027.514c-.39-.108-.8-.241-1.289-.416a.776.776 0 00-.9.3c-.231-.118-.467-.252-.76-.416a1.027 1.027 0 00-.889-.092l-.3-.236a.986.986 0 00-1.49.221 1.423 1.423 0 00.375 1.793l.241.185a1.541 1.541 0 00.74 1.428c.406.236.724.416 1.027.575a1.146 1.146 0 00.735 1.269c.555.2 1.027.36 1.474.514a1.464 1.464 0 001.151.925 14.26 14.26 0 004.284.067 1.433 1.433 0 001.151-.848 19.612 19.612 0 002.009-.632 1.146 1.146 0 00.735-1.269c.313-.159.632-.339 1.027-.575a1.541 1.541 0 00.74-1.428l.241-.185a1.423 1.423 0 00.381-1.799z"
            data-name="Path 18137"
            transform="translate(-213.401 -156.77)"
          ></path>
          <path
            fill="#fff"
            d="M354.785 209.949a.986.986 0 00-1.49-.221l-.3.236a1.027 1.027 0 00-.889.092c-.293.164-.514.3-.76.416a.776.776 0 00-.9-.3 13.871 13.871 0 01-1.69.514 1.094 1.094 0 00-1.027-.591 10.48 10.48 0 01-2.6-.041 1.094 1.094 0 00-1.027.514c-.39-.108-.8-.241-1.289-.416a.776.776 0 00-.9.3c-.231-.118-.467-.252-.76-.416a1.027 1.027 0 00-.889-.092l-.3-.236a.986.986 0 00-1.49.221 1.423 1.423 0 00.375 1.793l.241.185a1.541 1.541 0 00.74 1.428c.406.236.724.416 1.027.575a1.146 1.146 0 00.735 1.269c.555.2 1.027.36 1.474.514a1.464 1.464 0 001.151.925 14.26 14.26 0 004.284.067 1.433 1.433 0 001.151-.848 19.612 19.612 0 002.009-.632 1.146 1.146 0 00.735-1.269c.313-.159.632-.339 1.027-.575a1.541 1.541 0 00.74-1.428l.241-.185a1.423 1.423 0 00.381-1.799z"
            data-name="Path 18138"
            opacity="0.6"
            transform="translate(-213.401 -156.77)"
          ></path>
          <path
            d="M348.413 213s-1.644 2.789-.9 3.344.9-3.344.9-3.344z"
            data-name="Path 18139"
            opacity="0.1"
            transform="translate(-217.791 -158.491)"
          ></path>
          <path
            d="M344.345 212.53s-1.259 1.885-.694 2.26.694-2.26.694-2.26z"
            data-name="Path 18140"
            opacity="0.1"
            transform="translate(-215.937 -158.262)"
          ></path>
          <path
            d="M341.065 210.86s-.832 1.248-.565 1.541.565-1.541.565-1.541z"
            data-name="Path 18141"
            opacity="0.1"
            transform="translate(-214.45 -157.45)"
          ></path>
          <path
            d="M359.12 213.46s1.644 2.789.909 3.344-.909-3.344-.909-3.344z"
            data-name="Path 18142"
            opacity="0.1"
            transform="translate(-223.531 -158.715)"
          ></path>
          <path
            d="M364.19 212.21s1.259 1.88.693 2.255-.693-2.255-.693-2.255z"
            data-name="Path 18143"
            opacity="0.1"
            transform="translate(-225.996 -158.107)"
          ></path>
          <path
            d="M367.68 210.54s.832 1.243.57 1.541-.57-1.541-.57-1.541z"
            data-name="Path 18144"
            opacity="0.1"
            transform="translate(-227.693 -157.295)"
          ></path>
          <path
            fill="#177ba3"
            d="M354.622 184.608l-8.692-.914a1.156 1.156 0 00-1.269 1.027l-.668 6.267a1.151 1.151 0 001.027 1.269l2.055.216 2.055 2.569 2.568-2.055 2.055.216a1.156 1.156 0 001.269-1.027l.658-6.267a1.151 1.151 0 00-1.058-1.3z"
            data-name="Path 18145"
            transform="translate(-216.171 -144.237)"
          ></path>
          <path
            fill="#fff"
            d="M354.622 184.608l-8.692-.914a1.156 1.156 0 00-1.269 1.027l-.668 6.267a1.151 1.151 0 001.027 1.269l2.055.216 2.055 2.569 2.568-2.055 2.055.216a1.156 1.156 0 001.269-1.027l.658-6.267a1.151 1.151 0 00-1.058-1.3z"
            data-name="Path 18146"
            opacity="0.8"
            transform="translate(-216.171 -144.237)"
          ></path>
          <path
            fill="#177ba3"
            d="M355.341 189.508a1.469 1.469 0 00-2.055-.216l-1.14.925-.919-1.14a1.465 1.465 0 00-2.276 1.844l1.844 2.276a1.459 1.459 0 002.055.216l2.281-1.844a1.464 1.464 0 00.21-2.061z"
            data-name="Path 18147"
            transform="translate(-218.426 -146.593)"
          ></path>
          <path
            fill="#177ba3"
            d="M307.585 194.48l-5.435 5 4.562 5z"
            data-name="Path 18148"
            transform="translate(-195.826 -149.485)"
          ></path>
          <path
            fill="#fff"
            d="M307.585 194.48l-5.435 5 4.562 5z"
            data-name="Path 18149"
            opacity="0.7"
            transform="translate(-195.826 -149.485)"
          ></path>
          <path
            fill="#177ba3"
            d="M137.316 212.65H96.59a1.392 1.392 0 01-1.408-1.541l2.373-27.1a1.711 1.711 0 011.675-1.541h40.731a1.392 1.392 0 011.408 1.541l-2.369 27.1a1.716 1.716 0 01-1.68 1.541z"
            data-name="Path 18150"
            transform="translate(-95.174 -143.644)"
          ></path>
          <path
            fill="#fff"
            d="M137.316 212.65H96.59a1.392 1.392 0 01-1.408-1.541l2.373-27.1a1.711 1.711 0 011.675-1.541h40.731a1.392 1.392 0 011.408 1.541l-2.369 27.1a1.716 1.716 0 01-1.68 1.541z"
            data-name="Path 18151"
            opacity="0.7"
            transform="translate(-95.174 -143.644)"
          ></path>
          <path
            fill="#177ba3"
            d="M102.376 188.667l-1.962 22.4a1.274 1.274 0 001.284 1.408h36.036a1.567 1.567 0 001.541-1.408l1.947-22.4a1.264 1.264 0 00-1.279-1.408H103.9a1.541 1.541 0 00-1.526 1.408z"
            data-name="Path 18152"
            transform="translate(-97.719 -145.974)"
          ></path>
          <path
            fill="#fff"
            d="M102.376 188.667l-1.962 22.4a1.274 1.274 0 001.284 1.408h36.036a1.567 1.567 0 001.541-1.408l1.947-22.4a1.264 1.264 0 00-1.279-1.408H103.9a1.541 1.541 0 00-1.526 1.408z"
            data-name="Path 18153"
            opacity="0.3"
            transform="translate(-97.719 -145.974)"
          ></path>
          <path
            fill="#177ba3"
            d="M169.695 222.143h-.889a.411.411 0 01-.416-.37l-.93-8.363h1.819l.909 8.188a.514.514 0 01-.493.545z"
            data-name="Path 18154"
            transform="translate(-130.327 -158.69)"
          ></path>
          <path
            d="M169.695 222.143h-.889a.411.411 0 01-.416-.37l-.93-8.363h1.819l.909 8.188a.514.514 0 01-.493.545z"
            data-name="Path 18155"
            opacity="0.3"
            transform="translate(-130.327 -158.69)"
          ></path>
          <path
            fill="#177ba3"
            d="M133.453 212.843h-27.226a1.171 1.171 0 01-1.146-1.6l4.4-15.349a2.3 2.3 0 012.055-1.6h27.224a1.166 1.166 0 011.14 1.6l-4.392 15.349a2.306 2.306 0 01-2.055 1.6z"
            data-name="Path 18156"
            transform="translate(-99.952 -149.391)"
          ></path>
          <path
            d="M133.453 212.843h-27.226a1.171 1.171 0 01-1.146-1.6l4.4-15.349a2.3 2.3 0 012.055-1.6h27.224a1.166 1.166 0 011.14 1.6l-4.392 15.349a2.306 2.306 0 01-2.055 1.6z"
            data-name="Path 18157"
            opacity="0.2"
            transform="translate(-99.952 -149.391)"
          ></path>
          <path
            fill="#177ba3"
            d="M167.129 194.289h-1.413a1.166 1.166 0 011.14 1.6l-4.392 15.349a2.307 2.307 0 01-2.055 1.6h1.413a2.3 2.3 0 002.055-1.6l4.4-15.349a1.171 1.171 0 00-1.146-1.6z"
            data-name="Path 18158"
            transform="translate(-126.898 -149.391)"
          ></path>
          <path
            d="M167.129 194.289h-1.413a1.166 1.166 0 011.14 1.6l-4.392 15.349a2.307 2.307 0 01-2.055 1.6h1.413a2.3 2.3 0 002.055-1.6l4.4-15.349a1.171 1.171 0 00-1.146-1.6z"
            data-name="Path 18159"
            opacity="0.5"
            transform="translate(-126.898 -149.391)"
          ></path>
          <path
            fill="#177ba3"
            d="M135.547 212.746H110.92l4.3-15.026h24.627z"
            data-name="Path 18160"
            transform="translate(-102.831 -151.06)"
          ></path>
          <g
            data-name="Group 22587"
            opacity="0.2"
            transform="translate(8.089 46.66)"
          >
            <path
              fill="#fff"
              d="M135.547 212.746H110.92l4.3-15.026h24.627z"
              data-name="Path 18161"
              transform="translate(-110.92 -197.72)"
            ></path>
          </g>
          <path
            fill="#177ba3"
            d="M181.95 201.025l6.221-5.718-5.219-5.717z"
            data-name="Path 18162"
            transform="translate(-137.373 -147.107)"
          ></path>
          <path
            fill="#fff"
            d="M181.95 201.025l6.221-5.718-5.219-5.717z"
            data-name="Path 18163"
            opacity="0.7"
            transform="translate(-137.373 -147.107)"
          ></path>
          <path
            fill="#177ba3"
            d="M120.83 219.128l6.128-7.813c.586-.74 1.274-.74 1.541 0l2.81 7.813z"
            data-name="Path 18164"
            transform="translate(-107.651 -157.402)"
          ></path>
          <path
            d="M120.83 219.128l6.128-7.813c.586-.74 1.274-.74 1.541 0l2.81 7.813z"
            data-name="Path 18165"
            opacity="0.2"
            transform="translate(-107.651 -157.402)"
          ></path>
          <path
            fill="#177ba3"
            d="M126.958 211.368l-6.128 7.813h5.425c-.514-.432-1.772-.663-1.541-1.659.272-1.295 2.774-.842 3.031-2.055s-1.541-.842-1.243-2.332a6.632 6.632 0 011.14-2.265 1.464 1.464 0 00-.683.5z"
            data-name="Path 18166"
            transform="translate(-107.651 -157.455)"
          ></path>
          <path
            fill="#fff"
            d="M126.958 211.368l-6.128 7.813h5.425c-.514-.432-1.772-.663-1.541-1.659.272-1.295 2.774-.842 3.031-2.055s-1.541-.842-1.243-2.332a6.632 6.632 0 011.14-2.265 1.464 1.464 0 00-.683.5z"
            data-name="Path 18167"
            opacity="0.7"
            transform="translate(-107.651 -157.455)"
          ></path>
          <path
            fill="#177ba3"
            d="M138.57 223.088l5.3-3.9a1.2 1.2 0 011.541 0l3.642 3.9z"
            data-name="Path 18168"
            transform="translate(-116.278 -161.362)"
          ></path>
          <path
            d="M138.57 223.088l5.3-3.9a1.2 1.2 0 011.541 0l3.642 3.9z"
            data-name="Path 18169"
            opacity="0.2"
            transform="translate(-116.278 -161.362)"
          ></path>
          <path
            fill="#177ba3"
            d="M143.871 219.217l-5.3 3.9H144c-.565-.216-1.849-.334-1.741-.827.139-.647 2.682-.421 2.81-1.027s-1.649-.421-1.49-1.166a2.466 2.466 0 01.894-1.13 1.408 1.408 0 00-.602.25z"
            data-name="Path 18170"
            transform="translate(-116.278 -161.394)"
          ></path>
          <path
            fill="#fff"
            d="M143.871 219.217l-5.3 3.9H144c-.565-.216-1.849-.334-1.741-.827.139-.647 2.682-.421 2.81-1.027s-1.649-.421-1.49-1.166a2.466 2.466 0 01.894-1.13 1.408 1.408 0 00-.602.25z"
            data-name="Path 18171"
            opacity="0.7"
            transform="translate(-116.278 -161.394)"
          ></path>
          <path
            fill="#177ba3"
            d="M110.93 221.385l5.651-5.6a.959.959 0 011.541 0l3.277 5.6z"
            data-name="Path 18172"
            transform="translate(-102.836 -159.658)"
          ></path>
          <path
            d="M110.93 221.385l5.651-5.6a.959.959 0 011.541 0l3.277 5.6z"
            data-name="Path 18173"
            opacity="0.2"
            transform="translate(-102.836 -159.658)"
          ></path>
          <path
            fill="#177ba3"
            d="M116.651 215.8L111 221.4h5.425c-.514-.308-1.813-.478-1.664-1.192.2-.925 2.723-.6 2.908-1.485s-1.613-.6-1.382-1.67a4 4 0 011.027-1.623 1.336 1.336 0 00-.663.37z"
            data-name="Path 18174"
            transform="translate(-102.87 -159.673)"
          ></path>
          <path
            fill="#fff"
            d="M116.651 215.8L111 221.4h5.425c-.514-.308-1.813-.478-1.664-1.192.2-.925 2.723-.6 2.908-1.485s-1.613-.6-1.382-1.67a4 4 0 011.027-1.623 1.336 1.336 0 00-.663.37z"
            data-name="Path 18175"
            opacity="0.7"
            transform="translate(-102.87 -159.673)"
          ></path>
          <path
            fill="#177ba3"
            d="M110.655 203.009l-7.192-1.433a.745.745 0 00-.878.586l-.108.539a.75.75 0 00.586.878l7.192 1.433 4.9 1.4 2.378-.6a.426.426 0 00.149-.76l-1.962-1.469z"
            data-name="Path 18176"
            transform="translate(-98.719 -152.928)"
          ></path>
          <path
            d="M110.655 203.009l-7.192-1.433a.745.745 0 00-.878.586l-.108.539a.75.75 0 00.586.878l7.192 1.433 4.9 1.4 2.378-.6a.426.426 0 00.149-.76l-1.962-1.469z"
            data-name="Path 18177"
            opacity="0.6"
            transform="translate(-98.719 -152.928)"
          ></path>
          <path
            fill="#177ba3"
            d="M122.511 207.631l.534-2.676-3.134-.354-.432 2.163z"
            data-name="Path 18178"
            transform="translate(-106.994 -154.406)"
          ></path>
          <path
            fill="#177ba3"
            d="M153.3 204.11a3 3 0 01-2.789 2.3 1.8 1.8 0 01-1.808-2.3 3 3 0 012.784-2.3 1.808 1.808 0 011.813 2.3z"
            data-name="Path 18179"
            transform="translate(-121.172 -153.048)"
          ></path>
          <path
            fill="#fff"
            d="M153.3 204.11a3 3 0 01-2.789 2.3 1.8 1.8 0 01-1.808-2.3 3 3 0 012.784-2.3 1.808 1.808 0 011.813 2.3z"
            data-name="Path 18180"
            opacity="0.8"
            transform="translate(-121.172 -153.048)"
          ></path>
          <path
            fill="#177ba3"
            d="M315.554 135.56h-33.082a1.027 1.027 0 01-1.027-1.135l1.932-22.089a1.264 1.264 0 011.233-1.135h33.077a1.027 1.027 0 011.027 1.135l-1.932 22.089a1.269 1.269 0 01-1.228 1.135z"
            data-name="Path 18181"
            transform="translate(-185.754 -108.986)"
          ></path>
          <path
            fill="#fff"
            d="M315.554 135.56h-33.082a1.027 1.027 0 01-1.027-1.135l1.932-22.089a1.264 1.264 0 011.233-1.135h33.077a1.027 1.027 0 011.027 1.135l-1.932 22.089a1.269 1.269 0 01-1.228 1.135z"
            data-name="Path 18182"
            opacity="0.7"
            transform="translate(-185.754 -108.986)"
          ></path>
          <path
            fill="#177ba3"
            d="M287.252 116.25l-1.577 18.077a1.027 1.027 0 001.027 1.135h29.076a1.264 1.264 0 001.233-1.135l1.582-18.077a1.027 1.027 0 00-1.027-1.13H288.49a1.264 1.264 0 00-1.238 1.13z"
            data-name="Path 18183"
            transform="translate(-187.811 -110.892)"
          ></path>
          <path
            fill="#fff"
            d="M287.252 116.25l-1.577 18.077a1.027 1.027 0 001.027 1.135h29.076a1.264 1.264 0 001.233-1.135l1.582-18.077a1.027 1.027 0 00-1.027-1.13H288.49a1.264 1.264 0 00-1.238 1.13z"
            data-name="Path 18184"
            opacity="0.4"
            transform="translate(-187.811 -110.892)"
          ></path>
          <path
            fill="#fff"
            d="M307.032 118.644l.313-3.524h-1.218l-.344 3.966-2.836 10.146h-7.084l1.587-8.45 2.019-5.661h-1.264l-1.926 5.394-1.741 9.226-1.829 5.723h1.259l1.6-5.009h7.032l-1.4 5.009h1.243l2.676-9.575h7.855l-2.322 9.575h1.248l2.353-9.7 7.094-1.752.082-.945-7.731 1.906h-8.312l1.51-5.4 8.5-.58 6.447-.627.082-.92-6.529.632z"
            data-name="Path 18185"
            transform="translate(-191.235 -110.892)"
          ></path>
          <path
            fill="#177ba3"
            d="M324.857 125.4h-1.536l-1.531 1.536v1.86h4.6v-1.86z"
            data-name="Path 18186"
            transform="translate(-205.377 -115.891)"
          ></path>
          <path
            d="M324.857 125.4h-1.536l-1.531 1.536v1.86h4.6v-1.86z"
            data-name="Path 18187"
            opacity="0.3"
            transform="translate(-205.377 -115.891)"
          ></path>
          <path
            fill="#177ba3"
            d="M321.79 126.936l1.531-1.536 1.536 1.536v1.86h-3.067z"
            data-name="Path 18188"
            transform="translate(-205.377 -115.891)"
          ></path>
          <path
            fill="#177ba3"
            d="M323.021 106.89a3.421 3.421 0 00-3.421 3.421c0 1.885 3.421 5.651 3.421 5.651s3.421-3.76 3.421-5.651a3.421 3.421 0 00-3.421-3.421zm0 4.844a1.423 1.423 0 111.009-.415 1.428 1.428 0 01-1.009.415z"
            data-name="Path 18189"
            transform="translate(-204.312 -106.89)"
          ></path>
          <path
            fill="#177ba3"
            d="M280.062 133.34l-4.572 4.2 3.832 4.2z"
            data-name="Path 18190"
            transform="translate(-182.861 -119.753)"
          ></path>
          <path
            fill="#fff"
            d="M280.062 133.34l-4.572 4.2 3.832 4.2z"
            data-name="Path 18191"
            opacity="0.7"
            transform="translate(-182.861 -119.753)"
          ></path>
          <path
            fill="#177ba3"
            d="M175.578 143.107H146.1a1.027 1.027 0 01-1.027-1.135l2.569-29.476a1.259 1.259 0 011.233-1.135h29.476a1.027 1.027 0 011.027 1.135l-2.569 29.476a1.269 1.269 0 01-1.233 1.135z"
            data-name="Path 18192"
            transform="translate(-119.438 -109.064)"
          ></path>
          <path
            fill="#fff"
            d="M175.578 143.107H146.1a1.027 1.027 0 01-1.027-1.135l2.569-29.476a1.259 1.259 0 011.233-1.135h29.476a1.027 1.027 0 011.027 1.135l-2.569 29.476a1.269 1.269 0 01-1.233 1.135z"
            data-name="Path 18193"
            opacity="0.7"
            transform="translate(-119.438 -109.064)"
          ></path>
          <path
            fill="#177ba3"
            d="M151.245 116.1l-2.25 25.772a1.027 1.027 0 001.027 1.128h25.767a1.264 1.264 0 001.238-1.135L179.3 116.1a1.027 1.027 0 00-1.027-1.135h-25.8a1.259 1.259 0 00-1.228 1.135z"
            data-name="Path 18194"
            transform="translate(-121.344 -110.814)"
          ></path>
          <path
            fill="#fff"
            d="M151.245 116.1l-2.25 25.772a1.027 1.027 0 001.027 1.128h25.767a1.264 1.264 0 001.238-1.135L179.3 116.1a1.027 1.027 0 00-1.027-1.135h-25.8a1.259 1.259 0 00-1.228 1.135z"
            data-name="Path 18195"
            opacity="0.2"
            transform="translate(-121.344 -110.814)"
          ></path>
          <path
            fill="#177ba3"
            d="M197.221 138.263l-2.995-16.613-1.613.17-2.384 17.168z"
            data-name="Path 18196"
            transform="translate(-141.4 -114.068)"
          ></path>
          <path
            d="M197.221 138.263l-2.995-16.613-1.613.17-2.384 17.168z"
            data-name="Path 18197"
            opacity="0.3"
            transform="translate(-141.4 -114.068)"
          ></path>
          <path
            fill="#177ba3"
            d="M172.653 138.823L150.81 141.1l2.4-17.173 21.843-2.276z"
            data-name="Path 18198"
            transform="translate(-122.23 -114.068)"
          ></path>
          <path
            fill="#fff"
            d="M172.653 138.823L150.81 141.1l2.4-17.173 21.843-2.276z"
            data-name="Path 18199"
            opacity="0.9"
            transform="translate(-122.23 -114.068)"
          ></path>
          <path
            fill="#177ba3"
            d="M169.941 130.849l-12.411 1.295.252-1.819 12.418-1.295z"
            data-name="Path 18200"
            transform="translate(-125.498 -117.657)"
          ></path>
          <path
            fill="#177ba3"
            d="M157.8 146.545l-2.245.231.318-2.26 2.245-.236z"
            data-name="Path 18201"
            transform="translate(-124.54 -125.073)"
          ></path>
          <path
            fill="#177ba3"
            d="M156.935 152.79l-2.245.236.313-2.26 2.245-.236z"
            data-name="Path 18202"
            transform="translate(-124.117 -128.112)"
          ></path>
          <path
            fill="#fff"
            d="M157.8 146.545l-2.245.231.318-2.26 2.245-.236z"
            data-name="Path 18203"
            opacity="0.3"
            transform="translate(-124.54 -125.073)"
          ></path>
          <path
            fill="#fff"
            d="M156.935 152.79l-2.245.236.313-2.26 2.245-.236z"
            data-name="Path 18204"
            opacity="0.3"
            transform="translate(-124.117 -128.112)"
          ></path>
          <path
            fill="#177ba3"
            d="M158.685 140.3l-2.245.231.313-2.26 2.247-.241z"
            data-name="Path 18205"
            transform="translate(-124.968 -122.033)"
          ></path>
          <path
            fill="#177ba3"
            d="M164.765 139.66l-2.245.236.313-2.26 2.245-.236z"
            data-name="Path 18206"
            transform="translate(-127.924 -121.727)"
          ></path>
          <path
            fill="#fff"
            d="M158.685 140.3l-2.245.231.313-2.26 2.247-.241z"
            data-name="Path 18207"
            opacity="0.7"
            transform="translate(-124.968 -122.033)"
          ></path>
          <path
            fill="#fff"
            d="M164.765 139.66l-2.245.236.313-2.26 2.245-.236z"
            data-name="Path 18208"
            opacity="0.7"
            transform="translate(-127.924 -121.727)"
          ></path>
          <path
            fill="#177ba3"
            d="M163.885 145.91l-2.245.236.319-2.265 2.245-.231z"
            data-name="Path 18209"
            transform="translate(-127.496 -124.766)"
          ></path>
          <path
            fill="#177ba3"
            d="M163.015 152.16l-2.245.231.313-2.26 2.245-.231z"
            data-name="Path 18210"
            transform="translate(-127.073 -127.806)"
          ></path>
          <path
            fill="#177ba3"
            d="M170.845 139.03l-2.245.236.318-2.265 2.245-.231z"
            data-name="Path 18211"
            transform="translate(-130.881 -121.421)"
          ></path>
          <path
            fill="#177ba3"
            d="M169.975 145.28l-2.245.231.313-2.26 2.245-.231z"
            data-name="Path 18212"
            transform="translate(-130.458 -124.46)"
          ></path>
          <path
            fill="#177ba3"
            d="M169.095 151.525l-2.245.231.318-2.26 2.245-.236z"
            data-name="Path 18213"
            transform="translate(-130.03 -127.494)"
          ></path>
          <path
            fill="#177ba3"
            d="M176.925 138.4l-2.245.231.318-2.26 2.245-.236z"
            data-name="Path 18214"
            transform="translate(-133.838 -121.109)"
          ></path>
          <path
            fill="#177ba3"
            d="M176.055 144.64l-2.245.236.318-2.26 2.24-.236z"
            data-name="Path 18215"
            transform="translate(-133.415 -124.149)"
          ></path>
          <path
            fill="#177ba3"
            d="M175.185 150.89l-2.245.236.313-2.26 2.245-.236z"
            data-name="Path 18216"
            transform="translate(-132.992 -127.188)"
          ></path>
          <path
            fill="#177ba3"
            d="M183.015 137.76l-2.245.24.313-2.26 2.245-.236z"
            data-name="Path 18217"
            transform="translate(-136.799 -120.803)"
          ></path>
          <path
            fill="#177ba3"
            d="M182.135 144.01l-2.245.236.319-2.265 2.245-.231z"
            data-name="Path 18218"
            transform="translate(-136.371 -123.842)"
          ></path>
          <path
            fill="#177ba3"
            d="M181.265 150.26l-2.245.231.313-2.26 2.245-.231z"
            data-name="Path 18219"
            transform="translate(-135.948 -126.882)"
          ></path>
          <path
            fill="#fff"
            d="M163.885 145.91l-2.245.236.319-2.265 2.245-.231z"
            data-name="Path 18220"
            opacity="0.3"
            transform="translate(-127.496 -124.766)"
          ></path>
          <path
            fill="#fff"
            d="M163.015 152.16l-2.245.231.313-2.26 2.245-.231z"
            data-name="Path 18221"
            opacity="0.3"
            transform="translate(-127.073 -127.806)"
          ></path>
          <path
            fill="#fff"
            d="M170.845 139.03l-2.245.236.318-2.265 2.245-.231z"
            data-name="Path 18222"
            opacity="0.3"
            transform="translate(-130.881 -121.421)"
          ></path>
          <path
            fill="#fff"
            d="M169.975 145.28l-2.245.231.313-2.26 2.245-.231z"
            data-name="Path 18223"
            opacity="0.3"
            transform="translate(-130.458 -124.46)"
          ></path>
          <path
            fill="#fff"
            d="M169.095 151.525l-2.245.231.318-2.26 2.245-.236z"
            data-name="Path 18224"
            opacity="0.3"
            transform="translate(-130.03 -127.494)"
          ></path>
          <path
            fill="#fff"
            d="M176.925 138.4l-2.245.231.318-2.26 2.245-.236z"
            data-name="Path 18225"
            opacity="0.3"
            transform="translate(-133.838 -121.109)"
          ></path>
          <path
            fill="#fff"
            d="M176.055 144.64l-2.245.236.318-2.26 2.24-.236z"
            data-name="Path 18226"
            opacity="0.3"
            transform="translate(-133.415 -124.149)"
          ></path>
          <path
            fill="#fff"
            d="M175.185 150.89l-2.245.236.313-2.26 2.245-.236z"
            data-name="Path 18227"
            opacity="0.3"
            transform="translate(-132.992 -127.188)"
          ></path>
          <path
            fill="#fff"
            d="M183.015 137.76l-2.245.24.313-2.26 2.245-.236z"
            data-name="Path 18228"
            opacity="0.3"
            transform="translate(-136.799 -120.803)"
          ></path>
          <path
            fill="#fff"
            d="M182.135 144.01l-2.245.236.319-2.265 2.245-.231z"
            data-name="Path 18229"
            opacity="0.3"
            transform="translate(-136.371 -123.842)"
          ></path>
          <path
            fill="#fff"
            d="M181.265 150.26l-2.245.231.313-2.26 2.245-.231z"
            data-name="Path 18230"
            opacity="0.3"
            transform="translate(-135.948 -126.882)"
          ></path>
          <path
            fill="#177ba3"
            d="M189.095 137.13l-2.245.231.318-2.26 2.24-.231z"
            data-name="Path 18231"
            transform="translate(-139.756 -120.497)"
          ></path>
          <path
            fill="#177ba3"
            d="M188.215 143.38l-2.245.231.318-2.26 2.245-.231z"
            data-name="Path 18232"
            transform="translate(-139.328 -123.536)"
          ></path>
          <path
            d="M189.095 137.13l-2.245.231.318-2.26 2.24-.231z"
            data-name="Path 18233"
            opacity="0.2"
            transform="translate(-139.756 -120.497)"
          ></path>
          <path
            d="M188.215 143.38l-2.245.231.318-2.26 2.245-.231z"
            data-name="Path 18234"
            opacity="0.2"
            transform="translate(-139.328 -123.536)"
          ></path>
          <path
            fill="#177ba3"
            d="M187.345 149.625l-2.245.231.318-2.26 2.245-.236z"
            data-name="Path 18235"
            transform="translate(-138.905 -126.57)"
          ></path>
          <path
            fill="#fff"
            d="M187.345 149.625l-2.245.231.318-2.26 2.245-.236z"
            data-name="Path 18236"
            opacity="0.7"
            transform="translate(-138.905 -126.57)"
          ></path>
          <path
            fill="#263238"
            d="M159.077 125.677a.39.39 0 01-.437-.385v-.822a.478.478 0 01.426-.473.39.39 0 01.437.385v.822a.478.478 0 01-.426.473z"
            data-name="Path 18237"
            transform="translate(-126.038 -115.208)"
          ></path>
          <path
            fill="#263238"
            d="M163.792 125.187a.4.4 0 01-.442-.385v-.822a.478.478 0 01.421-.473.4.4 0 01.442.385v.822a.483.483 0 01-.421.473z"
            data-name="Path 18238"
            transform="translate(-128.328 -114.97)"
          ></path>
          <path
            fill="#263238"
            d="M168.507 124.7a.39.39 0 01-.437-.385v-.822a.478.478 0 01.426-.473.39.39 0 01.437.385v.822a.514.514 0 01-.426.473z"
            data-name="Path 18239"
            transform="translate(-130.623 -114.731)"
          ></path>
          <path
            fill="#263238"
            d="M173.222 124.2a.385.385 0 01-.442-.38V123a.514.514 0 01.426-.473.385.385 0 01.437.38v.822a.473.473 0 01-.421.471z"
            data-name="Path 18240"
            transform="translate(-132.914 -114.492)"
          ></path>
          <path
            fill="#263238"
            d="M177.937 123.711a.385.385 0 01-.437-.38v-.822a.478.478 0 01.421-.473.39.39 0 01.442.38v.822a.483.483 0 01-.426.473z"
            data-name="Path 18241"
            transform="translate(-135.209 -114.254)"
          ></path>
          <path
            fill="#263238"
            d="M182.652 123.221a.385.385 0 01-.442-.38v-.822a.483.483 0 01.426-.473.385.385 0 01.437.38v.822a.478.478 0 01-.421.473z"
            data-name="Path 18242"
            transform="translate(-137.5 -114.016)"
          ></path>
          <path
            fill="#263238"
            d="M187.362 122.731a.39.39 0 01-.442-.38v-.822a.473.473 0 01.421-.473.39.39 0 01.442.38v.822a.478.478 0 01-.421.473z"
            data-name="Path 18243"
            transform="translate(-139.79 -113.778)"
          ></path>
          <path
            fill="#263238"
            d="M192.077 122.241a.39.39 0 01-.437-.38v-.822a.473.473 0 01.426-.473.385.385 0 01.437.38v.822a.478.478 0 01-.426.473z"
            data-name="Path 18244"
            transform="translate(-142.085 -113.539)"
          ></path>
          <g
            fill="#fff"
            data-name="Group 22588"
            opacity="0.5"
            transform="translate(32.602 7.024)"
          >
            <path
              d="M159.077 125.677a.39.39 0 01-.437-.385v-.822a.478.478 0 01.426-.473.39.39 0 01.437.385v.822a.478.478 0 01-.426.473z"
              data-name="Path 18245"
              transform="translate(-158.64 -122.232)"
            ></path>
            <path
              d="M163.792 125.187a.4.4 0 01-.442-.385v-.822a.478.478 0 01.421-.473.4.4 0 01.442.385v.822a.483.483 0 01-.421.473z"
              data-name="Path 18246"
              transform="translate(-160.93 -121.994)"
            ></path>
            <path
              d="M168.507 124.7a.39.39 0 01-.437-.385v-.822a.478.478 0 01.426-.473.39.39 0 01.437.385v.822a.514.514 0 01-.426.473z"
              data-name="Path 18247"
              transform="translate(-163.226 -121.755)"
            ></path>
            <path
              d="M173.222 124.2a.385.385 0 01-.442-.38V123a.514.514 0 01.426-.473.385.385 0 01.437.38v.822a.473.473 0 01-.421.471z"
              data-name="Path 18248"
              transform="translate(-165.516 -121.516)"
            ></path>
            <path
              d="M177.937 123.711a.385.385 0 01-.437-.38v-.822a.478.478 0 01.421-.473.39.39 0 01.442.38v.822a.483.483 0 01-.426.473z"
              data-name="Path 18249"
              transform="translate(-167.812 -121.278)"
            ></path>
            <path
              d="M182.652 123.221a.385.385 0 01-.442-.38v-.822a.483.483 0 01.426-.473.385.385 0 01.437.38v.822a.478.478 0 01-.421.473z"
              data-name="Path 18250"
              transform="translate(-170.102 -121.04)"
            ></path>
            <path
              d="M187.362 122.731a.39.39 0 01-.442-.38v-.822a.473.473 0 01.421-.473.39.39 0 01.442.38v.822a.478.478 0 01-.421.473z"
              data-name="Path 18251"
              transform="translate(-172.392 -120.802)"
            ></path>
            <path
              d="M192.077 122.241a.39.39 0 01-.437-.38v-.822a.473.473 0 01.426-.473.385.385 0 01.437.38v.822a.478.478 0 01-.426.473z"
              data-name="Path 18252"
              transform="translate(-174.688 -120.563)"
            ></path>
          </g>
          <path
            fill="#177ba3"
            d="M174.279 142.6a3.832 3.832 0 011.413 0c.92.277.041 1.279-.539 1.479s-1.495.067-1.705-.591c-.247-.771.627-1.315 1.284-1.418.18 0 .175-.3 0-.272-.791.118-1.711.719-1.582 1.623a1.444 1.444 0 001.818.981c.678-.134 1.6-.817 1.387-1.6s-1.479-.56-2.055-.514c-.175 0-.17.293 0 .272z"
            data-name="Path 18253"
            transform="translate(-133.087 -123.865)"
          ></path>
          <g
            data-name="Group 22589"
            opacity="0.4"
            transform="translate(40.049 17.932)"
          >
            <path
              d="M174.279 142.6a3.832 3.832 0 011.413 0c.92.277.041 1.279-.539 1.479s-1.495.067-1.705-.591c-.247-.771.627-1.315 1.284-1.418.18 0 .175-.3 0-.272-.791.118-1.711.719-1.582 1.623a1.444 1.444 0 001.818.981c.678-.134 1.6-.817 1.387-1.6s-1.479-.56-2.055-.514c-.175 0-.17.293 0 .272z"
              data-name="Path 18254"
              transform="translate(-173.136 -141.797)"
            ></path>
          </g>
          <path
            fill="#177ba3"
            d="M207.06 144.487l5.44-5-4.562-5z"
            data-name="Path 18255"
            transform="translate(-149.584 -120.312)"
          ></path>
          <path
            fill="#fff"
            d="M207.06 144.487l5.44-5-4.562-5z"
            data-name="Path 18256"
            opacity="0.7"
            transform="translate(-149.584 -120.312)"
          ></path>
        </g>
        <g transform="translate(102.339 90.707)">
          <path
            fill="#263238"
            d="M238.786 124.51c-3.832.046-11.815-.971-9.95 14.563a9.015 9.015 0 01-.586 4.053l2.378.729a12.625 12.625 0 000-3.56s1.315 1.058.853 3.853l2.183.514.375-3.6 2.6-.216 1.1-.072 1.027-.514a10.655 10.655 0 00.555 3.853l2.224-1.027s-1.2-1.819-.586-3.272a10.905 10.905 0 001.408 2.5l1.454-.981a8.218 8.218 0 01-.462-4.988c.514-3.241 1.063-4.053 1.063-4.053s-.586 3.714 0 4.053l.781-.971a3.94 3.94 0 01-.128-3.714c.97-2.147-1.845-7.202-6.289-7.15z"
            data-name="Path 18257"
            transform="translate(-213.337 -124.509)"
          ></path>
          <path
            fill="#263238"
            d="M0 0H16.151V9.098H0z"
            data-name="Rectangle 17172"
            transform="translate(16.182 47.128)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M285.249 387.7l1.187 7.253-3.863.324-1.423-6.514z"
            data-name="Path 18258"
            transform="translate(-239.062 -252.498)"
          ></path>
          <path
            fill="#177ba3"
            d="M278.185 402.452a1.921 1.921 0 01-1.336-.247.411.411 0 01-.026-.462.555.555 0 01.318-.272c.729-.257 2.348.514 2.42.56a.1.1 0 01.056.108.118.118 0 01-.077.092 8.111 8.111 0 01-1.355.221zm-.771-.817a.688.688 0 00-.2.036.3.3 0 00-.2.164c-.072.139-.036.2 0 .231.226.277 1.351.205 2.173.046a4.84 4.84 0 00-1.793-.478z"
            data-name="Path 18259"
            transform="translate(-236.929 -259.169)"
          ></path>
          <path
            fill="#177ba3"
            d="M280.552 401.168a.072.072 0 01-.046 0c-.55-.2-1.69-1.089-1.628-1.6 0-.123.082-.277.37-.324a.765.765 0 01.6.139 3.052 3.052 0 01.817 1.675.113.113 0 01-.041.1.118.118 0 01-.072.005zm-1.217-1.731h-.062c-.19.026-.2.1-.2.134 0 .3.745 1.027 1.325 1.3a2.507 2.507 0 00-.688-1.325.545.545 0 00-.375-.108z"
            data-name="Path 18260"
            transform="translate(-237.956 -258.107)"
          ></path>
          <path
            fill="#263238"
            d="M276.875 401.394l4.87-.344a.4.4 0 01.406.283l1.156 3.9a.647.647 0 01-.432.812.674.674 0 01-.139.031c-1.757.092-3.324.1-5.507.262-2.569.18-1.906.442-4.937.7-1.824.154-2.3-1.9-1.541-2.055 3.514-.7 3.807-1.793 5.086-3.159a1.582 1.582 0 011.038-.43z"
            data-name="Path 18261"
            transform="translate(-233.836 -258.99)"
          ></path>
          <path
            d="M285.711 389.93l.447 2.717-3.966.7-.473-2.163z"
            data-name="Path 18262"
            opacity="0.2"
            transform="translate(-239.339 -253.583)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M235.4 386.906l-.776 7.31-3.807-.7.344-6.663z"
            data-name="Path 18263"
            transform="translate(-214.587 -252.085)"
          ></path>
          <path
            fill="#177ba3"
            d="M223.861 398.693c-.565-.123-1.125-.36-1.259-.642a.416.416 0 01.113-.467.575.575 0 01.4-.175c.807-.041 2.194 1.217 2.255 1.274a.118.118 0 01.026.123.123.123 0 01-.108.072 8.3 8.3 0 01-1.427-.185zm-.514-1.027a.6.6 0 00-.211 0 .3.3 0 00-.247.1c-.113.118-.1.19-.077.231.149.344 1.295.606 2.163.688a5.076 5.076 0 00-1.639-1.038z"
            data-name="Path 18264"
            transform="translate(-210.576 -257.219)"
          ></path>
          <path
            fill="#177ba3"
            d="M226.656 398.079h-.046c-.488-.365-1.361-1.592-1.151-2.086.036-.118.164-.252.467-.211a.817.817 0 01.56.313 3.19 3.19 0 01.313 1.921.1.1 0 01-.072.087.113.113 0 01-.071-.024zm-.7-2.1h-.062c-.2 0-.226.046-.241.072-.1.3.452 1.233.94 1.7a2.594 2.594 0 00-.293-1.541.6.6 0 00-.344-.221z"
            data-name="Path 18265"
            transform="translate(-211.964 -256.425)"
          ></path>
          <path
            fill="#263238"
            d="M221.934 399.177l4.264.95a.406.406 0 01.324.4v4.243c-.693 1.2-3.18-.442-5.137-.544a34.154 34.154 0 01-6.873-1.089c-1.87-.39-.786-2.661 0-2.569 3.744.325 7.036-1.484 7.422-1.391z"
            data-name="Path 18266"
            transform="translate(-206.152 -258.078)"
          ></path>
          <path
            d="M235.43 389.21l-.288 2.738-4.012-.37.113-2.214z"
            data-name="Path 18267"
            opacity="0.2"
            transform="translate(-214.737 -253.232)"
          ></path>
          <path
            fill="#263238"
            d="M227.731 224.252l-.971 44.754.457 39.406 6.92.76s2.055-13 2.481-23.6c.324-8.6-.432-11.908-.241-15.873.344-7.253 4.777-18.267 5.224-28.767.2-4.469.514-17.892.514-17.892z"
            data-name="Path 18268"
            transform="translate(-212.612 -172.424)"
          ></path>
          <path
            d="M246.07 250.45a134.114 134.114 0 001.89 20.831c.467-2.188 1.264-4.69 1.664-7a63.538 63.538 0 001.459-12.524l-1.726-.411z"
            data-name="Path 18269"
            opacity="0.5"
            transform="translate(-222.003 -185.754)"
          ></path>
          <path
            fill="#263238"
            d="M240.92 228l10.629 45.047 8.64 39.237 6.94-1.13s-1.161-16.752-2.24-22.259c-1.885-9.647-3.406-12.673-4.135-16.983-1.86-10.988-.724-33.709-7.192-43.911z"
            data-name="Path 18270"
            transform="translate(-219.498 -174.836)"
          ></path>
          <path
            d="M247.013 226.055h-5.728V224l-12.745 1.608v4.11a17.456 17.456 0 007.551.858l1.063-4.819 3.6 4.464a30.741 30.741 0 007.757-1.135 19.326 19.326 0 00-1.495-3.031z"
            data-name="Path 18271"
            opacity="0.3"
            transform="translate(-213.478 -172.891)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M275.837 199.43s1.762 5.235 2.969 8.64c.935 2.62 2.687 7.413 2.687 7.413s3.308 2.173 3.637 3.6.56 3.247-1.13 3.247-1.844-.38-2.692-2.219-2.127-2.219-2.081-3.6l-7.6-15.3z"
            data-name="Path 18272"
            transform="translate(-234.432 -160.943)"
          ></path>
          <path
            fill="#177ba3"
            d="M268.764 192.918l4.783-2.373-.547-1.433-3.26-12.252-5.24 3.683 3.185 9.843z"
            data-name="Path 18273"
            transform="translate(-230.965 -149.967)"
          ></path>
          <path
            fill="#fff"
            d="M268.764 192.918l4.783-2.373-.547-1.433-3.26-12.252-5.24 3.683 3.185 9.843z"
            data-name="Path 18274"
            opacity="0.5"
            transform="translate(-230.965 -149.967)"
          ></path>
          <path
            fill="#177ba3"
            d="M271.16 203.918l.755 1.336 5.337-2.517-.467-1.747z"
            data-name="Path 18275"
            transform="translate(-234.204 -161.702)"
          ></path>
          <path
            fill="#fff"
            d="M271.16 203.918l.755 1.336 5.337-2.517-.467-1.747z"
            data-name="Path 18276"
            opacity="0.7"
            transform="translate(-234.204 -161.702)"
          ></path>
          <path
            fill="#177ba3"
            d="M260.156 164.268s3.3-.473 4.813 1.654a36.987 36.987 0 012.928 5.291 31.541 31.541 0 01-6.986 5s-6.138-10.813-.755-11.945z"
            data-name="Path 18277"
            transform="translate(-227.777 -143.822)"
          ></path>
          <path
            d="M262.563 179.42a22.265 22.265 0 002.569-1.479l-1.156-7.13-2.635 6.1c.631 1.466 1.222 2.509 1.222 2.509z"
            data-name="Path 18278"
            opacity="0.1"
            transform="translate(-229.428 -147.025)"
          ></path>
          <path
            fill="#263238"
            d="M286.887 238.47l-1.063.514a.154.154 0 01-.206-.2l.514-1.069.031-.041 6.714-6.714a.437.437 0 01.616 0l.154.154a.442.442 0 010 .622l-6.714 6.709a.082.082 0 01-.046.025z"
            data-name="Path 18279"
            transform="translate(-241.229 -176.214)"
          ></path>
          <path
            fill="#fff"
            d="M286.887 238.47l-1.063.514a.154.154 0 01-.206-.2l.514-1.069.031-.041 6.714-6.714a.437.437 0 01.616 0l.154.154a.442.442 0 010 .622l-6.714 6.709a.082.082 0 01-.046.025z"
            data-name="Path 18280"
            opacity="0.7"
            transform="translate(-241.229 -176.214)"
          ></path>
          <path
            fill="#263238"
            d="M0 0H0.385V1.094H0z"
            data-name="Rectangle 17173"
            transform="rotate(-45 92.36 -33.868)"
          ></path>
          <path
            fill="#263238"
            d="M285.86 245.31l-.241.514a.154.154 0 00.205.2l.514-.241z"
            data-name="Path 18281"
            transform="translate(-241.229 -183.254)"
          ></path>
          <path
            fill="#fff"
            d="M0 0H0.385V1.094H0z"
            data-name="Rectangle 17174"
            opacity="0.8"
            transform="rotate(-45 92.36 -33.868)"
          ></path>
          <path
            fill="#fff"
            d="M285.86 245.31l-.241.514a.154.154 0 00.205.2l.514-.241z"
            data-name="Path 18282"
            opacity="0.8"
            transform="translate(-241.229 -183.254)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M289.67 236.552s.647 1.541 1.408 1.654-.55-3.036-.55-3.036z"
            data-name="Path 18283"
            transform="translate(-243.205 -178.323)"
          ></path>
          <path
            fill="#177ba3"
            d="M229.909 164.005a98.516 98.516 0 0113.829-.642l-2.908 5.964-5.1 1.7-3.473-.848-3.082-3.329z"
            data-name="Path 18284"
            transform="translate(-213.789 -143.39)"
          ></path>
          <path
            fill="#fff"
            d="M229.909 164.005a98.516 98.516 0 0113.829-.642l-2.908 5.964-5.1 1.7-3.473-.848-3.082-3.329z"
            data-name="Path 18285"
            opacity="0.5"
            transform="translate(-213.789 -143.39)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M240.677 158.459l2.887-2.63a3.385 3.385 0 01-1.146-3.18 11.233 11.233 0 01.216-1.31l-3.221-2.687-2.358-1.962c.637 2.861 1.356 7.793-.6 9.7a73.885 73.885 0 004.222 2.069z"
            data-name="Path 18286"
            transform="translate(-217.329 -135.296)"
          ></path>
          <path
            fill="#177ba3"
            d="M226.822 214.684l-.822 8.05a20.927 20.927 0 008.456 1.541l1.315-4.788v-4.372l.57 4.372 3.211 4.459s5.481 0 8.928-1.839a78.262 78.262 0 00-3.986-7.634s-12.761-.852-17.672.211z"
            data-name="Path 18287"
            transform="translate(-212.243 -168.101)"
          ></path>
          <path
            fill="#fff"
            d="M226.822 214.684l-.822 8.05a20.927 20.927 0 008.456 1.541l1.315-4.788v-4.372l.57 4.372 3.211 4.459s5.481 0 8.928-1.839a78.262 78.262 0 00-3.986-7.634s-12.761-.852-17.672.211z"
            data-name="Path 18288"
            opacity="0.5"
            transform="translate(-212.243 -168.101)"
          ></path>
          <path
            fill="#177ba3"
            d="M244.2 163.677a56.328 56.328 0 00-4.69-.437c-.581 1.942-3.421 4.921-6.308 4.921a5.66 5.66 0 01-5.219-4.377c-.791.123-1.6.257-2.409.421a2.265 2.265 0 00-1.762 1.664c-.735 2.944-1.86 10.788 1.366 25.356a72.625 72.625 0 009.154.236c2.687-.072 5.882.17 9.447-.616l1.87-25.367a1.685 1.685 0 00-1.449-1.801z"
            data-name="Path 18289"
            transform="translate(-210.787 -143.344)"
          ></path>
          <path
            fill="#177ba3"
            d="M233.66 163.846l1.8-.406 3.257 2.882-1.51 1.613z"
            data-name="Path 18290"
            transform="translate(-215.968 -143.441)"
          ></path>
          <path
            fill="#fff"
            d="M233.66 163.846l1.8-.406 3.257 2.882-1.51 1.613z"
            data-name="Path 18291"
            opacity="0.7"
            transform="translate(-215.968 -143.441)"
          ></path>
          <path
            fill="#177ba3"
            d="M248.5 162.964l-1.8-.164-3.822 3.211 1.51 1.613z"
            data-name="Path 18292"
            transform="translate(-220.451 -143.13)"
          ></path>
          <path
            fill="#fff"
            d="M248.5 162.964l-1.8-.164-3.822 3.211 1.51 1.613z"
            data-name="Path 18293"
            opacity="0.7"
            transform="translate(-220.451 -143.13)"
          ></path>
          <path
            d="M245.256 153.2a11.233 11.233 0 00-.216 1.31c-1.212-.241-3.021-1.32-3.19-2.682a5.676 5.676 0 01.185-1.315z"
            data-name="Path 18294"
            opacity="0.2"
            transform="translate(-219.95 -137.153)"
          ></path>
          <path
            fill="#ce7a63"
            d="M202.993 202.23l-.858 1.495a.647.647 0 00.026.7 3.216 3.216 0 001.12.925 3.559 3.559 0 001.392.447.658.658 0 00.56-.257l1.474-1.9z"
            data-name="Path 18295"
            transform="translate(-200.593 -162.305)"
          ></path>
          <path
            fill="#177ba3"
            d="M209.89 178.93l-6.56 11.286 4.438 2.4 7.885-10.2z"
            data-name="Path 18296"
            transform="translate(-201.218 -150.974)"
          ></path>
          <path
            fill="#fff"
            d="M209.89 178.93l-6.56 11.286 4.438 2.4 7.885-10.2z"
            data-name="Path 18297"
            opacity="0.5"
            transform="translate(-201.218 -150.974)"
          ></path>
          <path
            d="M214.344 187.718l.514-1.608a66.662 66.662 0 00-11.348 7.649l3.36 1.824.632-.267 2.851-2.425z"
            data-name="Path 18298"
            opacity="0.1"
            transform="translate(-201.306 -154.465)"
          ></path>
          <path
            fill="#177ba3"
            d="M221.564 164.9s-4.11-.293-8.024 7.952a26.539 26.539 0 007.331 4.372s5.129-11.883.693-12.324z"
            data-name="Path 18299"
            transform="translate(-206.183 -144.15)"
          ></path>
          <path
            d="M222.6 181.1l-.457-6.735-1.444 5.7c.601.367 1.239.716 1.901 1.035z"
            data-name="Path 18300"
            opacity="0.1"
            transform="translate(-209.665 -148.756)"
          ></path>
          <path
            fill="#177ba3"
            d="M202.045 199.43l5.625 2.784-.9 1.038-5.3-2.219z"
            data-name="Path 18301"
            transform="translate(-200.314 -160.943)"
          ></path>
          <path
            fill="#fff"
            d="M202.045 199.43l5.625 2.784-.9 1.038-5.3-2.219z"
            data-name="Path 18302"
            opacity="0.7"
            transform="translate(-200.314 -160.943)"
          ></path>
          <rect
            width="11.635"
            height="14.882"
            fill="#263238"
            data-name="Rectangle 17175"
            rx="1.99"
            transform="rotate(-5.92 227.706 -119.911)"
          ></rect>
          <rect
            width="11.635"
            height="14.882"
            fill="#fff"
            data-name="Rectangle 17176"
            opacity="0.7"
            rx="1.99"
            transform="rotate(-5.92 227.706 -119.911)"
          ></rect>
          <path
            fill="#263238"
            d="M237.484 181.684l-9.2.966a1.438 1.438 0 01-1.572-1.279l-1.284-12.406a1.428 1.428 0 011.274-1.572l9.18-.95a1.428 1.428 0 011.572 1.274l1.284 12.411a1.428 1.428 0 01-1.259 1.557zm-10.716-13.87a.986.986 0 00-.873 1.074l1.289 12.406a.976.976 0 001.074.873l9.18-.955a.971.971 0 00.868-1.068l-1.284-12.391a.981.981 0 00-1.074-.868z"
            data-name="Path 18303"
            transform="translate(-211.963 -144.897)"
          ></path>
          <path
            fill="#fff"
            d="M237.484 181.684l-9.2.966a1.438 1.438 0 01-1.572-1.279l-1.284-12.406a1.428 1.428 0 011.274-1.572l9.18-.95a1.428 1.428 0 011.572 1.274l1.284 12.411a1.428 1.428 0 01-1.259 1.557zm-10.716-13.87a.986.986 0 00-.873 1.074l1.289 12.406a.976.976 0 001.074.873l9.18-.955a.971.971 0 00.868-1.068l-1.284-12.391a.981.981 0 00-1.074-.868z"
            data-name="Path 18304"
            opacity="0.5"
            transform="translate(-211.963 -144.897)"
          ></path>
          <rect
            width="2.533"
            height="2.533"
            fill="#263238"
            data-name="Rectangle 17177"
            rx="1.07"
            transform="rotate(-5.92 237.427 -130.345)"
          ></rect>
          <rect
            width="2.533"
            height="2.533"
            fill="#fff"
            data-name="Rectangle 17178"
            opacity="0.5"
            rx="1.07"
            transform="rotate(-5.92 237.427 -130.345)"
          ></rect>
          <path
            fill="#263238"
            d="M230.587 172.352a.627.627 0 11-.683-.555.627.627 0 01.683.555z"
            data-name="Path 18305"
            transform="translate(-213.866 -147.504)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M214.132 188.24s-17.548 7.228-14.574 10.346c2.055 2.132 6.164.514 15.365-8.589 0 .003.622-1.063-.791-1.757z"
            data-name="Path 18306"
            transform="translate(-199.219 -155.501)"
          ></path>
          <path
            fill="#fff"
            d="M238.644 181.327a1.207 1.207 0 11-1.207-1.207 1.207 1.207 0 011.207 1.207z"
            data-name="Path 18307"
            transform="translate(-217.217 -151.552)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M225.6 186.361s2.322-2.872 4.027-3.082a16.728 16.728 0 013.046-.067s2.147 3.832 0 4.33c-1.634.38-1.7.272-2.5.514-.9.262-.832.154-3.447.267z"
            data-name="Path 18308"
            transform="translate(-212.048 -153.037)"
          ></path>
          <path
            fill="#177ba3"
            d="M284.51 386.3l.211 3.2-8.543 1.577-1.058-3.177z"
            data-name="Path 18309"
            transform="translate(-236.13 -251.817)"
          ></path>
          <path
            fill="#177ba3"
            d="M235.253 385.85l-.642 3.144-8.656-.735-.185-3.339z"
            data-name="Path 18310"
            transform="translate(-212.131 -251.146)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M233.884 139.782a4.11 4.11 0 001.505 2.363c.981.719 1.721-.154 1.67-1.382-.051-1.11-.616-2.815-1.7-3.041s-1.742.878-1.475 2.06z"
            data-name="Path 18311"
            transform="translate(-216.048 -130.92)"
          ></path>
          <path
            fill="#ffbf9d"
            d="M237.628 131.059c-.514 3.863-.981 6.092.586 8.466a5.062 5.062 0 008.923-.8c1.469-3.247 1.988-9.021-1.423-11.332a5.137 5.137 0 00-8.086 3.668z"
            data-name="Path 18312"
            transform="translate(-217.66 -125.449)"
          ></path>
          <path
            fill="#263238"
            d="M248.424 141.536c-.036.288.092.545.277.57s.375-.2.416-.483-.092-.55-.277-.575-.364.231-.416.488z"
            data-name="Path 18313"
            transform="translate(-223.145 -132.551)"
          ></path>
          <path
            fill="#263238"
            d="M254.826 142.406c-.031.3.092.55.283.575s.375-.2.411-.488-.087-.545-.277-.565-.376.191-.417.478z"
            data-name="Path 18314"
            transform="translate(-226.258 -132.979)"
          ></path>
          <path
            fill="#263238"
            d="M255.47 141.823l.724-.113s-.426.503-.724.113z"
            data-name="Path 18315"
            transform="translate(-226.574 -132.874)"
          ></path>
          <path
            fill="#e9a07a"
            d="M252.482 143.64a7.8 7.8 0 00.807 1.993 1.3 1.3 0 01-1.068.062z"
            data-name="Path 18316"
            transform="translate(-224.993 -133.812)"
          ></path>
          <path
            fill="#263238"
            d="M247.453 148.264a2.413 2.413 0 00.426.087.082.082 0 00.1-.067.092.092 0 00-.082-.092 2.348 2.348 0 01-1.767-1.146.082.082 0 00-.113-.036.087.087 0 00-.041.113 2.4 2.4 0 001.479 1.14z"
            data-name="Path 18317"
            transform="translate(-221.952 -135.447)"
          ></path>
          <path
            fill="#263238"
            d="M245.891 139.379a.164.164 0 00.19-.067 2.055 2.055 0 011.479-.688.175.175 0 00.175-.175.17.17 0 00-.17-.17 2.348 2.348 0 00-1.762.842.175.175 0 00.046.236.092.092 0 00.042.022z"
            data-name="Path 18318"
            transform="translate(-221.86 -131.206)"
          ></path>
          <path
            fill="#263238"
            d="M255.748 139.338a.2.2 0 00.175-.041.18.18 0 000-.247 1.69 1.69 0 00-1.49-.555.164.164 0 00-.144.185.17.17 0 00.2.139 1.392 1.392 0 011.2.442.185.185 0 00.059.077z"
            data-name="Path 18319"
            transform="translate(-225.999 -131.303)"
          ></path>
          <path
            fill="#263238"
            d="M249.05 141.053l.724-.113s-.421.483-.724.113z"
            data-name="Path 18320"
            transform="translate(-223.452 -132.499)"
          ></path>
          <path
            fill="#263238"
            d="M243.734 126.943s.688 3.164-1.4 4.8a14.06 14.06 0 00-2.753.565 5.388 5.388 0 00.729-2.188s-1.027 2.188-1.916 2.682a7.768 7.768 0 00-2.435.986 8.805 8.805 0 01.935-2.954 10.012 10.012 0 00-2.152 6.026l-1.854-1.233v-5.137l1.027-2.3 2.358-1.587 1.027-.658a13.981 13.981 0 002.625 0 6.935 6.935 0 011.726-.221z"
            data-name="Path 18321"
            transform="translate(-215.593 -125.098)"
          ></path>
          <path
            fill="#263238"
            d="M250.93 128.666s2.569.205 2.682 3.986a1.706 1.706 0 011.176 1.079 3.4 3.4 0 00.3-1.824s-.416-1.962-.447-2.081-.9-1.454-.9-1.454l-1.238-.863-.283-.19z"
            data-name="Path 18322"
            transform="translate(-224.366 -125.876)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default HiddenIdentityIcon;
