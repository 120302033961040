import classNames from "classnames";

const SpContainer = ({
  children,
  flex = "col",
  className: classes,
  customBox = false, // padding and margin dimensions
  customDimension = false, // height and width dimesions
}) => {
  return (
    <div
      className={classNames(
        "relative flex flex-col rounded-xl bg-white shadow-sm",
        { "min-h-[33rem]": !customDimension },
        { "mt-4 mb-16 p-4 px-2 py-2 pb-16 md:px-8 xs:px-4": !customBox },
        { [classes]: !!classes }
      )}
    >
      {children}
    </div>
  );
};

export default SpContainer;
