import React from "react";

function MainPageIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 17.019 16.987"
    >
      <path
        // fill="#177ba3"
        stroke="#177ba3"
        strokeWidth="0.6"
        d="M15.18 16.687H10.9a1.541 1.541 0 01-1.539-1.539v-4.276a1.541 1.541 0 011.539-1.54h4.28a1.541 1.541 0 011.539 1.539v4.276a1.541 1.541 0 01-1.539 1.54zm-4.28-6.328a.513.513 0 00-.513.513v4.276a.513.513 0 00.513.513h4.28a.513.513 0 00.513-.513v-4.276a.513.513 0 00-.513-.513zm-4.785 6.328H1.839A1.541 1.541 0 01.3 15.148v-4.276a1.541 1.541 0 011.539-1.54h4.276a1.541 1.541 0 011.539 1.539v4.276a1.541 1.541 0 01-1.539 1.54zm-4.276-6.328a.514.514 0 00-.513.513v4.276a.514.514 0 00.513.513h4.276a.514.514 0 00.513-.513v-4.276a.514.514 0 00-.513-.513zm13.34-2.7H10.9a1.541 1.541 0 01-1.536-1.544V1.839A1.541 1.541 0 0110.9.3h4.28a1.541 1.541 0 011.539 1.539v4.276a1.541 1.541 0 01-1.539 1.54zM10.9 1.326a.513.513 0 00-.513.513v4.276a.513.513 0 00.513.513h4.28a.513.513 0 00.513-.513V1.839a.513.513 0 00-.513-.513zM6.115 7.655H1.839A1.541 1.541 0 01.3 6.115V1.839A1.541 1.541 0 011.839.3h4.276a1.541 1.541 0 011.539 1.539v4.276a1.541 1.541 0 01-1.539 1.54zM1.839 1.326a.514.514 0 00-.513.513v4.276a.514.514 0 00.513.513h4.276a.514.514 0 00.513-.513V1.839a.514.514 0 00-.513-.513z"
      ></path>
    </svg>
  );
}

export default MainPageIcon;
