import React from "react";

function ClosedJobIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 14.576 19.268"
    >
      <g
        // fill="#fff"
        stroke="#fff"
        strokeWidth="0.5"
        transform="translate(-4.75 -.75)"
      >
        <path
          d="M12.038 1a5.256 5.256 0 00-5.279 5.278v2.346A1.728 1.728 0 005 10.384v7.624a1.728 1.728 0 001.759 1.759h10.557a1.728 1.728 0 001.759-1.759v-7.624a1.728 1.728 0 00-1.759-1.759V6.278A5.256 5.256 0 0012.038 1zm5.862 9.384v7.624a.554.554 0 01-.586.586H6.759a.554.554 0 01-.586-.586v-7.624a.554.554 0 01.586-.584h10.557a.554.554 0 01.584.584zm-9.968-1.76V6.278a4.105 4.105 0 018.211 0v2.346z"
          data-name="Path 16710"
        ></path>
        <path
          d="M12.037 11.557a1.728 1.728 0 00-1.759 1.759 1.708 1.708 0 001.173 1.641v1.29a.587.587 0 001.173 0v-1.29a1.708 1.708 0 001.173-1.642 1.728 1.728 0 00-1.76-1.758zm0 2.346a.586.586 0 11.586-.586.554.554 0 01-.586.586z"
          data-name="Path 16711"
        ></path>
      </g>
    </svg>
  );
}

export default ClosedJobIcon;
