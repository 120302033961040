import { FaSlidersH } from "react-icons/fa";
import { Button, Image } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import logo from "../../assets/pictures/logo.svg";
import SchoolsIcon from "../../assets/icons/school-icon";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../routes";
import Notifications from "../../pages/notifications/notifications-dropdown";
import NotificationsIcon from "../../assets/icons/notifications-icon";

const NavbarComponent = ({ isVisible, setIsVisible, isSmall, withLogo }) => {
  const history = useHistory();
  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const location = useLocation();

  return (
    <nav
      className={`${
        isSmall || withLogo ? "justify-between" : "justify-end "
      } fixed z-20 flex h-20 w-full items-center bg-white px-8 py-2 shadow-sm`}
    >
      <div className="flex items-center">
        {isSmall && (
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center">
              <FaSlidersH
                size={30}
                className="mx-2 rounded-lg bg-primary-900 fill-[#FCFCFC] p-2 hover:cursor-pointer"
                onClick={() => setIsVisible(!isVisible)}
              />
              {location.pathname.startsWith(routes.settings) && (
                <div className="font-bold">
                  {selectedContent[localizationKeys.settings]}
                </div>
              )}
              {location.pathname.startsWith(routes.home) && (
                <div className="font-bold">
                  {selectedContent[localizationKeys.mainPage]}
                </div>
              )}
              {location.pathname.startsWith(
                routes.employmentApplications.all
              ) && (
                <div className="font-bold">
                  {selectedContent[localizationKeys.employmentApplications]}
                </div>
              )}
              {location.pathname.startsWith(routes.jobs.active) && (
                <div className="font-bold">
                  {selectedContent[localizationKeys.jobs]}{" "}
                  {selectedContent[localizationKeys.active2]}
                </div>
              )}
              {location.pathname.startsWith(routes.jobs.drafted) && (
                <div className="font-bold">
                  {selectedContent[localizationKeys.jobs]}{" "}
                  {selectedContent[localizationKeys.drafted2]}
                </div>
              )}
              {location.pathname.startsWith(routes.jobs.closed) && (
                <div className="font-bold">
                  {selectedContent[localizationKeys.jobs]}{" "}
                  {selectedContent[localizationKeys.archived2]}
                </div>
              )}
              {location.pathname.startsWith(routes.subscribtions.all) && (
                <div className="font-bold">
                  {selectedContent[localizationKeys.paying]}
                </div>
              )}
              {location.pathname.startsWith(routes.notifications.all) && (
                <div className="font-bold">
                  {selectedContent[localizationKeys.notifications]}
                </div>
              )}
            </div>
          </div>
        )}

        {withLogo && <Image src={logo} className=" h-12" />}
      </div>

      {isSmall && !location.pathname.startsWith(routes.notifications.all) && (
        <div
          className=" mx-2 hover:cursor-pointer"
          onClick={() => history.push(routes.notifications.all)}
        >
          <NotificationsIcon size={20} />
        </div>
      )}
      {!isSmall && (
        <div className="flex items-center">
          <div className="flex items-center">
            {/* <NotificationsIcon className="mx-4 w-4 h-5 cursor-pointer"/> */}
            <Notifications />
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-900">
              <SchoolsIcon size="20" className=" fill-white" />
            </div>
            <div className="flex-col px-4">
              <div
                className="ml-2 cursor-pointer text-gray-700 underline"
                onClick={() =>
                  !location.pathname.startsWith("/app/completeProfile/") &&
                  history.push(routes.settings)
                }
              >
                {lang === "ar"
                  ? localStorage.getItem("name_ar")
                  : localStorage.getItem("name_en")}
              </div>
              <div className="-mb-1 text-xs">
                {selectedContent[localizationKeys.school]}
              </div>
            </div>
          </div>
          {lang === "ar" && (
            <Button
              circular
              onClick={() => setLang("en")}
              className="flex h-10 w-4 items-center justify-center bg-white font-sans 
          text-sm text-primary-900 ring-1 ring-primary-900 ltr:ml-6 rtl:mr-6 "
            >
              EN
            </Button>
          )}
          {lang === "en" && (
            <Button
              circular
              onClick={() => setLang("ar")}
              className="flex h-10 w-4 items-center  justify-center bg-white text-sm text-primary-900 
          ring-1 ring-primary-900 ltr:ml-6 rtl:mr-6 "
            >
              AR
            </Button>
          )}
        </div>
      )}
    </nav>
  );
};

export default NavbarComponent;
