import React from "react";

function GenderIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 12.706 12.736"
    >
      <g 
    //   fill="#cacaca" 
      transform="translate(-1 -.965)">
        <path
          d="M3.16 7.889l1.573-1.573a2.972 2.972 0 10-.6-.6L2.561 7.29l-.6-.6a.424.424 0 00-.6.6l.6.6-.838.838a.423.423 0 10.6.6l.838-.838.6.6a.423.423 0 10.6-.6zm3.346-6.062a2.118 2.118 0 11-2.118 2.117 2.118 2.118 0 012.118-2.117z"
          data-name="Path 10918"
        ></path>
        <path
          d="M19.026 11h-2.118a.424.424 0 100 .847H18l-2.287 2.287a2.972 2.972 0 10.6.6l2.287-2.288v1.1a.424.424 0 00.847 0v-2.122a.424.424 0 00-.421-.424zm-5.082 7.623a2.118 2.118 0 112.118-2.118 2.118 2.118 0 01-2.119 2.118z"
          data-name="Path 10919"
          transform="translate(-5.743 -5.785)"
        ></path>
      </g>
    </svg>
  );
}

export default GenderIcon;
