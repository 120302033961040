import React from "react";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form, Image } from "semantic-ui-react";
import FormikInput from "../../components/formik/formik-input";
import axios from "axios";
import toast from "react-hot-toast";
import routes from "../../routes";
import { useHistory } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import api from "../../api";
import logo from "../../assets/pictures/logo.svg";

const ResendEmailPage = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const { run, isLoading } = useAxios();
  const history = useHistory();
  const [showPassword, setShowPassword] = React.useState(false);

  const resendEmailSchema = Yup.object({
    email: Yup.string()
      .required(selectedContent[localizationKeys.required])
      .email(selectedContent[localizationKeys.emailError]),
    password: Yup.string()
      .min(8, selectedContent[localizationKeys.passwordError])
      .required(selectedContent[localizationKeys.required]),
  });

  const resendEmailSubmit = (values) => {
    run(axios.post(api.auth.resendEmail, values))
      .then(() => {
        toast.success(
          selectedContent[localizationKeys.verifyEmailAddressMessage]
        );
        history.push(routes.auth.login);
      })
      .catch((e) => {
        e.errors?.map((s) => toast.error(s.message));
      });
  };

  return (
    <div className="flex h-screen items-center justify-center ">
      <div className="mx-auto mt-8 w-auto max-w-md flex-grow rounded-xl bg-white p-8">
        <Image src={logo} className=" mx-auto mb-4 h-12" />
        <h3 className="text-primary text-center">
          {selectedContent[localizationKeys.verifyEmailAddress]}
        </h3>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={resendEmailSchema}
          onSubmit={resendEmailSubmit}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit} loading={isLoading}>
              <FormikInput
                name="email"
                label={selectedContent[localizationKeys.email]}
              />
              <FormikInput
                name="password"
                type={showPassword === true ? "text" : "password"}
                label={selectedContent[localizationKeys.password]}
                icon={
                  showPassword === true ? (
                    <AiOutlineEyeInvisible
                      className="absolute mt-2 flex items-center justify-center text-gray-700 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                      size={24}
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <AiOutlineEye
                      className="absolute mt-2 flex items-center justify-center text-gray-700 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                      size={24}
                      onClick={() => setShowPassword(true)}
                    />
                  )
                }
              />
              <Button
                primary
                className="my-4 mx-auto block h-14 w-96"
                type="submit"
              >
                {selectedContent[localizationKeys.submit]}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResendEmailPage;
