import { Dimmer, Loader } from "semantic-ui-react";
import InstructorNotFoundIcon from "../../assets/icons/instructors-not-found-icon";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import InstructorRow from "./instructor-row";

const RenderedInstructorsList = ({
  loading,
  instructors,
  ModalAction,
  price,
  viewLimit,
}) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  if (loading === "loading") {
    return (
      <Dimmer inverted active className="my-16">
        <Loader inverted size="medium">
          {selectedContent[localizationKeys.loading]}
        </Loader>
      </Dimmer>
    );
  } else if (instructors?.length > 0) {
    return instructors.map((instructor) => (
      <InstructorRow
        key={instructor._id}
        instructor={instructor}
        modalOpen={() => ModalAction.open(instructor)}
        price={price}
        viewLimit={viewLimit}
      />
    ));
  } else {
    return (
      <div className="flex h-[33rem] items-center justify-center rounded-xl bg-white px-2 py-1">
        <div className="flex-col">
          <InstructorNotFoundIcon
            size="80"
            className="mx-auto my-4 justify-center text-center"
          />
          <span className="text-primary text-center text-xl">
            {selectedContent[localizationKeys.instructorsNotFound]}
          </span>
        </div>
      </div>
    );
  }
};

export default RenderedInstructorsList;
