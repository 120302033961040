import React from "react";

function EducationalOfficeIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 18.261 16.105"
    >
      <g data-name="Group 22440" transform="translate(-584.629 -520.922)">
        <g data-name="Group 22431" transform="translate(380.026 182.717)">
          <g transform="translate(204.604 338.206)">
            <path
              fill="#177ba3"
              d="M13.883 9.09l6.752 1.341a1.132 1.132 0 01.912 1.119v7.817a1.148 1.148 0 01-1.15 1.15H13.5a.379.379 0 00.383-.383v-.383H20.4a.384.384 0 00.383-.383V11.55a.385.385 0 00-.3-.376l-6.6-1.3z"
              data-name="Path 16389"
              transform="translate(-3.286 -4.412)"
            ></path>
            <path
              fill="#177ba3"
              d="M18.916 14a.383.383 0 110 .766h-1.533a.383.383 0 010-.766z"
              data-name="Path 16390"
              transform="translate(-4.103 -5.559)"
            ></path>
            <path
              fill="#177ba3"
              d="M18.916 17a.383.383 0 110 .766h-1.533a.383.383 0 010-.766z"
              data-name="Path 16391"
              transform="translate(-4.103 -6.26)"
            ></path>
            <path
              fill="#177ba3"
              d="M18.916 20a.383.383 0 110 .766h-1.533a.383.383 0 010-.766z"
              data-name="Path 16392"
              transform="translate(-4.103 -6.961)"
            ></path>
            <path
              fill="#177ba3"
              d="M13.766 20.113a.383.383 0 11-.766 0V9.383a.407.407 0 01.138-.3.4.4 0 01.322-.077l.307.061v11.046z"
              data-name="Path 16393"
              transform="translate(-3.169 -4.391)"
            ></path>
            <path
              fill="#177ba3"
              d="M12.182 23v.383a.379.379 0 00.383.383H9.5a.379.379 0 00.383-.383V23z"
              data-name="Path 16394"
              transform="translate(-2.351 -7.661)"
            ></path>
            <path
              fill="#177ba3"
              d="M10.3 8.383a.379.379 0 01-.383.383H8.383a.383.383 0 110-.766h1.533a.379.379 0 01.384.383z"
              data-name="Path 16396"
              transform="translate(-2.001 -4.157)"
            ></path>
            <path
              fill="#177ba3"
              d="M9.916 11a.383.383 0 110 .766H8.383a.383.383 0 110-.766z"
              data-name="Path 16397"
              transform="translate(-2.001 -4.858)"
            ></path>
            <path
              fill="#177ba3"
              d="M9.916 14a.383.383 0 110 .766H8.383a.383.383 0 110-.766z"
              data-name="Path 16398"
              transform="translate(-2.001 -5.559)"
            ></path>
            <path
              fill="#177ba3"
              d="M5.3 14.383a.379.379 0 01-.383.383H3.383a.383.383 0 110-.766h1.533a.379.379 0 01.384.383z"
              data-name="Path 16399"
              transform="translate(-.833 -5.559)"
            ></path>
            <path
              fill="#177ba3"
              d="M4.916 8a.383.383 0 010 .766H3.383a.383.383 0 110-.766z"
              data-name="Path 16401"
              transform="translate(-.833 -4.157)"
            ></path>
            <path
              fill="#177ba3"
              d="M4.916 11a.383.383 0 010 .766H3.383a.383.383 0 110-.766z"
              data-name="Path 16402"
              transform="translate(-.833 -4.858)"
            ></path>
            <path
              fill="#177ba3"
              d="M7.832 18.15a.384.384 0 00-.383-.383H5.15a.379.379 0 00-.383.383v3.45H4v-3.45A1.148 1.148 0 015.15 17h2.3a1.148 1.148 0 011.15 1.15v3.45h-.768z"
              data-name="Path 16403"
              transform="translate(-1.067 -6.26)"
            ></path>
            <path
              fill="#177ba3"
              d="M4.766 23H8.6v.383a.379.379 0 01-.383.383H4.383A.379.379 0 014 23.383V23z"
              data-name="Path 16404"
              transform="translate(-1.067 -7.661)"
            ></path>
            <path
              fill="#177ba3"
              d="M9.273.194L.954 1.323a1.052 1.052 0 00-.954 1V6.2l.3-.05a.435.435 0 01.318.068.345.345 0 01.136.264V2.323a.355.355 0 01.318-.338L9.394.863a.233.233 0 01.068-.007.383.383 0 01.238.081.311.311 0 01.136.257v14.077a.361.361 0 01-.378.338H7.57v.338a.356.356 0 01-.378.338h2.27a1.079 1.079 0 001.138-1.014V1.194a.956.956 0 00-.4-.771 1.234 1.234 0 00-.924-.23z"
              data-name="Path 16405"
              transform="translate(0 -.181)"
            ></path>
            <path
              fill="#177ba3"
              d="M21.164 9.09l-6.752 1.753a1.441 1.441 0 00-.912 1.463v10.217a1.36 1.36 0 001.15 1.5h6.9c-.215 0-.383-.22-.383-.5v-.5H14.65a.455.455 0 01-.383-.5V12.306a.494.494 0 01.3-.491l6.6-1.7z"
              data-name="Path 16406"
              transform="translate(-13.5 -7.921)"
            ></path>
            <path
              fill="none"
              stroke="#fff"
              strokeWidth="1"
              d="M7.953 1.711L.98 3.423"
              data-name="Path 16407"
            ></path>
          </g>
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="0.5"
            d="M0 2.554L0 0"
            data-name="Line 90"
            transform="translate(205.748 341.135)"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="0.25"
          d="M592.487 522.119l-7.05 1.875"
          data-name="Path 17903"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="0.21"
          d="M0 0L0 2"
          data-name="Line 753"
          transform="translate(585.5 523.5)"
        ></path>
      </g>
    </svg>
  );
}

export default EducationalOfficeIcon;
