import React from "react";
import { Button, Form } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { Formik } from "formik";
import useAxios from "../../lib/use-axios";
import api from "../../api";
import toast from "react-hot-toast";
import * as Yup from "yup";
import FormikDropdown from "../../components/formik/formik-dropdown";
import FormikSelect from "../../components/formik/formik-select";
import { authAxios } from "../../config/axios-config";
import FormikInput from "../../components/formik/formik-input";
import FormikCheckbox from "../../components/formik/formik-checkbox";
import FileAttachment from "../../lib/file-attachment";
import convertToFormData from "../../utils/form-data-utils";
import { useHistory, useParams } from "react-router-dom";
import KsaFlagIcon from "../../assets/icons/ksa-flag-icon";
import FormikTextArea from "../../components/formik/formik-textArea";
import { RiImage2Fill } from "react-icons/ri";
import NavbarComponent from "../../components/shared/navbar";
import ClipboardIcon from "../../assets/icons/clipboard-icon";
import AddEducationalOffice from "../educational-offices/add-educational-office";
import { AiOutlinePlus } from "react-icons/ai";
import routes from "../../routes";
import useMediaQuery from "../../components/hooks/use-media-query";

const CompleteProfilePage = () => {
  const params = useParams();
  const history = useHistory();
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const { run, isLoading } = useAxios();
  const {
    run: getSchool,
    data: school,
    isLoading: isGettingSchool,
  } = useAxios({});
  const { run: getCities } = useAxios({});
  const { run: getEducationalOffices } = useAxios({});
  const { run: getEducationalLevels } = useAxios({});

  const [cities, setCities] = React.useState([]);
  const [educationalOffices, setEducationalOffices] = React.useState([]);
  const [educationalLevels, setEducationalLevels] = React.useState([]);
  const [choosenEducationalLevels, setChoosenEducationalLevels] =
    React.useState([]);
  const [image, setImage] = React.useState(null);
  const [logo, setLogo] = React.useState(null);
  const [city, setCity] = React.useState(null);
  // const [logoError, setLogoError] = React.useState(false);
  // const [imageError, setImageError] = React.useState(false);
  const [countryCode] = React.useState("+966");
  const [isAddingEducationalOffice, setIsAddingEducationalOffice] =
    React.useState(null);
  const [forceReload, setForceReload] = React.useState(false);

  const isSmall = useMediaQuery("(max-width: 776px)");

  const updateProfileSchema = Yup.object({
    name: Yup.object().shape({
      ar: Yup.string().required(selectedContent[localizationKeys.required]),
      en: Yup.string().required(selectedContent[localizationKeys.required]),
    }),
    phone: Yup.number()
      .typeError(selectedContent[localizationKeys.phoneMustBeNumber])
      .required(selectedContent[localizationKeys.required]),
    email: Yup.string()
      .required(selectedContent[localizationKeys.required])
      .email(selectedContent[localizationKeys.emailError]),
    address: Yup.string().required(selectedContent[localizationKeys.required]),
    breif: Yup.string().optional(),
    city: Yup.string().required(selectedContent[localizationKeys.required]),
    education_centers: Yup.string().required(
      selectedContent[localizationKeys.required]
    ),
    education_levels: Yup.array()
      .min(1, "You can't leave this blank.")
      .required("You can't leave this blank.")
      .nullable(),
    // students_gender1: school?.data?.students_gender?.includes("بنين") || "",
    // students_gender2: school?.data?.students_gender?.includes("بنات") || "",
    school_type: Yup.string().required(
      selectedContent[localizationKeys.required]
    ),
    commercial_registration_num: Yup.string().optional(),
  });

  const schoolTypesOptions = [
    {
      key: "حكومية",
      text: "حكومية",
      value: "حكومية",
    },
    {
      key: "أهلية",
      text: "أهلية",
      value: "أهلية",
    },
    {
      key: "خاصة",
      text: "خاصة",
      value: "خاصة",
    },
    {
      key: "عالمية",
      text: "عالمية",
      value: "عالمية",
    },
  ];

  React.useEffect(() => {
    getSchool(authAxios.get(`${api.schools.schools}/${params.id}`)).then(() => {
      setCity(school?.data?.city?._id);
      setChoosenEducationalLevels(
        school?.data?.education_levels?.map((a) => a?._id)
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSchool, params.id, school?.data?.city?._id]);

  React.useEffect(() => {
    if (city)
      getEducationalOffices(
        authAxios.get(`${api.educationalOffices.all}?city=${city}`)
      )
        .then(({ data }) => {
          let options = [];
          data?.data?.forEach((s) => {
            options.push({
              text: `${s?.name} - ${s?.gender}`,
              key: s._id,
              value: s._id,
            });
          });
          setEducationalOffices(options);
        })
        .catch((e) => {
          e?.errors?.map((s) => toast.error(s.message));
        });
  }, [city, getEducationalOffices, forceReload]);

  React.useEffect(() => {
    getCities(authAxios.get(api.city.all))
      .then(({ data }) => {
        let options = [];
        data?.data?.forEach((s) => {
          options.push({
            text: s.name,
            key: s._id,
            value: s._id,
          });
        });
        setCities(options);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });

    getEducationalLevels(authAxios.get(api.educationalLevels.all))
      .then(({ data }) => {
        let options = [];
        data?.data?.forEach((s) => {
          options.push({
            text: s.name,
            key: s._id,
            value: s._id,
          });
        });
        setEducationalLevels(options);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  }, [getCities, getEducationalLevels]);

  // React.useEffect(() => {
  //   logo && setLogoError(false);
  // }, [logo]);

  // React.useEffect(() => {
  //   image && setImageError(false);
  // }, [image]);

  const updateProfile = (values) => {
    const formData = new FormData();
    !values?.breif && delete values?.breif;
    convertToFormData(formData, values);
    if (logo) formData.append("logo", logo);
    if (image) formData.append("image", image);
    formData.delete("education_levels");
    formData.append("phone_country_code", countryCode);
    for (var i = 0; i < choosenEducationalLevels?.length; i++) {
      formData.append(`education_levels[${i}]`, choosenEducationalLevels[i]);
    }
    if (values?.students_gender1) {
      formData.delete("students_gender1");
      formData.append(`students_gender[]`, "بنين");
    }
    if (values?.students_gender2) {
      formData.delete("students_gender2");
      formData.append(`students_gender[]`, "بنات");
    }
    if (values?.name?.ar) {
      formData.append(`name[ar]`, values?.name?.ar);
    }
    if (values?.name?.en) {
      formData.append(`name[en]`, values?.name?.en);
    }
    // logo && image
    //   ?
    run(authAxios.put(`${api.schools.schools}/${params?.id}`, formData))
      .then(({ data }) => {
        setImage(null);
        setLogo(null);
        setChoosenEducationalLevels([]);
        toast.success(
          selectedContent[localizationKeys.profileIsCompletedSuccessfully]
        );
        localStorage.setItem(
          "isCompleteProfile",
          data?.data?.isCompleteProfile
        );
        history.push(routes.home);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
    // : (!logo && setLogoError(true)) || (!image && setImageError(true));
  };

  return (
    <div>
      <NavbarComponent withLogo={true} />
      <div className="mb-24 flex items-center justify-center">
        {isSmall ? (
          <Formik
            initialValues={{
              name: {
                ar: school?.data?.name?.ar || "",
                en: school?.data?.name?.en || "",
              },
              email: school?.data?.email || "",
              phone: school?.data?.phone || "",
              address: school?.data?.address || "",
              breif: school?.data?.breif || "",
              city: school?.data?.city?._id || "",
              education_centers: school?.data?.education_centers?._id || "",
              education_levels:
                school?.data?.education_levels?.map((a) => a?._id) || [],
              students_gender1:
                school?.data?.students_gender?.includes("بنين") || "",
              students_gender2:
                school?.data?.students_gender?.includes("بنات") || "",
              school_type: school?.data?.school_type || "",
              commercial_registration_num:
                school?.data?.commercial_registration_num || "",
            }}
            validationSchema={updateProfileSchema}
            onSubmit={updateProfile}
            enableReinitialize
          >
            {(formik) => (
              <Form
                onSubmit={formik.handleSubmit}
                loading={isLoading || isGettingSchool}
                className=" mx-auto mt-32 mb-36 max-w-7xl flex-grow justify-center rounded-lg bg-white
               p-6 px-8 shadow-sm"
              >
                <div className="">
                  <div className="flex items-center">
                    <ClipboardIcon size="30" />
                    <span className="mx-2 font-bold text-black">
                      {selectedContent[localizationKeys.completeProfileTitle]}
                    </span>
                  </div>
                  {false && (
                    <div>
                      <div className="grid grid-cols-2  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.name_ar]}
                        </div>
                        <FormikInput
                          name="name.ar"
                          placeholder={
                            selectedContent[localizationKeys.name_ar]
                          }
                          dir="rtl"
                        />
                      </div>
                      <div className="grid grid-cols-2  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.name_en]}
                        </div>
                        <FormikInput
                          name="name.en"
                          placeholder={
                            selectedContent[localizationKeys.name_en]
                          }
                          dir="ltr"
                        />
                      </div>
                      <div className="grid grid-cols-2  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.email]}
                        </div>
                        <FormikInput
                          disabled
                          name="email"
                          placeholder={selectedContent[localizationKeys.email]}
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    <FormikDropdown
                      // className="w-64"
                      options={cities}
                      name="city"
                      placeholder={selectedContent[localizationKeys.city]}
                      onChangeCallback={(e) => {
                        setCity(e);
                        formik.setFieldValue("education_centers", "");
                        formik.setFieldTouched("education_centers", false);
                      }}
                    />
                    <div className="grid grid-cols-4 items-center ">
                      <div className="col-span-3">
                        <FormikDropdown
                          className="md:w-full lg:w-80"
                          options={educationalOffices}
                          name="education_centers"
                          placeholder={
                            selectedContent[localizationKeys.educationalOffice]
                          }
                          onChangeCallback={(e) => console.log("")}
                          disabled={!city}
                        />
                      </div>
                      <div className="flex justify-end ">
                        <Button
                          primary
                          size="tiny"
                          type="button"
                          circular
                          icon
                          className="mt-3"
                          onClick={() => setIsAddingEducationalOffice(true)}
                        >
                          <AiOutlinePlus />
                        </Button>
                      </div>
                    </div>
                    <FormikSelect
                      options={educationalLevels}
                      name="education_levels"
                      placeholder={
                        selectedContent[localizationKeys.educationalLevels]
                      }
                      className="mt-6"
                      multiple
                      onChangeCallback={(e) => setChoosenEducationalLevels(e)}
                    />
                    <FormikInput
                      name="phone"
                      dir="ltr"
                      type="number"
                      // maxLength="9"
                      placeholder={
                        selectedContent[localizationKeys.phoneNumberPlaceHolder]
                      }
                      className="text-center"
                      iconPosition={lang === "ar" ? "right" : "left"}
                      icon={
                        <div className="absolute mt-2 flex items-center ">
                          <KsaFlagIcon
                            className="flex  items-center justify-center text-gray-700  ltr:mx-2 rtl:mx-2"
                            size={24}
                            type="button"
                          />
                          <span className="border-r-2 pr-2 ">(966)</span>
                        </div>
                      }
                    ></FormikInput>
                  </div>
                  <FormikDropdown
                    // className="w-64"
                    options={schoolTypesOptions}
                    name="school_type"
                    placeholder={selectedContent[localizationKeys.schoolType]}
                    onChangeCallback={(e) => console.log("")}
                  />
                  <FormikInput
                    name="commercial_registration_num"
                    placeholder={
                      selectedContent[localizationKeys.registrationNumber]
                    }
                    // className="w-64"
                  />
                  <FormikInput
                    name="address"
                    placeholder={selectedContent[localizationKeys.address]}
                    //  className="w-64"
                  />
                  <FormikTextArea
                    name="breif"
                    className=""
                    placeholder={selectedContent[localizationKeys.brief]}
                  />
                  <div className="grid grid-cols-6 items-center justify-center ">
                    <span className=" col-span-2 mt-2 ">
                      {selectedContent[localizationKeys.studentType]}
                    </span>
                    <div className="col-span-2 mb-6 grid grid-cols-2 items-center ">
                      <div className="flex items-center">
                        <FormikCheckbox
                          className="fill-primary-900 "
                          name="students_gender1"
                        />
                        <span className="mt-5 hover:cursor-pointer">
                          {selectedContent[localizationKeys.male]}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <FormikCheckbox
                          className="mt-2 ltr:ml-16 rtl:mr-16"
                          name="students_gender2"
                        />
                        <span className="mt-5 hover:cursor-pointer">
                          {selectedContent[localizationKeys.female]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="my-2 grid grid-cols-2 items-center justify-center gap-8">
                    <FileAttachment
                      setAttachmentData={setLogo}
                      label={selectedContent[localizationKeys.addLogo]}
                      name="image"
                      accept=".png,.jpg "
                      labelClassName="text-gray-400 flex py-2 cursor-pointer 
                      bg-[#F2F2F2] rounded-lg mx-2 h-30"
                      UploadIcon={RiImage2Fill}
                      textClassName="mt-2 text-sm"
                      // error={logoError}
                      dimensions="70 x 70 px"
                    />
                    <FileAttachment
                      setAttachmentData={setImage}
                      label={selectedContent[localizationKeys.addImage]}
                      name="logo"
                      accept=".png,.jpg "
                      labelClassName="text-gray-400 flex py-2 cursor-pointer 
                      bg-[#F2F2F2] rounded-lg mx-2 h-30"
                      UploadIcon={RiImage2Fill}
                      textClassName="mt-2 text-sm"
                      // error={imageError}
                      dimensions="1048 x 227 px"
                    />
                    <div></div>
                    <div className="col-span-2 mx-auto text-center">
                      <Button primary className="my-2 h-12 w-60" type="submit">
                        {selectedContent[localizationKeys.save]}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={{
              name: {
                ar: school?.data?.name?.ar || "",
                en: school?.data?.name?.en || "",
              },
              email: school?.data?.email || "",
              phone: school?.data?.phone || "",
              address: school?.data?.address || "",
              breif: school?.data?.breif || "",
              city: school?.data?.city?._id || "",
              education_centers: school?.data?.education_centers?._id || "",
              education_levels:
                school?.data?.education_levels?.map((a) => a?._id) || [],
              students_gender1:
                school?.data?.students_gender?.includes("بنين") || "",
              students_gender2:
                school?.data?.students_gender?.includes("بنات") || "",
              school_type: school?.data?.school_type || "",
              commercial_registration_num:
                school?.data?.commercial_registration_num || "",
            }}
            validationSchema={updateProfileSchema}
            onSubmit={updateProfile}
            enableReinitialize
          >
            {(formik) => (
              <Form
                onSubmit={formik.handleSubmit}
                loading={isLoading || isGettingSchool}
                className=" mx-auto mt-32 mb-36 max-w-7xl flex-grow justify-center rounded-lg bg-white
               p-6 px-8 shadow-sm"
              >
                <div className="">
                  <div className="flex items-center">
                    <ClipboardIcon size="30" />
                    <span className="mx-2 text-xl font-bold text-black">
                      {selectedContent[localizationKeys.completeProfileTitle]}
                    </span>
                  </div>
                  {false && (
                    <div>
                      <div className="grid grid-cols-2  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.name_ar]}
                        </div>
                        <FormikInput
                          name="name.ar"
                          placeholder={
                            selectedContent[localizationKeys.name_ar]
                          }
                          dir="rtl"
                        />
                      </div>
                      <div className="grid grid-cols-2  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.name_en]}
                        </div>
                        <FormikInput
                          name="name.en"
                          placeholder={
                            selectedContent[localizationKeys.name_en]
                          }
                          dir="ltr"
                        />
                      </div>
                      <div className="grid grid-cols-2  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.email]}
                        </div>
                        <FormikInput
                          disabled
                          name="email"
                          placeholder={selectedContent[localizationKeys.email]}
                        />
                      </div>
                    </div>
                  )}
                  <div className="mt-4 grid  grid-cols-2">
                    {/* right */}
                    <div>
                      <div className="grid grid-cols-3 items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.city]}
                        </div>
                        <div className="col-span-2">
                          <FormikDropdown
                            // className="w-64"
                            options={cities}
                            name="city"
                            placeholder={selectedContent[localizationKeys.city]}
                            onChangeCallback={(e) => {
                              setCity(e);
                              formik.setFieldValue("education_centers", "");
                              formik.setFieldTouched(
                                "education_centers",
                                false
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-3  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.educationalOffice]}
                        </div>
                        <div className="col-span-2 flex items-center justify-between">
                          <FormikDropdown
                            className="md:w-full lg:w-80"
                            options={educationalOffices}
                            name="education_centers"
                            placeholder={
                              selectedContent[
                                localizationKeys.educationalOffice
                              ]
                            }
                            onChangeCallback={(e) => console.log("")}
                            disabled={!city}
                          />
                          <Button
                            primary
                            size="tiny"
                            type="button"
                            circular
                            icon
                            className="mt-3"
                            onClick={() => setIsAddingEducationalOffice(true)}
                          >
                            <AiOutlinePlus />
                          </Button>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 items-center justify-center">
                        <span className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.educationalLevels]}
                        </span>
                        <div className="col-span-2">
                          <FormikSelect
                            options={educationalLevels}
                            name="education_levels"
                            placeholder={
                              selectedContent[
                                localizationKeys.educationalLevels
                              ]
                            }
                            className="mt-6"
                            multiple
                            onChangeCallback={(e) =>
                              setChoosenEducationalLevels(e)
                            }
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-3  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.phone]}
                        </div>
                        <div className="col-span-2">
                          <FormikInput
                            name="phone"
                            dir="ltr"
                            type="number"
                            // maxLength="9"
                            placeholder={
                              selectedContent[
                                localizationKeys.phoneNumberPlaceHolder
                              ]
                            }
                            className="text-center"
                            iconPosition={lang === "ar" ? "right" : "left"}
                            icon={
                              <div className="absolute mt-2 flex items-center ">
                                <KsaFlagIcon
                                  className="flex  items-center justify-center text-gray-700  ltr:mx-2 rtl:mx-2"
                                  size={24}
                                  type="button"
                                />
                                <span className="border-r-2 pr-2 ">(966)</span>
                              </div>
                            }
                          ></FormikInput>
                        </div>
                      </div>
                    </div>
                    {/* left */}
                    <div className="ltr:ml-16 rtl:mr-16">
                      <div className="grid grid-cols-3  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.schoolType]}
                        </div>
                        <div className="col-span-2">
                          <FormikDropdown
                            // className="w-64"
                            options={schoolTypesOptions}
                            name="school_type"
                            placeholder={
                              selectedContent[localizationKeys.schoolType]
                            }
                            onChangeCallback={(e) => console.log("")}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-3  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.registrationNumber]}
                        </div>
                        <div className="col-span-2">
                          <FormikInput
                            name="commercial_registration_num"
                            placeholder={
                              selectedContent[
                                localizationKeys.registrationNumber
                              ]
                            }
                            // className="w-64"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-3  items-center justify-center">
                        <div className="mt-2 font-semibold">
                          {selectedContent[localizationKeys.address]}
                        </div>
                        <div className="col-span-2">
                          <FormikInput
                            name="address"
                            placeholder={
                              selectedContent[localizationKeys.address]
                            }
                            //  className="w-64"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 grid grid-cols-6">
                    <span className="font-semibold ">
                      {selectedContent[localizationKeys.brief]}
                    </span>
                    <div className="col-span-5">
                      <FormikTextArea
                        name="breif"
                        className=""
                        placeholder={selectedContent[localizationKeys.brief]}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-6 items-center justify-center ">
                    <span className="mt-2 font-semibold">
                      {selectedContent[localizationKeys.studentType]}
                    </span>
                    <div className="col-span-2 mb-6 grid grid-cols-2 items-center ">
                      <div className="flex items-center">
                        <FormikCheckbox
                          className="fill-primary-900 "
                          name="students_gender1"
                        />
                        <span className="mt-5 font-bold hover:cursor-pointer">
                          {selectedContent[localizationKeys.male]}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <FormikCheckbox
                          className="mt-2 ltr:ml-16 rtl:mr-16"
                          name="students_gender2"
                        />
                        <span className="mt-5 font-bold hover:cursor-pointer">
                          {selectedContent[localizationKeys.female]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="my-2 grid grid-cols-2 items-center justify-center gap-8">
                    <FileAttachment
                      setAttachmentData={setLogo}
                      label={selectedContent[localizationKeys.addLogo]}
                      name="image"
                      accept=".png,.jpg "
                      labelClassName="text-gray-400 flex py-2 cursor-pointer 
                      bg-[#F2F2F2] rounded-lg mx-2 h-56 "
                      UploadIcon={RiImage2Fill}
                      textClassName="mt-2"
                      // error={logoError}
                      dimensions="70 x 70 px"
                    />
                    <FileAttachment
                      setAttachmentData={setImage}
                      label={selectedContent[localizationKeys.addImage]}
                      name="logo"
                      accept=".png,.jpg "
                      labelClassName="text-gray-400 flex py-2 cursor-pointer 
                      bg-[#F2F2F2] rounded-lg mx-2 h-56 "
                      UploadIcon={RiImage2Fill}
                      textClassName="mt-2"
                      // error={imageError}
                      dimensions="1048 x 227 px"
                    />
                    <div></div>
                    <div className="">
                      <Button
                        primary
                        className="float-left my-2 block h-12 w-40 "
                        type="submit"
                      >
                        {selectedContent[localizationKeys.save]}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <AddEducationalOffice
        isOpen={isAddingEducationalOffice}
        onClose={() => {
          setIsAddingEducationalOffice(false);
        }}
        reload={() => setForceReload((p) => !p)}
        cities={cities}
        city={city}
      />
    </div>
  );
};

export default CompleteProfilePage;
