import { useAppMedia } from "../../hooks/use-media-query";
import TabletInstructorList from "./tablet-instructors-list";
import MobileInstructorList from "./mobile-instructors-list";
import { useEffect, useState } from "react";
import useAxios from "../../../lib/use-axios";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import toast from "react-hot-toast";

const InstructorsListView = ({
  instructors,
  loading,
  ModalAction,
  viewLimit,
  pageState: { page, setPage, totalPages },
}) => {
  const [priceData, setPriceData] = useState();
  const { run: getrun, isLoading: getisLoading } = useAxios();
  useEffect(() => {
    getrun(authAxios.get(api.sendPaymentPrices))
      .then(({ data }) => {
        setPriceData(data?.data);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  }, [getrun]);

  const { isTablet } = useAppMedia();
  if (isTablet) {
    return (
      <MobileInstructorList
        priceData={priceData?.profileViewPrice}
        instructors={instructors}
        loading={loading}
        ModalAction={ModalAction}
        viewLimit={viewLimit}
      />
    );
  } else {
    return (
      <TabletInstructorList
        priceData={priceData?.profileViewPrice}
        instructors={instructors}
        loading={loading}
        ModalAction={ModalAction}
        pageState={{ page, setPage, totalPages }}
        viewLimit={viewLimit}
      />
    );
  }
};
export default InstructorsListView;
