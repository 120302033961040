import React from "react";
import { Formik } from "formik";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import content from "../../localization/content";
import * as Yup from "yup";
import localizationKeys from "../../localization/localization-keys";
import toast from "react-hot-toast";
import axios from "axios";
import api from "../../api";
import { Button, Form } from "semantic-ui-react";
import FormikInput from "../../components/formik/formik-input";
import loginImage from "../../assets/pictures/login.png";
import ForgetPasswordIcon from "../../assets/icons/forget-password-icon";
import AuthNavbarComponent from "../../components/shared/auth-navbar-component";

const ForgotPasswordPage = () => {
  const [lang] = useLanguage();
  const { run, isLoading } = useAxios();
  const selectedContent = content[lang];

  const forgotPasswordSchema = Yup.object({
    email: Yup.string()
      .required(selectedContent[localizationKeys.required])
      .email(selectedContent[localizationKeys.emailError]),
  });

  const onPasswordChange = (values) => {
    run(axios.post(api.auth.forgotPassword, values))
      .then(() => {
        toast.success(selectedContent[localizationKeys.sendSuccessfully]);
      })
      .catch((e) => toast.error(e.errors[0]?.message));
  };

  return (
    <div>
      <AuthNavbarComponent />
      <div
        className="flex h-screen w-screen items-center justify-center bg-slate-50"
        style={{
          backgroundImage: `url(${loginImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="mt-20 max-w-sm flex-grow justify-center rounded-lg bg-white px-4 py-8">
          {/* <div className="text-center text-primary">
            {selectedContent[localizationKeys.recoverPassword]}
          </div> */}
          <div className="flex items-center justify-center ">
            <ForgetPasswordIcon size="200" className="h-[13rem] w-[20rem]" />
          </div>
          <div className="my-2 text-sm text-gray-400">
            {selectedContent[localizationKeys.checkEmailResetLink]}
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={forgotPasswordSchema}
            onSubmit={onPasswordChange}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit} loading={isLoading}>
                <FormikInput
                  name="email"
                  placeholder={selectedContent[localizationKeys.email]}
                />
                <Button
                  primary
                  className="my-4 block h-12 w-full text-sm"
                  type="submit"
                >
                  {selectedContent[localizationKeys.send]}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
