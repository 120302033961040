import { useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useFilter from "../../lib/use-filter";
import { instructorAPI } from "../../services/instructor-list";
import {
  actionReducers,
  initialState,
  reducer,
} from "./instructors-list-reducer";
/* components */
import BuyToViewModal from "../../components/instructors/buy-to-view-modal";
import InstructorsListView from "../../components/instructors/instructors-list";
import SubjectFilterModal from "../../components/instructors/subject-filter-modal/subject-filter-modal";
import InstructorsListFilters from "./instructors-list-filters";
import InstructorsListStatus from "./instructors-list-status";
import ErrorBoundary from "../../components/error-boundary/error-boundary";
import { template } from "lodash";

const InstructorsList = () => {
  const [
    {
      loading,
      viewLimit,
      selected,
      subjectList,
      instructors,
      totalPages,
      totalInstructors,
      modal,
      filterModal,
      isViewed,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const {
    setLoading,
    setInstructors,
    setModal,
    setSubjectList,
    setSelected,
    setFilterModal,
    setTotalPages,
    setTotalInstructors,
    setViewLimit,
    setIsViewed,
  } = actionReducers(dispatch);
  const queryString = require("query-string");
  const location = useLocation();
  const history = useHistory();
  const parsed = queryString.parse(location.search, { arrayFormat: "comma" });

  const [page, setPage] = useFilter("page", parsed.page || 1);

  const limit = 10;

  const ModalAction = {
    open: (instructor) =>
      setModal({
        ...modal,
        instructor: { ...instructor },
        open: true,
      }),
    close: () => setModal({ open: false }),
    confirm: async () => {
      setModal({ ...modal, loading: "loading" });
      instructorAPI
        .payToView(modal.instructor._id)
        .then((res) => res.data)
        .then((data) => {
          window.open(data.InvoiceURL, "_blank");
        })
        .finally((data) => ModalAction.close());
    },
  };

  const fetchInstructorList = async (params) => {
    setLoading("loading");
    instructorAPI
      .listInstructors(params)
      .then((data) => {
        if (Object.keys(params).includes("name") && params.name.length > 0) {
          setInstructors(data.data.filter((item) => item.viewed));
        } else {
          setInstructors(data.data);
        }
        setTotalPages(data.totalPages);
        setViewLimit(data.instructorViewsLimit);
        setTotalInstructors(data.totalLength);
        setLoading("done");
      })
      .finally(() => {
        setLoading("done");
      });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search, { arrayFormat: "comma" });
    const params = {
      ...parsed,
      limit,
      isViewed: isViewed === "all" ? null : isViewed === "isViewed",
    };
    fetchInstructorList(params);
  }, [location.search, isViewed]);

  useEffect(() => {
    if (filterModal.open) return;
    if (selected.length < 1) {
      history.replace({ search: `` });
    } else {
      history.replace({
        search: `?subject${selected.length === 1 ? "[]" : ""}=${selected.join(
          ","
        )}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  return (
    <div className="px-8">
      <InstructorsListFilters
        filterState={{
          selected,
          filterModal,
          setFilterModal,
          isViewed,
          setIsViewed,
        }}
      />
      <InstructorsListStatus
        totalInstructors={totalInstructors}
        subjectsList={subjectList}
        states={{ setSelected, selected, limit: viewLimit }}
      />
      <InstructorsListView
        instructors={instructors}
        loading={loading}
        ModalAction={ModalAction}
        pageState={{ page, setPage, totalPages }}
        viewLimit={viewLimit}
      />
      <BuyToViewModal modal={modal} ModalAction={ModalAction} />
      <ErrorBoundary fallback={() => <h1> Something went wrong</h1>}>
        <SubjectFilterModal
          states={{
            filterModal,
            setFilterModal,
            selected,
            setSelected,
            subjectList,
            setSubjectList,
          }}
        />
      </ErrorBoundary>
    </div>
  );
};

export default InstructorsList;
