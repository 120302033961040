import React from "react";
import { Loader } from "semantic-ui-react";
import api from "../../../api";

import { authAxios } from "../../../config/axios-config";
import useAxios from "../../../lib/use-axios";
import EditProfile from "./edit-profile";
import ViewProfile from "./view-profile";

const SchoolProfilePage = () => {
  const id = localStorage.getItem("id");

  const [isEditing, setIsEditing] = React.useState(false);
  const [forceReload, setForceReload] = React.useState(false);

  const {
    run: getSchool,
    data: school,
    isLoading: isGettingSchool,
  } = useAxios({});

  React.useEffect(() => {
    getSchool(authAxios.get(`${api.schools.schools}/${id}`)).then(() => {});
  }, [getSchool, id, forceReload]);

  return (
    <div className="px-8">
      {isGettingSchool ? (
        <Loader active={true} size="small" className="mt-4" />
      ) : (
        <div className=" mx-auto mb-8 h-full  rounded-2xl bg-white p-6 shadow-sm ring-1 ring-[#E3E3E3]">
          {isEditing ? (
            <EditProfile
              school={school}
              setIsEditing={setIsEditing}
              reload={() => setForceReload((p) => !p)}
            />
          ) : (
            <ViewProfile school={school} setIsEditing={setIsEditing} />
          )}
        </div>
      )}
    </div>
  );
};

export default SchoolProfilePage;
