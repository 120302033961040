import { Button, Dimmer, Loader, Modal } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { ReactComponent as EWalletPanel } from "../../assets/pictures/e-wallet-panel.svg";

const BuyToViewModal = ({ modal, ModalAction }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  return (
    <Modal
      onClose={ModalAction.close}
      onOpen={ModalAction.open}
      open={modal.open}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      className="relative min-h-[10rem] max-w-lg"
    >
      {modal.loading && modal.loading === "loading" ? (
        <Modal.Content className="mb-0 pb-0 ">
          <Dimmer inverted active>
            <Loader inverted size="medium">
              {selectedContent[localizationKeys.loading]}
            </Loader>
          </Dimmer>
        </Modal.Content>
      ) : (
        <>
          <Modal.Content className="mb-0 pb-0">
            <div className="flex flex-col items-center justify-center">
              <EWalletPanel />
              <p className="mt-4 mb-3 font-bold">
                {selectedContent[localizationKeys.notPermittedWithoutBuy]}.
              </p>
              <p className="text-primary">
                {selectedContent[localizationKeys.wantToBuy]}
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions className="my-4 flex flex-col items-center justify-around gap-1 rounded-lg">
            <Button
              content={selectedContent[localizationKeys.confirmButton]}
              color="blue"
              className="w-1/2 min-w-min"
              onClick={ModalAction.confirm}
            />
            <Button
              content={selectedContent[localizationKeys.cancelButton]}
              basic
              color="blue"
              className="w-1/2 min-w-min"
              onClick={ModalAction.close}
            />
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};
export default BuyToViewModal;
