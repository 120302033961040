import React from "react";
import { Field } from "formik";
import { Form } from "semantic-ui-react";
function FormikTextArea({ label, name, className, ...props }) {
  return (
    <>
      <label htmlFor={name} className="font-bold text-base mt-4 text-gray-600 ">
        {label}
      </label>
      <Field name={name}>
        {({ form, field }) => {
          const { errors, touched } = form;
          return (
            <>
              <Form.TextArea
                id={name}
                {...field}
                {...props}
                className={`block mt-2 text-sm ${className}`}
                error={Boolean(touched[name] && errors[name])}
              />
              {/* {touched[name] && errors[name] && (
                <ErrorMessage message={errors[name]} />
              )} */}
            </>
          );
        }}
      </Field>
    </>
  );
}
export default FormikTextArea;
