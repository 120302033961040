import React, { useEffect } from "react";
import mask from "../../assets/pictures/landingPage-mask.svg";
import maskLtr from "../../assets/pictures/landingPage-mask-ltr.svg";
import AuthNavbarComponent from "../../components/shared/auth-navbar-component";
import { useLanguage } from "../../lib/language-context";
import Services from "./services";
import ContactUs from "./contactUs";
import AboutUs from "./aboutUs";
import FooterComponent from "../../components/shared/footer";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import landingPagePicture from "../../assets/pictures/landingpage-picture.svg";
import landingPagePictureltr from "../../assets/pictures/landingpage-picture-ltr.svg";
import useMediaQuery from "../../components/hooks/use-media-query";

const LandingPage = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [switchColor, setSwitchColor] = React.useState(false);
  const isSmall = useMediaQuery("(max-width: 992px)");
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener();
    };
  }, []);

  const handleScroll = (e) => {
    if (window.scrollY > 100) setSwitchColor(true);
    else setSwitchColor(false);

    // console.log(window.scrollY);
  };

  return (
    <div className="">
      <AuthNavbarComponent
        switchColor={true}
        isLandingPage={true}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <div className="relative max-w-7xl text-center text-white">
        {lang === "ar" ? (
          <img src={mask} alt="" />
        ) : (
          <img src={maskLtr} alt="" />
        )}
        {!isSmall && lang === "ar" && (
          <img
            src={landingPagePicture}
            alt=""
            className="absolute top-1/2 left-1/2 -translate-x-[46rem] 
            -translate-y-[15rem] transform"
          />
        )}
        {!isSmall && lang === "en" && (
          <img
            src={landingPagePictureltr}
            alt=""
            className="absolute top-1/2 right-1/2 translate-x-[46rem] 
            -translate-y-[15rem] transform text-white
            "
          />
        )}
        <div
          className="absolute top-1/2 -translate-y-1/2 transform text-white
         ltr:left-14 ltr:-translate-x-10 rtl:left-1/2 rtl:-translate-x-20"
        >
          <div
            className={`${
              isSmall ? "mt-4 text-sm font-bold " : "text-3xl ltr:ml-8"
            } `}
          >
            {selectedContent[localizationKeys.landingPageTitle]}!
          </div>
          <div
            className={`${
              isSmall ? "mt-2 w-72 text-sm" : "mt-8 w-[30rem] text-xl"
            } ltr:text-center rtl:text-center 
           lg:ltr:mr-8 lg:ltr:ml-8 lg:rtl:mr-16 lg:rtl:ml-16`}
          >
            {selectedContent[localizationKeys.landingPageBody]}.
          </div>
        </div>
      </div>
      <Services />
      <AboutUs />
      <ContactUs />
      <FooterComponent />
    </div>
  );
};

export default LandingPage;
