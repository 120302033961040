import classNames from "classnames";

const HeaderRow = ({ children, className }) => {
  return (
    <div
      className={classNames(
        "max-w-100 flex flex-col flex-wrap items-start gap-4 md:max-w-[80%] md:flex-row md:items-center",
        {
          [className]: !!className,
        }
      )}
    >
      {children}
    </div>
  );
};
export default HeaderRow;
