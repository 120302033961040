import React from "react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import mockup from "../../assets/pictures/mockup.svg";
import mockupltr from "../../assets/pictures/mockup-ltr.svg";
import mockupMobile from "../../assets/pictures/mockupMobile.png";
import { Button } from "semantic-ui-react";
import GooglePlayIcon from "../../assets/icons/google-play-icon";
import AppleIcon from "../../assets/icons/apple-icon";
import JobsGreenIcon from "../../assets/icons/jobs-green-icon";
import ViewJobsIcon from "../../assets/icons/view-jobs-icons";
import JobsActionsIcon from "../../assets/icons/jobs-actions-icon";
import ListIcon from "../../assets/icons/list-icon";
import useMediaQuery from "../../components/hooks/use-media-query";
import Slider from "react-slick";

const Services = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const isSmall = useMediaQuery("(max-width:768px)");

  return (
    <div id="services">
      {isSmall ? (
        <div>
          <div className="mx-auto mb-4 h-1 w-5 bg-primary-900"></div>
          <div className="mx-2 mt-4 text-center text-lg font-bold">
            {selectedContent[localizationKeys.ourServices]}
          </div>
          <div className="mx-2 text-center">
            {selectedContent[localizationKeys.ourServicesBody]}.
          </div>
          <div className="my-8 p-8">
            <Slider
              className=""
              dots={false}
              infinite
              autoplay
              arrows={false}
              slidesToShow={1}
              speed={500}
              rtl={lang === "ar"}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                  },
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 1,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                  },
                },
              ]}
              adaptiveHeight={true}
              autoplaySpeed={3000}
              centerMode={true}
              swipeToSlide={true}
              focusOnSelect={false}
            >
              <div
                className="h-[20rem] w-[15rem] rounded-2xl bg-white p-10"
                dir={lang === "ar" ? "rtl" : "ltr"}
              >
                <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gray-200">
                  <ListIcon className="fill-secondary h-10 w-11" />
                </div>
                <div className="mt-4 text-base font-bold">
                  {selectedContent[localizationKeys.service1Title]}
                </div>
                <div className="my-4 h-1 w-10 bg-secondary-300"></div>
                <div className="text-sm">
                  {selectedContent[localizationKeys.service1Body]}
                </div>
              </div>
              <div
                className="h-[20rem] w-[15rem] rounded-2xl bg-white p-10"
                dir={lang === "ar" ? "rtl" : "ltr"}
              >
                <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gray-200">
                  <JobsGreenIcon className="h-10 w-11" />
                </div>
                <div className="mt-4 text-base font-bold">
                  {selectedContent[localizationKeys.service2Title]}
                </div>
                <div className="my-4 h-1 w-10 bg-secondary-300"></div>
                <div className="text-sm">
                  {selectedContent[localizationKeys.service2Body]}
                </div>
              </div>
              <div>
                <div
                  className="h-[20rem] w-[15rem]  rounded-2xl bg-white p-10"
                  dir={lang === "ar" ? "rtl" : "ltr"}
                >
                  <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gray-200">
                    <ViewJobsIcon className="h-10 w-11" />
                  </div>
                  <div className="mt-4 text-base font-bold">
                    {selectedContent[localizationKeys.service3Title]}
                  </div>
                  <div className="my-4 h-1 w-10 bg-secondary-300"></div>
                  <div className="text-sm">
                    {selectedContent[localizationKeys.service3Body]}
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="h-[20rem] w-[15rem] rounded-2xl bg-white p-10"
                  dir={lang === "ar" ? "rtl" : "ltr"}
                >
                  <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gray-200">
                    <JobsActionsIcon className="h-10 w-11" />
                  </div>
                  <div className="mt-4 text-base font-bold">
                    {selectedContent[localizationKeys.service4Title]}
                  </div>
                  <div className="my-4 h-1 w-10 bg-secondary-300"></div>
                  <div className="text-sm">
                    {selectedContent[localizationKeys.service4Body]}
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className="mx-auto mb-4 mt-6 h-1 w-5 bg-primary-900"></div>
          <div className="mt-2 text-center text-lg font-bold">
            {selectedContent[localizationKeys.mockupTitle]}
          </div>
          <div className="mx-2 mt-2 text-center">
            {selectedContent[localizationKeys.mockupBody]}.
          </div>
          <div className="mx-2 mt-2 text-center">
            {selectedContent[localizationKeys.mockupBody2]}!
          </div>
          <img src={mockupMobile} alt="" className="mx-auto mt-4 w-72" />
          <div className="mt-4 flex justify-center">
            <Button
              primary
              className=""
              size="small"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=co.spiritude.ejad.ejad"
                )
              }
            >
              <div className="flex items-center justify-between">
                <div>
                  <span className="text-xs">
                    {selectedContent[localizationKeys.getItFrom]}
                  </span>
                  <div className="flex items-center  text-base">
                    Google play
                  </div>
                </div>
                <GooglePlayIcon size="30px" className="ltr:ml-2 rtl:mr-2" />
              </div>
            </Button>
          </div>
          <div className="mt-4 flex justify-center">
            <Button
              primary
              className=""
              size="small"
              onClick={() =>
                window.open("https://apps.apple.com/us/app/ejad/id1638155524")
              }
            >
              <div className="flex items-center justify-between">
                <div>
                  <span className="text-xs ">
                    {selectedContent[localizationKeys.downloadFrom]}
                  </span>
                  <div className="flex items-center text-base">App Store</div>
                </div>
                <AppleIcon size="30px" className="ltr:ml-8 rtl:mr-6" />
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="grid max-w-screen-xl grid-cols-3">
            <div className="-mt-16 flex items-center justify-center ltr:pl-20 rtl:pr-20">
              <div>
                <div className="mb-2 h-1 w-10 bg-primary-900"></div>
                <div className="text-2xl font-bold">
                  {" "}
                  {selectedContent[localizationKeys.ourServices]}
                </div>
                <div className="mt-4 text-lg font-medium lg:w-64">
                  {selectedContent[localizationKeys.ourServicesBody]}.
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <div className="flex items-center">
                {/* <TransitionGroup duration={300} animation='scale'> */}
                <div
                  className="mt-16 h-[24.4rem] w-[22rem] rounded-2xl bg-white p-10 shadow-lg
            transition-all duration-300 ease-in hover:-translate-y-3"
                >
                  <div className="flex h-28 w-28 items-center justify-center rounded-full bg-gray-200">
                    <ListIcon className="fill-secondary h-10 w-11" />
                  </div>
                  <div className="mt-4 text-xl font-bold">
                    {selectedContent[localizationKeys.service1Title]}
                  </div>
                  <div className="my-4 h-1 w-10 bg-secondary-300"></div>
                  <div className="text-lg lg:ltr:pr-12 lg:rtl:pl-12">
                    {selectedContent[localizationKeys.service1Body]}
                  </div>
                </div>
                <div
                  className="h-[24.4rem] w-[22rem] rounded-2xl bg-white p-10 shadow-lg transition-all duration-300
            ease-in hover:-translate-y-3 ltr:ml-16 rtl:mr-16"
                >
                  <div className="flex h-28 w-28 items-center justify-center rounded-full bg-gray-200">
                    <JobsGreenIcon className="h-10 w-11" />
                  </div>
                  <div className="mt-4 text-xl font-bold">
                    {selectedContent[localizationKeys.service2Title]}
                  </div>
                  <div className="my-4 h-1 w-10 bg-secondary-300"></div>
                  <div className="text-lg lg:ltr:pr-6 lg:rtl:pl-12">
                    {selectedContent[localizationKeys.service2Body]}
                  </div>
                </div>
              </div>
              <div className="mb-20 flex items-center">
                <div
                  className="mt-16 h-[24.4rem] w-[22rem] rounded-2xl bg-white p-10 shadow-lg
            transition-all duration-300 ease-in hover:-translate-y-3"
                >
                  <div className="flex h-28 w-28 items-center justify-center rounded-full bg-gray-200">
                    <ViewJobsIcon className="h-10 w-11" />
                  </div>
                  <div className="mt-4 text-xl font-bold">
                    {selectedContent[localizationKeys.service3Title]}
                  </div>
                  <div className="my-4 h-1 w-10 bg-secondary-300"></div>
                  <div className="text-lg lg:ltr:pr-12 lg:rtl:pl-12">
                    {selectedContent[localizationKeys.service3Body]}
                  </div>
                </div>
                <div
                  className="h-[24.4rem] w-[22rem] rounded-2xl bg-white p-10 shadow-lg transition-all duration-300
            ease-in hover:-translate-y-3 ltr:ml-16 rtl:mr-16"
                >
                  <div className="flex h-28 w-28 items-center justify-center rounded-full bg-gray-200">
                    <JobsActionsIcon className="h-10 w-11" />
                  </div>
                  <div className="mt-4 text-xl font-bold">
                    {selectedContent[localizationKeys.service4Title]}
                  </div>
                  <div className="my-4 h-1 w-10 bg-secondary-300"></div>
                  <div className="text-lg lg:ltr:pr-12 lg:rtl:pl-12">
                    {selectedContent[localizationKeys.service4Body]}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative grid grid-cols-2">
            <div className="mt-20 flex items-center ltr:pl-20 rtl:pr-20 ">
              <div>
                <div className="mb-2 h-1 w-10 bg-primary-900" />
                <div className="text-2xl font-bold">
                  {selectedContent[localizationKeys.mockupTitle]}
                </div>
                <div className="mt-6 text-xl lg:w-[24rem]">
                  {selectedContent[localizationKeys.mockupBody]}.
                </div>
                <div className="mt-6 text-xl lg:w-[24rem]">
                  {selectedContent[localizationKeys.mockupBody2]}!
                </div>
                <div className="mt-10 grid grid-cols-2 gap-8">
                  <Button
                    primary
                    size="large"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=co.spiritude.ejad.ejad"
                      )
                    }
                  >
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs">
                          {selectedContent[localizationKeys.getItFrom]}
                        </span>
                        <div className="mt-2 flex items-center">
                          Google play
                        </div>
                      </div>
                      <GooglePlayIcon
                        size="30px"
                        className="ltr:ml-2 rtl:mr-2"
                      />
                    </div>
                  </Button>
                  <Button
                    primary
                    size="large"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/ejad/id1638155524"
                      )
                    }
                  >
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs ">
                          {selectedContent[localizationKeys.downloadFrom]}
                        </span>
                        <div className="mt-2 flex items-center">App Store</div>
                      </div>
                      <AppleIcon size="30px" className="ltr:ml-8 rtl:mr-4" />
                    </div>
                  </Button>
                </div>
              </div>
            </div>

            {!isSmall && lang === "ar" ? (
              <img src={mockup} alt="" className="absolute left-0 -top-24" />
            ) : (
              !isSmall && (
                <img
                  src={mockupltr}
                  alt=""
                  className="absolute right-0 -top-24"
                />
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Services;
