import React from "react";

function BinIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16.6 18.887"
    >
      <g transform="translate(-31)">
        <g data-name="Group 22145" transform="translate(32.151 5.533)">
          <g data-name="Group 22144">
            <path
              fill="#da5959"
              d="M62.2 150l.98 11.831a1.669 1.669 0 001.653 1.522h9.03a1.669 1.669 0 001.653-1.522L76.5 150zm4.382 11.14a.553.553 0 01-.552-.519l-.553-8.927a.553.553 0 01.518-.587.545.545 0 01.587.518l.553 8.927a.554.554 0 01-.548.588zm3.32-.553a.553.553 0 01-1.107 0v-8.927a.553.553 0 111.107 0zm3.319-8.892l-.553 8.927a.553.553 0 01-1.1-.069l.553-8.927a.562.562 0 01.587-.518.553.553 0 01.518.587z"
              data-name="Path 15409"
              transform="translate(-62.205 -150)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22147" transform="translate(31)">
          <g data-name="Group 22146">
            <path
              fill="#da5959"
              d="M46.493 2.213h-3.32V1.66A1.662 1.662 0 0041.513 0h-4.426a1.662 1.662 0 00-1.66 1.66v.553h-3.32a1.107 1.107 0 100 2.213h14.386a1.107 1.107 0 000-2.213zm-4.427 0h-5.533V1.66a.554.554 0 01.553-.553h4.427a.554.554 0 01.553.553z"
              data-name="Path 15410"
              transform="translate(-31)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BinIcon;
