import React from "react";

function EmailVerificationIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 346.337 258.063"
    >
      <g data-name="New message-rafiki" transform="translate(0 -55)">
        <g transform="translate(0 55)">
          <path
            fill="#ebebeb"
            d="M0 0H346.337V0.173H0z"
            data-name="Rectangle 5192"
            transform="translate(0 226.781)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H22.941V0.173H0z"
            data-name="Rectangle 5193"
            transform="translate(72.412 231.498)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H28.226V0.173H0z"
            data-name="Rectangle 5194"
            transform="translate(118.544 237.843)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H35.382V0.173H0z"
            data-name="Rectangle 5195"
            transform="translate(36.338 235.675)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H64.516V0.173H0z"
            data-name="Rectangle 5196"
            transform="translate(247.118 232.662)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H4.385V0.173H0z"
            data-name="Rectangle 5197"
            transform="translate(236.569 232.662)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H29.252V0.173H0z"
            data-name="Rectangle 5198"
            transform="translate(192.016 235.585)"
          ></path>
          <path
            fill="#ebebeb"
            d="M175.907 250.888H42.158a3.955 3.955 0 01-3.948-3.955V58.921A3.955 3.955 0 0142.158 55h133.749a3.955 3.955 0 013.955 3.955v187.978a3.955 3.955 0 01-3.955 3.955zM42.158 55.139a3.782 3.782 0 00-3.775 3.782v188.012a3.782 3.782 0 003.775 3.782h133.749a3.789 3.789 0 003.782-3.782V58.921a3.789 3.789 0 00-3.782-3.782z"
            data-name="Path 16507"
            transform="translate(-11.743 -55)"
          ></path>
          <path
            fill="#ebebeb"
            d="M392.21 250.888H258.455a3.962 3.962 0 01-3.955-3.955V58.921A3.962 3.962 0 01258.455 55H392.21a3.955 3.955 0 013.941 3.921v188.012a3.955 3.955 0 01-3.941 3.955zM258.455 55.139a3.789 3.789 0 00-3.782 3.782v188.012a3.789 3.789 0 003.782 3.782H392.21a3.789 3.789 0 003.782-3.782V58.921a3.789 3.789 0 00-3.782-3.782z"
            data-name="Path 16508"
            transform="translate(-78.215 -55)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H139.352V55.933H0z"
            data-name="Rectangle 5199"
            transform="rotate(90 36.078 123.507)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H41.02V49.789H0z"
            data-name="Rectangle 5200"
            transform="rotate(90 32.791 123.719)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H41.02V49.789H0z"
            data-name="Rectangle 5201"
            transform="rotate(90 10.442 146.075)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H41.02V49.789H0z"
            data-name="Rectangle 5202"
            transform="rotate(90 -11.907 168.424)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H41.02V19.582H0z"
            data-name="Rectangle 5203"
            transform="rotate(90 32.795 123.722)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H41.02V19.582H0z"
            data-name="Rectangle 5204"
            transform="rotate(90 10.442 146.068)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H13.569V16.389H0z"
            data-name="Rectangle 5205"
            transform="translate(114.596 160.264)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H5.728V16.389H0z"
            data-name="Rectangle 5206"
            transform="rotate(180 57.298 88.323)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H13.569V16.389H0z"
            data-name="Rectangle 5207"
            transform="translate(136.512 160.264)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H5.728V16.389H0z"
            data-name="Rectangle 5208"
            transform="rotate(180 68.26 88.323)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H13.569V16.389H0z"
            data-name="Rectangle 5209"
            transform="translate(125.796 143.875)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H5.728V16.389H0z"
            data-name="Rectangle 5210"
            transform="rotate(180 62.898 80.132)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H41.02V19.582H0z"
            data-name="Rectangle 5211"
            transform="rotate(90 -11.907 168.424)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H139.352V15.19H0z"
            data-name="Rectangle 5212"
            transform="rotate(90 8.111 95.54)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H40.431V35.105H0z"
            data-name="Rectangle 5213"
            transform="translate(112.705 186.246)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H5.985V35.105H0z"
            data-name="Rectangle 5214"
            transform="rotate(180 56.356 110.672)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H40.431V35.105H0z"
            data-name="Rectangle 5215"
            transform="translate(112.705 96.843)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H5.985V35.105H0z"
            data-name="Rectangle 5216"
            transform="rotate(180 56.356 65.97)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H204.152V96.365H0z"
            data-name="Rectangle 5217"
            transform="rotate(90 136.225 158.855)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H204.152V0.36H0z"
            data-name="Rectangle 5218"
            transform="rotate(90 88.042 110.672)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H78.66V197.454H0z"
            data-name="Rectangle 5219"
            transform="translate(205.683 29.328)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H80.904V197.454H0z"
            data-name="Rectangle 5220"
            transform="translate(207.65 29.328)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M408.07 242.24v2.487l2.487-2.487z"
            data-name="Path 16509"
            transform="translate(-125.411 -112.544)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H185.657V69.115H0z"
            data-name="Rectangle 5221"
            transform="rotate(90 123.715 158.944)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M388.672 240.78a1.912 1.912 0 100 3.824h12.108v-3.824z"
            data-name="Path 16510"
            transform="translate(-118.862 -112.095)"
          ></path>
          <path
            fill="#fff"
            d="M330.18 291.517l21.092-185.657h14.207l-21.092 185.657z"
            data-name="Path 16511"
            transform="translate(-101.473 -70.631)"
          ></path>
          <path
            fill="#fff"
            d="M355.1 291.517l21.092-185.657h5.541l-21.092 185.657z"
            data-name="Path 16512"
            transform="translate(-109.132 -70.631)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H185.657V0.748H0z"
            data-name="Rectangle 5222"
            transform="rotate(90 123.718 158.94)"
          ></path>
          <path
            fill="#ebebeb"
            d="M404.493 240.934h-12.1a1.919 1.919 0 01-1.912-1.912 1.919 1.919 0 011.912-1.912h12.1a1.912 1.912 0 011.912 1.912 1.912 1.912 0 01-1.912 1.912z"
            data-name="Path 16513"
            transform="translate(-120.005 -110.967)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H34.003V54.091H0z"
            data-name="Rectangle 5223"
            transform="translate(44.31 46.305)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H35.153V54.091H0z"
            data-name="Rectangle 5224"
            transform="translate(45.204 46.305)"
          ></path>
          <path
            fill="#fff"
            d="M0 0H46.201V29.265H0z"
            data-name="Rectangle 5225"
            transform="rotate(-90 72.302 24.146)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H34.003V54.091H0z"
            data-name="Rectangle 5226"
            transform="translate(92.32 20.205)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H35.153V54.091H0z"
            data-name="Rectangle 5227"
            transform="translate(93.22 20.205)"
          ></path>
          <path
            fill="#fff"
            d="M0 0H46.201V29.265H0z"
            data-name="Rectangle 5228"
            transform="rotate(-90 83.26 -12.905)"
          ></path>
        </g>
        <g transform="translate(38.866 297.38)">
          <ellipse
            cx="134.302"
            cy="7.841"
            fill="#f5f5f5"
            rx="134.302"
            ry="7.841"
          ></ellipse>
        </g>
        <g transform="translate(71.725 98.917)">
          <path
            fill="#263238"
            d="M161.024 125.23c1.489 1.974 1.669 4.4.069 8.312-.693 1.607-1.593 3.179-3.6.485s-3.734-7.619-.693-8.056 4.224-.741 4.224-.741z"
            data-name="Path 16514"
            transform="translate(-119.407 -120.501)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M162.7 401.6h5.147l.222-11.921h-5.147z"
            data-name="Path 16515"
            transform="translate(-121.727 -201.773)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M107.54 388.819l4.987 1.268 5.16-10.979-4.987-1.268z"
            data-name="Path 16516"
            transform="translate(-104.775 -198.135)"
          ></path>
          <path
            fill="#263238"
            d="M111.965 393.929l-5.479-1.849a.436.436 0 00-.52.194l-2.32 4.038a.776.776 0 00.436 1.115c1.926.616 2.888.817 5.3 1.635 1.482.5 5.7 2.272 7.751 2.965s2.944-1.254 2.168-1.725c-3.5-2.078-5.777-4.3-6.643-5.8a1.288 1.288 0 00-.693-.575z"
            data-name="Path 16517"
            transform="translate(-103.548 -202.503)"
          ></path>
          <path
            fill="#263238"
            d="M166.963 406h-5.583a.436.436 0 00-.429.346l-.956 4.6a.776.776 0 00.769.914c2.023 0 4.953-.152 7.495-.152 2.985 0 3.609.159 7.1.159 2.113 0 2.646-2.133 1.759-2.327-4.052-.88-5.382-.977-8.915-3.117a2.439 2.439 0 00-1.24-.423z"
            data-name="Path 16518"
            transform="translate(-120.892 -206.789)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M168.13 170.911l7.024-2.251s7.259 16.8 9.7 17.85c3.311 1.385 5.9 3.747 10.148 4.156a7.482 7.482 0 011.427 3.56c-5.7 2.147-14.484-.132-16.9-2.369-5.539-5.084-11.399-20.946-11.399-20.946z"
            data-name="Path 16519"
            transform="translate(-123.396 -133.848)"
          ></path>
          <path
            fill="#177ba3"
            d="M165.62 164.839c-1.974 4.5 5.957 18.8 5.957 18.8l10.494-4.849a64.947 64.947 0 00-5.2-12.579c-3.299-5.756-9.083-6.318-11.251-1.372z"
            data-name="Path 16520"
            transform="translate(-122.527 -131.641)"
          ></path>
          <path
            fill="#126282"
            d="M165.62 164.839c-1.974 4.5 5.957 18.8 5.957 18.8l10.494-4.849a64.947 64.947 0 00-5.2-12.579c-3.299-5.756-9.083-6.318-11.251-1.372z"
            data-name="Path 16521"
            opacity="0.3"
            transform="translate(-122.527 -131.641)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M205.66 199.518l3.962-2.078 2.016 5.825s-1.3.575-4.994.118z"
            data-name="Path 16522"
            transform="translate(-134.93 -142.693)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M215.328 195.33l1.268 4.98-3.2 2.327-2.016-5.825z"
            data-name="Path 16523"
            transform="translate(-136.688 -142.044)"
          ></path>
          <path
            d="M168.114 389.69l-.114 6.144h-5.15l.118-6.144z"
            data-name="Path 16524"
            opacity="0.2"
            transform="translate(-121.773 -201.776)"
          ></path>
          <path
            d="M113.8 377.84l4.994 1.268-2.667 5.659-4.987-1.267z"
            data-name="Path 16525"
            opacity="0.2"
            transform="translate(-105.881 -198.135)"
          ></path>
          <path
            fill="#157399"
            d="M162.676 161.4s6.456 17.684 1.385 46.624h-27.513c-.3-4.225 1.856-26.98-2.708-46.887a70.733 70.733 0 019.185-1.164 101.345 101.345 0 0111.7 0 52.748 52.748 0 017.951 1.427z"
            data-name="Path 16526"
            transform="translate(-112.858 -131.127)"
          ></path>
          <path
            d="M140.133 167.484c.533 3.276-.3 11.083-3.207 20.171a131.878 131.878 0 00-2.466-23.108v-.042c1.808-.18 5.153-.097 5.673 2.979z"
            data-name="Path 16528"
            opacity="0.2"
            transform="translate(-113.048 -132.554)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M148.361 144.25c.831 3.768 1.309 8.43-1.261 10.944 0 0 1.607 3.817 8.6 3.817 6.393 0 3.1-3.817 3.1-3.817-4.218-1-4.156-6.193-3.463-9.116z"
            data-name="Path 16529"
            transform="translate(-116.933 -126.346)"
          ></path>
          <path
            fill="#fff"
            d="M155.171 163.187a7.342 7.342 0 00-1.254 3.616s-8.839-3.089-8.541-7.619c0 0 1.226-.741 2-1.344.003.007.377 3.574 7.795 5.347z"
            data-name="Path 16530"
            transform="translate(-116.401 -130.523)"
          ></path>
          <path
            fill="#fff"
            d="M159.52 163.559a13.854 13.854 0 011.8 3.346s3.907-2.688 2.6-7.162a15.142 15.142 0 00-2.265-.693s.601 3.55-2.135 4.509z"
            data-name="Path 16531"
            transform="translate(-120.75 -130.895)"
          ></path>
          <path
            d="M155.905 146.079a11.284 11.284 0 00.173 6.179 6.678 6.678 0 01-1.884-.575c-2.4-1.115-3.609-2.868-4.883-5.479a29.93 29.93 0 00-.381-1.953z"
            data-name="Path 16532"
            opacity="0.2"
            transform="translate(-117.495 -126.346)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M166.071 140.823a4.683 4.683 0 01-2.715 2.459c-1.6.54-2.514-.894-2.168-2.473.312-1.385 1.517-3.408 3.166-3.262a2.237 2.237 0 011.718 3.276z"
            data-name="Path 16533"
            transform="translate(-121.24 -124.284)"
          ></path>
          <path
            d="M166.071 140.823a4.683 4.683 0 01-2.715 2.459c-1.6.54-2.514-.894-2.168-2.473.312-1.385 1.517-3.408 3.166-3.262a2.237 2.237 0 011.718 3.276z"
            data-name="Path 16534"
            opacity="0.2"
            transform="translate(-121.24 -124.284)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M145.473 132.8c.942 5.063 1.24 8.077 4.1 10.425 4.308 3.54 10.231.554 10.8-4.669.513-4.7-1.108-12.191-6.283-13.708a6.816 6.816 0 00-8.617 7.952z"
            data-name="Path 16535"
            transform="translate(-116.387 -120.294)"
          ></path>
          <path
            fill="#263238"
            d="M147.227 127.884c1.164 2.175.554 3.595-1.053 7.467-.693 1.607-1.787 3.318-3.6.485-1.711-2.674-4.322-7.619-2.078-8.16a17.641 17.641 0 016.731.208z"
            data-name="Path 16536"
            transform="translate(-114.635 -121.174)"
          ></path>
          <path
            fill="#263238"
            d="M145.605 126.533a3.266 3.266 0 004.121-1.351c1.662.734 3.844.831 4.551-.111 1.385 0 2.868.249 3.533-1.732s-.159-3.761-2.334-4.766c-.048.443-1.178 1.018-2.237.284a3.54 3.54 0 00-3.837.236c-2.265-.589-3.865-.914-5.167.575-2.154-.45-4.779 1.5-4.419 3.2-1.69 2.078-.055 3.8 1.676 4.1.345.853 3.656.445 4.113-.435z"
            data-name="Path 16537"
            transform="translate(-114.475 -118.403)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M143.017 143.3a4.925 4.925 0 003.249 1.829c1.683.194 2.223-1.385 1.5-2.854-.651-1.323-2.314-3.006-3.879-2.528a2.175 2.175 0 00-.87 3.553z"
            data-name="Path 16538"
            transform="translate(-115.503 -124.936)"
          ></path>
          <path
            fill="#0c445b"
            d="M151.37 229.42s.547 6.47 1.24 15.239c.8 9.94 1.773 22.858 2.355 32.673v.139c.111 1.863.208 3.616.284 5.216.783 16.035 2.39 62.7 2.39 62.7h8.714s4.385-47.621 4.572-63.941c.173-15.7-1.475-52.027-1.475-52.027z"
            data-name="Path 16539"
            transform="translate(-118.245 -152.521)"
          ></path>
          <path
            fill="#177ba3"
            d="M159.5 392.843c-.035 0 .069 2.847.069 2.847h10.051l.346-3.2z"
            data-name="Path 16540"
            transform="translate(-120.741 -202.637)"
          ></path>
          <path
            d="M153.16 248.227c.8 9.94 1.773 22.858 2.355 32.673v.139c2.133-9.323 3-20.919 2.695-40.009z"
            data-name="Path 16541"
            opacity="0.2"
            transform="translate(-118.795 -156.089)"
          ></path>
          <path
            fill="#0c445b"
            d="M146.641 229.42s-4.849 37.183-7.723 51.951c-3.118 16.129-20.892 57.249-20.892 57.249l-9.136-2.32s13.015-37.494 14.629-53.467c1.746-17.317 5.361-53.377 5.361-53.377z"
            data-name="Path 16542"
            transform="translate(-105.19 -152.521)"
          ></path>
          <path
            fill="#177ba3"
            d="M109.219 380.21c-.035 0-1.039 2.452-1.039 2.452l10.037 2.556 1.565-2.722z"
            data-name="Path 16543"
            transform="translate(-104.972 -198.863)"
          ></path>
          <path
            fill="#263238"
            d="M156.806 136.993c.055.416.312.727.582.693s.443-.388.388-.8-.312-.693-.575-.693-.443.385-.395.8z"
            data-name="Path 16544"
            transform="translate(-119.913 -123.869)"
          ></path>
          <path
            fill="#263238"
            d="M163.5 136.292c.048.416.3.693.575.693s.443-.388.388-.8-.3-.727-.575-.693-.448.385-.388.8z"
            data-name="Path 16545"
            transform="translate(-121.968 -123.653)"
          ></path>
          <path
            fill="#ed847e"
            d="M161.41 137.17a15.239 15.239 0 002.4 3.346 2.32 2.32 0 01-1.912.575z"
            data-name="Path 16546"
            transform="translate(-121.331 -124.17)"
          ></path>
          <path
            fill="#263238"
            d="M159.255 143.966a.132.132 0 00.1-.139.125.125 0 00-.139-.1 3.3 3.3 0 01-2.833-.928.132.132 0 00-.173 0 .125.125 0 000 .173 3.5 3.5 0 003.045.994z"
            data-name="Path 16547"
            transform="translate(-119.723 -125.889)"
          ></path>
          <path
            fill="#263238"
            d="M154.409 134.669a.256.256 0 00.18-.111 1.87 1.87 0 011.441-.977.242.242 0 00.229-.256.249.249 0 00-.256-.236 2.327 2.327 0 00-1.836 1.212.249.249 0 00.09.332.208.208 0 00.152.036z"
            data-name="Path 16548"
            transform="translate(-119.095 -122.916)"
          ></path>
          <path
            fill="#263238"
            d="M165.293 133.285a.242.242 0 00.1-.042.236.236 0 00.048-.339 2.452 2.452 0 00-1.96-1.074.236.236 0 00-.229.256.249.249 0 00.256.236 1.946 1.946 0 011.545.866.256.256 0 00.242.1z"
            data-name="Path 16549"
            transform="translate(-121.896 -122.529)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M126.6 172.83l6.629 3.553s-7.55 16.354-6.567 18.785c1.337 3.311 3.463 10.626 5.749 14.228a7.377 7.377 0 01-1.995 3.193c-5.285-3.256-11.367-14.359-11.478-17.594-.25-7.369 7.662-22.165 7.662-22.165z"
            data-name="Path 16550"
            transform="translate(-108.276 -135.13)"
          ></path>
          <path
            fill="#177ba3"
            d="M129.4 161.688c-4.849.693-10.016 17.58-10.016 17.58l10.39 5.777a121.508 121.508 0 006.38-13.985c2.146-6.241-1.554-10.134-6.754-9.372z"
            data-name="Path 16551"
            transform="translate(-108.414 -131.676)"
          ></path>
          <path
            fill="#126282"
            d="M129.4 161.688c-4.849.693-10.016 17.58-10.016 17.58l10.39 5.777a121.508 121.508 0 006.38-13.985c2.146-6.241-1.554-10.134-6.754-9.372z"
            data-name="Path 16552"
            opacity="0.3"
            transform="translate(-108.414 -131.676)"
          ></path>
          <path
            fill="#177ba3"
            d="M117.071 396.316a1.531 1.531 0 001.011.069.734.734 0 00.436-.54.4.4 0 00-.1-.395c-.45-.457-2.327 0-2.542 0a.118.118 0 00-.09.1.132.132 0 00.062.125 5.882 5.882 0 001.223.641zm1.039-.769a.243.243 0 01.139.076.152.152 0 010 .159.506.506 0 01-.291.374 2.48 2.48 0 01-1.759-.5 4.641 4.641 0 011.911-.109z"
            data-name="Path 16553"
            transform="translate(-107.31 -203.484)"
          ></path>
          <path
            fill="#177ba3"
            d="M115.869 395.076h.062c.589-.111 1.9-.852 1.967-1.441a.409.409 0 00-.27-.429.693.693 0 00-.54 0c-.693.277-1.268 1.656-1.3 1.711a.132.132 0 000 .125.111.111 0 00.076.035zm1.607-1.662h.048c.145.069.139.145.132.18 0 .339-.914.949-1.531 1.157.187-.395.6-1.164 1.053-1.344a.443.443 0 01.298.006z"
            data-name="Path 16554"
            transform="translate(-107.307 -202.84)"
          ></path>
          <path
            fill="#177ba3"
            d="M172.424 406.742a1.933 1.933 0 001.24-.312.693.693 0 00.215-.6.409.409 0 00-.208-.326c-.637-.346-2.681.693-2.909.824a.118.118 0 00-.062.125.125.125 0 00.1.1 9.277 9.277 0 001.624.189zm.9-1.06a.52.52 0 01.229.042.159.159 0 01.083.132.45.45 0 01-.139.395 3.323 3.323 0 01-2.3.139 6.608 6.608 0 012.127-.708z"
            data-name="Path 16555"
            transform="translate(-124.185 -206.616)"
          ></path>
          <path
            fill="#177ba3"
            d="M170.83 406.029h.083c.616-.284 1.815-1.385 1.711-1.974 0-.139-.132-.312-.464-.339a.838.838 0 00-.693.208 3.326 3.326 0 00-.748 1.981.118.118 0 00.048.111.16.16 0 00.063.013zm1.205-2.078h.069c.222 0 .236.1.242.132.062.353-.748 1.226-1.385 1.607a2.771 2.771 0 01.637-1.586.644.644 0 01.436-.159z"
            data-name="Path 16556"
            transform="translate(-124.191 -206.084)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M135.678 224.66l4.849.5-3.373 4.89s-4.253-.575-4.454-3.941z"
            data-name="Path 16557"
            transform="translate(-112.507 -151.058)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M145.323 227.271l-3.269 4.274-2.854-1.275 3.373-4.89z"
            data-name="Path 16558"
            transform="translate(-114.505 -151.28)"
          ></path>
        </g>
        <g transform="translate(146.667 65.556)">
          <path
            fill="#177ba3"
            d="M346.741 220.837L351.59 277c.388 4.572-2.4 8.582-6.234 8.908l-106.575 9.142c-3.81.332-7.252-3.145-7.619-7.709l-4.849-56.169a9.434 9.434 0 01.242-3.145c.762-3.138 3.075-5.541 5.971-5.756L339.1 213.12c2.888-.249 5.541 1.7 6.864 4.648a9.614 9.614 0 01.776 3.069z"
            data-name="Path 16559"
            transform="translate(-216.207 -114.144)"
          ></path>
          <path
            d="M346.741 220.837L351.59 277c.388 4.572-2.4 8.582-6.234 8.908l-106.575 9.142c-3.81.332-7.252-3.145-7.619-7.709l-4.849-56.169a9.434 9.434 0 01.242-3.145c.762-3.138 3.075-5.541 5.971-5.756L339.1 213.12c2.888-.249 5.541 1.7 6.864 4.648a9.614 9.614 0 01.776 3.069z"
            data-name="Path 16560"
            opacity="0.1"
            transform="translate(-216.207 -114.144)"
          ></path>
          <path
            fill="#177ba3"
            d="M0 0H0.762V2.549H0z"
            data-name="Rectangle 5229"
            transform="rotate(-6.89 1233.133 -1113.044)"
          ></path>
          <path
            fill="#177ba3"
            d="M0 0H0.762V9.497H0z"
            data-name="Rectangle 5230"
            transform="rotate(-6.89 1130.916 -1106.91)"
          ></path>
          <path
            fill="#177ba3"
            d="M352.248 206.352l6.968 57.6c.561 4.682-2.168 8.908-6.075 9.379l-109.3 13.223c-3.907.471-7.571-2.979-8.139-7.668l-6.968-57.6a9.933 9.933 0 01.139-3.249c.693-3.249 2.965-5.777 5.936-6.137l109.3-13.216c2.972-.36 5.8 1.545 7.232 4.537a9.913 9.913 0 01.907 3.131z"
            data-name="Path 16561"
            transform="translate(-216.941 -109.703)"
          ></path>
          <path
            d="M351.444 203.228l-49.575 33.38a18.314 18.314 0 01-16.763 2.03L229 218.044c.693-3.249 2.965-5.777 5.936-6.137l109.3-13.216c2.948-.361 5.774 1.544 7.208 4.537z"
            data-name="Path 16562"
            opacity="0.1"
            transform="translate(-217.044 -109.703)"
          ></path>
          <path
            fill="#177ba3"
            d="M340.325 205.586l-14.241 9.587-4.662 3.145-12.177 8.2a18.245 18.245 0 01-16.354 2.175l-.436-.145-6.83-2.507-15.377-5.641-7.19-2.639-8.146-2.992-.589-14.4-1.323-32 62.569-2.542 23.849 18.072.249 6.144z"
            data-name="Path 16563"
            transform="translate(-224.42 -99.614)"
          ></path>
          <path
            fill="#fafafa"
            d="M340.325 205.586l-14.241 9.587-4.662 3.145-12.177 8.2a18.245 18.245 0 01-16.354 2.175l-.436-.145-6.83-2.507-15.377-5.641-7.19-2.639-8.146-2.992-.589-14.4-1.323-32 62.569-2.542 23.849 18.072.249 6.144z"
            data-name="Path 16564"
            opacity="0.7"
            transform="translate(-224.42 -99.614)"
          ></path>
          <g
            fill="#fff"
            data-name="Group 22293"
            opacity="0.9"
            transform="translate(36.281 87.726)"
          >
            <path
              d="M334.925 199.806l-69.267 2.812a1.482 1.482 0 01-1.538-1.385 1.482 1.482 0 011.38-1.533l69.267-2.812a1.482 1.482 0 011.538 1.385 1.475 1.475 0 01-1.38 1.533z"
              data-name="Path 16565"
              transform="translate(-264.119 -196.888)"
            ></path>
            <path
              d="M335.455 213.093l-69.268 2.807a1.482 1.482 0 01-1.538-1.385 1.489 1.489 0 011.385-1.538l69.266-2.807a1.489 1.489 0 011.538 1.385 1.5 1.5 0 01-1.383 1.538z"
              data-name="Path 16566"
              transform="translate(-264.282 -200.97)"
            ></path>
            <path
              d="M335.995 226.376l-69.267 2.812a1.482 1.482 0 01-1.538-1.385 1.475 1.475 0 011.385-1.531l69.267-2.812a1.475 1.475 0 011.531 1.385 1.475 1.475 0 01-1.378 1.531z"
              data-name="Path 16567"
              transform="translate(-264.447 -205.054)"
            ></path>
            <path
              d="M330.546 237.07l-4.662 3.145-51.175 2.078-7.19-2.639a.757.757 0 01.18 0z"
              data-name="Path 16568"
              transform="translate(-265.164 -209.237)"
            ></path>
            <path
              d="M321.613 252.993v.055a1.482 1.482 0 01-1.385 1.482l-12.821.52-.436-.145-6.83-2.507 19.97-.817a1.482 1.482 0 011.5 1.413z"
              data-name="Path 16569"
              transform="translate(-275.189 -213.696)"
            ></path>
          </g>
          <path
            fill="#263238"
            d="M367.219 183.892l-16.569-.713-7.28-17.358z"
            data-name="Path 16570"
            opacity="0.1"
            transform="translate(-252.193 -99.614)"
          ></path>
          <path
            fill="#177ba3"
            d="M367.219 183.892l-16.077-2.75-7.772-15.322z"
            data-name="Path 16571"
            transform="translate(-252.193 -99.614)"
          ></path>
          <path
            fill="#177ba3"
            d="M408.056 87.342c0 14.089-15.44 27.936-17.753 27.936s-17.753-13.854-17.753-27.936c0-9.441 7.945-17.1 17.753-17.1s17.753 7.658 17.753 17.1z"
            data-name="Path 16572"
            transform="translate(-261.161 -70.24)"
          ></path>
          <path
            fill="#fff"
            d="M388.563 85.008c-3.214 3.526-7.578 4.786-9.746 2.771s-1.323-6.435 1.884-9.961 7.578-4.786 9.753-2.812 1.323 6.476-1.891 10.002z"
            data-name="Path 16573"
            opacity="0.1"
            transform="translate(-262.718 -71.418)"
          ></path>
          <path
            fill="#fff"
            d="M379.636 109.3c-.423-1.053-.312-2.078.249-2.314s1.385.443 1.78 1.5.312 2.078-.249 2.307-1.357-.444-1.78-1.493z"
            data-name="Path 16574"
            opacity="0.1"
            transform="translate(-263.26 -81.52)"
          ></path>
          <path
            fill="#263238"
            d="M396.953 179.38a.291.291 0 01-.284-.284 4.336 4.336 0 01.8-2.611 4.108 4.108 0 000-4.627 4.648 4.648 0 010-5.223 4.094 4.094 0 000-4.62 4.634 4.634 0 010-5.216 4.094 4.094 0 000-4.62 4.634 4.634 0 010-5.216 4.094 4.094 0 000-4.62 4.634 4.634 0 010-5.216 3.81 3.81 0 00.693-2.307.291.291 0 01.575 0 4.33 4.33 0 01-.8 2.6 3.851 3.851 0 00-.693 2.314 3.809 3.809 0 00.693 2.307 4.634 4.634 0 010 5.216 4.066 4.066 0 000 4.62 4.634 4.634 0 010 5.216 4.066 4.066 0 000 4.62 4.634 4.634 0 010 5.216 4.073 4.073 0 000 4.627 4.648 4.648 0 010 5.223 3.817 3.817 0 00-.693 2.314.284.284 0 01-.291.284z"
            data-name="Path 16575"
            transform="translate(-268.573 -90.012)"
          ></path>
          <path
            fill="#177ba3"
            d="M396.336 134.3c-.547 0-4.156 3.256-4.156 6.567h8.326c0-3.311-3.606-6.567-4.17-6.567z"
            data-name="Path 16576"
            transform="translate(-267.194 -89.927)"
          ></path>
          <rect
            width="2.12"
            height="0.658"
            fill="#263238"
            data-name="Rectangle 5231"
            rx="0.329"
            transform="translate(128.082 44.484)"
          ></rect>
          <path
            fill="#0c445b"
            d="M247.246 106.349c0 14.082-15.44 27.929-17.753 27.929s-17.753-13.853-17.753-27.929c0-9.448 7.945-17.109 17.753-17.109s17.753 7.66 17.753 17.109z"
            data-name="Path 16577"
            transform="translate(-211.74 -76.079)"
          ></path>
          <ellipse
            cx="8.638"
            cy="5.313"
            fill="#fff"
            data-name="Ellipse 175"
            opacity="0.1"
            rx="8.638"
            ry="5.313"
            transform="rotate(-47.68 30.67 11.923)"
          ></ellipse>
          <path
            fill="#fff"
            d="M219.426 129.133c-.423-1.053-.312-2.078.249-2.314s1.385.443 1.78 1.5.312 2.078-.249 2.314-1.357-.447-1.78-1.5z"
            data-name="Path 16578"
            opacity="0.1"
            transform="translate(-214.023 -87.614)"
          ></path>
          <path
            fill="#177ba3"
            d="M236.1 198.364a.284.284 0 01-.284-.284 4.336 4.336 0 01.8-2.611 4.108 4.108 0 000-4.627 4.648 4.648 0 010-5.223 3.81 3.81 0 00.693-2.307 3.851 3.851 0 00-.693-2.314 4.634 4.634 0 010-5.216 3.81 3.81 0 00.693-2.307 3.851 3.851 0 00-.693-2.314 4.634 4.634 0 010-5.216 4.087 4.087 0 000-4.613 4.634 4.634 0 010-5.216 3.851 3.851 0 00.693-2.314.291.291 0 01.284-.284.284.284 0 01.284.284 4.35 4.35 0 01-.8 2.611 4.052 4.052 0 000 4.613 4.669 4.669 0 010 5.216 3.851 3.851 0 00-.693 2.314 3.81 3.81 0 00.693 2.307 4.669 4.669 0 010 5.216 3.851 3.851 0 00-.693 2.314 3.81 3.81 0 00.693 2.307 4.669 4.669 0 010 5.216 4.073 4.073 0 000 4.627 4.676 4.676 0 010 5.223 3.838 3.838 0 00-.693 2.314.284.284 0 01-.291.284z"
            data-name="Path 16579"
            transform="translate(-219.14 -95.834)"
          ></path>
          <path
            fill="#0c445b"
            d="M235.486 153.31c-.547 0-4.156 3.249-4.156 6.56h8.312c.014-3.311-3.616-6.56-4.156-6.56z"
            data-name="Path 16580"
            transform="translate(-217.761 -95.77)"
          ></path>
          <rect
            width="2.12"
            height="0.658"
            fill="#177ba3"
            data-name="Rectangle 5232"
            rx="0.329"
            transform="translate(16.666 57.644)"
          ></rect>
          <circle
            cx="10.314"
            cy="10.314"
            r="10.314"
            fill="#de5753"
            data-name="Ellipse 176"
            transform="rotate(-42.56 179.29 -103.457)"
          ></circle>
          <path
            fill="#fff"
            d="M402.343 199.67v10.39h-2.868v-6.8a8.879 8.879 0 01-1.351.859 11.036 11.036 0 01-1.635.623v-2.32a6.878 6.878 0 002.251-1.122 4.641 4.641 0 001.254-1.614z"
            data-name="Path 16581"
            transform="translate(-268.519 -110.017)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default EmailVerificationIcon;
