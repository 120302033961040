import classNames from "classnames";

const TagSpan = ({ children, className }) => {
  return (
    <div
      className={classNames(
        "flex h-7 min-w-[26px] items-center gap-x-2 whitespace-nowrap rounded-lg px-2",
        {
          [className]: !!className,
        }
      )}
    >
      {children}
    </div>
  );
};
export default TagSpan;
