const routes = {
  landingPage: "/",
  auth: {
    base: "/app/auth",
    signup: "/app/auth/signup",
    login: "/app/auth/login",
    forgotPassword: "/app/auth/forgotPassword",
    resetPassword: "/app/resetPassword",
    resendEmail: "/app/resendEmail",
    completeProfile: "/app/completeProfile",
    privacyPolicy: "/app/auth/privacy-policy",
    contactUs: "/app/auth/contact -us",
  },
  navbar: "/app/navbar",
  home: "/app/home",
  settings: "/app/settings",
  completeProfile: {
    home: (id = ":id") => `/app/completeProfile/home/${id}`,
    form: (id = ":id") => `/app/completeProfile/form/${id}`,
  },
  employmentApplications: {
    all: "/app/employmentApplications/all",
    job: (id = ":id", instructor = ":instructor") =>
      `/app/employmentApplications/job/${id}/${instructor}`,
  },
  jobs: {
    active: "/app/jobs/active",
    drafted: "/app/jobs/drafted",
    closed: "/app/jobs/closed",
  },
  instructor: {
    all: "/app/instructors",
    profile: (application = ":application") => `/app/instructor/${application}`,
    profilePage: "/app/instructor-page/:instructorId",
  },
  payment: {
    success: "/app/payment/success",
    failed: "/app/payment/failed",
  },
  subscribtions: {
    all: "/app/subscribtions",
  },
  notifications: {
    all: "/app/notifications",
  },
};
export default routes;
