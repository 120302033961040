import { Link, useHistory } from "react-router-dom";
import { Sidebar, Image, Button, Dropdown } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import logoOnBlueBackground from "../../assets/pictures/logo-with-blue-background.svg";
import logo from "../../assets/pictures/logo.svg";
import routes from "../../routes";
import { BsArrowLeft } from "react-icons/bs";
import { Link as ScrollLink } from "react-scroll";
import { useAppMedia } from "../hooks/use-media-query";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineCancel } from "react-icons/md";

const AuthNavbarComponent = ({
  switchColor,
  isLandingPage,
  isVisible,
  setIsVisible,
}) => {
  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];

  const { isLargeScreen, isLaptop } = useAppMedia();
  const history = useHistory();

  return (
    <div>
      <div className="flex h-0 w-full">
        {isLaptop && isLandingPage ? (
          !isVisible ? (
            <Sidebar
              direction="top"
              visible={true}
              className={`fixed flex w-full items-center justify-between
          py-4 shadow-none drop-shadow-none transition-colors duration-300 xl:z-20
          ${
            switchColor || isLargeScreen
              ? "bg-white text-primary-900 shadow-lg"
              : "bg-transparent"
          }
          `}
            >
              <GiHamburgerMenu
                size={25}
                className={`mx-2 rounded-lg hover:cursor-pointer ${
                  switchColor ? "fill-primary-900" : "fill-white"
                }`}
                onClick={() => setIsVisible(!isVisible)}
              />
              {switchColor ? (
                <Image src={logo} className="h-8 w-28" />
              ) : (
                <Image src={logoOnBlueBackground} className="h-8 w-28" />
              )}
              <div></div>
            </Sidebar>
          ) : (
            <Sidebar
              className="rounded-tl-3x w-64 overflow-hidden bg-primary-900 shadow-none ltr:pl-4 rtl:pr-4"
              visible={!isLaptop || isVisible}
              animation="overlay"
            >
              <MdOutlineCancel
                className="mt-8 mb-8 cursor-pointer fill-white"
                size={20}
                onClick={() => setIsVisible(false)}
              />
              <div>
                <ScrollLink
                  to="services"
                  spy={true}
                  className={`my-12
                   cursor-pointer
                  text-white`}
                  offset={-100}
                  duration={500}
                  smooth={true}
                  onClick={() => setIsVisible(false)}
                >
                  {selectedContent[localizationKeys.services]}
                </ScrollLink>
              </div>
              <div className="my-4">
                <ScrollLink
                  to="about-us"
                  spy={true}
                  className={`cursor-pointer text-white`}
                  offset={-100}
                  duration={500}
                  smooth={true}
                  onClick={() => setIsVisible(false)}
                >
                  {selectedContent[localizationKeys.aboutEjad]}
                </ScrollLink>
              </div>
              {/* <div
                className="my-4 text-white cursor-pointer"
                onClick={() => history.push(routes.auth.contactUs)}
              >
                {selectedContent[localizationKeys.contactUs]}
              </div> */}
              <div className="my-4">
                <ScrollLink
                  to="contact-us"
                  spy={true}
                  className={`cursor-pointer text-white`}
                  offset={-100}
                  duration={500}
                  smooth={true}
                  onClick={() => setIsVisible(false)}
                >
                  {selectedContent[localizationKeys.contactUs]}
                </ScrollLink>
              </div>
              <div className="flex items-center justify-between ">
                <div className="text-white ltr:mr-4 rtl:ml-4 ">
                  {selectedContent[localizationKeys.language]}
                </div>
                <Dropdown
                  className="mx-8 text-white"
                  text={
                    lang === "ar"
                      ? selectedContent[localizationKeys.arabic]
                      : selectedContent[localizationKeys.english]
                  }
                >
                  <Dropdown.Menu>
                    {lang === "ar" ? (
                      <Dropdown.Item
                        text={"English"}
                        onClick={() => setLang("en")}
                      />
                    ) : (
                      <Dropdown.Item
                        text={"العربية"}
                        onClick={() => setLang("ar")}
                      />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="my-4 cursor-pointer text-white"
                onClick={() => history.push(routes.auth.login)}
              >
                {selectedContent[localizationKeys.loginButton]}
              </div>
            </Sidebar>
          )
        ) : (
          <Sidebar
            direction="top"
            visible={true}
            className={`" fixed flex w-full items-center justify-between px-5 py-4 
          shadow-none drop-shadow-none transition-colors duration-300 lg:pl-4 xl:z-20 xl:px-8
          ${
            switchColor || isLargeScreen
              ? "bg-white text-primary-900 shadow-lg"
              : "bg-transparent"
          }
          `}
          >
            {switchColor || isLargeScreen ? (
              <Image src={logo} className="h-14 w-28" />
            ) : (
              <Image src={logoOnBlueBackground} className="h-14 w-28" />
            )}
            {isLandingPage && (
              <div
                className={`flex items-center gap-20  ${
                  switchColor || isLargeScreen
                    ? " text-primary-900"
                    : "text-white"
                } `}
              >
                <ScrollLink
                  to="services"
                  spy={true}
                  className={`hover:${
                    switchColor ? "text-primary-900" : "text-white"
                  } cursor-pointer`}
                  offset={-100}
                  duration={500}
                  smooth={true}
                >
                  {selectedContent[localizationKeys.services]}
                </ScrollLink>
                <ScrollLink
                  to="about-us"
                  spy={true}
                  className={`hover:${
                    switchColor ? "text-primary-900" : ""
                  } cursor-pointer`}
                  offset={-100}
                  duration={500}
                  smooth={true}
                >
                  {selectedContent[localizationKeys.aboutEjad]}
                </ScrollLink>
                <ScrollLink
                  to="contact-us"
                  spy={true}
                  className={`hover:${
                    switchColor ? "text-primary-900" : ""
                  } cursor-pointer`}
                  offset={-100}
                  duration={500}
                  smooth={true}
                >
                  {selectedContent[localizationKeys.contactUs]}
                </ScrollLink>
                {/* <section id="services" className="cursor-pointer">
                {selectedContent[localizationKeys.services]}
              </section>
              <section id="aboutUs" className="cursor-pointer">
                {selectedContent[localizationKeys.aboutEjad]}
              </section>
              <section id="contactUs" className="cursor-pointer">
                {selectedContent[localizationKeys.contactUs]}
              </section> */}
              </div>
            )}
            <div className="flex items-center">
              {/* <Link
              to={routes.auth.signup}
              className={`flex items-center gap-4 px-8 py-4  rounded-3xl h-10 rtl:ml-4 ltr:mr-4 ${
                location.pathname.startsWith(routes.auth.signup)
                  ? "bg-primary-900 text-white hover:text-white"
                  : "bg-white ring-1 ring-primary-900 text-primary-900 "
              }
              }`}
            >
              <p className=" font-semibold text-lg">
                {selectedContent[localizationKeys.registration]}
              </p>
            </Link> */}
              <Link
                to={routes.auth.login}
                className={`flex h-10 items-center gap-4  rounded-md bg-primary-900 px-8 py-4 text-white hover:text-white ltr:mr-4
              rtl:ml-4`}
              >
                <p className=" flex items-center">
                  {selectedContent[localizationKeys.loginButton]}
                  <BsArrowLeft className="mx-2 ltr:rotate-180" />
                </p>
              </Link>
              {lang === "ar" && (
                <Button
                  onClick={() => setLang("en")}
                  className="flex h-10 w-4
                items-center justify-center rounded-md bg-white
                 text-sm text-primary-900 ring-1 ring-primary-900 xl:ltr:ml-6 xl:rtl:mr-6"
                >
                  EN
                </Button>
              )}
              {lang === "en" && (
                <Button
                  circular
                  size="tiny"
                  primary
                  onClick={() => setLang("ar")}
                  className="flex h-10 w-4 
                items-center justify-center rounded-md bg-white 
                text-sm text-primary-900 ring-1 ring-primary-900 ltr:ml-6 rtl:mr-6"
                >
                  AR
                </Button>
              )}
            </div>
          </Sidebar>
        )}
        <div className="mt-20 w-full lg:ltr:ml-80 lg:ltr:mr-14 lg:rtl:mr-80 lg:rtl:ml-14"></div>
      </div>
    </div>
  );
};

export default AuthNavbarComponent;
