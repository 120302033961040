import React from "react";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import { Loader, Message } from "semantic-ui-react";
import api from "../../api";
import JobsNotFoundIcon from "../../assets/icons/jobs-not-found-icon";
import PeopleIcon from "../../assets/icons/people-icon";
import PaginationComponent from "../../components/shared/pagination";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import useFilter from "../../lib/use-filter";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import routes from "../../routes";
import { truncateString } from "../../utils/string";

const AllEmploymentApplicationsPage = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const id = localStorage.getItem("id");

  const history = useHistory();

  const [limit] = React.useState("12");
  const [totalPages, setTotalPages] = React.useState(0);
  const [vacancies, setVacancies] = React.useState([]);
  const [page, setPage] = useFilter("page", 1);

  const { run: getVacancies, isLoading: isGettingVacancies } = useAxios({});

  const location = useLocation();
  const queryString = require("query-string");
  const parsed = queryString.parse(location.search);

  React.useEffect(() => {
    if (page < 1) setPage(1);
    if (page && page !== "undefined")
      getVacancies(
        authAxios.get(
          `${api.vacancies.all(id)}&limit=${limit}&page=${
            parsed?.page
          }&status[]=active&status[]=archived`
        )
      )
        .then(({ data }) => {
          setTotalPages(data?.totalPages);
          // if (page > data?.totalPages) setPage(1);
          if (page > data?.totalPages && data?.totalPages !== 0) setPage(1);
          setVacancies(data);
        })
        .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVacancies, limit, parsed?.page]);

  return (
    <div className="px-8">
      <div className="mb-8 flex items-center justify-end gap-8 ">
        <div className="flex items-center gap-2">
          <div className="h-6 w-6 rounded-lg bg-[#E4F5DF] ring-1 ring-[#34C00A8C]" />
          <span>{selectedContent[localizationKeys.active]}</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="h-6 w-6 rounded-lg bg-[#F9E3E3] ring-1 ring-[#DB00008C]" />
          <span>{selectedContent[localizationKeys.closed]}</span>
        </div>
      </div>
      {!window.navigator.onLine && (
        <Message
          error
          className="mb-8"
          content={selectedContent[localizationKeys.noInternet]}
        />
      )}
      {isGettingVacancies ? (
        <Loader active={true} size="small" className="mt-8" />
      ) : vacancies?.data?.length > 0 ? (
        <div className="relative mb-16 min-h-[32rem] rounded-xl bg-white p-4 px-14 py-2 pb-16 shadow-sm ">
          <div className="mt-4 flex flex-wrap items-center gap-4  ">
            {vacancies.data?.map((d) => (
              <div
                className={`${
                  d?.status === "active"
                    ? "border-2 border-[#34C00A1E] bg-[#34C00A1E]"
                    : "border-2 border-[#DB000019] bg-[#DB000019]"
                } flex h-[8.5rem] w-[15rem] items-center justify-center rounded-lg p-2 px-4
                hover:cursor-pointer`}
                onClick={() =>
                  history.push(routes.employmentApplications.job(d?._id))
                }
              >
                <div className="">
                  <div className="text-center font-bold">
                    {truncateString(d?.title, 20)}
                  </div>
                  <div className="mx-2 flex items-center gap-2">
                    <PeopleIcon />
                    <span>{d?.applied_count}</span>
                    <span>{selectedContent[localizationKeys.applied]}</span>
                  </div>
                </div>
              </div>
            ))}
            <div className="mx-auto mb-4 flex w-full items-center justify-center pb-4">
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                className="absolute bottom-0 mb-4 rtl:flex-row-reverse"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="-mt-4 flex h-[32rem] items-center justify-center rounded-xl bg-white px-8 shadow-sm">
          <div className="mt-8 flex-col justify-center ">
            <JobsNotFoundIcon className="h-44 w-64" />
            <div className="mt-8 flex items-center justify-center text-lg font-semibold">
              {selectedContent[localizationKeys.noEmploymentApplications]}.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllEmploymentApplicationsPage;
