import classNames from "classnames";
import { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Search } from "semantic-ui-react";
import { authAxios } from "../../../config/axios-config";
import { useLanguage } from "../../../lib/language-context";
import content from "../../../localization/content";
import localizationKeys from "../../../localization/localization-keys";
import { useAppMedia } from "../../hooks/use-media-query";
import styles from "./subject-filter-modal.module.css";
import debounce from "lodash/debounce";

const FilterSearch = memo(({ search, setSearch, isTablet }) => {
  return (
    <Search
      input={{
        icon: "search",
        iconPosition: "left",
      }}
      open={false}
      className={classNames(styles.field, {
        "my-3 w-full": isTablet,
      })}
      value={search}
      onSearchChange={(e, data) => setSearch(data?.value)}
    />
  );
});

const SubjectFilterModal = ({
  states: {
    filterModal,
    setFilterModal,
    selected,
    setSelected,
    subjectList,
    setSubjectList,
  },
}) => {
  const [lang] = useLanguage();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const selectedContent = content[lang];
  const { isTablet } = useAppMedia();
  const filterModalAction = {
    open: () =>
      setFilterModal({
        ...filterModal,
        open: true,
      }),
    close: () => {
      setFilterModal({ open: false });
    },
    cancel: () => {
      setSelected(filterModal.initSelect || []);
      setFilterModal({ open: false });
    },
    confirm: () => {
      if (selected.length < 1) {
        history.replace({ search: `` });
      } else {
        history.replace({
          search: `?subject${selected.length === 1 ? "[]" : ""}=${selected.join(
            ","
          )}`,
        });
      }
      filterModalAction.close();
    },
  };

  useEffect(() => {
    authAxios
      .get("/subjects", { params: { name: search || null } })
      .then((res) => setSubjectList(res.data.data));
  }, [search]);
  useEffect(() => {
    authAxios.get("/subjects").then((res) => setSubjectList(res.data.data));
  }, []);

  return (
    <Modal
      onClose={filterModalAction.close}
      onOpen={filterModalAction.open}
      open={filterModal.open}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      className="relative min-h-[10rem] max-w-xl"
    >
      <Modal.Content className="mb-0 pb-0">
        <div className="flex flex-col items-center justify-center">
          <div className="flex w-full flex-row items-center justify-between gap-1">
            <p className="whitespace-nowrap">
              {selectedContent[localizationKeys.searchBySubject]}
            </p>
            {!isTablet && <FilterSearch {...{ search, setSearch, isTablet }} />}
            <Button
              className={classNames("bg-transparent p-0", {
                "mr-auto": lang === "ar",
                "ml-auto": lang !== "ar",
              })}
              onClick={() => setSelected([])}
            >
              {selectedContent[localizationKeys.reset]}
            </Button>
          </div>
          {isTablet && <FilterSearch {...{ search, setSearch, isTablet }} />}
          <div className="mt-5 flex flex-row flex-wrap gap-4">
            {subjectList.length > 0 &&
              subjectList.map((subject) => (
                <label className="hover:cursor-pointer" key={subject._id}>
                  <input
                    type="checkbox"
                    tabIndex="0"
                    className="peer hidden"
                    checked={selected.includes(subject._id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (selected.includes(subject._id) && !e.target.checked) {
                        setSelected(
                          [...selected].filter((sub) => sub !== subject._id)
                        );
                      } else if (e.target.checked) {
                        setSelected([...selected, subject._id]);
                      }
                    }}
                  />
                  <div className="text-primary rounded-full px-4 py-1 text-lg outline outline-1 outline-primary-900 transition-[outline] hover:bg-primary-900 hover:bg-opacity-90 hover:text-white peer-checked:bg-primary-900 peer-checked:text-white">
                    {subject.name}
                  </div>
                </label>
              ))}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions className="my-4 flex flex-col justify-end gap-1 rounded-lg md:flex-row">
        <Button
          content={selectedContent[localizationKeys.cancel]}
          basic
          color="blue"
          className="w-full min-w-min md:w-1/4"
          onClick={filterModalAction.cancel}
        />
        <Button
          content={selectedContent[localizationKeys.filter]}
          color="blue"
          className="w-full min-w-min md:w-1/4"
          onClick={filterModalAction.confirm}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SubjectFilterModal;
