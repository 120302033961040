import React from 'react'
import {Field} from 'formik'
import {Form} from 'semantic-ui-react'
import localizationKeys from '../../localization/localization-keys'
import {useLanguage} from '../../lib/language-context'
import content from '../../localization/content'

function FormikDropdown({label, name, className, onChangeCallback, ...props}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]
  return (
    <>
      <Field name={name}>
        {({form, field}) => {
          const {setFieldValue, setFieldTouched, errors, touched} = form
          return (
            <div>
              <Form.Dropdown
                noResultsMessage={
                  selectedContent[localizationKeys.noResultMessage]
                }
                fluid
                search
                selectOnBlur={false}
                id={name}
                {...field}
                {...props}
                label={
                  <label
                    htmlFor={name}
                    className="font-bold text-base mt-4 text-gray-600 "
                  >
                    {label}
                  </label>
                }
                className={`block mt-2  ${className}`}
                error={Boolean(touched[name] && errors[name])}
                onBlur={() => setFieldTouched(name, true)}
                onChange={(e, {value}) => {
                  setFieldValue(name, value)
                  // onChangeCallback(value)
                  onChangeCallback && onChangeCallback(value)
                }}
                selection
              >
                {props.children}
            
              </Form.Dropdown>
              {/* {touched[name] && errors[name] && (
                <ErrorMessage message={errors[name]} />
              )} */}
            </div>
          )
        }}
      </Field>
    </>
  )
}

export default FormikDropdown
