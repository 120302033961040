import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useSWR from "swr";
import SpContainer from "../../components/container/sp-container";
import CardContainer from "../../components/instructor-profile/card-container";
import DetailsSection from "../../components/instructor-profile/details-section";
import HeaderRow from "../../components/instructor-profile/headers-row";
import StyledIcon from "../../components/instructor-profile/styled-icon";
import TagSpan from "../../components/instructor-profile/tag-span";
import { AiOutlineUser } from "react-icons/ai";
import { FaCrown, FaEye, FaUserAlt } from "react-icons/fa";
import {
  BsArrowRight,
  BsDownload,
  BsFlag,
  BsGenderFemale,
  BsGenderMale,
  BsGlobe2,
  BsInfoCircle,
  BsTelephone,
} from "react-icons/bs";
import { Button, Dimmer, Loader, Popup, Progress } from "semantic-ui-react";
import { ReactComponent as CvIcon } from "../../assets/pictures/icons/cv-icon.svg";
import { ReactComponent as BuildingIcon } from "../../assets/pictures/icons/building-icon.svg";
import { ReactComponent as PinIcon } from "../../assets/pictures/icons/pin-icon.svg";
import { ReactComponent as GraduationCapIcon } from "../../assets/pictures/icons/graduation-cap-icon.svg";
import { ReactComponent as BookIcon } from "../../assets/pictures/icons/book-icon.svg";
import { ReactComponent as LockIcon } from "../../assets/pictures/icons/lock-icon.svg";
import ResumeModal from "../../components/instructor-profile/resume-modal";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { TbRadar2 } from "react-icons/tb";
import BuyToViewModal from "../../components/instructors/buy-to-view-modal";
import { instructorAPI } from "../../services/instructor-list";
import classNames from "classnames";
import useAxios from "../../lib/use-axios";

const SmallBtnIcon = ({ icon: Icon, ...props }) => {
  return (
    <button
      {...props}
      className="mx-1 rounded-full bg-primary-300/40 p-2 text-primary-900 outline outline-1 outline-primary-900/60 hover:bg-primary-300/80"
    >
      <Icon className="h-3 w-3" />
    </button>
  );
};
const fetcher = (args) => authAxios.get(args).then((res) => res);
const InstructorProfilePage = () => {
  const { instructorId } = useParams();
  const history = useHistory();
  const { ...location } = useLocation();
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [modal, setModal] = useState({ open: false, loaded: false });
  const [buyModal, setBuyModal] = useState({ open: false, loading: "idle" });
  const { data, error, isLoading } = useSWR(
    `/schools/instructors/${instructorId}`,
    fetcher
  );
  const { data: instructorListData } = useSWR(
    "listInstructor",
    instructorAPI.listInstructors
  );
  const instructor = data?.data?.data;
  const viewLimit = instructorListData?.instructorViewsLimit;

  if (error) return <>error</>;
  if (isLoading)
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    );
  const {
    _id,
    application_limit,
    city,
    country,
    country_code,
    education_center, // {}
    education_levels, // [{}]
    ejadaCertified,
    englishLevel,
    gender,
    generatedStars,
    isDeleted,
    jobPlace, // [{}]
    jobType, // [{}]
    language,
    localizedEnglishLevel,
    profileStatus,
    secondary_tracks, // []
    subject, // [{}]
    viewed, // {true,false} -> open or not
  } = instructor;

  const BuyModalAction = {
    open: (instructor) =>
      setBuyModal((state) => ({
        ...state,
        instructor: { ...instructor },
        open: true,
      })),
    close: () => setBuyModal({ open: false }),
    confirm: async () => {
      setBuyModal((state) => ({ ...state, loading: "loading" }));
      authAxios
        .get(`/schools/instructors/${instructor._id}/pay-to-view`)
        .then((res) => res.data.data)
        .then((data) => {
          window.open(data.InvoiceURL, "_blank");
        })
        .finally((data) => BuyModalAction.close());
    },
  };

  const ClickToBuyContainer = ({ children }) => {
    return (
      <div
        className="hover:cursor-pointer"
        onClick={() => BuyModalAction.open(instructor)}
      >
        {children}
      </div>
    );
  };

  return (
    <div className="px-8">
      <CardContainer className={"sticky top-20 z-10  md:h-16 xs:static"}>
        <div className="flex h-full flex-col flex-nowrap items-start justify-between gap-y-4 px-8 md:flex-row md:items-center">
          <div className="flex flex-row flex-nowrap items-center justify-center gap-3 ltr:mr-auto rtl:ml-auto">
            <BsArrowRight
              className={classNames("h-8 w-8 cursor-pointer", {
                "rotate-180": lang === "en",
              })}
              onClick={() => history.goBack()}
            />
            {viewed ? (
              <span className="text-xl font-bold">
                {instructor?.name?.[lang]}
              </span>
            ) : (
              <span className="text-xl font-bold">
                {selectedContent[localizationKeys.instructorName]}
              </span>
            )}
          </div>

          {viewLimit !== undefined && (
            <div className="mx-auto -mt-1 flex items-center gap-2 text-primary-900 md:mx-0">
              <span className="text-sm">
                {selectedContent[localizationKeys.freeTrial]}
              </span>
              <span> {viewLimit}/3</span>
              <span className="cursor-pointer ltr:mr-5 rtl:ml-5">
                <Popup
                  trigger={<BsInfoCircle />}
                  position="bottom center"
                  basic
                  style={{ borderRadius: "0.5rem" }}
                >
                  <div className="text-[#909090]">
                    {selectedContent[localizationKeys.remainLimitPopup]
                      .replace("{NUM} ", 20)
                      .replace("{X}", location?.state?.price)}
                  </div>
                </Popup>
              </span>
              <Progress
                color="blue"
                percent={(viewLimit / 3) * 100}
                size="small"
                className="m-0 w-[100px] rounded-full bg-primary-900/10 text-[0.65rem]"
              />
            </div>
          )}
          {/* free count */}
          {!instructor?.viewed && (
            <div className="md:mx-auto">
              <Button
                primary
                className="flex w-full  items-center justify-center rounded-md bg-primary-900 px-2  py-2 text-xs text-white hover:shadow-md"
                size="small"
                color="blue"
                // disabled={!d?.instructor?.resume_link}
                onClick={() => BuyModalAction.open(instructor)}
              >
                {selectedContent[localizationKeys.payToView].replace(
                  "{X}",
                  location?.state?.price
                )}
              </Button>
            </div>
          )}
        </div>
      </CardContainer>

      <SpContainer className={"min-h-0"}>
        <div className="flex max-w-[90vw] flex-col py-8 px-0 md:px-4">
          {/* HERO */}
          {/* <div className="flex flex-row items-center gap-8"> */}
          <div className="grid grid-cols-[min-content_1fr] gap-x-8 gap-y-4 xs:gap-y-3">
            {/* HERO - image */}
            <div className="col-span-1 col-start-1 row-span-2 flex aspect-square h-32 items-center justify-center self-start rounded-xl bg-[#ECECEC] shadow shadow-slate-400 xs:h-40">
              {instructor.image_link ? (
                <img
                  src={instructor.image_link}
                  alt={instructor.name?.[lang]}
                  className="h-full rounded-xl object-cover"
                />
              ) : (
                (!instructor?.viewed && (
                  <ClickToBuyContainer>
                    <div className="flex h-32 w-32 items-center justify-center overflow-hidden rounded-xl bg-gradient-to-b from-primary-300 to-white xs:h-40 xs:w-40">
                      <LockIcon />
                    </div>
                  </ClickToBuyContainer>
                )) || <FaUserAlt className="h-auto w-10 text-[#ADADAD]" />
              )}
            </div>

            {/* HERO - details */}
            <div className="col-span-1 col-start-2 flex w-4/5 flex-col gap-3">
              {/* ROW#1 */}
              <HeaderRow>
                <TagSpan className="bg-transparent">
                  <StyledIcon icon={AiOutlineUser} />
                  {instructor.viewed ? (
                    <span>{instructor.name?.[lang]}</span>
                  ) : (
                    <ClickToBuyContainer>
                      <TagSpan
                        className={"bg-primary-300/40 text-sm text-primary-900"}
                      >
                        <span>
                          {selectedContent[localizationKeys.instructorName]}
                        </span>
                        <StyledIcon icon={LockIcon} />
                      </TagSpan>
                    </ClickToBuyContainer>
                  )}
                </TagSpan>
                {instructor.ejadaCertified && (
                  <span className="flex flex-row gap-3">
                    <TagSpan className="bg-secondary-900">
                      <StyledIcon icon={FaCrown} color="text-secondary-300" />
                      {selectedContent[localizationKeys.ejad]}
                    </TagSpan>
                    <Popup
                      trigger={
                        <span>
                          <StyledIcon
                            icon={BsInfoCircle}
                            color="text-secondary-300"
                            className="cursor-pointer"
                          />
                        </span>
                      }
                      position="bottom center"
                      basic
                      style={{ borderRadius: "0.5rem" }}
                    >
                      <div className="text-[#909090]">
                        {selectedContent[localizationKeys.gotEjadCertificate]}{" "}
                      </div>
                    </Popup>
                  </span>
                )}
              </HeaderRow>
              {/* ROW#2 */}
              <HeaderRow className={"justify-between"}>
                <TagSpan>
                  <StyledIcon
                    icon={
                      instructor.gender === "ذكر" ||
                      instructor.gender === "male"
                        ? BsGenderMale
                        : BsGenderFemale
                    }
                  />
                  <span>{instructor.gender}</span>
                  {/* <BsGenderFemale /> */}
                </TagSpan>
                {!!instructor.country && (
                  <TagSpan>
                    <StyledIcon icon={BsFlag} />
                    <span>{instructor.country}</span>
                  </TagSpan>
                )}

                <TagSpan>
                  <StyledIcon icon={CvIcon} />
                  {viewed ? (
                    <>
                      <span
                        className="cursor-pointer hover:underline"
                        onClick={() =>
                          setModal((state) => ({ ...state, open: true }))
                        }
                      >
                        السيرة الذاتية
                      </span>
                      <SmallBtnIcon
                        icon={FaEye}
                        onClick={() =>
                          setModal((state) => ({ ...state, open: true }))
                        }
                      />
                      <a
                        href={instructor.resume_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        download={instructor.name?.en + ".pdf"}
                      >
                        <SmallBtnIcon icon={BsDownload} />
                      </a>
                    </>
                  ) : (
                    <ClickToBuyContainer>
                      <TagSpan className="cursor-pointer bg-primary-300/40 text-primary-900 hover:underline">
                        {selectedContent[localizationKeys.cv]}{" "}
                        <StyledIcon icon={LockIcon} />
                      </TagSpan>
                    </ClickToBuyContainer>
                  )}
                </TagSpan>
              </HeaderRow>
            </div>
            <div className="col-span-2 col-start-1 flex w-4/5 flex-col gap-3 md:col-span-1 md:col-start-2">
              {/* ROW#3 */}
              <HeaderRow>
                {(!!instructor.englishLevel ||
                  !!instructor.localizedEnglishLevel) && (
                  <TagSpan className={"bg-primary-300/40 text-primary-900"}>
                    <span className="text-black">
                      {selectedContent[localizationKeys.englishLanguageLevel]}:
                    </span>{" "}
                    &nbsp;
                    <span>
                      {lang === "ar"
                        ? instructor.localizedEnglishLevel
                        : instructor.englishLevel}
                    </span>
                  </TagSpan>
                )}
                {[...instructor?.jobPlace, ...instructor?.jobType]?.map((item) => (
                  <TagSpan
                    key={item._id}
                    className="bg-primary-300/40 text-primary-900"
                  >
                    {item.name[lang]}
                  </TagSpan>
                ))}
              </HeaderRow>
              {!!instructor.summary && <div>{instructor.summary}</div>}
            </div>
          </div>
          {/* SUMMARY */}

          <CardContainer>
            <div className="grid grid-cols-1 grid-rows-3 gap-y-4 md:grid-flow-col md:grid-cols-3">
              {!!instructor.education_center && (
                <TagSpan>
                  <StyledIcon icon={BuildingIcon} />
                  <span>
                    {instructor.education_center.name} -{" "}
                    {instructor.education_center.gender}
                  </span>
                </TagSpan>
              )}
              {!!instructor.education_levels &&
                !!instructor.education_levels.length && (
                  <TagSpan className="overflow-hidden">
                    <StyledIcon icon={GraduationCapIcon} />
                    {instructor.education_levels.map((item, idx) => (
                      <span key={item._id}>
                        {" "}
                        {idx > 0 ? ", " : ""}
                        {item.name}
                      </span>
                    ))}
                  </TagSpan>
                )}
              {!!instructor.secondary_tracks &&
                !!instructor.secondary_tracks.length && (
                  <TagSpan>
                    <StyledIcon
                      icon={TbRadar2}
                      className="rotate-180 -scale-x-100"
                    />
                    {instructor.secondary_tracks.map((item, idx) => (
                      <span key={item._id}>
                        {idx > 0 ? ", " : ""}
                        {item.name}
                      </span>
                    ))}
                  </TagSpan>
                )}
              {!!instructor.city && (
                <TagSpan>
                  <StyledIcon icon={BsGlobe2} />
                  <span>{instructor.city.name}</span>
                </TagSpan>
              )}
              {!!instructor.subject && !!instructor.subject.length && (
                <TagSpan className="overflow-hidden">
                  <StyledIcon icon={BookIcon} />
                  {instructor.subject.map((item, idx) => (
                    <span key={item._id}>
                      {idx > 0 ? ", " : ""}
                      {item.name}
                    </span>
                  ))}
                </TagSpan>
              )}
              {!!instructor.country_code && !!instructor.phone && (
                <TagSpan>
                  <StyledIcon icon={BsTelephone} />
                  <span dir="ltr">
                    {instructor.country_code} {instructor.phone}
                  </span>
                </TagSpan>
              )}
              {!viewed && (
                <TagSpan>
                  <StyledIcon icon={BsTelephone} />
                  <ClickToBuyContainer>
                    <TagSpan
                      className={"bg-primary-300/40 text-sm text-primary-900"}
                    >
                      <span className="capitalize">
                        {selectedContent[localizationKeys.mobileNumber]}
                      </span>
                      <StyledIcon icon={LockIcon} />
                    </TagSpan>
                  </ClickToBuyContainer>
                </TagSpan>
              )}
              {!!instructor.address && (
                <TagSpan className="row-span-3 h-auto items-baseline">
                  <StyledIcon icon={PinIcon} />
                  <p className="self-start whitespace-normal">
                    {instructor.address}
                  </p>
                </TagSpan>
              )}
              {!viewed && (
                <TagSpan>
                  <StyledIcon icon={PinIcon} />
                  <ClickToBuyContainer>
                    <TagSpan
                      className={"bg-primary-300/40 text-sm text-primary-900"}
                    >
                      <span>{selectedContent[localizationKeys.address]}</span>
                      <StyledIcon icon={LockIcon} />
                    </TagSpan>
                  </ClickToBuyContainer>
                </TagSpan>
              )}
            </div>
          </CardContainer>
          {/* DETAILS */}

          <DetailsSection
            title={selectedContent[localizationKeys.studies]}
            api="specializations"
            instructorId={instructorId}
          />
          <DetailsSection
            title={selectedContent[localizationKeys.trainingCourses]}
            api="training-courses"
            instructorId={instructorId}
          />
          <DetailsSection
            title={selectedContent[localizationKeys.pastTrainingCourses]}
            api="work-experience"
            instructorId={instructorId}
          />
        </div>
        <ResumeModal
          modal={modal}
          setModal={setModal}
          instructor={instructor}
        />
        <BuyToViewModal modal={buyModal} ModalAction={BuyModalAction} />
      </SpContainer>
    </div>
  );
};

export default InstructorProfilePage;
