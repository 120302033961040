import React from "react";

function SubjectIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 13.836 17.164"
    >
      <g data-name="Group 22373" transform="translate(-343.367 -607.411)">
        <g data-name="Group 22372" transform="translate(343.726 607.764)">
          <g data-name="Group 22371" transform="translate(.07 .001)">
            <g data-name="Group 22370">
              <g>
                <g>
                  <g data-name="Group 22369">
                    <g data-name="Group 22358" transform="translate(.01)">
                      <path
                        fill="#177ba3"
                        fillRule="evenodd"
                        d="M194.8 15.057a.24.24 0 01-.243-.243V.476H184.01c-.718 0-1.544.33-1.544 1.291V14.7a.238.238 0 01-.233.243.232.232 0 01-.233-.243V1.767A1.817 1.817 0 01184.01 0h10.8a.24.24 0 01.243.243v14.591a.237.237 0 01-.253.223z"
                        data-name="Path 17876"
                        transform="translate(-182)"
                      ></path>
                    </g>
                    <g data-name="Group 22359" transform="translate(0 12.805)">
                      <path
                        fill="#177ba3"
                        fillRule="evenodd"
                        d="M193.814 1322.757h-10.931a1.879 1.879 0 110-3.757h10.931a.247.247 0 01.243.243v3.291a.237.237 0 01-.243.223zm-10.931-3.281a1.408 1.408 0 100 2.815h10.688v-2.815z"
                        data-name="Path 17877"
                        transform="translate(-181 -1319)"
                      ></path>
                    </g>
                    <g data-name="Group 22360" transform="translate(2.417)">
                      <path
                        fill="#177ba3"
                        fillRule="evenodd"
                        d="M430.243 13.28a.24.24 0 01-.243-.243V.243a.243.243 0 01.485 0v12.8a.252.252 0 01-.242.237z"
                        data-name="Path 17878"
                        transform="translate(-430)"
                      ></path>
                    </g>
                    <g
                      data-name="Group 22361"
                      transform="translate(4.213 14.436)"
                    >
                      <path
                        fill="#177ba3"
                        fillRule="evenodd"
                        d="M623.6 1487.485h-8.359a.243.243 0 010-.485h8.359a.243.243 0 010 .485z"
                        data-name="Path 17879"
                        transform="translate(-615 -1487)"
                      ></path>
                    </g>
                    <g data-name="Group 22362" transform="translate(8.368)">
                      <path
                        fill="#177ba3"
                        fillRule="evenodd"
                        d="M1045.708 4.339a.249.249 0 01-.155-.068l-1.039-.961-1.116.961a.278.278 0 01-.262.039.239.239 0 01-.136-.21V.243a.24.24 0 01.242-.243h2.466a.24.24 0 01.243.243v3.863a.223.223 0 01-.136.214c-.049 0-.078.019-.107.019zm-2.233-3.863v3.106l.874-.748a.217.217 0 01.311 0l.8.718V.476z"
                        data-name="Path 17880"
                        transform="translate(-1043)"
                      ></path>
                    </g>
                    <g data-name="Group 22368" transform="translate(4.174 5)">
                      <g data-name="Group 22363">
                        <path
                          fill="#177ba3"
                          fillRule="evenodd"
                          d="M617.9 515.485h-6.66a.243.243 0 010-.485h6.66a.24.24 0 01.243.243.247.247 0 01-.243.242z"
                          data-name="Path 17881"
                          transform="translate(-611 -515)"
                        ></path>
                      </g>
                      <g data-name="Group 22364" transform="translate(0 1.573)">
                        <path
                          fill="#177ba3"
                          fillRule="evenodd"
                          d="M617.9 677.485h-6.66a.243.243 0 010-.485h6.66a.24.24 0 01.243.243.247.247 0 01-.243.242z"
                          data-name="Path 17882"
                          transform="translate(-611 -677)"
                        ></path>
                      </g>
                      <g data-name="Group 22365" transform="translate(0 3.136)">
                        <path
                          fill="#177ba3"
                          fillRule="evenodd"
                          d="M617.9 838.485h-6.66a.243.243 0 010-.485h6.66a.24.24 0 01.243.243.246.246 0 01-.243.242z"
                          data-name="Path 17883"
                          transform="translate(-611 -838)"
                        ></path>
                      </g>
                      <g data-name="Group 22366" transform="translate(0 4.728)">
                        <path
                          fill="#177ba3"
                          fillRule="evenodd"
                          d="M617.9 1002.485h-6.66a.243.243 0 010-.485h6.66a.24.24 0 01.243.243.247.247 0 01-.243.242z"
                          data-name="Path 17884"
                          transform="translate(-611 -1002)"
                        ></path>
                      </g>
                      <g data-name="Group 22367" transform="translate(0 6.291)">
                        <path
                          fill="#177ba3"
                          fillRule="evenodd"
                          d="M617.9 1163.485h-6.66a.243.243 0 110-.485h6.66a.24.24 0 01.243.243.246.246 0 01-.243.242z"
                          data-name="Path 17885"
                          transform="translate(-611 -1163)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <path
                fill="#fff"
                d="M-7092.882 644.516V656.9h-9.653v-12.384h9.653z"
                data-name="Path 17886"
                transform="translate(7105.461 -644.06)"
              ></path>
            </g>
            <path
              fill="none"
              stroke="#177ba3"
              strokeLinecap="round"
              strokeWidth="0.65"
              d="M2.683 0L0 0"
              data-name="Line 668"
              transform="translate(6.529 3.628)"
            ></path>
          </g>
          <path
            fill="none"
            stroke="#177ba3"
            strokeWidth="0.7"
            d="M13.128.001H2.694a2.557 2.557 0 00-2.68 2.8v10.463s-.36 3.247 2.68 3.2 10.434 0 10.434 0z"
            data-name="Path 17887"
          ></path>
          <path
            fill="none"
            stroke="#177ba3"
            strokeWidth="0.7"
            d="M2.617 0v13.109"
            data-name="Path 17888"
          ></path>
          <path
            fill="none"
            stroke="#177ba3"
            strokeWidth="0.5"
            d="M13.127 13.109H1.793"
            data-name="Path 17889"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SubjectIcon;
