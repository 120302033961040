import React from "react";

function JobsNotFoundIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 242.251 156.241"
    >
      <g data-name="File searching-pana" transform="translate(-35 -38.48)">
        <g transform="translate(40.102 38.48)">
          <path
            fill="#e0e0e0"
            d="M463.961 73.34l4.18 10.7-2.6-.011-2.085-6.575z"
            data-name="Path 16789"
            transform="translate(-311.871 -60.591)"
          ></path>
          <path
            fill="#ebebeb"
            d="M423.307 46.32l-1.437 20.494 14.837.307 1.668-20.8z"
            data-name="Path 16790"
            transform="translate(-285.491 -43.453)"
          ></path>
          <path
            fill="#fafafa"
            d="M427.78 50.662l-1.2 17.053 11.03.519 1.543-17.675z"
            data-name="Path 16791"
            transform="translate(-288.478 -46.142)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M434.922 57.43l-.892 11.988h6.082l1.075-11.988z"
            data-name="Path 16792"
            transform="translate(-293.204 -50.5)"
          ></path>
          <path
            fill="#ebebeb"
            d="M385.79 84.912V74.65h7.51V84.9z"
            data-name="Path 16793"
            transform="translate(-262.606 -61.422)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M393.267 51.708s1.81-13.246 1.3-13.228-1.781 12.068-1.781 12.068-.059-9.976-.53-9.925 0 10.573 0 10.573-.819-8.536-1.258-8.331.187 8.843.187 8.843h2.081"
            data-name="Path 16794"
            transform="translate(-265.811 -38.48)"
          ></path>
          <path
            fill="#ebebeb"
            d="M393.73 56.5s.629-6.6.83-6.151a25.466 25.466 0 010 3.931s1.737-8.532 2.194-8.239-1.781 9.26-1.781 9.26 1.7-5.12 2.07-4.582-1.386 5.343-1.386 5.343 1.829-3.4 1.946-2.926a17.463 17.463 0 01-.87 3.379z"
            data-name="Path 16795"
            transform="translate(-267.642 -43.269)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M384.622 61.5s-4.023-7.885-3.518-7.64 3.065 5.336 3.065 5.336-1.7-4.922-1.328-5.065 2.238 5.522 2.238 5.522l.421 1.847z"
            data-name="Path 16796"
            transform="translate(-259.606 -48.232)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M389.312 74.643L389.5 84.9h-3.569l-.071-10.29z"
            data-name="Path 16797"
            transform="translate(-262.65 -61.397)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M385.639 75.43l3.46.059 4.045-.059.128-.98h-7.732z"
            data-name="Path 16798"
            transform="translate(-262.447 -61.296)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H52.599V2.71H0z"
            data-name="Rectangle 5332"
            transform="translate(115.578 23.204)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M82.087 226.26l.757 85.568H48.95v-84.4z"
            data-name="Path 16799"
            transform="translate(-48.95 -157.588)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M63.827 228.02l.3 84.924H48.95v-84.4z"
            data-name="Path 16800"
            transform="translate(-48.95 -158.704)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M108.8 229.15v11.992H92.75v-11.425z"
            data-name="Path 16801"
            transform="translate(-76.732 -159.421)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M112.991 237.891v1.77h-4.911v-1.931z"
            data-name="Path 16802"
            transform="translate(-86.456 -164.863)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H5.072V0.724H0z"
            data-name="Rectangle 5333"
            transform="translate(21.463 76.29)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M108.8 344.07v12H92.75v-11.433z"
            data-name="Path 16803"
            transform="translate(-76.732 -232.314)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M112.991 352.821v1.77h-4.911v-1.931z"
            data-name="Path 16804"
            transform="translate(-86.456 -237.762)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H5.072V0.724H0z"
            data-name="Rectangle 5334"
            transform="translate(21.463 118.317)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M108.8 266.12v12H92.75v-11.433z"
            data-name="Path 16805"
            transform="translate(-76.732 -182.871)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M112.991 274.861v1.774h-4.911V274.7z"
            data-name="Path 16806"
            transform="translate(-86.456 -188.313)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H5.072V0.724H0z"
            data-name="Rectangle 5335"
            transform="translate(21.463 89.81)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M108.8 381.04v11.991H92.75v-11.424z"
            data-name="Path 16807"
            transform="translate(-76.732 -255.764)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M112.991 389.781v1.77h-4.911v-1.931z"
            data-name="Path 16808"
            transform="translate(-86.456 -261.206)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H5.072V0.724H0z"
            data-name="Rectangle 5336"
            transform="translate(21.463 131.837)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M109.274 418v12H93.22v-11.433z"
            data-name="Path 16809"
            transform="translate(-77.03 -279.207)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M113.461 426.741v1.774h-4.911v-1.935z"
            data-name="Path 16810"
            transform="translate(-86.754 -284.65)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H5.072V0.724H0z"
            data-name="Rectangle 5337"
            transform="translate(21.635 145.354)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H16.054V11.995H0z"
            data-name="Rectangle 5338"
            transform="translate(16.226 97.611)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M113.779 319.91v1.664h-4.619v-1.814z"
            data-name="Path 16811"
            transform="translate(-87.141 -216.894)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H4.769V0.68H0z"
            data-name="Rectangle 5339"
            transform="translate(21.869 106.08)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M410.14 431.69v10.456h32.153V431.69z"
            data-name="Path 16812"
            transform="translate(-278.051 -287.891)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M406.45 423.7v2.922h34.625V423.7H406.45z"
            data-name="Path 16813"
            transform="translate(-275.71 -282.823)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M452.623 437.078h16.19v-10.456h1.123V423.7H451.95v2.651z"
            data-name="Path 16814"
            transform="translate(-304.57 -282.823)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H6.521V1.573H0z"
            data-name="Rectangle 5340"
            transform="translate(136.588 145.485)"
          ></path>
          <path
            fill="#fff"
            d="M0 0H8.371V3.324H0z"
            data-name="Rectangle 5341"
            transform="translate(135.746 149.025)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M523.73 395.11v10.456h32.149V395.11z"
            data-name="Path 16815"
            transform="translate(-350.1 -264.688)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M520.04 387.12v2.922h34.621v-2.922H520.04z"
            data-name="Path 16816"
            transform="translate(-347.76 -259.62)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M566.207 400.5h16.186v-10.458h1.123v-2.922H565.53v2.651z"
            data-name="Path 16817"
            transform="translate(-376.614 -259.62)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H6.521V1.573H0z"
            data-name="Rectangle 5342"
            transform="translate(178.124 132.108)"
          ></path>
          <path
            fill="#fff"
            d="M0 0H8.371V3.324H0z"
            data-name="Rectangle 5343"
            transform="translate(177.287 135.648)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M519.51 431.69v10.456h32.153V431.69z"
            data-name="Path 16818"
            transform="translate(-347.423 -287.891)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M515.83 423.7v2.922h34.621V423.7H515.83z"
            data-name="Path 16819"
            transform="translate(-345.089 -282.823)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M562 437.078h16.186v-10.456h1.123V423.7H561.32v2.651z"
            data-name="Path 16820"
            transform="translate(-373.943 -282.823)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H6.521V1.573H0z"
            data-name="Rectangle 5344"
            transform="translate(176.585 145.485)"
          ></path>
          <path
            fill="#fff"
            d="M0 0H8.371V3.324H0z"
            data-name="Rectangle 5345"
            transform="translate(175.747 149.025)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M604.877 226.14l-.757 85.56h33.89v-84.4z"
            data-name="Path 16821"
            transform="translate(-401.091 -157.512)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M655.6 227.9l-.3 84.921h15.173v-84.4z"
            data-name="Path 16822"
            transform="translate(-433.554 -158.628)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M609.1 229.03v11.992h16.054V229.6z"
            data-name="Path 16823"
            transform="translate(-404.25 -159.345)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M624.24 237.771v1.77h4.911v-1.931z"
            data-name="Path 16824"
            transform="translate(-413.853 -164.787)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H5.072V0.724H0z"
            data-name="Rectangle 5346"
            transform="translate(210.387 76.246)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M609.1 343.95v12h16.054v-11.433z"
            data-name="Path 16825"
            transform="translate(-404.25 -232.238)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M624.24 352.7v1.77h4.911v-1.93z"
            data-name="Path 16826"
            transform="translate(-413.853 -237.686)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H5.072V0.724H0z"
            data-name="Rectangle 5347"
            transform="translate(210.387 118.273)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M609.1 266v12h16.054v-11.433z"
            data-name="Path 16827"
            transform="translate(-404.25 -182.795)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M624.24 274.741v1.774h4.911v-1.935z"
            data-name="Path 16828"
            transform="translate(-413.853 -188.237)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H5.072V0.724H0z"
            data-name="Rectangle 5348"
            transform="translate(210.387 89.766)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M609.1 380.92v11.991h16.054v-11.424z"
            data-name="Path 16829"
            transform="translate(-404.25 -255.688)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M624.24 389.661v1.77h4.911V389.5z"
            data-name="Path 16830"
            transform="translate(-413.853 -261.13)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H5.072V0.724H0z"
            data-name="Rectangle 5349"
            transform="translate(210.387 131.793)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M608.63 417.88v12h16.054v-11.433z"
            data-name="Path 16831"
            transform="translate(-403.952 -279.131)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M623.76 426.621v1.774h4.915v-1.935z"
            data-name="Path 16832"
            transform="translate(-413.549 -284.573)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H5.072V0.724H0z"
            data-name="Rectangle 5350"
            transform="translate(210.211 145.31)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H16.054V11.995H0z"
            data-name="Rectangle 5351"
            transform="translate(204.642 97.567)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M623.96 319.784v1.664h4.619v-1.818z"
            data-name="Path 16833"
            transform="translate(-413.675 -216.812)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H4.769V0.68H0z"
            data-name="Rectangle 5352"
            transform="translate(210.285 106.036)"
          ></path>
        </g>
        <g transform="translate(35 192.632)">
          <path
            fill="#263238"
            d="M277.251 460.091c0 .051-54.234.1-121.118.1S35 460.143 35 460.091 89.223 460 156.133 460s121.118.037 121.118.091z"
            data-name="Path 16834"
            transform="translate(-35 -460)"
          ></path>
        </g>
        <g transform="translate(105.513 74.246)">
          <path
            fill="#177ba3"
            d="M232.271 185.939l2.582 44.027a2.97 2.97 0 002.959 2.8l79.249.241a2.969 2.969 0 002.98-2.962l.106-34.281a2.973 2.973 0 00-2.984-2.98l-48.738.165a2.966 2.966 0 01-2.1-.863l-8.444-8.367a2.984 2.984 0 00-2.085-.863l-20.545-.062a2.977 2.977 0 00-2.977 3.149z"
            data-name="Path 16835"
            transform="translate(-230.638 -165.781)"
          ></path>
          <path
            d="M232.271 185.939l2.582 44.027a2.97 2.97 0 002.959 2.8l79.249.241a2.969 2.969 0 002.98-2.962l.106-34.281a2.973 2.973 0 00-2.984-2.98l-48.738.165a2.966 2.966 0 01-2.1-.863l-8.444-8.367a2.984 2.984 0 00-2.085-.863l-20.545-.062a2.977 2.977 0 00-2.977 3.149z"
            data-name="Path 16836"
            opacity="0.3"
            transform="translate(-230.638 -165.781)"
          ></path>
          <path
            fill="#fff"
            d="M0 0H43.003V34.38H0z"
            data-name="Rectangle 5353"
            transform="rotate(-52.7 36.266 14.775)"
          ></path>
          <path
            fill="#263238"
            d="M260.6 137.53l-.121.168-.366.479c-.322.428-.794 1.05-1.4 1.865l-5.314 7.014-18.786 24.733-.018-.117 27.354 20.828-.135.018 26.071-34.2.018.11-19.818-15.148-5.53-4.239-1.463-1.119-.49-.391.5.366 1.463 1.1 5.53 4.195L288 158.3l.062.048-.048.066-26.046 34.219-.059.077-.077-.059L234.5 171.8l-.066-.051.051-.069 18.889-24.725 5.339-6.974 1.412-1.829.366-.468z"
            data-name="Path 16837"
            transform="translate(-232.01 -137.073)"
          ></path>
          <path
            fill="#263238"
            d="M0 0H39.983V30.054H0z"
            data-name="Rectangle 5354"
            transform="rotate(-52.7 37.707 12.198)"
          ></path>
          <path
            fill="#455a64"
            d="M296.18 227.1l-6.4-22.429 14.54.2-1.415-8.455 17.5-1.123z"
            data-name="Path 16838"
            transform="translate(-267.118 -173.71)"
          ></path>
          <circle
            cx="3.149"
            cy="3.149"
            r="3.149"
            fill="#455a64"
            data-name="Ellipse 190"
            transform="rotate(-7.77 152.763 -158.705)"
          ></circle>
          <path
            fill="#fff"
            d="M0 0H47.853V59.855H0z"
            data-name="Rectangle 5355"
            transform="rotate(-87.64 41.164 7.06)"
          ></path>
          <path
            fill="#263238"
            d="M378.02 138.822h-.285l-.834-.029-3.244-.124-12.236-.479-43.208-1.737.088-.08c-.581 14.23-1.251 30.544-1.957 47.809l-.091-.1 59.819 2.472-.08.077c.611-14.409 1.115-26.353 1.463-34.695.183-4.176.325-7.453.421-9.684.051-1.115.088-1.971.113-2.56s.044-.878.044-.878 0 .289-.026.863-.055 1.426-.1 2.534a2566.64 2566.64 0 00-.366 9.669c-.333 8.356-.812 20.319-1.39 34.742v.077h-.084l-59.826-2.432h-.1v-.1c.713-17.265 1.39-33.579 1.978-47.809v-.084h.084l43.259 1.829 12.215.527 3.226.143.826.037z"
            data-name="Path 16839"
            transform="translate(-283.845 -136.28)"
          ></path>
          <path
            fill="#455a64"
            d="M0 0H41.829V55.649H0z"
            data-name="Rectangle 5356"
            transform="rotate(-87.64 40.732 4.46)"
          ></path>
          <path
            fill="#263238"
            d="M322.4 215.569l20.487-25.179 11.363 16.749 8.517-8.353L378 217.858z"
            data-name="Path 16840"
            transform="translate(-287.809 -170.602)"
          ></path>
          <circle
            cx="4.381"
            cy="4.381"
            r="4.381"
            fill="#263238"
            data-name="Ellipse 191"
            transform="rotate(-77.36 44.137 -28.955)"
          ></circle>
          <path
            fill="#ebebeb"
            d="M352.741 199.43l-81.011 11.154 8.579 59.332 80.346-11.316z"
            data-name="Path 16841"
            transform="translate(-255.669 -176.336)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M355.215 179.19l-80.075 16.6 12.566 58.61 79.4-16.716z"
            data-name="Path 16842"
            transform="translate(-257.832 -163.498)"
          ></path>
          <path
            fill="#fff"
            d="M340.968 161.2l-77.138 27.143 20.278 56.417 76.458-27.172z"
            data-name="Path 16843"
            transform="translate(-250.658 -152.087)"
          ></path>
          <path
            fill="#263238"
            d="M340.823 161.2c.212.607 7.757 22.217 19.671 56.363l.026.073-.073.026-76.433 27.187-.091.033-.029-.091-.15-.41c-7.215-20.088-14.058-39.131-20.114-56.008l-.029-.084.08-.026 77.163-27.063-77.131 27.223.051-.11 20.139 56 .15.413-.124-.059 76.471-27.152-.048.1c-11.831-34.18-19.317-55.808-19.529-56.415z"
            data-name="Path 16844"
            transform="translate(-250.513 -152.087)"
          ></path>
          <path
            fill="#263238"
            d="M452.92 234.285c-.15.055-3.855-9.567-8.276-21.489s-7.892-21.632-7.742-21.686 3.851 9.563 8.276 21.489 7.888 21.624 7.742 21.686z"
            data-name="Path 16845"
            transform="translate(-360.434 -171.058)"
          ></path>
          <path
            fill="#263238"
            d="M434.9 242.515c-.146.055-3.855-9.567-8.276-21.485s-7.888-21.635-7.742-21.69 3.855 9.567 8.28 21.489 7.888 21.631 7.738 21.686z"
            data-name="Path 16846"
            transform="translate(-349.004 -176.278)"
          ></path>
          <path
            fill="#263238"
            d="M378.97 265.945c-.146.055-3.855-9.567-8.276-21.489s-7.888-21.632-7.742-21.686 3.855 9.563 8.276 21.489 7.888 21.631 7.742 21.686z"
            data-name="Path 16847"
            transform="translate(-313.528 -191.14)"
          ></path>
          <path
            fill="#263238"
            d="M396.8 258.765c-.15.055-3.855-9.567-8.276-21.489s-7.892-21.632-7.742-21.686 3.851 9.563 8.276 21.489 7.892 21.631 7.742 21.686z"
            data-name="Path 16848"
            transform="translate(-324.837 -186.585)"
          ></path>
          <path
            fill="#263238"
            d="M414.62 251.585c-.146.055-3.851-9.567-8.276-21.485s-7.888-21.635-7.742-21.69 3.855 9.567 8.28 21.489 7.888 21.631 7.738 21.686z"
            data-name="Path 16849"
            transform="translate(-336.141 -182.031)"
          ></path>
          <path
            fill="#263238"
            d="M358.32 271.885c-.146.055-3.855-9.567-8.276-21.489s-7.888-21.632-7.742-21.686 3.855 9.563 8.28 21.489 7.888 21.631 7.738 21.686z"
            data-name="Path 16850"
            transform="translate(-300.43 -194.907)"
          ></path>
          <path
            fill="#263238"
            d="M338.57 279.153c-.146.055-3.851-9.567-8.276-21.485s-7.888-21.632-7.742-21.668 3.855 9.567 8.28 21.489 7.888 21.611 7.738 21.664z"
            data-name="Path 16851"
            transform="translate(-287.903 -199.532)"
          ></path>
          <path
            fill="#263238"
            d="M318.83 286.365c-.15.055-3.855-9.567-8.28-21.485s-7.888-21.635-7.738-21.69 3.851 9.567 8.276 21.489 7.881 21.631 7.742 21.686z"
            data-name="Path 16852"
            transform="translate(-275.381 -204.092)"
          ></path>
          <path
            fill="#177ba3"
            d="M227.827 253.589l6.447 53.975a1.913 1.913 0 001.891 1.686l84.383.256a1.909 1.909 0 001.913-1.755l4.414-53.942a1.916 1.916 0 00-1.9-2.07l-95.241-.289a1.913 1.913 0 00-1.905 2.139z"
            data-name="Path 16853"
            transform="translate(-227.813 -209.332)"
          ></path>
        </g>
        <g transform="translate(194.505 78.586)">
          <path
            fill="#bf7a62"
            d="M505.225 216.14l-2.845 2.037 1.66 8.155 3.738-3.332z"
            data-name="Path 16854"
            transform="translate(-490.962 -191.275)"
          ></path>
          <path
            fill="#455a64"
            d="M511.9 373.889l11.831-5.749 15.177 1.829a4.948 4.948 0 011.905 3.149 1.6 1.6 0 01-.172 1.2 1.521 1.521 0 01-1.605.395 14.5 14.5 0 00-1.679-.5 4.107 4.107 0 00-3.021 1.346 6.86 6.86 0 01-2.8 1.876 4.312 4.312 0 01-2.889-.541c-.885-.461-1.675-1.1-2.56-1.591a8.587 8.587 0 00-5.343-1.189c-1.463.194-2.852.837-4.323 1.072s-3.741-.026-4.538-1.28"
            data-name="Path 16855"
            transform="translate(-496.988 -287.688)"
          ></path>
          <path
            fill="#d89377"
            d="M548.123 336.687s-9.691-33.042-9.874-34.1c-.168-.954 1.342-24.1 2.019-30.109l-10.675.088v.1l-5.072.124 2.267 65.7 6.564.5-.044-21.679c4.14 10.47 8.942 22.129 8.942 22.129z"
            data-name="Path 16856"
            transform="translate(-505.005 -227.011)"
          ></path>
          <path
            fill="#bf7a62"
            d="M536.386 350.67s-.022.256-.044.731a13.429 13.429 0 00.029 1.957 17.6 17.6 0 00.479 2.864 28.4 28.4 0 001.156 3.368c.9 2.37 1.7 4.524 2.275 6.082.271.761.5 1.386.666 1.854a5.168 5.168 0 01.219.691 4.9 4.9 0 01-.3-.662c-.183-.464-.432-1.1-.731-1.828a577.39 577.39 0 01-2.333-6.063c-.223-.592-.443-1.174-.651-1.733a15.635 15.635 0 01-.5-1.66 16.833 16.833 0 01-.439-2.9 12.11 12.11 0 01.037-1.975 5.83 5.83 0 01.08-.53.573.573 0 01.057-.196z"
            data-name="Path 16857"
            transform="translate(-512.41 -276.607)"
          ></path>
          <path
            fill="#bf7a62"
            d="M544.617 392.733c0-.2.15-7.354.15-7.354s-2.377-6.638-2.439-6.436 2.289 13.79 2.289 13.79z"
            data-name="Path 16858"
            transform="translate(-516.3 -294.537)"
          ></path>
          <path
            fill="#177ba3"
            d="M521.5 451.671v-.731l6.948.139-.037 2.436-.428.022c-1.913.07-9.735.23-11.008-.179-1.386-.469 4.525-1.687 4.525-1.687z"
            data-name="Path 16859"
            transform="translate(-500.086 -340.207)"
          ></path>
          <g
            data-name="Group 22329"
            opacity="0.3"
            transform="translate(25.845 111.257)"
          >
            <path
              fill="#fff"
              d="M541.83 454.318a2.406 2.406 0 01.911-1.463 2.326 2.326 0 011.627-.472l-.033 1.935z"
              data-name="Path 16860"
              transform="translate(-541.83 -452.374)"
            ></path>
          </g>
          <path
            fill="#fff"
            d="M518.6 454.85s-2.077.556-1.829.9 8.163.479 11.655.234v-.154l-9.227-.08s-.194-.878-.599-.9z"
            data-name="Path 16861"
            opacity="0.3"
            transform="translate(-500.079 -342.687)"
          ></path>
          <path
            fill="#263238"
            d="M528.607 457.175a.5.5 0 01-.121 0h-.34l-1.262.033c-1.064.018-2.534.029-4.158 0s-3.094-.084-4.158-.139l-1.257-.069-.34-.026h.34l1.262.048c1.064.04 2.531.088 4.154.113s3.094.029 4.158.026h1.6z"
            data-name="Path 16862"
            transform="translate(-500.14 -344.032)"
          ></path>
          <path
            fill="#263238"
            d="M522.317 455.713a2.744 2.744 0 00-.227-.6 2.848 2.848 0 00-.421-.49.914.914 0 01.494.446.951.951 0 01.154.644z"
            data-name="Path 16863"
            transform="translate(-503.197 -342.541)"
          ></path>
          <path
            fill="#263238"
            d="M528.458 453.862a.859.859 0 01-.216-.271.753.753 0 01-.143-.311.823.823 0 01.216.267.757.757 0 01.143.315z"
            data-name="Path 16864"
            transform="translate(-507.276 -341.692)"
          ></path>
          <path
            fill="#263238"
            d="M529.881 453.394a1.145 1.145 0 01-.241-.168c-.121-.11-.2-.212-.183-.23s.124.059.241.168.202.197.183.23z"
            data-name="Path 16865"
            transform="translate(-508.135 -341.509)"
          ></path>
          <path
            fill="#263238"
            d="M530.361 452.229a.852.852 0 01-.366.037.793.793 0 01-.366-.044.849.849 0 01.366-.04.8.8 0 01.366.047z"
            data-name="Path 16866"
            transform="translate(-508.246 -340.991)"
          ></path>
          <path
            fill="#263238"
            d="M530.579 451.059a1.021 1.021 0 01-.819.048 2.081 2.081 0 01.41-.022 2.446 2.446 0 01.41-.026z"
            data-name="Path 16867"
            transform="translate(-508.329 -340.282)"
          ></path>
          <path
            fill="#263238"
            d="M526.211 452.127a1.389 1.389 0 01-.442 0 2.6 2.6 0 01-.475-.113 1.381 1.381 0 01-.267-.117.252.252 0 01-.11-.139.186.186 0 01.073-.179.819.819 0 011.006.26.832.832 0 01.139.3v.121a1.2 1.2 0 00-.194-.366.83.83 0 00-.366-.256.684.684 0 00-.53.029c-.073.051-.048.132.033.183a1.348 1.348 0 00.245.11 2.954 2.954 0 00.457.128 3.171 3.171 0 01.431.039z"
            data-name="Path 16868"
            transform="translate(-505.255 -340.564)"
          ></path>
          <path
            fill="#263238"
            d="M528.048 451.782a.417.417 0 01-.048-.311.731.731 0 01.128-.322.442.442 0 01.366-.219.179.179 0 01.135.208.534.534 0 01-.08.2 1.157 1.157 0 01-.216.263c-.139.135-.252.186-.256.176s.091-.077.216-.216a1.643 1.643 0 00.19-.263c.062-.1.106-.263 0-.285s-.227.1-.3.187a.709.709 0 00-.132.285 1.554 1.554 0 00-.003.297z"
            data-name="Path 16869"
            transform="translate(-507.205 -340.201)"
          ></path>
          <path
            fill="#263238"
            d="M544.315 452.241a3.144 3.144 0 00-.512 0 2.194 2.194 0 00-1.123.439 2.253 2.253 0 00-.731.973c-.11.3-.124.5-.139.494a.409.409 0 010-.139 1.6 1.6 0 01.08-.366 2.106 2.106 0 01.731-1.02 2.143 2.143 0 011.17-.435 1.867 1.867 0 01.366.018.449.449 0 01.158.036z"
            data-name="Path 16870"
            transform="translate(-515.968 -340.996)"
          ></path>
          <path
            fill="#263238"
            d="M535.966 455.755a3.985 3.985 0 01-1.013.084 3.927 3.927 0 01-1.013-.1c0-.026.453 0 1.013 0a8.472 8.472 0 011.013.016z"
            data-name="Path 16871"
            transform="translate(-510.98 -343.245)"
          ></path>
          <path
            fill="#263238"
            d="M542.833 455.854a1.488 1.488 0 01-.088.2c-.048.1-.073.194-.1.194s-.033-.11.022-.23.151-.182.166-.164z"
            data-name="Path 16872"
            transform="translate(-516.49 -343.322)"
          ></path>
          <path
            fill="#263238"
            d="M544.159 454.135a.341.341 0 01-.1.161c-.073.073-.146.113-.161.1s.029-.088.1-.161.142-.115.161-.1z"
            data-name="Path 16873"
            transform="translate(-517.293 -342.232)"
          ></path>
          <path
            fill="#263238"
            d="M545.946 453.307c0 .026-.1.022-.2.055s-.176.088-.194.073.04-.113.168-.154.237.004.226.026z"
            data-name="Path 16874"
            transform="translate(-518.345 -341.682)"
          ></path>
          <path
            fill="#263238"
            d="M547.7 452.983c0 .022-.033.059-.091.077s-.11.022-.121 0 .033-.055.091-.077a.217.217 0 01.121 0z"
            data-name="Path 16875"
            transform="translate(-519.572 -341.497)"
          ></path>
          <path
            fill="#177ba3"
            d="M566.839 449.67l-.314-.669 6.29-2.981 1.061 2.194-.366.212c-1.682.918-8.612 4.549-9.933 4.754-1.492.227 3.262-3.51 3.262-3.51z"
            data-name="Path 16876"
            transform="translate(-529.595 -337.087)"
          ></path>
          <g
            data-name="Group 22330"
            opacity="0.3"
            transform="translate(41.91 109.306)"
          >
            <path
              fill="#fff"
              d="M585.846 449.9a2.4 2.4 0 01.161-1.715 2.348 2.348 0 011.247-1.148l.834 1.748z"
              data-name="Path 16877"
              transform="translate(-585.757 -447.04)"
            ></path>
          </g>
          <path
            fill="#fff"
            d="M564.539 455.184s-1.605 1.426-1.229 1.62 7.519-3.211 10.54-4.988l-.077-.135-8.3 4.045s-.561-.7-.934-.542z"
            data-name="Path 16878"
            opacity="0.3"
            transform="translate(-529.573 -340.677)"
          ></path>
          <path
            fill="#263238"
            d="M573.879 451.54a.561.561 0 01-.1.066l-.3.165-1.1.592c-.944.49-2.256 1.156-3.723 1.854s-2.8 1.306-3.781 1.73l-1.174.516-.318.128-.113.04a.625.625 0 01.106-.055l.311-.143 1.148-.519c.969-.439 2.3-1.053 3.77-1.752s2.779-1.357 3.73-1.829l1.126-.567.307-.15a.992.992 0 01.111-.076z"
            data-name="Path 16879"
            transform="translate(-529.584 -340.588)"
          ></path>
          <path
            fill="#263238"
            d="M567.614 461.79a2.474 2.474 0 00-.468-.443 2.929 2.929 0 00-.6-.252.95.95 0 01.64.183.892.892 0 01.424.512z"
            data-name="Path 16880"
            transform="translate(-531.664 -346.647)"
          ></path>
          <path
            fill="#263238"
            d="M572.286 457.367a.772.772 0 01-.311-.146c-.161-.1-.282-.2-.267-.216s.15.044.311.143a1 1 0 01.267.219z"
            data-name="Path 16881"
            transform="translate(-534.935 -344.052)"
          ></path>
          <path
            fill="#263238"
            d="M573.355 456.35c0 .022-.135 0-.289-.044s-.274-.1-.267-.121.135 0 .293.04.271.099.263.125z"
            data-name="Path 16882"
            transform="translate(-535.628 -343.529)"
          ></path>
          <path
            fill="#263238"
            d="M573.3 454.61a.923.923 0 01-.307.2.893.893 0 01-.344.124.925.925 0 01.307-.2.893.893 0 01.344-.124z"
            data-name="Path 16883"
            transform="translate(-535.534 -342.535)"
          ></path>
          <path
            fill="#263238"
            d="M572.953 453.327a1.014 1.014 0 01-.315.282.7.7 0 01-.4.128 2.169 2.169 0 01.366-.2c.188-.111.331-.229.349-.21z"
            data-name="Path 16884"
            transform="translate(-535.274 -341.72)"
          ></path>
          <path
            fill="#263238"
            d="M569.648 456.342a1.225 1.225 0 01-.4.19 2.7 2.7 0 01-.475.11 1.1 1.1 0 01-.293 0 .231.231 0 01-.157-.077.172.172 0 010-.19.826.826 0 011.017-.219.79.79 0 01.256.208c.051.059.069.1.062.1a1.21 1.21 0 00-.344-.256.808.808 0 00-.45-.059.687.687 0 00-.464.26c-.04.081.018.143.11.15a1.385 1.385 0 00.274 0 3.293 3.293 0 00.464-.088c.239-.063.4-.14.4-.129z"
            data-name="Path 16885"
            transform="translate(-532.769 -343.459)"
          ></path>
          <path
            fill="#263238"
            d="M571.362 455.31a.479.479 0 01-.179-.256.7.7 0 01-.029-.347.435.435 0 01.234-.366.176.176 0 01.212.124.435.435 0 01.018.212 1.316 1.316 0 01-.073.336.6.6 0 01-.154.271 2.258 2.258 0 00.1-.289 1.551 1.551 0 00.055-.322c0-.113-.022-.282-.132-.252s-.157.187-.183.3a.731.731 0 000 .314 1.365 1.365 0 00.131.275z"
            data-name="Path 16886"
            transform="translate(-534.578 -342.361)"
          ></path>
          <path
            fill="#263238"
            d="M587.007 447a2.756 2.756 0 00-.453.238 2.235 2.235 0 00-.812.9 2.264 2.264 0 00-.2 1.192c.033.311.11.5.1.5a.367.367 0 01-.062-.124 2.251 2.251 0 01-.091-.366 2.1 2.1 0 011.39-2.3.47.47 0 01.128-.04z"
            data-name="Path 16887"
            transform="translate(-543.655 -337.708)"
          ></path>
          <path
            fill="#263238"
            d="M579.848 454.227a3.842 3.842 0 01-.87.527 3.793 3.793 0 01-.947.366c0-.022.41-.19.911-.439s.906-.475.906-.454z"
            data-name="Path 16888"
            transform="translate(-538.946 -342.292)"
          ></path>
          <path
            fill="#263238"
            d="M586.22 452.629c.022 0 0 .1 0 .216s.022.208 0 .216-.077-.084-.08-.216.06-.227.08-.216z"
            data-name="Path 16889"
            transform="translate(-544.09 -341.278)"
          ></path>
          <path
            fill="#263238"
            d="M586.677 450.449c.022 0 0 .091 0 .19s-.08.168-.1.161 0-.091.022-.19.056-.168.078-.161z"
            data-name="Path 16890"
            transform="translate(-544.36 -339.896)"
          ></path>
          <path
            fill="#263238"
            d="M587.812 448.853c0 .026-.077.062-.154.139s-.121.157-.143.15 0-.117.084-.208.21-.103.213-.081z"
            data-name="Path 16891"
            transform="translate(-544.958 -338.878)"
          ></path>
          <path
            fill="#263238"
            d="M589.19 447.911a.171.171 0 01-.044.11c-.044.044-.091.069-.106.051s0-.062.044-.11.092-.062.106-.051z"
            data-name="Path 16892"
            transform="translate(-545.927 -338.283)"
          ></path>
          <path
            fill="#263238"
            d="M528.2 224.325c-2.926-5.047-3.657-4.458-2.67-7.4l2.852-9.567-1.207-6a6.583 6.583 0 00-5.054-1.85l-5.954.41s-2.926-.64-7.248 3.551l-2.977 2.79 3.763 7.142.19 3.24 2.714 8.861-.731 3.909c-.315 2.973-4.312 37.072-3.77 37.613s8.9-3.609 10.92-1.905 6.082 2.75 8.375 2.015 5.731-5.3 9.2-2.22c.005.004-1.129-30.25-8.403-40.589z"
            data-name="Path 16893"
            transform="translate(-493.22 -180.718)"
          ></path>
          <path
            fill="#263238"
            d="M548.878 200.67s6.217 1.649 5.284 6.078c-.45 2.194-3.423 6.722-3.423 6.722l-7.029-3.324z"
            data-name="Path 16894"
            transform="translate(-517.177 -181.462)"
          ></path>
          <path
            fill="#fafafa"
            d="M520.592 272.858a6.473 6.473 0 014.425-1.573 4.849 4.849 0 012.326.691 4.5 4.5 0 011.828 2.1 3.459 3.459 0 01.256 1.5 3.656 3.656 0 01-.457 1.521c-.5.962-1.291 1.763-1.828 2.743a5.6 5.6 0 00-.494 3.149 10.321 10.321 0 010 3.076 4.18 4.18 0 01-.534 1.346 2.538 2.538 0 01-.984.954 2.1 2.1 0 01-1.265.216 2.926 2.926 0 01-1.1-.421 8.5 8.5 0 01-1.51-1.313 14.073 14.073 0 00-1.126-.987 6.417 6.417 0 00-.783-.534c-.187-.11-.293-.157-.289-.165s.029 0 .08.026.132.055.227.1a5.967 5.967 0 01.808.508 14.011 14.011 0 011.156.973 8.693 8.693 0 001.507 1.276 2.823 2.823 0 001.053.388 1.949 1.949 0 001.17-.208 2.38 2.38 0 00.914-.9 4.021 4.021 0 00.5-1.295 10.73 10.73 0 000-3.024 9.1 9.1 0 010-1.638 4.364 4.364 0 01.5-1.616c.545-1.013 1.331-1.807 1.829-2.735a3.508 3.508 0 00.443-1.463 3.291 3.291 0 00-.238-1.426 4.319 4.319 0 00-1.737-2.033 4.674 4.674 0 00-2.249-.691 6.034 6.034 0 00-3.332.761 8.886 8.886 0 00-.808.494l-.2.143c-.059.046-.08.06-.088.057z"
            data-name="Path 16895"
            transform="translate(-501.542 -226.251)"
          ></path>
          <path
            fill="#fafafa"
            d="M563.247 314.641l-.1.026-.285.048c-.252.04-.622.1-1.1.161a24.852 24.852 0 01-4.081.278 12.456 12.456 0 01-2.8-.366 9.3 9.3 0 01-2.97-1.32 6.524 6.524 0 01-2.322-2.721 5.771 5.771 0 01-.311-3.818 4.508 4.508 0 01.947-1.733 11.481 11.481 0 011.423-1.265 8.122 8.122 0 001.313-1.232 3.7 3.7 0 00.71-1.54 19.368 19.368 0 00.175-3.211 5.654 5.654 0 01.695-2.736 5.552 5.552 0 011.558-1.737 5.486 5.486 0 011.569-.8 4.8 4.8 0 011.1-.219h.388a6.035 6.035 0 00-1.463.3 5.484 5.484 0 00-1.529.819 5.351 5.351 0 00-2.172 4.388 18.825 18.825 0 01-.161 3.244 3.808 3.808 0 01-.731 1.613 8.169 8.169 0 01-1.338 1.269 11.268 11.268 0 00-1.4 1.247 4.359 4.359 0 00-.9 1.657 5.585 5.585 0 00.3 3.69 6.319 6.319 0 002.253 2.655 9.185 9.185 0 002.907 1.309 12.574 12.574 0 002.768.4 27.512 27.512 0 004.067-.216l1.1-.124.285-.033z"
            data-name="Path 16896"
            transform="translate(-520.591 -239.678)"
          ></path>
          <path
            fill="#fafafa"
            d="M523.359 364.027a.682.682 0 01.128-.091 1.671 1.671 0 00.366-.249c.128-.128.077-.366 0-.625a5.123 5.123 0 00-.4-.823c-.333-.6-.732-1.32-1.207-2.088a11.771 11.771 0 00-1.828-2.286 6.751 6.751 0 00-2.461-1.529 4.861 4.861 0 00-2.341-.223 3.432 3.432 0 00-1.463.625c-.3.23-.446.391-.461.366a.583.583 0 01.1-.124 3.015 3.015 0 01.322-.311 3.291 3.291 0 011.481-.7 4.868 4.868 0 012.421.187 6.8 6.8 0 012.538 1.558 11.435 11.435 0 011.847 2.333c.479.775.856 1.507 1.178 2.114a4.982 4.982 0 01.366.856 1.717 1.717 0 01.073.388.42.42 0 01-.113.322 1.374 1.374 0 01-.388.23c-.103.056-.154.078-.158.07z"
            data-name="Path 16897"
            transform="translate(-498.136 -279.922)"
          ></path>
          <path
            fill="#fafafa"
            d="M556.041 254.395a.641.641 0 01-.176.19 1.605 1.605 0 01-.647.285 1.927 1.927 0 01-1.046-.088 2.235 2.235 0 01-1.009-.8 3.269 3.269 0 01-.534-2.22 2.637 2.637 0 01.2-.925c.033 0-.048.366-.07.933a3.375 3.375 0 00.552 2.1 2.157 2.157 0 00.918.731 1.909 1.909 0 00.962.121 2.267 2.267 0 00.85-.327z"
            data-name="Path 16898"
            transform="translate(-522.819 -213.285)"
          ></path>
          <path
            fill="#fafafa"
            d="M558.2 214.071a.5.5 0 00-.143.033 2.324 2.324 0 01-.417 0 2.717 2.717 0 01-1.426-.538 2.56 2.56 0 01-.706-.8 2.626 2.626 0 01-.329-1.185 2.71 2.71 0 01.274-1.331 5.121 5.121 0 01.885-1.141 2.534 2.534 0 00.395-.57 1.1 1.1 0 00.106-.629 3.951 3.951 0 00-.534-1.174 1.975 1.975 0 01.172-2.194 1.9 1.9 0 011.349-.688 1.635 1.635 0 01.417.026.388.388 0 01.139.04c0 .022-.2-.033-.549 0a1.873 1.873 0 00-1.262.717 1.829 1.829 0 00-.124 2.033 3.967 3.967 0 01.567 1.229 1.312 1.312 0 01-.121.731 2.667 2.667 0 01-.424.614 5.376 5.376 0 00-.863 1.1 2.633 2.633 0 00-.263 1.24 2.469 2.469 0 00.94 1.887 2.827 2.827 0 001.353.571c.364.04.564.011.564.029z"
            data-name="Path 16899"
            transform="translate(-524.453 -183.48)"
          ></path>
          <path
            fill="#fafafa"
            d="M515.1 222.342a4.057 4.057 0 00.589.029 2.311 2.311 0 001.437-.6 2.161 2.161 0 00.6-2.128 2.195 2.195 0 00-.731-1.141 4.968 4.968 0 01-1.075-1.115 2.516 2.516 0 01-.245-1.54c.055-.5.172-.965.249-1.408a4.252 4.252 0 00.077-1.254 1.891 1.891 0 00-.4-.991 2.147 2.147 0 00-1.335-.779 3.958 3.958 0 00-.589-.022 1.126 1.126 0 01.15-.04 1.828 1.828 0 01.446 0 2.169 2.169 0 011.434.768 2 2 0 01.45 1.053 4.284 4.284 0 01-.062 1.306c-.073.453-.186.914-.234 1.4a2.347 2.347 0 00.223 1.426 5.057 5.057 0 001.039 1.072 2.4 2.4 0 01.764 1.236 2.293 2.293 0 01-.684 2.271 2.326 2.326 0 01-1.525.57 1.828 1.828 0 01-.446-.048c-.086-.039-.132-.058-.132-.065z"
            data-name="Path 16900"
            transform="translate(-498.129 -188.232)"
          ></path>
          <path
            fill="#455a64"
            d="M521.882 150.351a4.462 4.462 0 015.325 4.1c.044.7-.044 1.507.45 2a5.022 5.022 0 001.32.662 2.015 2.015 0 01.892 2.414c-.168.435-.49.8-.655 1.243a3.333 3.333 0 00-.245 2.052c1.017 3.474-1.463 3.9-2.757 4.132-1.85.326-3.727-.263-3.917-2.194-.062-.625-1.178-.556-1.8-.475a3.832 3.832 0 00-1.854 1.24 6.326 6.326 0 01-3.035 1.719 3.6 3.6 0 01-3.269-.885 2.559 2.559 0 01-.3-3.226 3.2 3.2 0 01-1.2-4.878 10.988 10.988 0 001.423-1.675c.271-.556.563-1.829.775-2.417a5.892 5.892 0 012.118-2.64"
            data-name="Path 16901"
            transform="translate(-495.898 -149.491)"
          ></path>
          <path
            fill="#d89377"
            d="M532.431 172.227l-6.868.1c-.117-.794-.347-3.876-.347-3.876a3.423 3.423 0 01-3.182-3.006c-.3-2.831-.424-9.391-.424-9.391a9.9 9.9 0 0110.017.311l.45.282z"
            data-name="Path 16902"
            transform="translate(-503.159 -152.394)"
          ></path>
          <path
            fill="#263238"
            d="M529.052 172.855a1.829 1.829 0 00-.644-.077c-.1 0-.187 0-.2-.069a.545.545 0 01.044-.3l.234-.823a10.551 10.551 0 00.472-2.147 10.821 10.821 0 00-.687 2.088c-.073.285-.146.56-.216.826a.661.661 0 000 .432.271.271 0 00.19.146.731.731 0 00.175 0 2.03 2.03 0 00.632-.076z"
            data-name="Path 16903"
            transform="translate(-507.226 -161.653)"
          ></path>
          <path
            fill="#bf7a62"
            d="M531.44 189.511a6.645 6.645 0 003.6-1.4 3.508 3.508 0 01-3.536 2.136z"
            data-name="Path 16904"
            transform="translate(-509.394 -173.496)"
          ></path>
          <path
            fill="#263238"
            d="M535.411 170.1a.366.366 0 01-.366.366.344.344 0 01-.366-.333.366.366 0 01.366-.366.344.344 0 01.366.333z"
            data-name="Path 16905"
            transform="translate(-511.449 -161.862)"
          ></path>
          <path
            fill="#263238"
            d="M535.778 168.934c-.044.044-.311-.161-.7-.168s-.673.179-.731.132.029-.106.154-.194a1 1 0 01.574-.165.936.936 0 01.556.194c.132.095.169.18.147.201z"
            data-name="Path 16906"
            transform="translate(-511.227 -161.082)"
          ></path>
          <path
            fill="#263238"
            d="M524.581 170.079a.366.366 0 01-.366.366.34.34 0 01-.366-.329.367.367 0 01.731-.037z"
            data-name="Path 16907"
            transform="translate(-504.58 -161.85)"
          ></path>
          <path
            fill="#263238"
            d="M524.738 169.1c-.044.044-.311-.161-.7-.168s-.673.179-.731.132.029-.106.154-.194a1 1 0 01.574-.161.936.936 0 01.556.19c.132.096.172.18.147.201z"
            data-name="Path 16908"
            transform="translate(-504.224 -161.19)"
          ></path>
          <path
            fill="#bf7a62"
            d="M532.512 179.817a.769.769 0 01-.062-.285.26.26 0 01.154-.234c.066-.022.146 0 .2-.048s.08-.168.128-.256a.314.314 0 01.34-.139.71.71 0 01.329.2.614.614 0 01.2.395.472.472 0 01-.212.366.95.95 0 01-.421.146 2.128 2.128 0 01-.483.018.366.366 0 01-.135-.029.117.117 0 01-.066-.113"
            data-name="Path 16909"
            transform="translate(-510.035 -167.62)"
          ></path>
          <path
            fill="#bf7a62"
            d="M531.85 179.757a1.829 1.829 0 001.463-1.1"
            data-name="Path 16910"
            transform="translate(-509.654 -167.502)"
          ></path>
          <path
            fill="#263238"
            d="M533.343 178.658a1.4 1.4 0 01-1.463 1.1 6.566 6.566 0 00.827-.428c.402-.303.585-.698.636-.672z"
            data-name="Path 16911"
            transform="translate(-509.673 -167.5)"
          ></path>
          <path
            fill="#bf7a62"
            d="M523.42 166.645a1.657 1.657 0 011.061-.121"
            data-name="Path 16912"
            transform="translate(-504.307 -159.78)"
          ></path>
          <path
            fill="#263238"
            d="M524.467 166.206c-.029.106-.26.132-.519.161s-.49.051-.541-.04.157-.3.5-.336.593.116.56.215z"
            data-name="Path 16913"
            transform="translate(-504.294 -159.462)"
          ></path>
          <path
            fill="#bf7a62"
            d="M534.31 166.818a2.026 2.026 0 011.5.084"
            data-name="Path 16914"
            transform="translate(-511.215 -159.923)"
          ></path>
          <path
            fill="#263238"
            d="M535.832 166.556c-.051.1-.366.022-.753 0s-.706 0-.731-.088.293-.322.768-.293.767.301.716.381z"
            data-name="Path 16915"
            transform="translate(-511.238 -159.581)"
          ></path>
          <path
            fill="#455a64"
            d="M526.194 148.953a2.271 2.271 0 00-1.624-.779 5.35 5.35 0 00-4.868 2.194 2.906 2.906 0 00-.366 3.079 1.916 1.916 0 003.456-.483 1.6 1.6 0 103.2-.143 2.871 2.871 0 001.016 1.463 1.126 1.126 0 001.573-.3c.322.366.772.772 1.254.651a.976.976 0 00.655-.98 2.5 2.5 0 00-.417-1.189 7.287 7.287 0 00-4.136-3.624"
            data-name="Path 16916"
            transform="translate(-501.526 -148.148)"
          ></path>
          <path
            fill="#455a64"
            d="M543.12 158.063a5.12 5.12 0 01.04 4.941 8.415 8.415 0 00-.779 1.437 1.353 1.353 0 00.333 1.492c.267.2.677.3.731.629a.907.907 0 01-.1.483 3.251 3.251 0 00-.088 2.059 1.761 1.761 0 001.218 1.463 2.145 2.145 0 001.96.347c2-.516 1.295-5 1.1-7.054a20.119 20.119 0 00-2.3-7.449"
            data-name="Path 16917"
            transform="translate(-516.273 -153.389)"
          ></path>
          <path
            fill="#d89377"
            d="M530 197.018s-2.077 7.757-.753 6.989 7.749-5.877 7.749-5.877l-3.247-2.45z"
            data-name="Path 16918"
            transform="translate(-507.722 -178.297)"
          ></path>
          <path
            fill="#d89377"
            d="M473.872 226.963c-.23-.761-3.1-2.326-2.67-2.746.567-.552 2.926 1.77 3.141 1.949s.475-.128.274-.282c-.293-.219-3.291-2.607-2.728-3.152.307-.3.79.311.79.311s2.633 2.447 2.885 2.194-1.243-1.759-1.785-2.224-.538-.907-.146-.98a10.83 10.83 0 012.479 1.829c.523.494 1.938 1.8 1.759.563v-.059a3.35 3.35 0 01.289-2.45.477.477 0 01.684.516v.091a5.965 5.965 0 00.08 1.254 3.32 3.32 0 00.344 1.068c.2.366.395.709.56.976 1.924 1.068 7.453 3.884 13 4.326 1 .08 5.423-6.9 5.423-6.9l6.583 3.109a64.081 64.081 0 01-5.241 8.265c-5.588 7.5-22.933-5.05-22.933-5.05l-.161-.08a39.881 39.881 0 01-3.833-2.165c-1.492-1.053-1.4-1.192-1.254-1.349.397-.429 2.688 1.743 2.46.986z"
            data-name="Path 16919"
            transform="translate(-471.158 -194.882)"
          ></path>
        </g>
        <g transform="translate(59.137 72.891)">
          <path
            fill="#455a64"
            d="M195.8 450.469l-.033-3-5.625.029v4.388l.366.022c1.551.077 7.9.285 8.931-.033 1.142-.357-3.639-1.406-3.639-1.406z"
            data-name="Path 16920"
            transform="translate(-157.541 -332.311)"
          ></path>
          <path
            fill="#263238"
            d="M189.94 459.106h.373l1.024.04a97.903 97.903 0 006.747-.026l1.02-.048.278-.018h.1a.367.367 0 00-.1 0h-.282l-1.024.022c-.863.022-2.055.047-3.372.051s-2.509 0-3.372-.022l-1.024-.018h-.366z"
            data-name="Path 16921"
            transform="translate(-157.414 -339.657)"
          ></path>
          <path
            fill="#263238"
            d="M210.644 458a2.236 2.236 0 01.186-.49 2.359 2.359 0 01.366-.391.977.977 0 00-.552.881z"
            data-name="Path 16922"
            transform="translate(-170.544 -338.432)"
          ></path>
          <path
            fill="#263238"
            d="M206.1 456.438a.69.69 0 00.176-.216.618.618 0 00.121-.252 1.042 1.042 0 00-.176.216c-.085.131-.14.241-.121.252z"
            data-name="Path 16923"
            transform="translate(-167.66 -337.703)"
          ></path>
          <path
            fill="#263238"
            d="M204.848 456.044a.864.864 0 00.2-.135c.1-.088.165-.168.154-.183s-.1.044-.2.132-.165.172-.154.186z"
            data-name="Path 16924"
            transform="translate(-166.869 -337.546)"
          ></path>
          <path
            fill="#263238"
            d="M204.06 455.086a.731.731 0 00.293.037.692.692 0 00.3-.033.658.658 0 00-.3-.037.68.68 0 00-.293.033z"
            data-name="Path 16925"
            transform="translate(-166.37 -337.119)"
          ></path>
          <path
            fill="#263238"
            d="M203.79 454.129a.567.567 0 00.325.091.618.618 0 00.34-.044 1.4 1.4 0 00-.333-.022 1.635 1.635 0 00-.333-.026z"
            data-name="Path 16926"
            transform="translate(-166.199 -336.535)"
          ></path>
          <path
            fill="#263238"
            d="M206.62 455.032a.9.9 0 00.366 0 2.014 2.014 0 00.388-.084 1.1 1.1 0 00.219-.091.216.216 0 00.088-.113.146.146 0 00-.055-.146.669.669 0 00-.819.2.662.662 0 00-.117.245.176.176 0 000 .1.914.914 0 01.161-.307.647.647 0 01.307-.2.552.552 0 01.432.026c.059.044.037.11-.029.146a.818.818 0 01-.2.088 1.962 1.962 0 01-.366.1c-.236.025-.375.025-.375.036z"
            data-name="Path 16927"
            transform="translate(-167.994 -336.79)"
          ></path>
          <path
            fill="#263238"
            d="M206.049 454.795a.333.333 0 00.04-.249.549.549 0 00-.1-.263.366.366 0 00-.3-.183.146.146 0 00-.113.165.4.4 0 00.062.161 1.145 1.145 0 00.172.219.527.527 0 00.208.146 2.264 2.264 0 01-.172-.179 1.169 1.169 0 01-.154-.216c-.048-.077-.084-.212 0-.23s.183.08.238.154a.567.567 0 01.1.234 1.115 1.115 0 01.019.241z"
            data-name="Path 16928"
            transform="translate(-167.332 -336.516)"
          ></path>
          <path
            fill="#263238"
            d="M190.23 454.9h.417a1.763 1.763 0 011.463 1.167c.088.241.1.4.11.4a.364.364 0 000-.113 1.243 1.243 0 00-.062-.3 1.726 1.726 0 00-.57-.837 1.763 1.763 0 00-.947-.366 1.312 1.312 0 00-.307 0 .391.391 0 00-.104.049z"
            data-name="Path 16929"
            transform="translate(-157.598 -336.983)"
          ></path>
          <path
            fill="#263238"
            d="M191.709 448.59a9.506 9.506 0 00-.044 1.156 8.542 8.542 0 00.026 1.156 10.071 10.071 0 00.04-1.156 9.819 9.819 0 00-.022-1.156z"
            data-name="Path 16930"
            transform="translate(-158.502 -333.021)"
          ></path>
          <path
            fill="#263238"
            d="M197.66 457.886a3.472 3.472 0 00.819.077 3.147 3.147 0 00.823-.069c0-.022-.366 0-.823 0s-.819-.03-.819-.008z"
            data-name="Path 16931"
            transform="translate(-162.311 -338.913)"
          ></path>
          <path
            fill="#263238"
            d="M194.64 457.9l.069.165c.036.085.059.157.077.157s.029-.091 0-.186-.131-.15-.146-.136z"
            data-name="Path 16932"
            transform="translate(-160.395 -338.924)"
          ></path>
          <path
            fill="#263238"
            d="M193.5 456.494a.4.4 0 00.084.128c.059.058.113.1.128.084s-.022-.073-.08-.132-.117-.094-.132-.08z"
            data-name="Path 16933"
            transform="translate(-159.672 -338.033)"
          ></path>
          <path
            fill="#263238"
            d="M191.879 455.8c0 .018.077.018.161.048s.143.069.157.059-.033-.091-.135-.124-.19.003-.183.017z"
            data-name="Path 16934"
            transform="translate(-158.644 -337.577)"
          ></path>
          <path
            fill="#263238"
            d="M190.72 455.536c0 .018.026.048.073.066a.152.152 0 00.1 0c.007 0-.026-.048-.073-.066s-.093-.018-.1 0z"
            data-name="Path 16935"
            transform="translate(-157.909 -337.419)"
          ></path>
          <path
            fill="#455a64"
            d="M106.526 434.192l1.591-2.56-4.754-3-2.363 3.71.282.2c1.265.9 6.495 4.495 7.537 4.783 1.17.327-2.293-3.133-2.293-3.133z"
            data-name="Path 16936"
            transform="translate(-101 -320.361)"
          ></path>
          <path
            fill="#263238"
            d="M101 438.36l.077.062.227.161.837.585c.731.486 1.708 1.148 2.82 1.854s2.132 1.324 2.878 1.759l.885.512.245.135c.055.029.084.044.088.04l-.08-.055-.238-.143-.874-.53c-.731-.446-1.755-1.072-2.867-1.777s-2.11-1.357-2.827-1.829l-.852-.567-.234-.15z"
            data-name="Path 16937"
            transform="translate(-101 -326.533)"
          ></path>
          <path
            fill="#263238"
            d="M118.28 448.983a2.472 2.472 0 01.421-.311 2.194 2.194 0 01.5-.143.757.757 0 00-.534.08.731.731 0 00-.387.374z"
            data-name="Path 16938"
            transform="translate(-111.961 -332.972)"
          ></path>
          <path
            fill="#263238"
            d="M115.679 445.018c0 .018.128-.022.263-.088s.245-.132.238-.15a.636.636 0 00-.267.088c-.113.066-.241.132-.234.15z"
            data-name="Path 16939"
            transform="translate(-110.311 -330.605)"
          ></path>
          <path
            fill="#263238"
            d="M115 444.069c0 .018.11 0 .238 0s.23-.055.227-.073-.11 0-.238 0a.3.3 0 00-.227.073z"
            data-name="Path 16940"
            transform="translate(-109.88 -330.102)"
          ></path>
          <path
            fill="#263238"
            d="M115.09 442.56a1.439 1.439 0 00.227.19c.135.091.256.146.267.128s-.091-.1-.227-.187a.71.71 0 00-.267-.131z"
            data-name="Path 16941"
            transform="translate(-109.937 -329.197)"
          ></path>
          <path
            fill="#263238"
            d="M115.41 441.55a.826.826 0 00.227.256.575.575 0 00.307.143 2.328 2.328 0 00-.267-.2 2.191 2.191 0 00-.267-.199z"
            data-name="Path 16942"
            transform="translate(-110.14 -328.556)"
          ></path>
          <path
            fill="#263238"
            d="M116.814 444.316a.973.973 0 00.3.194 2.085 2.085 0 00.366.132 1.062 1.062 0 00.234.044.2.2 0 00.135-.048.143.143 0 00.029-.15.662.662 0 00-.794-.278.676.676 0 00-.23.143c-.044.044-.062.073-.059.077a1 1 0 01.3-.172.629.629 0 01.366 0 .56.56 0 01.366.256c.026.066-.029.11-.106.11a1.1 1.1 0 01-.216-.037 2.556 2.556 0 01-.366-.117c-.204-.088-.321-.162-.325-.154z"
            data-name="Path 16943"
            transform="translate(-111.021 -330.217)"
          ></path>
          <path
            fill="#263238"
            d="M116.745 443.383a.411.411 0 00.168-.19.556.556 0 00.059-.274.366.366 0 00-.157-.315.143.143 0 00-.183.08.366.366 0 00-.033.168 1.057 1.057 0 00.026.278.534.534 0 00.1.234 1.81 1.81 0 01-.051-.245 1.2 1.2 0 010-.263c0-.091.044-.227.128-.19s.11.165.121.256a.666.666 0 01-.04.252 1.437 1.437 0 01-.135.208z"
            data-name="Path 16944"
            transform="translate(-110.893 -329.219)"
          ></path>
          <path
            fill="#263238"
            d="M103.53 435a2.182 2.182 0 01.34.234 1.807 1.807 0 01.567.8 1.788 1.788 0 01.051.976c-.059.252-.135.391-.124.4a.431.431 0 00.059-.1 1.464 1.464 0 00.113-.289 1.759 1.759 0 00-.033-1.013 1.778 1.778 0 00-.6-.819 1.533 1.533 0 00-.267-.157.334.334 0 00-.106-.032z"
            data-name="Path 16945"
            transform="translate(-102.605 -324.401)"
          ></path>
          <path
            fill="#263238"
            d="M106.112 430.43a9.393 9.393 0 00-.662.947 9 9 0 00-.6.987 9.56 9.56 0 00.658-.951 11.052 11.052 0 00.604-.983z"
            data-name="Path 16946"
            transform="translate(-103.442 -321.503)"
          ></path>
          <path
            fill="#263238"
            d="M108.22 441.419a3.018 3.018 0 00.647.508 3.088 3.088 0 00.731.384c0-.018-.314-.194-.695-.443s-.672-.468-.683-.449z"
            data-name="Path 16947"
            transform="translate(-105.58 -328.472)"
          ></path>
          <path
            fill="#263238"
            d="M105.576 439.809a.628.628 0 01-.029.172c-.029.091-.037.165 0 .176s.073-.062.088-.165-.04-.192-.059-.183z"
            data-name="Path 16948"
            transform="translate(-103.868 -327.452)"
          ></path>
          <path
            fill="#263238"
            d="M105.47 438v.154c0 .084.044.143.062.139s.022-.073 0-.154-.044-.139-.062-.139z"
            data-name="Path 16949"
            transform="translate(-103.835 -326.304)"
          ></path>
          <path
            fill="#263238"
            d="M104.44 436.579c0 .018.059.059.113.128s.08.135.1.135.022-.1-.048-.179-.158-.102-.165-.084z"
            data-name="Path 16950"
            transform="translate(-103.182 -325.399)"
          ></path>
          <path
            fill="#263238"
            d="M103.63 435.713a.193.193 0 00.026.091c.026.04.066.062.08.051s0-.051-.026-.091-.065-.064-.08-.051z"
            data-name="Path 16951"
            transform="translate(-102.668 -324.851)"
          ></path>
          <path
            fill="#177ba3"
            d="M128.661 280.269s4.754 31.688 4.074 33.217-22.963 26.02-22.963 26.02l-8.2-5.438s19.448-24.5 19.3-25.076-7.372-31.272-7.372-31.272z"
            data-name="Path 16952"
            transform="translate(-101.362 -224.64)"
          ></path>
          <path
            fill="#177ba3"
            d="M125.6 276.54l-.289 1.174a13.86 13.86 0 001.463 10.214l12.926 22.425a17.862 17.862 0 011.982 5.1l5.851 25.892h9.007c0 .538-2.33-21.332-3.5-28.595-.878-5.486-8.21-22.48-11.761-30.511l2.194-2.706-3.825-.629z"
            data-name="Path 16953"
            transform="translate(-116.173 -223.891)"
          ></path>
          <path
            fill="#263238"
            d="M170.4 291.93a1.707 1.707 0 01.2.366c.117.234.278.578.472 1.006.388.852.9 2.044 1.415 3.375s.947 2.56 1.24 3.445c.143.446.256.808.329 1.061a1.8 1.8 0 01.1.4 1.518 1.518 0 01-.165-.366c-.106-.285-.238-.633-.388-1.042a181.518 181.518 0 00-2.651-6.813c-.161-.4-.3-.731-.417-1.028a1.463 1.463 0 01-.135-.404z"
            data-name="Path 16954"
            transform="translate(-145.02 -233.653)"
          ></path>
          <path
            fill="#263238"
            d="M154.823 355.165a1.409 1.409 0 01-.059-.252c-.033-.187-.077-.428-.128-.731-.029-.161-.059-.336-.091-.527s-.088-.4-.139-.622c-.1-.442-.2-.951-.366-1.5-.077-.278-.154-.567-.238-.867l-.3-.933c-.19-.644-.457-1.313-.731-2.026a56.089 56.089 0 00-2.019-4.5 84.731 84.731 0 00-4.546-7.727l-1.5-2.267c-.168-.256-.3-.461-.406-.618a1.522 1.522 0 01-.132-.223 1.2 1.2 0 01.165.2l.435.6c.366.523.911 1.284 1.554 2.238a75.558 75.558 0 014.6 7.713c.79 1.569 1.463 3.1 2.015 4.52.252.731.516 1.393.7 2.044l.285.936c.077.307.154.6.227.878.154.556.245 1.068.336 1.514l.121.625c.029.194.051.366.077.53.037.3.069.549.1.731a1.253 1.253 0 01.04.264z"
            data-name="Path 16955"
            transform="translate(-128.383 -259.304)"
          ></path>
          <path
            fill="#263238"
            d="M112.723 433.74a1.7 1.7 0 01-.366-.124 9.284 9.284 0 01-.976-.45 23.139 23.139 0 01-2.977-1.935c-1.1-.826-2.106-1.58-2.86-2.077s-1.24-.775-1.225-.8a1.661 1.661 0 01.366.161c.23.11.549.293.936.53.779.472 1.8 1.21 2.9 2.037a28.877 28.877 0 002.926 1.967c.772.443 1.287.659 1.276.691z"
            data-name="Path 16956"
            transform="translate(-103.105 -320.183)"
          ></path>
          <path
            fill="#263238"
            d="M194.853 449.613a1.566 1.566 0 01-.366 0h-1.02l-3.354-.026-3.357.018h-1.02a1.67 1.67 0 01-.366-.022 1.308 1.308 0 01.366-.055c.241-.018.589-.044 1.02-.062.856-.044 2.048-.08 3.361-.077s2.5.037 3.361.084c.428.022.775.044 1.017.066a1.31 1.31 0 01.358.074z"
            data-name="Path 16957"
            transform="translate(-154.515 -333.528)"
          ></path>
          <path
            fill="#263238"
            d="M135.537 162.723a4.089 4.089 0 01-2.319-.366 2.172 2.172 0 01-1.218-1.894 8.471 8.471 0 00.139-1.379 4.344 4.344 0 00-.636-1.232 2.56 2.56 0 01.688-3.138 9.265 9.265 0 001.492-1.006 6.9 6.9 0 00.841-1.949 3.156 3.156 0 012.348-1.8 10.26 10.26 0 001.686-.2 5.914 5.914 0 001.247-.731 5.782 5.782 0 014.3-.808 3.467 3.467 0 011.741.859 4.683 4.683 0 011.386 1.792 5.738 5.738 0 01-.274 2.732 2.245 2.245 0 01-1.763 1.708"
            data-name="Path 16958"
            transform="translate(-120.177 -142.421)"
          ></path>
          <path
            fill="#ffbe9d"
            d="M142.51 175.622l6.868.1c.117-.79.366-3.876.366-3.876a3.413 3.413 0 003.178-3.006c.3-2.834.424-9.388.424-9.388a9.892 9.892 0 00-10.02.311l-.45.285z"
            data-name="Path 16959"
            transform="translate(-127.33 -148.85)"
          ></path>
          <path
            fill="#263238"
            d="M162.87 176.246a2.033 2.033 0 01.647-.073c.1 0 .183-.018.2-.073a.578.578 0 00-.048-.3l-.234-.823a11.241 11.241 0 01-.472-2.15 11.118 11.118 0 01.688 2.092c.077.285.146.556.216.827a.625.625 0 010 .428.267.267 0 01-.194.146.588.588 0 01-.176 0 1.828 1.828 0 01-.625-.077z"
            data-name="Path 16960"
            transform="translate(-140.244 -158.109)"
          ></path>
          <path
            fill="#eb996e"
            d="M156.029 192.907a6.619 6.619 0 01-3.6-1.4 3.512 3.512 0 003.536 2.132z"
            data-name="Path 16961"
            transform="translate(-133.622 -169.957)"
          ></path>
          <path
            fill="#ffbe9d"
            d="M140.39 171.281c-.059-.026-1.942-.333-1.829 1.909s2.333 1.393 2.337 1.328-.508-3.237-.508-3.237z"
            data-name="Path 16962"
            transform="translate(-124.822 -157.109)"
          ></path>
          <path
            fill="#eb996e"
            d="M141.191 175.078a.932.932 0 01-.1.062.4.4 0 01-.293.022.977.977 0 01-.49-.845 1.3 1.3 0 01.084-.567.454.454 0 01.278-.311.2.2 0 01.238.1c.033.062 0 .106.033.11s.044-.04.022-.132a.245.245 0 00-.1-.135.271.271 0 00-.216-.048.538.538 0 00-.391.366 1.32 1.32 0 00-.106.633 1.006 1.006 0 00.614.94.413.413 0 00.366-.077.17.17 0 00.061-.118z"
            data-name="Path 16963"
            transform="translate(-125.832 -158.422)"
          ></path>
          <path
            fill="#263238"
            d="M155.894 170.317c-.033-.04.022-.146.168-.26a1.284 1.284 0 01.662-.234 1.258 1.258 0 01.688.143c.161.091.227.19.2.23-.055.1-.417-.029-.863 0s-.789.204-.855.121z"
            data-name="Path 16964"
            transform="translate(-135.813 -156.199)"
          ></path>
          <path
            fill="#263238"
            d="M166.778 170.821c-.022-.1.285-.245.688-.241s.709.146.691.249-.325.132-.691.132-.666-.034-.688-.14z"
            data-name="Path 16965"
            transform="translate(-142.722 -156.681)"
          ></path>
          <path
            fill="#263238"
            d="M141.62 157.388q.165 1.031.322 2.066a1.7 1.7 0 00.325.911 1.236 1.236 0 001.5.1 3.589 3.589 0 001.349-1.9 5.918 5.918 0 011.1-2.081 2.728 2.728 0 003.149.578c.457-.227.837-.585 1.276-.848a1.679 1.679 0 011.463-.234c.26.11.494.322.772.307a.622.622 0 00.5-.344 1.1 1.1 0 00.091-.625 2.418 2.418 0 00-1.463-1.77 7.572 7.572 0 00-3.335-.574 12.657 12.657 0 00-3.218.318 5.591 5.591 0 00-2.772 1.591 3.6 3.6 0 00-.922 3"
            data-name="Path 16966"
            transform="translate(-126.765 -145.508)"
          ></path>
          <path
            fill="#263238"
            d="M165.74 156.841a3.375 3.375 0 001.1 1.872 1.463 1.463 0 00.947.424 1.174 1.174 0 001.035-1.174 2.249 2.249 0 00-.7-1.532 2.154 2.154 0 00-1.43-.753 1.1 1.1 0 00-1.141.958"
            data-name="Path 16967"
            transform="translate(-141.944 -147.228)"
          ></path>
          <path
            fill="#263238"
            d="M157 173.5a.366.366 0 00.366.366.344.344 0 00.366-.333.366.366 0 00-.366-.366.34.34 0 00-.366.333z"
            data-name="Path 16968"
            transform="translate(-136.52 -158.322)"
          ></path>
          <path
            fill="#263238"
            d="M155.41 172.321c.048.048.315-.161.7-.168s.673.183.713.132-.026-.1-.154-.19a.984.984 0 00-1.126.026c-.115.098-.155.2-.133.2z"
            data-name="Path 16969"
            transform="translate(-135.508 -157.537)"
          ></path>
          <path
            fill="#263238"
            d="M167.86 173.473a.366.366 0 00.366.366.34.34 0 00.366-.333.366.366 0 00-.731-.033z"
            data-name="Path 16970"
            transform="translate(-143.409 -158.305)"
          ></path>
          <path
            fill="#263238"
            d="M166.45 172.491c.044.048.315-.161.7-.168s.673.183.731.135-.026-.106-.154-.194a.98.98 0 00-1.13.029c-.129.091-.169.179-.147.198z"
            data-name="Path 16971"
            transform="translate(-142.511 -157.645)"
          ></path>
          <path
            fill="#263238"
            d="M160.66 183.388c0 .026-.1.069-.271.059a.918.918 0 01-.6-.285.936.936 0 01-.26-.614c0-.172.048-.271.07-.267a1.312 1.312 0 00.325.75c.326.328.74.306.736.357z"
            data-name="Path 16972"
            transform="translate(-138.125 -164.102)"
          ></path>
          <path
            fill="#eb996e"
            d="M166.089 222.059l5.709-1.909s3.972 9.4 5 9.508c5.665.516 11.728-1.36 13.853-2.1a13.7 13.7 0 00.731-.892 3.423 3.423 0 00.534-1.017 5.354 5.354 0 00.3-1.4.446.446 0 01.764-.366 3.653 3.653 0 01-.252 2.838c-.56 1.306 1.419.267 2.147-.1s3.035-1.39 3.324-1.254c.424.2.293.688-.443 1.035s-2.871 1.551-2.659 1.924 3.891-1.54 3.891-1.54.731-.538.98-.113c.468.768-3.6 2.512-3.986 2.67-.274.11-.059.527.227.391s3.628-1.993 4.111-1.214c.366.6-3.328 1.463-3.8 2.234s2.735-.944 3.068-.366c.121.219.183.4-1.807 1.1a42.49 42.49 0 01-4.432 1.134s-20.48 9.165-24.137.322c-3.759-8.972-3.123-10.885-3.123-10.885z"
            data-name="Path 16973"
            transform="translate(-142.26 -188.123)"
          ></path>
          <path
            fill="#ebebeb"
            d="M145.01 210.8c-2.286-5.35-5.277-7.446-6.857-8.221a4.575 4.575 0 00-1.77-.453l-.19-.922-7.57-.68-.187 1.17-1.1-.062s-6.524.655-6.539 8.214c0 3.536 1.905 6.978 2.578 8.664.421 3.525-1.1 9.922-1.05 11.479l18.552 1.317.761-11.589-.172-1.1 6.107-1.872zm-16.388-9.1z"
            data-name="Path 16974"
            transform="translate(-113.559 -175.672)"
          ></path>
          <path
            fill="#263238"
            d="M212.948 144.756A21.558 21.558 0 00174.739 156a21.54 21.54 0 007.278 18.527l-18.867 22.145 2.706 2.308 19.017-22.33a21.423 21.423 0 009 3.076 21.572 21.572 0 0019.068-34.983zm1.119 15.447a17.993 17.993 0 11-3.887-13.213 17.993 17.993 0 013.887 13.21z"
            data-name="Path 16975"
            transform="translate(-140.421 -135.217)"
          ></path>
          <path
            fill="#455a64"
            d="M208.4 140.6a21.555 21.555 0 00-38.209 11.245 21.533 21.533 0 007.278 18.527l-19.1 22.308 2.893 2.315 19.072-22.517a21.47 21.47 0 009 3.076 22.716 22.716 0 002.333.124 21.577 21.577 0 0016.733-35.11zm1.119 15.447a18 18 0 11-3.887-13.213 18 18 0 013.887 13.213z"
            data-name="Path 16976"
            transform="translate(-137.389 -132.575)"
          ></path>
          <path
            fill="#263238"
            d="M215.785 189.325a.826.826 0 01-.143-.157 5.889 5.889 0 01-.366-.483 16.091 16.091 0 01-1.2-1.894 17.817 17.817 0 01-1.218-14.358 15.964 15.964 0 01.863-2.066c.113-.241.219-.417.289-.538a.68.68 0 01.113-.179 1.227 1.227 0 01-.08.2c-.055.128-.15.307-.256.549a19.826 19.826 0 00-.8 2.081 18.322 18.322 0 001.214 14.263 19.119 19.119 0 001.141 1.913c.143.223.267.366.344.5a1 1 0 01.106.172z"
            data-name="Path 16977"
            transform="translate(-171.334 -156.091)"
          ></path>
          <path
            fill="#263238"
            d="M223.546 162.917c.033.044-.187.249-.406.538s-.366.549-.417.53.022-.336.263-.647.534-.465.56-.421z"
            data-name="Path 16978"
            transform="translate(-178.199 -151.816)"
          ></path>
          <path
            fill="#263238"
            d="M173.16 217.57a2.926 2.926 0 01.132.5c.073.325.168.8.282 1.393.219 1.178.486 2.816.731 4.633s.366 3.471.435 4.666c.037.6.059 1.1.066 1.419a2.636 2.636 0 010 .523 2.9 2.9 0 01-.066-.516l-.128-1.415c-.106-1.192-.267-2.842-.49-4.655s-.472-3.449-.658-4.633l-.219-1.4a2.441 2.441 0 01-.084-.516z"
            data-name="Path 16979"
            transform="translate(-146.771 -186.487)"
          ></path>
          <path
            fill="#ffbe9d"
            d="M153.412 245.029s-20.48 9.183-24.137.34c-3.752-9.007-3.116-10.92-3.116-10.92l5.709-1.909s3.975 9.4 5 9.508c5.668.519 11.732-1.36 13.857-2.1.216-.241.461-.534.731-.889s.483-.731.731-1.05a7.524 7.524 0 011.207-1.057c.563-.64 1.1-.172.834.172a5.9 5.9 0 00-1.412 2c-.56 1.309 1.434.662 2.224.457.87-.23 2.505-.867 2.505-.867a1.909 1.909 0 01-1.13 1.5 11.625 11.625 0 01-1.225.512s1.51-.187 1.551.161-.446 2.893-2.055 3.657z"
            data-name="Path 16980"
            transform="translate(-116.932 -195.982)"
          ></path>
          <path
            fill="#ebebeb"
            d="M127.867 210.14l3.679 11.106-8.046 3.182s-4.2-10.909 1.119-13.579z"
            data-name="Path 16981"
            transform="translate(-114.274 -181.774)"
          ></path>
          <path
            fill="#263238"
            d="M138.641 217.97a.67.67 0 01-.11-.1 2.457 2.457 0 01-.241-.366 12.692 12.692 0 01-.581-1.463c-.45-1.243-1.061-2.97-1.722-4.875l-.925-2.692a6.73 6.73 0 00-.929-2.088 1.7 1.7 0 00-1.317-.655 1.638 1.638 0 00-.545.132.5.5 0 01.124-.088 1.038 1.038 0 01.417-.117 1.73 1.73 0 011.423.64 6.545 6.545 0 01.991 2.117c.293.816.607 1.733.94 2.688.666 1.905 1.254 3.657 1.671 4.893.212.629.366 1.145.519 1.463s.299.5.285.511z"
            data-name="Path 16982"
            transform="translate(-120.834 -178.929)"
          ></path>
          <path
            fill="#263238"
            d="M134.6 239.9a1.378 1.378 0 01-.3.19c-.2.113-.5.267-.867.45-.731.366-1.774.841-2.926 1.313s-2.245.848-3.032 1.1c-.391.124-.731.219-.936.278a1.357 1.357 0 01-.366.077 9.737 9.737 0 011.243-.479c.775-.282 1.829-.677 3-1.145s2.194-.925 2.955-1.262a10.77 10.77 0 011.229-.522z"
            data-name="Path 16983"
            transform="translate(-116.965 -200.651)"
          ></path>
          <path
            fill="#263238"
            d="M128.7 245.223a4.2 4.2 0 01-.651-1.653 2.136 2.136 0 01.413.794 2.052 2.052 0 01.238.859z"
            data-name="Path 16984"
            transform="translate(-118.158 -202.978)"
          ></path>
          <path
            fill="#263238"
            d="M133.468 243.56c-.048.026-.267-.3-.428-.731a1.565 1.565 0 01-.146-.848 2.691 2.691 0 01.325.786 2.808 2.808 0 01.249.793z"
            data-name="Path 16985"
            transform="translate(-121.225 -201.97)"
          ></path>
          <path
            fill="#263238"
            d="M138.274 241.336a1.591 1.591 0 01-.457-.764 1.609 1.609 0 01-.154-.881 3.171 3.171 0 01.333.812 3.24 3.24 0 01.278.833z"
            data-name="Path 16986"
            transform="translate(-124.249 -200.517)"
          ></path>
          <path
            fill="#263238"
            d="M143.6 239.052a2.373 2.373 0 01-.366-.848 2.341 2.341 0 01-.187-.9 2.194 2.194 0 01.366.845 2.231 2.231 0 01.187.903z"
            data-name="Path 16987"
            transform="translate(-127.672 -199.001)"
          ></path>
          <path
            fill="#263238"
            d="M183.574 244.56a27.626 27.626 0 01-3.1 1.1 28.762 28.762 0 01-3.156.907 54.236 54.236 0 016.254-2z"
            data-name="Path 16988"
            transform="translate(-149.409 -203.606)"
          ></path>
          <path
            fill="#263238"
            d="M180.024 245.874c-.051.022-.212-.249-.366-.6s-.23-.655-.183-.677.208.249.366.6.238.644.183.677z"
            data-name="Path 16989"
            transform="translate(-150.768 -203.63)"
          ></path>
          <path
            fill="#263238"
            d="M184.557 244.469a1.59 1.59 0 01-.336-.731 1.463 1.463 0 01-.08-.8 2.174 2.174 0 01.263.731 2.106 2.106 0 01.153.8z"
            data-name="Path 16990"
            transform="translate(-153.721 -202.579)"
          ></path>
          <path
            fill="#263238"
            d="M189.444 242.925a3.4 3.4 0 01-.318-1.565 1.792 1.792 0 01.252.764 1.763 1.763 0 01.066.801z"
            data-name="Path 16991"
            transform="translate(-156.896 -201.577)"
          ></path>
          <path
            fill="#263238"
            d="M144.1 201.892c-.055 0-.08-.2-.066-.453s.077-.442.128-.439.08.208.062.454-.074.446-.124.438z"
            data-name="Path 16992"
            transform="translate(-128.293 -175.977)"
          ></path>
          <path
            fill="#263238"
            d="M148.58 202.162c-.055 0-.1-.2-.113-.443s.026-.45.077-.45.1.194.113.443-.026.447-.077.45z"
            data-name="Path 16993"
            transform="translate(-131.107 -176.148)"
          ></path>
          <path
            fill="#263238"
            d="M152.141 202.715c-.051 0-.091-.208-.091-.464s.048-.461.1-.461.1.208.091.461-.041.464-.1.464z"
            data-name="Path 16994"
            transform="translate(-133.381 -176.478)"
          ></path>
          <path
            fill="#263238"
            d="M156.78 202.962c-.048 0-.154-.223-.161-.538s.08-.556.128-.545.051.249.062.538.026.53-.029.545z"
            data-name="Path 16995"
            transform="translate(-136.279 -176.534)"
          ></path>
          <path
            fill="#263238"
            d="M160.686 202.962c-.055 0-.124-.23-.161-.53s-.022-.549.029-.552.124.23.161.53.018.545-.029.552z"
            data-name="Path 16996"
            transform="translate(-138.743 -176.535)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default JobsNotFoundIcon;
