import React from "react";
import { useLanguage } from "../lib/language-context";
import content from "../localization/content";
import localizationKeys from "../localization/localization-keys";

const PrivacyPolicy = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  return (
    <div className="mx-auto mt-32 max-w-7xl px-8 pb-10">
      <h1 className="mb-4 text-2xl text-primary-900">
        {selectedContent[localizationKeys.privacyPolicyTitle]}
      </h1>
      <p className="mb-4">
        {selectedContent[localizationKeys.privacyPolicyBody]}
      </p>
      <h2 className="mb-4 text-xl  text-primary-900">
        {selectedContent[localizationKeys.contestTitle]}
      </h2>
      <p className="mb-4">{selectedContent[localizationKeys.contestBody]}</p>
      <h2 className="mb-4 text-xl  text-primary-900">
        {selectedContent[localizationKeys.informationWeCollectTitle]}
      </h2>
      <p className="mb-4">
        {selectedContent[localizationKeys.informationWeCollectBody]}
      </p>
      <h2 className="mb-4 text-xl  text-primary-900">
        {selectedContent[localizationKeys.howWeUseYourInformationTitle]}
      </h2>
      <p className="mb-4">
        {selectedContent[localizationKeys.howWeUseYourInformationBody]}
      </p>
      <h2 className="mb-4 text-xl  text-primary-900">
        {selectedContent[localizationKeys.logFilesTitle]}
      </h2>
      <p className="mb-4">{selectedContent[localizationKeys.logFilesBody]}</p>
      <h2 className="mb-4 text-xl  text-primary-900">
        {selectedContent[localizationKeys.cookiesAndWebBeaconsTitle]}
      </h2>
      <p className="mb-4">
        {selectedContent[localizationKeys.cookiesAndWebBeaconsBody]}
      </p>
      <h2 className="mb-4 text-xl  text-primary-900">
        {
          selectedContent[
            localizationKeys.advertisingPartnersPrivacyPoliciesTitle
          ]
        }
      </h2>
      <p className="mb-4">
        {
          selectedContent[
            localizationKeys.advertisingPartnersPrivacyPoliciesBody
          ]
        }
      </p>
      <h2 className="mb-4 text-xl  text-primary-900">
        {selectedContent[localizationKeys.thirdPartyPrivacyPoliciesTitle]}
      </h2>
      <p className="mb-4">
        {selectedContent[localizationKeys.thirdPartyPrivacyPoliciesBody]}
      </p>
      <h2 className="mb-4 text-xl  text-primary-900">
        {selectedContent[localizationKeys.CCPAPrivacyRightsTitle]}
      </h2>
      <p className="mb-4">
        {selectedContent[localizationKeys.CCPAPrivacyRightsBody]}
      </p>
      <h2 className="mb-4 text-xl  text-primary-900">
        {selectedContent[localizationKeys.GDPRDataProtectionRightsTitle]}
      </h2>
      <p className="mb-4">
        {selectedContent[localizationKeys.GDPRDataProtectionRightsBody]}
      </p>
      <h2 className="mb-4 text-xl  text-primary-900">
        {" "}
        {selectedContent[localizationKeys.ChildrensInformationTitle]}
      </h2>
      <p className="mb-4">
        {selectedContent[localizationKeys.ChildrensInformationBody]}
      </p>
    </div>
  );
};

export default PrivacyPolicy;
