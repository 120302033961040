import React from "react";
import { Dropdown } from "semantic-ui-react";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import NotificationsIcon from "../../assets/icons/notifications-icon";
import routes from "../../routes";
import localizationKeys from "../../localization/localization-keys";
import api from "../../api";
import NewApplicantNotificationIcon from "../../assets/icons/new-applicant-notfication-icon";

const Notifications = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [isOpeningNotifications, setIsOpeningNotifications] =
    React.useState(false);
  const history = useHistory();
  const { run, data } = useAxios({ data: [] });

  React.useEffect(() => {
    run(authAxios.get(api.notifications.all)).catch((e) =>
      e?.errors?.map((s) => toast.error(s.message))
    );
  }, [run, lang]);

  return (
    <div className="m-2 mx-3 flex items-center justify-center">
      <Dropdown
        text=""
        icon={<NotificationsIcon size={20} className="cursor-pointer" />}
        onClick={() => {
          setIsOpeningNotifications(!isOpeningNotifications);
        }}
        direction="left"
        onClose={() => setIsOpeningNotifications(false)}
      >
        <Dropdown.Menu className="mt-4 -ml-32 w-96 rounded-md hover:rounded-md ">
          <Dropdown.Menu scrolling className="max-h-72 overflow-y-scroll">
            {data?.data?.length > 0 ? (
              data?.data?.map((d, idx) => (
                <React.Fragment key={idx}>
                  <Dropdown.Item
                    className="border-2 border-primary-900"
                    onClick={() =>
                      history.push(
                        routes.employmentApplications.job(
                          d?.vacancy,
                          d?.instructor
                        )
                      )
                    }
                    text={
                      <div className="text-sm">
                        <div className="flex items-center">
                          <NewApplicantNotificationIcon className="h-8 w-8 ltr:mr-1.5 rtl:ml-1.5" />
                          <div className="text-[#9B9B9B]">{d?.title}</div>
                        </div>
                        <div className="text-primary mt-2">{d?.body}</div>
                        <div className="mt-1 -mb-2 font-medium text-black">
                          {selectedContent[localizationKeys.from]} "{d?.content}
                          "
                        </div>
                      </div>
                    }
                    description={
                      <div className="text-sm">
                        <div className="flex items-center text-gray-500">
                          <RiCheckboxBlankCircleFill className="mx-1 text-secondary-300" />
                          <span className="flex items-center gap-1">
                            <span>{moment(d?.createdAt).format("L")}</span>,
                            <span>{moment(d?.createdAt).format("LT")}</span>
                          </span>
                        </div>
                      </div>
                    }
                  />
                  <hr className=" mx-4" />
                </React.Fragment>
              ))
            ) : (
              <div className="p-20 text-center text-lg text-gray-700 ">
                <NotificationsIcon className="mx-auto mb-2 h-12 w-12 fill-primary-300" />
                {selectedContent[localizationKeys.notificationsNotFound]}
              </div>
            )}
          </Dropdown.Menu>
          {data?.data?.length > 0 && (
            <div
              className="my-2 flex h-7 items-center justify-center font-bold hover:cursor-pointer"
              onClick={() => history.push(routes.notifications.all)}
            >
              {selectedContent[localizationKeys.viewAll]}
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Notifications;
