import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AiFillCheckSquare } from "react-icons/ai";
import { MdError } from "react-icons/md";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { parse, stringify } from "query-string";
import AuthNavbarComponent from "./components/shared/auth-navbar-component";
import AuthLayout from "./layouts/auth-layout";
import SidebarLayout from "./layouts/sidebar-layout";
import { useLanguage } from "./lib/language-context";
import ResendEmailPage from "./pages/auth/re-send-email-page";
import ResetPasswordPage from "./pages/auth/reset-password-page";
import CompleteProfileHome from "./pages/complete-profile/complete-profile-home";
import CompleteProfilePage from "./pages/complete-profile/complete-profile-page";
import FailedPage from "./pages/payment/failed-page";
import SuccessPage from "./pages/payment/success-page";
import routes from "./routes";
import LandingPage from "./pages/landingPage/landing-page";

function App() {
  const [lang] = useLanguage();

  React.useEffect(() => {
    const directionTag = document
      .getElementsByTagName("html")[0]
      .getAttribute("dir");
    const style = document.getElementById("style");

    if (directionTag === "ltr") {
      style.href = "/assets/css/semantic.min.css";
      document.title = "Ejad - schools";
    } else {
      style.href = "/assets/css/semantic.rtl.min.css";
      document.title = "منصة إيجاد - مدارس";
    }
  }, [lang]);
  return (
    <BrowserRouter>
      {" "}
      <QueryParamProvider
        adapter={ReactRouter5Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        <Switch>
          {/* <Route exact path={routes.login} component={LoginPage} /> */}
          <Route exact path={routes.landingPage} component={LandingPage} />

          <Route path={routes.auth.base} component={AuthLayout} />
          <Route path={routes.navbar} component={AuthNavbarComponent} />
          <Route
            path={routes.auth.resetPassword}
            component={ResetPasswordPage}
          />
          <Route path={routes.auth.resendEmail} component={ResendEmailPage} />
          <Route path={routes.payment.success} component={SuccessPage} />
          <Route path={routes.payment.failed} component={FailedPage} />
          <Route
            path={routes.completeProfile.home()}
            component={CompleteProfileHome}
          />
          <Route
            exact
            path={routes.completeProfile.form()}
            component={CompleteProfilePage}
          />
          <Route path="/" component={SidebarLayout} />
          {/* <Route path={routes.profile.edit()} component={EditProfilePage} />
        <Route path={routes.profile.view()} component={ViewProfilePage} /> */}
          <Redirect to={routes.landingPage} />
        </Switch>
        <Toaster
          toastOptions={{
            success: {
              duration: 4000,
              style: {
                borderRadius: "4px",
              },
              icon: (
                <AiFillCheckSquare
                  size="25px"
                  className="rounded-md fill-green-600"
                />
              ),
            },
            error: {
              duration: 4000,
              style: {
                borderRadius: "4px",
              },
              icon: <MdError size="25px" className="fill-red-600" />,
            },
          }}
        />
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default App;
