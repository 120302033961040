import React from "react";

function NewApplicantNotificationIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 33 33"
    >
      <g data-name="Group 22556" transform="translate(-351 -86)">
        <circle
          cx="16.5"
          cy="16.5"
          r="16.5"
          fill="#e8f9ff"
          data-name="Ellipse 201"
          transform="translate(351 86)"
        ></circle>
        <g fill="#177ba3" transform="translate(358.862 92.561)">
          <circle
            cx="3.773"
            cy="3.773"
            r="3.773"
            data-name="Ellipse 202"
            transform="translate(3.383 1.5)"
          ></circle>
          <path
            d="M12.39 12.079a5.479 5.479 0 00-1.62-1.549 4.956 4.956 0 01-7.228 0A5.325 5.325 0 001 15.073v1.589a.6.6 0 00.6.6h7.779a4.924 4.924 0 013.011-5.183z"
            data-name="Path 18018"
            transform="translate(0 -1.858)"
          ></path>
          <path
            d="M17.023 13.25a3.773 3.773 0 103.777 3.773 3.773 3.773 0 00-3.777-3.773zm.993 5.361a.6.6 0 11-1.191 0V16.03h-.2a.6.6 0 010-1.191h.794a.6.6 0 01.6.6z"
            data-name="Path 18019"
            transform="translate(-2.52 -2.417)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default NewApplicantNotificationIcon;
