import React from "react";
import { Button, Confirm, Form, FormCheckbox, Modal } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikInput from "../../components/formik/formik-input";
import FormikTextArea from "../../components/formik/formik-textArea";
import FormikRadio from "../../components/formik/formik-radio";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import toast from "react-hot-toast";
import FormikDropdown from "../../components/formik/formik-dropdown";
import useMediaQuery from "../../components/hooks/use-media-query";
import HiddenIdentityIcon from "../../assets/icons/hidden-identity";
import { BsQuestionCircle } from "react-icons/bs";
import QuestionMarkIcon from "../../assets/icons/question-mark-icon";

const AddVacancy = ({
  isOpen,
  onClose,
  reload,
  school,
  vacancy,
  isEditingVacancy,
}) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const { run: getWorkTypes, data: workTypes } = useAxios({});
  const { run: getWorkPlaces, data: workPlaces } = useAxios({});

  const [atStepOne, setAtStepOne] = React.useState(true);
  const [atStepTwo, setAtStepTwo] = React.useState(false);
  // const [educationalOffices, setEducationalOffices] = React.useState([]);
  const [educationalLevels, setEducationalLevels] = React.useState([]);
  const [tracks, setTracks] = React.useState([]);
  const [subjects, setSubjects] = React.useState([]);

  const [educationalLevel, setEducationalLevel] = React.useState("");
  const [track, setTrack] = React.useState("");
  const [gender, setGender] = React.useState("ذكر");
  const [workType, setWorkType] = React.useState(workTypes?.data[0]?._id);
  const [workPlace, setWorkPlace] = React.useState(workPlaces?.data[0]?._id);
  const [wantToPublish, setWantToPublish] = React.useState(false);
  const [isPublishingVacancy, setIsPublishingVacancy] = React.useState(false);
  const [hideSchoolIdentity, setHideSchoolIdentity] = React.useState(false);
  const [
    selectedSecondaryEducationalLevel,
    setSelectedSecondaryEducationalLevel,
  ] = React.useState(false);
  const [viewingHideIdentityInfoPopup, setIsViewingHideIdentityInfoPopup] =
    React.useState(false);

  const { run: getEducationalLevels } = useAxios({});
  const { run: getTracks } = useAxios({});
  // const { run: getEducationalOffices } = useAxios({});
  const { run: getSubjects, isLoading: isGettingSubjects } = useAxios({});
  const {
    run: postVacancy,
    data: newVacancy,
    isLoading: loadPostingVacancy,
  } = useAxios({});
  const { run: editVacancy, isLoading: loadEditingVacancy } = useAxios({});
  const { run: publish } = useAxios({ data: [] });
  const isSmall = useMediaQuery("(max-width: 992px)");

  React.useEffect(() => {
    if (
      (educationalLevel && educationalLevel !== "62b09a853288312c26b80c8b") ||
      (educationalLevel && track) ||
      vacancy?.subject
    )
      getSubjects(
        authAxios.get(
          `${api.subjects.all}?education_levels[0]=${
            educationalLevel || vacancy?.education_levels?._id
          }${
            track
              ? `&secondary_tracks[0]=${
                  track || vacancy?.secondary_tracks?._id
                }`
              : ""
          }`
        )
      )
        .then(({ data }) => {
          let options = [];
          data?.data?.forEach((s) => {
            options.push({
              text: s?.name,
              key: s._id,
              value: s._id,
            });
          });
          setSubjects(options);
        })
        .catch((e) => {
          e?.errors?.map((s) => toast.error(s.message));
        });
  }, [
    educationalLevel,
    getSubjects,
    track,
    vacancy?.education_levels?._id,
    vacancy?.secondary_tracks?._id,
    vacancy?.subject,
  ]);

  React.useEffect(() => {
    getEducationalLevels(authAxios.get(`${api.educationalLevels.all}`))
      .then(({ data }) => {
        let options = [];

        data?.data.forEach((s) => {
          options.push({
            text: s.name,
            key: s._id,
            value: s._id,
            isSecondary: s.isSecondary,
          });
        });
        setEducationalLevels(options);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
    getTracks(authAxios.get(`${api.tracks.all}`))
      .then(({ data }) => {
        let options = [];

        data?.data.forEach((s) => {
          options.push({ text: s.name, key: s._id, value: s._id });
        });
        setTracks(options);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEducationalLevels, isOpen]);

  React.useEffect(() => {
    getWorkPlaces(authAxios.get(`${api.vacancies.workPlaces}`)).catch((e) => {
      e?.errors?.map((s) => toast.error(s.message));
    });
    getWorkTypes(authAxios.get(`${api.vacancies.workTypes}`)).catch((e) => {
      e?.errors?.map((s) => toast.error(s.message));
    });
  }, [getWorkPlaces, getWorkTypes, isOpen]);

  const handleModalClose = () => {
    onClose();
    setAtStepOne(true);
    setAtStepTwo(false);
    setEducationalLevel("");
    setTrack("");
  };

  const vacancySchema = Yup.object({
    title: Yup.string().required(selectedContent[localizationKeys.required]),
    description: Yup.string().required(
      selectedContent[localizationKeys.required]
    ),
    salary: Yup.number().optional(),
    education_levels: Yup.string().required(
      selectedContent[localizationKeys.required]
    ),
    secondary_tracks: Yup.string().when("education_levels", {
      is: (education_levels) =>
        education_levels === isSecondaryEducationalLevel(education_levels),
      then: Yup.string().required(selectedContent[localizationKeys.required]),
      otherwise: Yup.string(),
    }),
    subject: Yup.string().required(selectedContent[localizationKeys.required]),
  });

  React.useEffect(() => {
    if (vacancy) {
      setGender(vacancy?.gender);
      setWorkPlace(vacancy?.work_place?._id);
      setWorkType(vacancy?.work_type?._id);
      setHideSchoolIdentity(vacancy?.isSchoolNameHidden);
    } else {
      setGender("ذكر");
      setWorkPlace(workPlaces?.data[0]?._id);
      setWorkType(workTypes?.data[0]?._id);
      setHideSchoolIdentity(false);
    }
  }, [vacancy, workPlaces?.data, workTypes?.data]);

  const onAddingVacancy = (values) => {
    values?.secondary_tracks?.length === 0 && delete values.secondary_tracks;
    values?.salary === "" && delete values.salary;
    isEditingVacancy
      ? editVacancy(
          authAxios.put(api.vacancies.edit(vacancy?._id), {
            ...values,
            gender: gender,
            work_type: workType,
            work_place: workPlace,
            city: school?.data?.city?._id,
            address: school?.data?.address,
            education_center: school?.data?.education_centers?._id,
            isSchoolNameHidden: hideSchoolIdentity,
          })
        )
          .then(() => {
            reload();
            handleModalClose();
            toast.success(
              selectedContent[localizationKeys.updatedSuccessfully]
            );
          })
          .catch((e) => {
            e?.errors?.map((s) => toast.error(s.message));
          })
      : postVacancy(
          authAxios.post(api.vacancies.post, {
            ...values,
            gender: gender,
            work_type: workType,
            work_place: workPlace,
            city: school?.data?.city?._id,
            address: school?.data?.address,
            education_center: school?.data?.education_centers?._id,
            isSchoolNameHidden: hideSchoolIdentity,
          })
        )
          .then(({ data }) => {
            reload();
            handleModalClose();
            wantToPublish
              ? publishVacancy(data?.data?._id)
              : toast.success(
                  selectedContent[localizationKeys.createdSuccessfully]
                );
          })
          .catch((e) => {
            e?.errors?.map((s) => toast.error(s.message));
          });
  };

  const publishVacancy = (id) => {
    publish(authAxios.patch(`${api.vacancies.activate(id)}`))
      .then(({ data }) => {
        setIsPublishingVacancy(false);
        setWantToPublish(false);
        data?.data?.InvoiceURL
          ? window.open(data?.data?.InvoiceURL)
          : toast.success(
              selectedContent[localizationKeys.publishedSuccessfully]
            );
        reload();
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  const isSecondaryEducationalLevel = (educationalLevel) => {
    return educationalLevels.filter(
      (e) => educationalLevel === e.value && e.isSecondary
    )[0];
  };

  const resetFormikField = (formik, field) => {
    formik.setFieldValue(field, "");
    formik.setFieldTouched(field, false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      size="small"
      closeOnEscape={false}
      closeIcon
      closeOnDimmerClick={false}
      className="max-w-screen-2xl "
    >
      <Modal.Content>
        <div className="mb-8 text-center text-lg font-bold">
          {isEditingVacancy
            ? selectedContent[localizationKeys.editJobPosting]
            : selectedContent[localizationKeys.addNewJobPosting]}
        </div>
        <div className="mb-4 flex items-center justify-center gap-0 text-sm">
          <div className="mx-2 h-5 w-5 rounded-full bg-primary-900 text-center text-sm text-white ">
            1
          </div>
          <div
            className="font-bold text-primary-900 hover:cursor-pointer hover:underline"
            onClick={() => {
              setAtStepOne(true);
              setAtStepTwo(false);
            }}
          >
            {selectedContent[localizationKeys.stepOne]}
          </div>
          <hr className="mx-3 w-20 border-2 border-gray-300" />
          <div
            className={`mx-2 h-5 w-5 rounded-full text-center text-sm ring-1  ${
              atStepTwo
                ? " bg-primary-900 text-white "
                : "text-gray-300 ring-gray-300"
            }`}
          >
            2
          </div>
          <div
            className={`font-semibold ${
              atStepTwo ? " text-primary-900 " : "text-gray-300"
            }`}
          >
            {selectedContent[localizationKeys.stepTwo]}
          </div>
        </div>
        <Formik
          initialValues={{
            title: vacancy?.title ?? "",
            description: vacancy?.description ?? "",
            salary: vacancy?.salary ?? "",
            education_levels: vacancy?.education_levels?._id ?? "",
            secondary_tracks: vacancy?.secondary_tracks?._id ?? "",
            subject: vacancy?.subject?._id ?? "",
          }}
          validationSchema={vacancySchema}
          onSubmit={onAddingVacancy}
        >
          {(formik) => (
            <Form
              onSubmit={formik.handleSubmit}
              loading={loadEditingVacancy || loadPostingVacancy}
              className="px-8"
            >
              {atStepOne && (
                <>
                  <div className="grid grid-cols-3 items-center justify-center">
                    <div className="mt-2 font-semibold">
                      {selectedContent[localizationKeys.jobName]}
                    </div>
                    <div className="col-span-2">
                      <FormikInput
                        name="title"
                        placeholder={selectedContent[localizationKeys.jobName]}
                      />
                    </div>
                  </div>
                  <div className="mt-2 grid grid-cols-3 justify-center">
                    <div className="mt-2 font-semibold">
                      {selectedContent[localizationKeys.description]}
                    </div>
                    <div className="col-span-2">
                      <FormikTextArea
                        name="description"
                        placeholder={
                          selectedContent[
                            localizationKeys.descriptionPlaceHolder
                          ]
                        }
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-3 items-center justify-center">
                    <div className="mt-2 font-semibold">
                      {selectedContent[localizationKeys.salary]}
                    </div>
                    <div className="col-span-2">
                      <FormikInput
                        name="salary"
                        placeholder={selectedContent[localizationKeys.salary]}
                        type="number"
                        min="0"
                        step="any"
                        dir="ltr"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-3 items-center">
                    <div className="mt-5 font-bold">
                      {selectedContent[localizationKeys.typeOfInstructors]}
                    </div>
                    <Form.Group className="col-span-2 grid grid-cols-2 items-center justify-center">
                      <div className="flex items-center">
                        <FormikRadio
                          checked={gender === "ذكر" || gender === "ذكور"}
                          className="fill-primary-900"
                          value="ذكر"
                          onChangeCallback={(e) => {
                            setGender("ذكر");
                          }}
                        />
                        <span
                          className="mt-8 hover:cursor-pointer"
                          onClick={() => setGender("ذكر")}
                        >
                          {selectedContent[localizationKeys.male2]}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <FormikRadio
                          checked={gender === "إناث" || gender === "أنثى"}
                          className="mt-2 ltr:ml-16 rtl:mr-16"
                          value="أنثى"
                          onChangeCallback={(e) => {
                            setGender("أنثى");
                          }}
                        />
                        <span
                          className="mt-8 hover:cursor-pointer"
                          onClick={() => setGender("أنثى")}
                        >
                          {selectedContent[localizationKeys.female2]}
                        </span>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="mt-20 flex items-center pb-8 ltr:float-right rtl:float-left">
                    <Button
                      type="button"
                      className="rounded-md bg-white font-bold"
                      onClick={() => handleModalClose()}
                    >
                      {selectedContent[localizationKeys.cancel]}
                    </Button>
                    <Button
                      type="button"
                      primary
                      className="w-28 rounded-md font-bold"
                      disabled={
                        formik.values.title.length === 0 ||
                        formik.values.description.length === 0
                        //  ||
                        // formik.values.salary.length === 0
                      }
                      onClick={() => {
                        setAtStepOne(false);
                        setAtStepTwo(true);
                      }}
                    >
                      {selectedContent[localizationKeys.next]}
                    </Button>
                  </div>
                </>
              )}
              {atStepTwo && (
                <>
                  <div className="mt-10 grid grid-cols-3 items-center justify-center">
                    <div className="mt-2 font-semibold text-gray-400">
                      {selectedContent[localizationKeys.city]}
                    </div>
                    <div className="col-span-2 rounded-md bg-gray-100 p-2 px-4 text-gray-400 ring-1 ring-gray-300">
                      {school?.data?.city?.name}
                    </div>
                  </div>
                  <div className="mt-4 grid grid-cols-3 items-center justify-center">
                    <div className="mt-2 font-semibold text-gray-400">
                      {selectedContent[localizationKeys.educationalOffice]}
                    </div>
                    <div className="col-span-2 rounded-md bg-gray-100 p-2 px-4 text-gray-400 ring-1 ring-gray-300">
                      {school?.data?.education_centers?.name}
                    </div>
                  </div>
                  <div className="grid grid-cols-3 items-center justify-center">
                    <div className="mt-4 font-bold ">
                      {selectedContent[localizationKeys.educationalLevel]}
                    </div>
                    <div className="col-span-2">
                      <FormikDropdown
                        options={educationalLevels}
                        name="education_levels"
                        placeholder={
                          selectedContent[localizationKeys.educationalLevel]
                        }
                        className=""
                        onChangeCallback={(selectedEducationalLevel) => {
                          setEducationalLevel(selectedEducationalLevel);
                          resetFormikField(formik, "subject");
                          if (
                            isSecondaryEducationalLevel(
                              selectedEducationalLevel
                            )
                          ) {
                            resetFormikField(formik, "secondary_tracks");
                            setSelectedSecondaryEducationalLevel(true);
                            setTrack("");
                          } else {
                            setSelectedSecondaryEducationalLevel(false);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-3 items-center justify-center">
                    <span className="mt-4 font-bold">
                      {selectedContent[localizationKeys.track]}
                    </span>
                    <div className="col-span-2">
                      <FormikDropdown
                        options={tracks}
                        name="secondary_tracks"
                        placeholder={selectedContent[localizationKeys.track]}
                        className=""
                        onChangeCallback={(e) => {
                          setTrack(e);
                        }}
                        disabled={
                          (!educationalLevel && !vacancy?.secondary_tracks) ||
                          (educationalLevel &&
                            !selectedSecondaryEducationalLevel &&
                            !vacancy?.secondary_tracks) ||
                          (educationalLevel &&
                            !selectedSecondaryEducationalLevel)
                        }
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-3 items-center justify-center">
                    <span className="mt-psi4 font-bold">
                      {selectedContent[localizationKeys.subject]}
                    </span>
                    <div className="col-span-2">
                      <FormikDropdown
                        options={subjects}
                        name="subject"
                        isLoading={isGettingSubjects}
                        placeholder={selectedContent[localizationKeys.subject]}
                        className=""
                        disabled={
                          (selectedSecondaryEducationalLevel &&
                            !track &&
                            !vacancy?.subject) ||
                          (!educationalLevel && !vacancy?.subject) ||
                          (selectedSecondaryEducationalLevel && !track)
                        }
                      />
                    </div>
                  </div>
                  <div className="-mt-2 grid grid-cols-3 items-center">
                    <div className="mt-5 font-bold">
                      {selectedContent[localizationKeys.workingPlace]}
                    </div>
                    <Form.Group className="col-span-2 grid grid-cols-2 items-center justify-center">
                      <div className="flex items-center">
                        <FormikRadio
                          checked={workPlace === workPlaces?.data[0]?._id}
                          className="fill-primary-900"
                          value={workPlaces?.data[0]?._id}
                          onChangeCallback={(e) => {
                            setWorkPlace(workPlaces?.data[0]?._id);
                          }}
                        />
                        <span
                          className={`mt-8 hover:cursor-pointer ${
                            isSmall ? "text-sm" : ""
                          }`}
                          onClick={() => setWorkPlace(workPlaces?.data[0]?._id)}
                        >
                          {lang === "ar"
                            ? workPlaces?.data[0]?.name?.ar
                            : workPlaces?.data[0]?.name?.en}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <FormikRadio
                          checked={workPlace === workPlaces?.data[1]?._id}
                          className={`${
                            isSmall
                              ? "ltr:ml-4 rtl:mr-4"
                              : " ltr:ml-16 rtl:mr-16"
                          } mt-2`}
                          value={workPlaces?.data[1]?._id}
                          onChangeCallback={(e) => {
                            setWorkPlace(workPlaces?.data[1]?._id);
                          }}
                        />
                        <span
                          className={`mt-8 hover:cursor-pointer ${
                            isSmall ? "text-sm" : ""
                          }`}
                          onClick={() => setWorkPlace(workPlaces?.data[1]?._id)}
                        >
                          {lang === "ar"
                            ? workPlaces?.data[1]?.name?.ar
                            : workPlaces?.data[1]?.name?.en}
                        </span>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="-mt-4 grid grid-cols-3 items-center">
                    <div className="mt-5 font-bold">
                      {selectedContent[localizationKeys.workingTime]}
                    </div>
                    <Form.Group className="col-span-2 grid grid-cols-2 items-center justify-center">
                      <div className="flex items-center">
                        <FormikRadio
                          checked={workType === workTypes?.data[0]?._id}
                          className="fill-primary-900"
                          value={workTypes?.data[0]?._id}
                          onChangeCallback={(e) => {
                            setWorkType(workTypes?.data[0]?._id);
                          }}
                        />
                        <span
                          className={`mt-8 hover:cursor-pointer ${
                            isSmall ? "text-sm" : ""
                          }`}
                          onClick={() => setWorkType(workTypes?.data[0]?._id)}
                        >
                          {lang === "ar"
                            ? workTypes?.data[0]?.name?.ar
                            : workTypes?.data[0]?.name?.en}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <FormikRadio
                          checked={workType === workTypes?.data[1]?._id}
                          className={`${
                            isSmall
                              ? "ltr:ml-4 rtl:mr-4"
                              : " ltr:ml-16 rtl:mr-16"
                          } mt-2`}
                          value={workTypes?.data[1]?._id}
                          onChangeCallback={(e) => {
                            setWorkType(workTypes?.data[1]?._id);
                          }}
                        />
                        <span
                          className={`mt-8 hover:cursor-pointer ${
                            isSmall ? "text-sm" : ""
                          }`}
                          onClick={() => setWorkType(workTypes?.data[1]?._id)}
                        >
                          {lang === "ar"
                            ? workTypes?.data[1]?.name?.ar
                            : workTypes?.data[1]?.name?.en}
                        </span>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="flex items-center">
                    <FormCheckbox
                      className="mt-2"
                      label={
                        selectedContent[localizationKeys.hideSchoolIdentity]
                      }
                      checked={hideSchoolIdentity}
                      onChange={() =>
                        setHideSchoolIdentity(!hideSchoolIdentity)
                      }
                    />
                    {lang === "ar" ? (
                      <div
                        onClick={() => setIsViewingHideIdentityInfoPopup(true)}
                      >
                        <QuestionMarkIcon
                          className="mx-2 -mt-2 cursor-pointer"
                          size="20"
                        />
                      </div>
                    ) : (
                      <BsQuestionCircle
                        className="text-primary/75 mx-2 -mt-2 cursor-pointer"
                        size="20"
                        onClick={() => setIsViewingHideIdentityInfoPopup(true)}
                      />
                    )}
                  </div>
                  <div className="flex items-center pb-8 ltr:float-right rtl:float-left  ">
                    <Button
                      type="button"
                      className="rounded-md bg-white font-extrabold "
                      onClick={() => handleModalClose()}
                    >
                      {selectedContent[localizationKeys.cancel]}
                    </Button>
                    {!isEditingVacancy && (
                      <Button
                        type="submit"
                        primary
                        basic
                        className={`rounded-md font-bold ltr:mr-4 rtl:ml-4`}
                      >
                        {selectedContent[localizationKeys.saveDraft]}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      primary
                      className={`w-28 rounded-md ${
                        isSmall ? "text-sm" : "font-bold"
                      }`}
                      disabled={Object.keys(formik.errors).length > 0}
                      onClick={() => setWantToPublish(true)}
                    >
                      {isEditingVacancy
                        ? selectedContent[localizationKeys.save]
                        : selectedContent[localizationKeys.publish]}
                    </Button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Modal.Content>
      <Confirm
        closeOnEscape
        className="text-center text-xl font-extrabold text-black "
        cancelButton={selectedContent[localizationKeys.no]}
        confirmButton={selectedContent[localizationKeys.publish]}
        content={selectedContent[localizationKeys.publishVacancyTitle]}
        size="tiny"
        open={isPublishingVacancy}
        onCancel={() => setIsPublishingVacancy(false)}
        onConfirm={() => publishVacancy(newVacancy?._id)}
      />
      <HideIdentityInfo
        isOpen={viewingHideIdentityInfoPopup}
        onClose={() => {
          setIsViewingHideIdentityInfoPopup(false);
        }}
      />
    </Modal>
  );
};

export default AddVacancy;

const HideIdentityInfo = ({ isOpen, onClose }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="tiny"
      closeOnEscape
      closeIcon
      className="mt-28"
    >
      <Modal.Content className="">
        <div className="mx-auto mt-4  text-center text-lg font-bold">
          {selectedContent[localizationKeys.hideIdentityTitle]}
        </div>
        <HiddenIdentityIcon
          size="200"
          className="mx-auto h-[13rem] w-[20rem] text-center"
        />
        <div className="mx-auto mt-4 mb-4 w-9/12 text-center text-lg">
          {selectedContent[localizationKeys.hideIdentityBody]}
        </div>
        <div className="flex items-center justify-center">
          <Button primary onClick={onClose} className="w-64 rounded">
            {selectedContent[localizationKeys.okUnderstand]}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
