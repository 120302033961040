import React from "react";

function PublishmentsNotFoundIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 254.243 189.441"
    >
      <g transform="translate(0 -55)">
        <g transform="translate(0 55)">
          <path
            fill="#ebebeb"
            d="M0 0H254.243V0.127H0z"
            data-name="Rectangle 5255"
            transform="translate(0 166.478)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H16.841V0.127H0z"
            data-name="Rectangle 5256"
            transform="translate(211.927 174.66)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H4.419V0.127H0z"
            data-name="Rectangle 5257"
            transform="translate(164.002 176.043)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H9.758V0.127H0z"
            data-name="Rectangle 5258"
            transform="translate(201.66 169.941)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H21.961V0.127H0z"
            data-name="Rectangle 5259"
            transform="translate(26.675 170.795)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H3.219V0.127H0z"
            data-name="Rectangle 5260"
            transform="translate(53.167 170.795)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H47.635V0.127H0z"
            data-name="Rectangle 5261"
            transform="translate(66.851 172.941)"
          ></path>
          <path
            fill="#ebebeb"
            d="M139.292 198.8H41.108a2.9 2.9 0 01-2.9-2.9V57.878a2.9 2.9 0 012.9-2.878h98.183a2.9 2.9 0 012.9 2.9v138a2.9 2.9 0 01-2.899 2.9zM41.108 55.1a2.776 2.776 0 00-2.771 2.776V195.9a2.776 2.776 0 002.771 2.776h98.183a2.781 2.781 0 002.776-2.776V57.878a2.781 2.781 0 00-2.776-2.776z"
            data-name="Path 16728"
            transform="translate(-18.781 -55)"
          ></path>
          <path
            fill="#ebebeb"
            d="M355.592 198.8H257.4a2.909 2.909 0 01-2.9-2.9V57.878A2.909 2.909 0 01257.4 55h98.189a2.9 2.9 0 012.893 2.878V195.9a2.9 2.9 0 01-2.89 2.9zM257.4 55.1a2.781 2.781 0 00-2.776 2.776V195.9a2.781 2.781 0 002.776 2.776h98.189a2.781 2.781 0 002.776-2.776V57.878a2.781 2.781 0 00-2.776-2.776z"
            data-name="Path 16729"
            transform="translate(-125.09 -55)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H64.283V4.536H0z"
            data-name="Rectangle 5262"
            transform="translate(46.796 117.394)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H27.951V4.536H0z"
            data-name="Rectangle 5263"
            transform="translate(83.127 117.394)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H9.499V44.548H0z"
            data-name="Rectangle 5264"
            transform="translate(46.796 121.93)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M101.529 299.387l-9.5-3.234v-1.363h9.5z"
            data-name="Path 16730"
            transform="translate(-45.234 -172.86)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H9.499V44.548H0z"
            data-name="Rectangle 5265"
            transform="translate(101.58 121.93)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H9.499V44.548H0z"
            data-name="Rectangle 5266"
            transform="translate(83.127 121.93)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M172.973 299.387l-9.493-3.234v-1.363h9.493z"
            data-name="Path 16731"
            transform="translate(-80.353 -172.86)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H9.499V44.548H0z"
            data-name="Rectangle 5267"
            transform="translate(65.249 121.93)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H81.017V68.986H0z"
            data-name="Rectangle 5268"
            transform="translate(36.102 16.358)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H0.905V68.986H0z"
            data-name="Rectangle 5269"
            transform="rotate(180 59.013 42.67)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H62.884V74.915H0z"
            data-name="Rectangle 5270"
            transform="rotate(90 47.335 66.739)"
          ></path>
          <path
            fill="#fff"
            d="M148.89 164.283c4.576-15.255 5.848-37.755 15.58-34.653S161.455 166 161.455 166z"
            data-name="Path 16732"
            transform="translate(-73.182 -91.539)"
          ></path>
          <path
            fill="#fff"
            d="M140.778 150.447c-4.658-22.109-3.483-51.459-17.955-50.788s0 51.749 0 51.749z"
            data-name="Path 16733"
            transform="translate(-57.208 -76.945)"
          ></path>
          <path
            fill="#ebebeb"
            d="M151.275 194.669h-31.683a2.466 2.466 0 00-2.542 2.344c-.646 14.594 1.744 29.492 6.193 44.03H147.5c4.449-14.563 6.839-29.436 6.193-44.03a2.441 2.441 0 00-2.42-2.344z"
            data-name="Path 16734"
            transform="translate(-57.48 -123.649)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H1.785V142.427H0z"
            data-name="Rectangle 5271"
            transform="translate(164.083 24.051)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H1.785V142.427H0z"
            data-name="Rectangle 5272"
            transform="translate(206.028 24.051)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H1.785V142.427H0z"
            data-name="Rectangle 5273"
            transform="translate(151.427 24.051)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H1.785V142.427H0z"
            data-name="Rectangle 5274"
            transform="translate(193.372 24.051)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H56.386V1.947H0z"
            data-name="Rectangle 5275"
            transform="translate(151.427 23.075)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H56.386V1.947H0z"
            data-name="Rectangle 5276"
            transform="translate(151.427 57.952)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H56.386V1.947H0z"
            data-name="Rectangle 5277"
            transform="translate(151.427 92.829)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H56.386V1.947H0z"
            data-name="Rectangle 5278"
            transform="translate(151.427 127.706)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H56.386V1.947H0z"
            data-name="Rectangle 5279"
            transform="translate(151.427 162.583)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H12.656V1.947H0z"
            data-name="Rectangle 5280"
            transform="translate(195.157 23.075)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H12.656V1.947H0z"
            data-name="Rectangle 5281"
            transform="translate(195.157 57.952)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H12.656V1.947H0z"
            data-name="Rectangle 5282"
            transform="translate(195.157 92.829)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H12.656V1.947H0z"
            data-name="Rectangle 5283"
            transform="translate(195.157 127.706)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H12.656V1.947H0z"
            data-name="Rectangle 5284"
            transform="translate(195.157 162.583)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M329.7 121.056v-2.746h-6.778v2.746a3.249 3.249 0 01-3.244 3.249 3.249 3.249 0 00-3.249 3.244v16.536H336.2v-16.536a3.249 3.249 0 00-3.249-3.244 3.249 3.249 0 01-3.249-3.249z"
            data-name="Path 16735"
            transform="translate(-155.53 -86.118)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H29.706V11.344H0z"
            data-name="Rectangle 5285"
            transform="translate(158.495 81.49)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H5.171V11.344H0z"
            data-name="Rectangle 5286"
            transform="rotate(180 96.686 46.415)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H5.171V1.251H0z"
            data-name="Rectangle 5287"
            transform="rotate(180 96.686 43.893)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H29.706V1.251H0z"
            data-name="Rectangle 5288"
            transform="translate(158.495 86.534)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H23.202V20.055H0z"
            data-name="Rectangle 5289"
            transform="translate(157.041 142.529)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H4.037V20.055H0z"
            data-name="Rectangle 5290"
            transform="rotate(180 92.14 81.294)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H4.037V2.212H0z"
            data-name="Rectangle 5291"
            transform="rotate(180 92.14 76.832)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H23.202V2.212H0z"
            data-name="Rectangle 5292"
            transform="translate(157.041 151.452)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H4.037V0.788H0z"
            data-name="Rectangle 5293"
            transform="rotate(180 92.14 74.951)"
          ></path>
          <path
            fill="#f5f5f5"
            d="M0 0H23.202V0.788H0z"
            data-name="Rectangle 5294"
            transform="translate(157.041 149.113)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H4.2V20.39H0z"
            data-name="Rectangle 5295"
            transform="translate(158.495 107.316)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H4.2V5.858H0z"
            data-name="Rectangle 5296"
            transform="translate(158.495 114.582)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H14.288V20.39H0z"
            data-name="Rectangle 5297"
            transform="rotate(180 88.492 63.856)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H4.744V23.024H0z"
            data-name="Rectangle 5298"
            transform="translate(164.439 104.682)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H4.744V3.544H0z"
            data-name="Rectangle 5299"
            transform="translate(164.439 111.999)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H4.744V3.544H0z"
            data-name="Rectangle 5300"
            transform="translate(164.439 117.918)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H16.134V23.024H0z"
            data-name="Rectangle 5301"
            transform="rotate(180 92.662 63.853)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M0 0H2.558V20.39H0z"
            data-name="Rectangle 5302"
            transform="translate(171.909 107.316)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H1.678V15.224H0z"
            data-name="Rectangle 5303"
            transform="translate(172.346 109.899)"
          ></path>
          <path
            fill="#e6e6e6"
            d="M0 0H8.69V20.39H0z"
            data-name="Rectangle 5304"
            transform="rotate(180 91.576 63.856)"
          ></path>
          <path
            fill="#ebebeb"
            d="M0 0H2.888V21.463H0z"
            data-name="Rectangle 5305"
            transform="translate(178.407 106.243)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H2.888V3.305H0z"
            data-name="Rectangle 5306"
            transform="translate(178.407 113.062)"
          ></path>
          <path
            fill="#fafafa"
            d="M0 0H2.888V3.305H0z"
            data-name="Rectangle 5307"
            transform="translate(178.407 118.584)"
          ></path>
          <path
            fill="#e0e0e0"
            d="M0 0H9.814V21.463H0z"
            data-name="Rectangle 5308"
            transform="rotate(180 95.555 63.853)"
          ></path>
          <circle
            cx="6.326"
            cy="6.326"
            r="6.326"
            fill="#f5f5f5"
            data-name="Ellipse 180"
            transform="rotate(-45 174.013 -163.479)"
          ></circle>
          <circle
            cx="4.383"
            cy="4.383"
            r="4.383"
            fill="#ebebeb"
            data-name="Ellipse 181"
            transform="rotate(-9.22 544.307 -1063.81)"
          ></circle>
        </g>
        <g transform="translate(28.531 232.929)">
          <ellipse
            cx="98.59"
            cy="5.756"
            fill="#f5f5f5"
            rx="98.59"
            ry="5.756"
          ></ellipse>
        </g>
        <g transform="translate(58.179 190.532)">
          <path
            fill="#177ba3"
            d="M126.11 338.69h65.31v33.718a3.29 3.29 0 01-3.29 3.29H129.4a3.29 3.29 0 01-3.29-3.29z"
            data-name="Path 16736"
            transform="translate(-120.164 -329.969)"
          ></path>
          <path
            fill="#177ba3"
            d="M120.363 330.261h46.216l-5.685-7.276a4.891 4.891 0 00-3.458-1.444h-41.563c-1.281 0-1.82.646-1.195 1.444z"
            data-name="Path 16737"
            transform="translate(-114.417 -321.54)"
          ></path>
          <path
            d="M120.363 330.261h46.216l-5.685-7.276a4.891 4.891 0 00-3.458-1.444h-41.563c-1.281 0-1.82.646-1.195 1.444z"
            data-name="Path 16738"
            opacity="0.2"
            transform="translate(-114.417 -321.54)"
          ></path>
          <path
            fill="#177ba3"
            d="M209.881 333.195H163.67l5.685-4.744a6.5 6.5 0 013.458-.941h41.559c1.286 0 1.82.422 1.195.941z"
            data-name="Path 16739"
            transform="translate(-138.626 -324.474)"
          ></path>
          <path
            fill="#fff"
            d="M163.67 338.69h46.211v33.718a3.29 3.29 0 01-3.29 3.29H166.96a3.29 3.29 0 01-3.29-3.29z"
            data-name="Path 16740"
            opacity="0.2"
            transform="translate(-138.626 -329.969)"
          ></path>
          <path
            fill="#fff"
            d="M137.39 338.69v23.126l1.6-1.261 1.124 1.261.976-1.4 1 1.4 1.393-1.053 1.536 1.053V338.69z"
            data-name="Path 16741"
            opacity="0.5"
            transform="translate(-125.709 -329.969)"
          ></path>
        </g>
        <g transform="translate(66.098 70.285)">
          <path
            fill="none"
            stroke="#177ba3"
            strokeWidth="1"
            d="M8.212 6.895L0 0"
            data-name="Line 749"
            transform="translate(5.888 71.625)"
          ></path>
          <path
            fill="none"
            stroke="#177ba3"
            strokeWidth="1"
            d="M2.502 2.105L0 0"
            data-name="Line 750"
            transform="translate(15.855 79.99)"
          ></path>
          <path
            fill="#177ba3"
            d="M178.769 212.885l27.092-33.86-36.072-30.285-23.9 27.423-3.559 6.859a1.912 1.912 0 00.239 2.792l32.543 27.326a2.7 2.7 0 003.657-.255z"
            data-name="Path 16742"
            transform="translate(-135.812 -116.36)"
          ></path>
          <path
            fill="#fff"
            d="M174.16 225.78l-3.9 6.28 1.571.341.534 1.454 1.042.061.483 1.246 1.276.371.981 1.561 3.9-6.28z"
            data-name="Path 16743"
            opacity="0.5"
            transform="translate(-149.783 -154.226)"
          ></path>
          <path
            d="M208.586 178.034l-24.3 27.1-34.506-28.971 23.914-27.423z"
            data-name="Path 16744"
            opacity="0.1"
            transform="translate(-139.717 -116.36)"
          ></path>
          <path
            fill="#177ba3"
            d="M241.945 206.35l-24.3 27.1 10.058.742 23.919-27.423z"
            data-name="Path 16745"
            transform="translate(-173.076 -144.676)"
          ></path>
          <path
            d="M241.945 206.35l-24.3 27.1 10.058.742 23.919-27.423z"
            data-name="Path 16746"
            opacity="0.3"
            transform="translate(-173.076 -144.676)"
          ></path>
          <path
            fill="#177ba3"
            d="M154.291 147.9l-24.3 27.107 10.063.742 23.914-27.423z"
            data-name="Path 16747"
            transform="translate(-129.99 -115.947)"
          ></path>
          <path
            d="M154.291 147.9l-24.3 27.107 10.063.742 23.914-27.423z"
            data-name="Path 16748"
            opacity="0.4"
            transform="translate(-129.99 -115.947)"
          ></path>
          <path
            d="M219.907 186.378l7.48-8.344-34.892-29.294-4.465 10.76z"
            data-name="Path 16749"
            opacity="0.1"
            transform="translate(-158.518 -116.36)"
          ></path>
          <path
            d="M149.78 176.163l23.914-27.423-4.464 10.76-16.841 18.855z"
            data-name="Path 16750"
            opacity="0.2"
            transform="translate(-139.717 -116.36)"
          ></path>
          <path
            fill="#263238"
            d="M281.217 105.522c.1.325 0 .646-.214.707s-.463-.153-.559-.478 0-.646.214-.712.467.161.559.483z"
            data-name="Path 16751"
            transform="translate(-203.922 -94.874)"
          ></path>
          <path
            fill="#ed847e"
            d="M281.352 107.26a12.205 12.205 0 01-.742 3.27 1.978 1.978 0 001.658-.168z"
            data-name="Path 16752"
            transform="translate(-204.022 -95.972)"
          ></path>
          <path
            fill="#263238"
            d="M281.484 101.249a.2.2 0 00.091-.112.193.193 0 00-.122-.254 1.983 1.983 0 00-1.82.208.2.2 0 00.244.32 1.586 1.586 0 011.444-.153.2.2 0 00.163-.009z"
            data-name="Path 16753"
            transform="translate(-203.513 -92.773)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M296.789 109.76c.224 2.695.89 7.627 2.481 8.766 0 0-.376 3.239-6.1 4.068-6.275.925-3.458-2.675-3.458-2.675 3.3-1.322 2.837-3.849 1.892-6.148l2.42-2.776c.929-1.026 2.643-2.613 2.765-1.235z"
            data-name="Path 16754"
            transform="translate(-208.185 -96.936)"
          ></path>
          <path
            fill="#fff"
            d="M301.2 127.376c.508-.463-.508-2.314-1.134-2.573-1.637-.686-8.334 0-10.851 2.161a2.776 2.776 0 00-.2 2.385z"
            data-name="Path 16755"
            transform="translate(-208.065 -104.468)"
          ></path>
          <path
            fill="#177ba3"
            d="M353.915 396.577a1.363 1.363 0 01-1.058.03.417.417 0 01-.137-.458.463.463 0 01.234-.315c.549-.32 1.907.1 1.963.122a.1.1 0 01.071.081.1.1 0 01-.051.1 5.471 5.471 0 01-1.022.44zm-.742-.62l-.117.051a.275.275 0 00-.137.183c-.036.183.036.239.056.254.244.173 1.073-.076 1.647-.366a3.331 3.331 0 00-1.45-.123z"
            data-name="Path 16756"
            transform="translate(-239.454 -237.751)"
          ></path>
          <path
            fill="#177ba3"
            d="M355.436 395.005a.087.087 0 01-.051 0c-.509-.1-1.571-.737-1.627-1.215a.325.325 0 01.229-.346.549.549 0 01.447 0c.59.244 1.047 1.383 1.063 1.429a.092.092 0 010 .1.076.076 0 01-.061.032zm-1.322-1.388a.082.082 0 00-.046 0c-.117.051-.112.112-.107.137.03.285.753.8 1.266.986a2.509 2.509 0 00-.87-1.124.341.341 0 00-.243.001z"
            data-name="Path 16757"
            transform="translate(-239.974 -236.612)"
          ></path>
          <path
            fill="#177ba3"
            d="M295.208 407.16c-.544 0-1.068-.076-1.246-.32a.376.376 0 010-.437.508.508 0 01.315-.239c.707-.188 2.192.656 2.253.692a.1.1 0 01-.03.183 7.189 7.189 0 01-1.292.121zm-.671-.824a.575.575 0 00-.193.025.32.32 0 00-.2.137c-.071.127-.046.188 0 .224.2.275 1.271.28 2.034.168a4.515 4.515 0 00-1.641-.554z"
            data-name="Path 16758"
            transform="translate(-210.55 -242.874)"
          ></path>
          <path
            fill="#177ba3"
            d="M297.556 406.073h-.041c-.508-.229-1.525-1.149-1.449-1.627 0-.112.1-.254.376-.28a.722.722 0 01.554.173 2.847 2.847 0 01.661 1.632.092.092 0 01-.041.092.1.1 0 01-.06.01zm-1.017-1.719h-.061c-.183 0-.193.092-.2.112-.046.29.646 1.017 1.164 1.327a2.324 2.324 0 00-.559-1.312.509.509 0 00-.344-.127z"
            data-name="Path 16759"
            transform="translate(-211.617 -241.903)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M303.668 398.224h-4.246L299 388.4h4.246z"
            data-name="Path 16760"
            transform="translate(-213.061 -234.156)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M359.128 385.58l-3.91 1.347-4.058-9.26 3.91-1.347z"
            data-name="Path 16761"
            transform="translate(-238.698 -228.218)"
          ></path>
          <path
            fill="#263238"
            d="M351.627 393.9l4.576-1.4a.371.371 0 01.432.173l1.907 3.356a.625.625 0 01-.371.905c-1.6.463-2.4.605-4.4 1.22-1.236.376-4.348 1.556-6.1 1.907s-2.319-1.327-1.637-1.627a10.183 10.183 0 005.029-4.068 1.047 1.047 0 01.57-.468z"
            data-name="Path 16762"
            transform="translate(-236.038 -236.165)"
          ></path>
          <path
            fill="#263238"
            d="M291.037 406.75h4.632a.376.376 0 01.361.29l.839 3.773a.615.615 0 01-.61.753c-1.673-.025-4.068-.127-6.188-.127-2.456 0-3.488.137-6.371.137-1.739 0-2.222-1.759-1.495-1.922 3.32-.727 4.943-.8 7.81-2.573a2 2 0 011.022-.331z"
            data-name="Path 16763"
            transform="translate(-204.645 -243.175)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M316.492 132.84c1.236 1.134 2.3 2.268 3.412 3.453a48 48 0 013.132 3.717 35.46 35.46 0 012.827 4.19 18.279 18.279 0 011.19 2.512c.086.239.173.468.244.727a7.9 7.9 0 01.208.8l.086.422.061.468a4.909 4.909 0 010 1.292 5.4 5.4 0 01-.875 2.258 7.5 7.5 0 01-1.169 1.347 13.568 13.568 0 01-2.319 1.693 30.117 30.117 0 01-4.576 2.131 55.143 55.143 0 01-9.244 2.542l-.89-3.051c2.761-1.195 5.558-2.487 8.136-3.865a30.791 30.791 0 003.615-2.227 8.135 8.135 0 001.317-1.17 1.846 1.846 0 00.3-.432c.041-.086 0-.076-.051.107-.036.341-.031.076-.112-.107-.025-.086-.071-.2-.117-.315s-.1-.239-.158-.361a16.1 16.1 0 00-.92-1.612 38.427 38.427 0 00-2.541-3.359c-.936-1.108-1.922-2.212-2.934-3.295s-2.075-2.176-3.051-3.183z"
            data-name="Path 16764"
            transform="translate(-217.77 -108.545)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M307 180.07l-4 1.21 2.573 4.231s2.364-.976 2.888-2.985z"
            data-name="Path 16765"
            transform="translate(-215.027 -131.759)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M299.82 183.95l1.576 3.488 2.588-.753-2.573-4.236z"
            data-name="Path 16766"
            transform="translate(-213.464 -132.929)"
          ></path>
          <path
            fill="#177ba3"
            d="M305.688 131.273c-.381 4.068 8.222 10.363 8.222 10.363l8.283-5.67a17.43 17.43 0 00-6.1-6.483c-4.751-3.041-9.927-3.264-10.405 1.79z"
            data-name="Path 16767"
            transform="translate(-216.342 -105.828)"
          ></path>
          <g
            data-name="Group 22311"
            opacity="0.3"
            transform="translate(89.334 21.485)"
          >
            <path
              fill="#fff"
              d="M305.688 131.273c-.381 4.068 8.222 10.363 8.222 10.363l8.283-5.67a17.43 17.43 0 00-6.1-6.483c-4.751-3.041-9.927-3.264-10.405 1.79z"
              data-name="Path 16768"
              transform="translate(-305.676 -127.314)"
            ></path>
          </g>
          <path
            fill="#177ba3"
            d="M277.249 130.9s-3.717 5.705 5.11 36.306l22.441-3.316c-.315-3.1-4.068-16.633-4.2-31.424a5.288 5.288 0 00-5.563-5.242c-.651.036-1.322.081-1.988.147a83.649 83.649 0 00-9.539 1.409 43.032 43.032 0 00-6.261 2.12z"
            data-name="Path 16769"
            transform="translate(-201.988 -105.78)"
          ></path>
          <g
            data-name="Group 22312"
            opacity="0.3"
            transform="translate(74.484 21.436)"
          >
            <path
              fill="#fff"
              d="M277.249 130.9s-3.717 5.705 5.11 36.306l22.441-3.316c-.315-3.1-4.068-16.633-4.2-31.424a5.288 5.288 0 00-5.563-5.242c-.651.036-1.322.081-1.988.147a83.649 83.649 0 00-9.539 1.409 43.032 43.032 0 00-6.261 2.12z"
              data-name="Path 16770"
              transform="translate(-276.472 -127.216)"
            ></path>
          </g>
          <path
            d="M276.92 153.716l.076-.086 5.278.468c.8 3.407-2.136 8.054-3.687 10.21a99.159 99.159 0 01-1.668-10.592z"
            data-name="Path 16771"
            opacity="0.2"
            transform="translate(-202.208 -118.763)"
          ></path>
          <path
            d="M299 388.41l.219 5.065h4.246l-.219-5.065z"
            data-name="Path 16772"
            opacity="0.2"
            transform="translate(-213.061 -234.161)"
          ></path>
          <path
            d="M355.07 376.32l-3.91 1.347 2.095 4.775 3.91-1.347z"
            data-name="Path 16773"
            opacity="0.2"
            transform="translate(-238.698 -228.218)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M292.112 93.961c.936 4.1 1.688 6.458.259 9.153-2.146 4.012-7.668 3.626-9.809-.117-1.927-3.371-3.137-9.514.341-12.28a5.746 5.746 0 019.209 3.244z"
            data-name="Path 16774"
            transform="translate(-204.063 -87.218)"
          ></path>
          <path
            fill="#263238"
            d="M282.841 97.6l-1.561.956.147-5.339a4.612 4.612 0 01-2.827-.817c.564-.539 9.351-3.615 12.092-3.081.285-.117.631-.646.631-.646s.107.666-.356.92a1.5 1.5 0 011.409.59 1.836 1.836 0 00-1.164.066s3.051.463 1.968 4.693a20.268 20.268 0 00-.814 7.343s-2.268 1.754-4.241 1.429c-2.106-2.322-5.147-5.108-5.284-6.114z"
            data-name="Path 16775"
            transform="translate(-203.034 -86.834)"
          ></path>
          <path
            fill="#263238"
            d="M277.192 91.5a4.145 4.145 0 01-4-2.227c1.78-.188 5.487-1.332 8.237-1.525s5.766.32 4.856 1.525-9.093 2.227-9.093 2.227z"
            data-name="Path 16776"
            transform="translate(-200.375 -86.363)"
          ></path>
          <path
            fill="#263238"
            d="M279.2 89.1c-2.034-.285-4.017-2.2-2.492-4.037.564 1.347 4.373 1.19 7.078.625 3.559-.742 6.026.458 4.765 2.883-.692 1.329-9.351.529-9.351.529z"
            data-name="Path 16777"
            transform="translate(-201.838 -85.06)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M288.456 102.6a4.775 4.775 0 01-.132 3.051c-.508 1.276-1.637 1.017-2.227-.086-.508-1.017-.895-2.863-.031-3.783s2.024-.388 2.39.818z"
            data-name="Path 16778"
            transform="translate(-206.452 -93.049)"
          ></path>
          <path
            fill="#263238"
            d="M303.71 201.481s3.641 30.992 6.285 43.135c2.893 13.3 17.166 49.567 17.166 49.567l7.119-2.461s-9.153-27.306-11.583-45.9c-1.866-14.278-4.526-46.481-4.526-46.481z"
            data-name="Path 16779"
            transform="translate(-215.376 -141.23)"
          ></path>
          <path
            fill="#177ba3"
            d="M346.249 379.321c-.036 0 1.459 3.051 1.459 3.051l8.314-2.7-.976-2.481z"
            data-name="Path 16780"
            transform="translate(-236.284 -228.646)"
          ></path>
          <path
            d="M307.66 234.82c1.139 8.614 2.6 18.814 3.88 25.328 1.886-9.982 2.329-22.587-3.88-25.328z"
            data-name="Path 16781"
            opacity="0.3"
            transform="translate(-217.317 -158.669)"
          ></path>
          <path
            fill="#263238"
            d="M285.543 203.555s-3.02 29.116-2.542 42.052c.478 13.455 6.763 54.855 6.763 54.855h7.185s-1.322-40.618-1.017-53.828c.351-14.405 5.751-45.464 5.751-45.464z"
            data-name="Path 16782"
            transform="translate(-205.172 -142.13)"
          ></path>
          <path
            fill="#177ba3"
            d="M293.6 390.81c-.031 0 .407 2.98.407 2.98h8.741l.239-2.659z"
            data-name="Path 16783"
            transform="translate(-210.405 -235.341)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M257.3 145.168c-.793 1.261-1.6 2.395-2.431 3.559s-1.708 2.288-2.6 3.407a64.363 64.363 0 01-5.883 6.417c-.569.508-1.093 1.017-1.708 1.48l-.447.361-.224.178c-.081.066-.117.1-.285.224a6.4 6.4 0 01-1.642.844 9.55 9.55 0 01-2.812.508 15.673 15.673 0 01-2.425-.107 30.545 30.545 0 01-8.517-2.41l.88-3.051a56.007 56.007 0 007.841.376 15.516 15.516 0 001.729-.188 4.16 4.16 0 001.241-.386c.122-.066.188-.132.173-.147s.066-.066.112-.112l.173-.158.341-.32c.458-.407.89-.9 1.337-1.347.88-.946 1.754-1.917 2.583-2.949s1.668-2.07 2.481-3.137 1.6-2.151 2.395-3.234 1.566-2.222 2.3-3.275z"
            data-name="Path 16784"
            transform="translate(-178.326 -112.899)"
          ></path>
          <path
            fill="#177ba3"
            d="M276.95 140.108c1.708 5.593-8.212 11.792-8.212 11.792l-6.448-8.212a35.965 35.965 0 015.339-7.47c4.388-4.775 8.019-.366 9.321 3.89z"
            data-name="Path 16785"
            transform="translate(-195.017 -109.187)"
          ></path>
          <g
            data-name="Group 22313"
            opacity="0.3"
            transform="translate(67.273 24.96)"
          >
            <path
              fill="#fff"
              d="M276.95 140.108c1.708 5.593-8.212 11.792-8.212 11.792l-6.448-8.212a35.965 35.965 0 015.339-7.47c4.388-4.775 8.019-.366 9.321 3.89z"
              data-name="Path 16786"
              transform="translate(-262.29 -134.147)"
            ></path>
          </g>
          <path
            fill="#ffc3bd"
            d="M227.952 170.406l-5.222-.956.549 4.515a3.749 3.749 0 004.683-.859z"
            data-name="Path 16787"
            transform="translate(-175.573 -126.539)"
          ></path>
          <path
            fill="#ffc3bd"
            d="M218.77 170.467l1.129 3.259 1.424.239-.549-4.515z"
            data-name="Path 16788"
            transform="translate(-173.627 -126.539)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default PublishmentsNotFoundIcon;
