import React from "react";

function DraftedJobsIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 13.816 18.247"
    >
      <path
        // fill="#fff"
        stroke="#fff"
        strokeWidth="0.65"
        d="M11.873.325h-9.93A1.618 1.618 0 00.325 1.943v14.682a1.3 1.3 0 002.221.889l3.785-3.784a.819.819 0 011.153 0l3.785 3.784a1.3 1.3 0 002.221-.889V1.943A1.618 1.618 0 0011.873.325zm.588 16.3a.265.265 0 01-.464.16c-.757-.758-2.914-2.915-3.783-3.782a1.857 1.857 0 00-2.61 0l-2.7 2.7-1.078 1.077a.264.264 0 01-.469-.144V1.943a.588.588 0 01.588-.588h9.929a.588.588 0 01.588.588z"
        data-name="save-instagram (1)"
      ></path>
    </svg>
  );
}

export default DraftedJobsIcon;
