import React from "react";

function PaymentIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20.193 16.706"
    >
      <g
        // fill="#fff"
        stroke="#fff"
        strokeWidth="0.6"
        transform="translate(.3 -10.699)"
      >
        <path
          d="M18.639 13.52l-14.022-2.5a1.149 1.149 0 00-1.318.931L2.4 17.13H1.105A1.107 1.107 0 000 18.235V26a1.107 1.107 0 001.105 1.105h14.3A1.107 1.107 0 0016.513 26v-2.73a1.614 1.614 0 001.187-.07 1.164 1.164 0 00.545-.79l1.319-7.565a1.147 1.147 0 00-.925-1.325zM3.921 12.057a.512.512 0 01.585-.417l14.022 2.5a.515.515 0 01.41.6l-.218 1.249-15.02-2.68zM15.881 26a.474.474 0 01-.474.474H1.105A.474.474 0 01.632 26v-7.765a.474.474 0 01.474-.474h14.3a.474.474 0 01.474.474zm1.738-3.7c-.1.562-.722.393-1.107.324v-4.389a1.107 1.107 0 00-1.105-1.105H3.036l.557-3.2.915.163 14.1 2.516z"
          data-name="Path 16718"
        ></path>
        <path
          d="M7.439 25.211H5.624a.316.316 0 110-.632h1.815a.316.316 0 110 .632z"
          data-name="Path 16719"
        ></path>
        <path
          d="M3.987 25.211H2.174a.316.316 0 110-.632h1.813a.316.316 0 010 .632z"
          data-name="Path 16720"
        ></path>
        <path
          d="M10.89 25.211H9.073a.316.316 0 110-.632h1.817a.316.316 0 110 .632z"
          data-name="Path 16721"
        ></path>
        <path
          d="M14.338 25.211h-1.815a.316.316 0 110-.632h1.816a.316.316 0 010 .632z"
          data-name="Path 16722"
        ></path>
        <path
          d="M4.806 22.573H2.518a.79.79 0 01-.789-.789v-.907a.79.79 0 01.789-.789h2.287a.79.79 0 01.789.789v.907a.791.791 0 01-.788.789zm-2.287-1.854a.158.158 0 00-.158.158v.907a.158.158 0 00.158.158h2.287a.158.158 0 00.158-.158v-.907a.158.158 0 00-.158-.158z"
          data-name="Path 16723"
        ></path>
      </g>
    </svg>
  );
}

export default PaymentIcon;
