import React from 'react'

function KsaFlagIcon({className, size = '1em'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 25.914 16.978"
    >
      <g transform="translate(0 -88.276)">
        <path
          fill="#73af00"
          d="M23.974 105.254H1.941A1.941 1.941 0 010 103.314v-13.1a1.941 1.941 0 011.941-1.941h22.033a1.941 1.941 0 011.941 1.941v13.1a1.941 1.941 0 01-1.941 1.94z"
          data-name="Path 13929"
        ></path>
        <g
          fill="#f5f5f5"
          data-name="Group 12463"
          transform="translate(5.123 91.85)"
        >
          <path
            d="M102.388 167.9c.044.282.215.371.363.359a.365.365 0 00.328-.342v-.969a.088.088 0 11.176 0v.973a.352.352 0 00.483.327l.088-.035c.008.813-.041 1.528-.042 1.541a.176.176 0 00.163.188h.013a.176.176 0 00.176-.164c0-.057.058-.84.042-1.706l.053-.021a.177.177 0 01.144.006.5.5 0 00.581-.094l.032-.032a.526.526 0 00.128-.541l-.15-.45a.176.176 0 00.131-.326l-.352-.176a.176.176 0 00-.246.213l.283.85a.175.175 0 01-.043.18l-.032.032a.151.151 0 01-.174.028.526.526 0 00-.366-.037 6.075 6.075 0 00-.215-1.517.176.176 0 00-.334.111 6.056 6.056 0 01.2 1.537l-.211.084v-.973a.44.44 0 10-.881 0v.853l-.18-.888a.176.176 0 10-.345.07z"
            data-name="Path 13930"
            transform="translate(-102.149 -165.706)"
          ></path>
          <path
            d="M109.336 163.122a.176.176 0 00.176-.176v-.176a.176.176 0 10-.352 0v.176a.176.176 0 00.176.176z"
            data-name="Path 13931"
            transform="translate(-108.758 -162.407)"
          ></path>
          <path
            d="M144.133 162.946h.176a.176.176 0 100-.352h-.176a.176.176 0 100 .352z"
            data-name="Path 13932"
            transform="translate(-141.794 -162.407)"
          ></path>
          <path
            d="M106.645 214.814l-.881.528a.176.176 0 10.181.3l.881-.528a.176.176 0 10-.181-.3z"
            data-name="Path 13933"
            transform="translate(-105.454 -211.96)"
          ></path>
          <path
            d="M118.857 168.874a.4.4 0 00.081.166.346.346 0 00.3.117.486.486 0 00.366-.193.32.32 0 00.037.022.334.334 0 00.31-.01.669.669 0 00.315-.62.18.18 0 00-.183-.167.177.177 0 00-.169.183s0 .211-.124.289a.429.429 0 01-.055-.148.176.176 0 00-.35.029c0 .026-.006.252-.18.271a.43.43 0 010-.23.176.176 0 00-.27-.186l-.1.067a57.913 57.913 0 00-.156-2.227.176.176 0 10-.351.032c0 .016.126 1.393.164 2.445l-.027.026a.454.454 0 00-.386-.3.643.643 0 00-.62.393.417.417 0 00.135.525.587.587 0 00.525.024 1.473 1.473 0 01-.286.543.739.739 0 01-.563.287.463.463 0 01-.342-.146 1.006 1.006 0 01-.186-.63.176.176 0 00-.166-.185.173.173 0 00-.186.166 1.351 1.351 0 00.277.885.808.808 0 00.59.262h.035a1.073 1.073 0 00.808-.41 1.822 1.822 0 00.4-.9 2 2 0 01-.845 1.581.879.879 0 01-.882.064.755.755 0 01-.387-.745.176.176 0 00-.349-.044 1.106 1.106 0 00.581 1.1 1.2 1.2 0 00.533.127 1.3 1.3 0 00.691-.208 2.342 2.342 0 001.012-1.882c.009-.118.006-.247.003-.373zm-1.062.182c-.031-.021-.007-.087 0-.1a.3.3 0 01.246-.173h.014c.023 0 .082.008.108.141q0 .021.007.043c-.198.115-.33.119-.375.089z"
            data-name="Path 13934"
            transform="translate(-115.291 -165.709)"
          ></path>
          <path
            d="M101.891 243.362a.177.177 0 00-.241.062.36.36 0 01-.03.042l-.006-.014a1.184 1.184 0 01.005-.6.176.176 0 00-.34-.091 1.517 1.517 0 00.005.812c.081.215.2.27.295.277h.024a.463.463 0 00.351-.252.176.176 0 00-.063-.236z"
            data-name="Path 13935"
            transform="translate(-101.225 -238.395)"
          ></path>
          <path
            d="M175.3 164.179a.175.175 0 00.109-.038l.888-.7a.169.169 0 00.029-.034.441.441 0 10-.506-.039l-.628.493a.176.176 0 00.109.314zm.8-1.233a.088.088 0 11-.088.088.088.088 0 01.088-.088z"
            data-name="Path 13936"
            transform="translate(-171.388 -162.407)"
          ></path>
          <path
            d="M185.409 190.44a.176.176 0 00-.223.111.538.538 0 00.069.519.355.355 0 00.263.1.773.773 0 00.243-.045.176.176 0 10-.112-.334.61.61 0 01-.144.031.337.337 0 01.015-.161.176.176 0 00-.111-.221z"
            data-name="Path 13937"
            transform="translate(-180.901 -188.835)"
          ></path>
          <path
            d="M227.742 163.145a.176.176 0 00.218-.12l.1-.352a.176.176 0 00-.338-.1l-.1.352a.176.176 0 00.12.22z"
            data-name="Path 13938"
            transform="translate(-221.218 -162.268)"
          ></path>
          <path
            d="M205.994 159.754a.176.176 0 10.178-.3c-.035-.021-.031-.079-.029-.09a.138.138 0 01.081-.118.176.176 0 00-.1-.336.484.484 0 00-.326.414.434.434 0 00.196.43z"
            data-name="Path 13939"
            transform="translate(-200.497 -158.897)"
          ></path>
          <path
            d="M178.93 232.189a.176.176 0 00-.033.349.331.331 0 01.033.179.176.176 0 00.352 0c0-.517-.337-.528-.352-.528z"
            data-name="Path 13940"
            transform="translate(-174.83 -228.479)"
          ></path>
          <path
            d="M234.777 208.333a.406.406 0 00.066.005.6.6 0 00.417-.208.176.176 0 10-.249-.249c-.105.105-.162.108-.177.1-.034 0-.064-.049-.071-.061a.176.176 0 00-.314.16.476.476 0 00.328.253z"
            data-name="Path 13941"
            transform="translate(-227.689 -205.353)"
          ></path>
          <path
            d="M293.931 190.942v-.008a.176.176 0 00-.334.113.4.4 0 00.287.274h.039a.431.431 0 00.341-.235.176.176 0 00-.293-.2.541.541 0 01-.04.056z"
            data-name="Path 13942"
            transform="translate(-283.849 -189.199)"
          ></path>
          <path
            d="M204.385 170.645c0-.751-.033-1.456-.079-2.073a8.921 8.921 0 01.618 1.254.176.176 0 00.165.114.178.178 0 00.062-.011.176.176 0 00.1-.227 11.666 11.666 0 00-1.019-1.93c-.1-.949-.2-1.551-.206-1.562a.176.176 0 00-.347.063c0 .008.057.318.123.84-.135-.2-.261-.382-.367-.531h.009a.176.176 0 10.111-.334l-.528-.176a.175.175 0 00-.2.064.178.178 0 00-.026.054.384.384 0 00-.368-.088.3.3 0 00-.168.313 1.591 1.591 0 00.122.5c.1.294.169.51.059.613a.193.193 0 01-.18.063c-.109-.028-.216-.172-.288-.387a.163.163 0 00-.014-.024 3 3 0 00-.054-.975.176.176 0 00-.345.069 1.677 1.677 0 01-.092 1.255.316.316 0 01-.263.119.1.1 0 01-.084-.039 1.7 1.7 0 01-.094-.993.176.176 0 00-.174-.2c-.3 0-.418.345-.519.649-.044.134-.127.382-.185.408-.107 0-.176-.313-.176-.529a.176.176 0 00-.176-.176.176.176 0 00-.176.176c0 .406.138.881.528.881.29 0 .408-.316.507-.611a1.1 1.1 0 00.2.626.452.452 0 00.35.161.67.67 0 00.536-.248.809.809 0 00.065-.1.657.657 0 00.369.277.508.508 0 00.124.016.559.559 0 00.384-.164.518.518 0 00.059-.071.475.475 0 00.13.02.713.713 0 00.365-.112.176.176 0 00-.189-.3.374.374 0 01-.2.052 1.818 1.818 0 00-.133-.579 2.451 2.451 0 01-.091-.294l.041.037a.176.176 0 00.249 0 .17.17 0 00.026-.039c.183.251.558.78.944 1.389.074.749.138 1.7.138 2.752a.176.176 0 00.352 0z"
            data-name="Path 13943"
            transform="translate(-194.649 -165.703)"
          ></path>
          <path
            d="M293.762 166.78a.176.176 0 10.352 0 1.8 1.8 0 00-.195-.607.176.176 0 00-.315.158 1.564 1.564 0 01.158.449z"
            data-name="Path 13944"
            transform="translate(-283.85 -165.712)"
          ></path>
          <path
            d="M263.229 249.96a.176.176 0 00-.236.078.5.5 0 01-.073.1.986.986 0 01.046-.326.176.176 0 00-.293-.176l-.352.352a.176.176 0 00.249.249l.009-.009a.364.364 0 00.094.185.322.322 0 00.229.1.313.313 0 00.048 0 .573.573 0 00.357-.319.176.176 0 00-.078-.234z"
            data-name="Path 13945"
            transform="translate(-254.119 -244.999)"
          ></path>
          <path
            d="M170.654 205.593h-1.241q.016-.066.034-.125c.5-.1.789-.263.876-.476a.394.394 0 00-.066-.4.437.437 0 00-.445-.19c-.25.051-.473.32-.633.752a2.024 2.024 0 00-1.017.44 2.729 2.729 0 00-.365-1.171.176.176 0 00-.293.2 2.543 2.543 0 01.323 1.311 1.394 1.394 0 01-1.057 1.409c-.2 0-.284-.235-.322-.434a2.023 2.023 0 00.846-.933 1.4 1.4 0 00-.117-.955l-.013-.031.011.01a.176.176 0 10.249-.249c-.1-.1-.3-.265-.485-.189a.3.3 0 00-.168.313.749.749 0 00.082.283 1.065 1.065 0 01.1.731 1.49 1.49 0 01-.576.636.173.173 0 00-.133-.065.176.176 0 00-.176.176v.1a9.262 9.262 0 01-1.133.62.176.176 0 00.073.336.174.174 0 00.073-.016 10.143 10.143 0 001.052-.563.685.685 0 00.636.579 1.5 1.5 0 001.178-.861 1.026 1.026 0 00.118.168 1.985 1.985 0 001.346.517.176.176 0 00.013-.352.377.377 0 01-.22-.107.3.3 0 01-.058-.231c.018-.217.718-.379 1.335-.389a.176.176 0 00.163-.117l.176-.5a.176.176 0 00-.166-.235zm-.773-.841h.013c.017 0 .043.008.079.056.007.009.028.04.023.053-.02.05-.142.132-.4.207.117-.219.226-.304.285-.316zm-1.064 2.315a1.078 1.078 0 01-.488-.3.749.749 0 01-.156-.62.52.52 0 01.037-.075c.248-.023.911.075.957.265a.591.591 0 00-.372.46.742.742 0 00.023.271z"
            data-name="Path 13946"
            transform="translate(-161.612 -202.049)"
          ></path>
          <path
            d="M291.507 166.719l-.176-.528a.176.176 0 00-.342.073c.122 1.215.351 3.585.351 3.857 0 .244-.434.725-1.135 1.075a.176.176 0 00.158.315c.641-.32 1.33-.892 1.33-1.39 0-.26-.18-2.133-.283-3.184a.176.176 0 00.097-.218z"
            data-name="Path 13947"
            transform="translate(-280.547 -165.707)"
          ></path>
          <path
            d="M335.926 194.084a.176.176 0 00.3-.168.889.889 0 01-.025-.342.179.179 0 00.183-.151.176.176 0 00-.149-.2.318.318 0 00-.29.1.4.4 0 00-.089.189l-.2-.2a.176.176 0 00-.249.249z"
            data-name="Path 13948"
            transform="translate(-323.496 -191.482)"
          ></path>
          <path
            d="M340.863 213.3l-.66.473a.176.176 0 10.205.286l.66-.473a.176.176 0 10-.205-.286z"
            data-name="Path 13949"
            transform="translate(-328.037 -210.511)"
          ></path>
          <path
            d="M352.779 225.73a.176.176 0 00.015.234.488.488 0 01.036.254.176.176 0 00.17.182h.006a.176.176 0 00.176-.17c.013-.359-.1-.491-.156-.531a.176.176 0 00-.247.031z"
            data-name="Path 13950"
            transform="translate(-340.012 -222.283)"
          ></path>
          <path
            d="M324.582 170.458a.176.176 0 00.176-.176c0-.26-.013-.55-.035-.852a6.425 6.425 0 01.413.767.176.176 0 00.162.107.174.174 0 00.069-.014.176.176 0 00.092-.231 9.7 9.7 0 00-.806-1.389 38.771 38.771 0 00-.229-1.734.17.17 0 00.11-.027.174.174 0 00.078-.159.171.171 0 00.019.051.641.641 0 01.064.431.176.176 0 00.137.208.186.186 0 00.036 0 .176.176 0 00.172-.141 1 1 0 00-.112-.692.177.177 0 00-.243-.054.174.174 0 00-.079.159.173.173 0 00-.02-.051l-.3-.462a.176.176 0 00-.322.126c0 .014.137.787.256 1.718-.135-.191-.27-.377-.4-.555-.021-.267-.043-.513-.061-.717l.029-.011a.176.176 0 00.079-.236l-.176-.352a.176.176 0 00-.333.1c.021.21.043.443.064.687-.111-.147-.215-.281-.3-.4a.176.176 0 00-.036-.291l-.352-.176a.176.176 0 00-.216.268c.009.012.443.555.962 1.257a14.164 14.164 0 01.065 2.628c-.016.046-.043.1-.068.1-.106.015-.361-.223-.488-.478a.245.245 0 00-.236-.16c-.189.012-.263.22-.4.6a2.48 2.48 0 01-.35.745 2 2 0 01-.219-.693.175.175 0 00.34-.059c0-.263-.184-2.263-.286-3.359a.176.176 0 00.1-.219l-.176-.528a.176.176 0 00-.342.072c.12 1.256.344 3.683.351 4.018v-.009a.176.176 0 10-.349.05c.051.36.183.979.486 1.08a.293.293 0 00.094.015.346.346 0 00.248-.115 2.539 2.539 0 00.436-.88l.085-.233c.162.236.444.53.729.507a.432.432 0 00.377-.343 9.92 9.92 0 00-.006-2.2c.148.208.3.421.439.631.055.527.094 1.056.094 1.49a.176.176 0 00.178.181z"
            data-name="Path 13951"
            transform="translate(-310.278 -165.714)"
          ></path>
          <path
            d="M382.632 169.941a97.28 97.28 0 00-.409-2.667.173.173 0 00.148-.031.176.176 0 00.029-.243l-.319-.418a.176.176 0 00-.314.137c0 .018.317 1.826.511 3.273a1.651 1.651 0 01-.29 1.322.779.779 0 01-.646.241.176.176 0 00-.022.352h.088a1.111 1.111 0 00.838-.355 1.967 1.967 0 00.386-1.611z"
            data-name="Path 13952"
            transform="translate(-366.991 -166.123)"
          ></path>
          <path
            d="M371.061 256.188a.178.178 0 00.009.247.074.074 0 01.021.036.083.083 0 01-.027.039.176.176 0 00.249.249.4.4 0 00.13-.3.419.419 0 00-.138-.287.174.174 0 00-.244.016z"
            data-name="Path 13953"
            transform="translate(-357.358 -251.209)"
          ></path>
          <path
            d="M154.877 326.972h-1.233v-.172a.176.176 0 10-.352 0v.176h-9.335a2.459 2.459 0 001.585.528h7.749v.352a.176.176 0 00.176.176h1.409a.528.528 0 100-1.057zm0 .7h-1.233v-.172h1.409l-.032-.095a.171.171 0 01.032.095.176.176 0 01-.176.177z"
            data-name="Path 13954"
            transform="translate(-141.794 -318.131)"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default KsaFlagIcon
