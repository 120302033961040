/*** hooks ***/
import { useRef, useState } from "react";
import useSWR from "swr";
import { useQueryParams } from "use-query-params";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
/*** utils ***/
import { generatePath, Link, useHistory } from "react-router-dom";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import routes from "../../routes";
/*** components ***/
import { BsSliders } from "react-icons/bs";
import { Button, Label, Select } from "semantic-ui-react";
import InstructorsListSearch from "./instructors-list-search";

const fetcher = (args) => authAxios.get(args).then((res) => res.data.data);
const getSearchLang = (val) => {
  const arabicReg = /[\u0621-\u064A]/g;
  const isArabic = arabicReg.test(val);
  return isArabic ? "ar" : "en";
};

const InstructorsListFilters = ({
  filterState: { selected, filterModal, setFilterModal, isViewed, setIsViewed },
}) => {
  const [lang] = useLanguage();
  const { push } = useHistory();
  const selectedContent = content[lang];
  const timeoutRef = useRef();
  const [value, setValue] = useState("");
  const [_, setQuery] = useQueryParams({
    name: "",
    lang: "",
  });
  const [detectedLang, setDetectedLang] = useState("");
  const { data: searchResults, isLoading } = useSWR(
    `/instructors?name=${value}&page=1&limit=10&lang=${getSearchLang(value)}`,
    fetcher
  );
  const handleSearchChange = (e, data) => {
    const lang = getSearchLang(data.value);
    setDetectedLang(lang);
    setValue(e.target.value);

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setQuery({ name: data.value, lang });
    }, 500);
  };

  const resultRenderer = (props) => {
    return (
      <Link
        to={{
          pathname: generatePath(routes.instructor.profilePage, {
            instructorId: props._id,
          }),
          state: { list: "instructors" },
        }}
        className="mx-auto"
      >
        <Label
          content={
            props?.name?.[detectedLang] || props?.name?.en || props?.name?.ar
          }
        />
      </Link>
    );
  };

  return (
    <div className="my-3 flex flex-row justify-between gap-4">
      <InstructorsListSearch
        loading={isLoading}
        onResultSelect={(e, item) => {
          push(
            generatePath(routes.instructor.profilePage, {
              instructorId: item.result._id,
            })
          );
        }}
        onSearchChange={handleSearchChange}
        value={value}
        resultRenderer={resultRenderer}
        results={searchResults?.filter((item) => item.viewed)}
      />
      <Button
        basic
        className="flex flex-row flex-nowrap items-center gap-2 ltr:ml-auto rtl:mr-auto"
        onClick={() =>
          setFilterModal({
            ...filterModal,
            initSelect: selected,
            open: true,
          })
        }
      >
        <span>
          <BsSliders size={20} className="rotate-90 hover:cursor-pointer" />
        </span>
        <span className="hidden font-bold md:inline">
          {selectedContent[localizationKeys.filterBySubject]}
        </span>
      </Button>
      <Select
        className="z-[15] hidden md:block"
        text={
          <>
            <span className="font-bold">
              {selectedContent[localizationKeys.view]}:
            </span>{" "}
            <span>{selectedContent[localizationKeys[isViewed]]}</span>
          </>
        }
        options={[
          {
            key: "all",
            value: "all",
            text: selectedContent[localizationKeys.all],
          },
          {
            key: "isViewed",
            value: "isViewed",
            text: selectedContent[localizationKeys.isViewed],
          },
          {
            key: "isNotViewed",
            value: "isNotViewed",
            text: selectedContent[localizationKeys.isNotViewed],
          },
        ].filter((item) => item.value !== isViewed)}
        onChange={(e, data) => {
          setIsViewed(data.value);
        }}
      />
    </div>
  );
};

export default InstructorsListFilters;
