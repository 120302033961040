import React from "react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import toast from "react-hot-toast";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../routes";
import { Loader } from "semantic-ui-react";
import api from "../../api";
import { truncateString } from "../../utils/string";
import PaginationComponent from "../../components/shared/pagination";
import useFilter from "../../lib/use-filter";
import useMediaQuery from "../../components/hooks/use-media-query";
import NewApplicantNotificationIcon from "../../assets/icons/new-applicant-notfication-icon";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import NotificationsIcon from "../../assets/icons/notifications-icon";

const AllNotifications = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const { run, isLoading } = useAxios({ data: [] });
  const history = useHistory();

  const [limit] = React.useState("10");
  const [totalPages, setTotalPages] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [page, setPage] = useFilter("page", 1);

  const location = useLocation();
  const queryString = require("query-string");
  const parsed = queryString.parse(location.search);

  const isSmall = useMediaQuery("(max-width: 776px)");

  React.useEffect(() => {
    if (page < 1) setPage(1);
    if (page && page !== "undefined")
      run(
        authAxios.get(
          `${api.notifications.all}?limit=${limit}&page=${parsed?.page}`
        )
      )
        .then(({ data }) => {
          setTotalPages(data?.totalPages);
          // if (page > data?.totalPages) setPage(1);
          if (page > data?.totalPages && data?.totalPages !== 0) setPage(1);
          setData(data);
        })
        .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, parsed?.page, run, lang]);

  return (
    <div>
      {!isSmall && (
        <div className="-mt-4 text-xl font-bold">
          {selectedContent[localizationKeys.notifications]}
        </div>
      )}
      {isLoading ? (
        <Loader active={true} />
      ) : data?.data?.length > 0 ? (
        <>
          <div className="relative mt-4 mb-10 min-h-[36rem] rounded-xl bg-white px-2 py-1 ">
            {isSmall ? (
              data?.data?.length > 0 ? (
                data?.data?.map((d) => (
                  <>
                    <div
                      className="cursor-pointer p-4 text-sm hover:rounded-lg hover:bg-gray-50"
                      onClick={() =>
                        history.push(
                          routes.employmentApplications.job(
                            d?.vacancy,
                            d?.instructor
                          )
                        )
                      }
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <NewApplicantNotificationIcon className="h-8 w-8 ltr:mr-1.5 rtl:ml-1.5" />
                          <div className="text-[#9B9B9B]">{d?.title}</div>
                        </div>
                        <div className="text-sm">
                          <div className="flex items-center text-gray-500">
                            <RiCheckboxBlankCircleFill className="mx-1 text-secondary-300" />
                            <span className="flex items-center gap-1">
                              <span>{moment(d?.createdAt).format("L")}</span>,
                              <span>{moment(d?.createdAt).format("LT")}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="text-primary mt-2">{d?.body}</div>
                      <div className="mt-1 font-medium text-black">
                        {d?.content}
                      </div>
                    </div>
                    <hr className=" mx-4" />
                  </>
                ))
              ) : (
                <div className="p-20 text-center text-lg text-gray-700 ">
                  <NotificationsIcon className="mx-auto mb-2 h-12 w-12 fill-primary-300" />
                  {selectedContent[localizationKeys.notificationsNotFound]}
                </div>
              )
            ) : (
              <>
                <div className="my-4 grid w-full grid-cols-4 rounded-md bg-gray-100 p-2 text-[#878787]">
                  <div className="ltr:ml-10 rtl:mr-10">
                    {selectedContent[localizationKeys.notification]}
                  </div>
                  <div className="col-span-2 ltr:ml-16 rtl:mr-16">
                    {selectedContent[localizationKeys.body]}
                  </div>
                  <div className="ltr:ml-16 rtl:mr-16">
                    {selectedContent[localizationKeys.dateAndTime]}
                  </div>
                </div>
                {data?.data?.map((d) => (
                  <div
                    className="grid w-full cursor-pointer grid-cols-4 items-center py-2
                transition-all duration-300 ease-in hover:-translate-y-1 hover:rounded-lg hover:shadow-sm"
                    key={d._id}
                    onClick={() =>
                      history.push(
                        routes.employmentApplications.job(
                          d?.vacancy,
                          d?.instructor
                        )
                      )
                    }
                  >
                    <div className="ltr:ml-10 rtl:mr-10">
                      {truncateString(d?.title, 20)}
                    </div>
                    <div className="col-span-2 ltr:ml-16 rtl:mr-16">
                      <span className="inline ltr:mr-1 rtl:ml-1">
                        {d?.body} {selectedContent[localizationKeys.from]}
                      </span>
                      <span className="inline text-primary-900">
                        "{d?.content}"
                      </span>
                    </div>
                    <div className="text-center ltr:ml-10 rtl:mr-10">
                      <span className="flex items-center gap-1">
                        <span>{moment(d?.createdAt).format("L")}</span>,
                        <span>{moment(d?.createdAt).format("LT")}</span>
                      </span>
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className="mt-20 flex items-center justify-center">
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                className="absolute bottom-0 my-8 rtl:flex-row-reverse"
              />
            </div>
          </div>
        </>
      ) : (
        <div className="mt-6 flex h-[33rem] items-center justify-center rounded-xl bg-white px-2 py-1">
          <div className="p-20 text-center text-lg text-gray-700 ">
            <NotificationsIcon className="mx-auto mb-2 h-12 w-12 fill-primary-300" />
            {selectedContent[localizationKeys.notificationsNotFound]}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllNotifications;
