import React from "react";

function QuestionMarkIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 15.271 15.271"
    >
      <g opacity="0.77" transform="translate(.1 .1)">
        <g data-name="Group 22594">
          <path
            fill="#177ba3"
            stroke="#177ba3"
            strokeWidth="0.2"
            d="M7.535 15.071A7.535 7.535 0 100 7.535a7.552 7.552 0 007.535 7.536zm0-14.188A6.652 6.652 0 11.883 7.535 6.66 6.66 0 017.535.883z"
            data-name="Path 18326"
          ></path>
        </g>
        <g data-name="Group 22595" transform="translate(5.328 3.562)">
          <path
            fill="#177ba3"
            stroke="#177ba3"
            strokeWidth="0.2"
            d="M2.208 6.181a.442.442 0 00.442-.442v-.473a2.216 2.216 0 00-1.1-1.912 1.325 1.325 0 111.987-1.148.442.442 0 00.883 0 2.208 2.208 0 10-3.312 1.912 1.33 1.33 0 01.663 1.148v.473a.442.442 0 00.437.442z"
            data-name="Path 18327"
          ></path>
        </g>
        <g data-name="Group 22596" transform="translate(7.192 10.692)">
          <circle
            cx="0.422"
            cy="0.422"
            r="0.422"
            fill="#177ba3"
            stroke="#177ba3"
            strokeWidth="0.2"
            data-name="Ellipse 210"
          ></circle>
        </g>
      </g>
    </svg>
  );
}

export default QuestionMarkIcon;
