import classNames from "classnames";

const StyledIcon = ({ icon: Icon, ...props }) => {
  return (
    <span
      className={classNames(
        "flex h-6 w-6 items-center justify-center",
        {
          "text-primary-900": !props.color,
          [`${props.color}`]: !!props.color,
        },
        {
          [props.className]: !!props.className,
        }
      )}
    >
      <Icon className="h-5 w-5" />
    </span>
  );
};

export default StyledIcon;
